import React, { PureComponent } from 'react';

interface ReservationLinkProps {
  reservation: { id: string; url: string; booking: { bookingNr: string } };
}

export default class ReservationLink extends PureComponent<ReservationLinkProps> {
  render() {
    const { id, url, booking } = this.props.reservation;

    return (
      <a key={id} href={url} target="_blank" rel="noopener noreferrer">
        {booking.bookingNr}
      </a>
    );
  }
}
