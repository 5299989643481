import React from 'react';
import LocaleDate from '../locale-date';
import EventPopover from '../event-popover';
import Translation from '../translation';

interface AgendaPeriodEventPopoverProps {
  title: string;
  startDate: string;
  endDate: string;
  renderBadge: () => React.ReactNode;
}

export default function AgendaPeriodEventPopover({ title, startDate, endDate, renderBadge }: AgendaPeriodEventPopoverProps) {
  return (
    <Translation>
      {(translate) => (
        <EventPopover>
          <EventPopover.Header title={title} />
          <EventPopover.Divider />
          <EventPopover.Details>
            <EventPopover.Detail description={translate('Channel')} value={renderBadge()} />
          </EventPopover.Details>
          <EventPopover.Divider />
          <EventPopover.Details>
            <EventPopover.Detail description={translate('Start date')} value={<LocaleDate date={startDate} format="dddd D MMMM" />} />
            <EventPopover.Detail description={translate('End date')} value={<LocaleDate date={endDate} format="dddd D MMMM" />} />
          </EventPopover.Details>
        </EventPopover>
      )}
    </Translation>
  );
}
