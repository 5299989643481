import React from 'react';
import NotEnoughStockAgendaPeriodSidebar from './NotEnoughStockAgendaPeriodSidebar';
import NotEnoughStockAgendaPeriodSidebarQuery from './NotEnoughStockAgendaPeriodSidebarQuery';
import { AgendaPeriodSidebarPlaceholder } from '../agenda-period-sidebar';
import { SidebarErrorMessage } from '../sidebar';

interface NotEnoughStockAgendaPeriodSidebarContainerProps {
  notEnoughStockAgendaPeriodId: string;
  onCloseButtonClick: () => void;
}

export default function NotEnoughStockAgendaPeriodSidebarContainer({
  notEnoughStockAgendaPeriodId,
  ...rest
}: NotEnoughStockAgendaPeriodSidebarContainerProps) {
  return (
    <NotEnoughStockAgendaPeriodSidebarQuery notEnoughStockAgendaPeriodId={notEnoughStockAgendaPeriodId}>
      {({ data, loading, error }) => {
        if (error) {
          return <SidebarErrorMessage error={error} {...rest} />;
        }
        if (loading) {
          return <AgendaPeriodSidebarPlaceholder {...rest} />;
        } else if (data) {
          return <NotEnoughStockAgendaPeriodSidebar notEnoughStockAgendaPeriod={data.notEnoughStockAgendaPeriod} {...rest} />;
        } else {
          return null;
        }
      }}
    </NotEnoughStockAgendaPeriodSidebarQuery>
  );
}
