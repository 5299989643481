import React, { PureComponent } from 'react';
import MoveActionOverlay from './MoveActionOverlay';
import { connect, ConnectedProps } from 'react-redux';
import * as sidebarPanelActions from '../../actions/sidebarPanel';
import * as moveActions from '../../actions/moveAction';
// tslint:disable-next-line:no-duplicate-imports
import { SidebarType } from '../../actions/sidebarPanel';
import { State } from '../../reducers';
import { Moment } from 'moment';
import isSidebarOpened from '../../utils/isSidebarOpened';

interface OwnProps {
  calculateOffset: (args: { date: Moment }) => number;
}

interface StateProps {
  isVisible: boolean;
  startDate?: string;
  endDate?: string;
}

const mapStateToProps = (state: State): StateProps => {
  const { moveAction, sidebarPanel } = state;

  if (
    moveAction.state !== 'inactive' &&
    isSidebarOpened(sidebarPanel, SidebarType.ReservationAgendaPeriod, moveAction.reservationAgendaPeriodId)
  ) {
    return { isVisible: true, startDate: moveAction.startDate, endDate: moveAction.endDate };
  } else {
    return { isVisible: false };
  }
};

const mapDispatchToProps = {
  closeSidebarPanel: sidebarPanelActions.closeSidebarPanel,
  deactivateMoveAction: moveActions.deactivateMoveAction,
};

type MoveActionOverlayContainerProps = ConnectedProps<typeof connector> & OwnProps;

class MoveActionOverlayContainer extends PureComponent<MoveActionOverlayContainerProps> {
  handleClick = () => {
    this.props.closeSidebarPanel();
    this.props.deactivateMoveAction();
  };

  render() {
    const { isVisible, startDate, endDate, ...props } = this.props;

    if (isVisible) {
      return <MoveActionOverlay startDate={startDate!} endDate={endDate!} {...props} onClick={this.handleClick} />;
    } else {
      return null;
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MoveActionOverlayContainer);
