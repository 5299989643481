import React, { PureComponent } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import AgendaPeriodEventItem from './AgendaPeriodEventItem';
import { State } from '../../reducers';
import { DisplayMode } from '../../actions/displayMode';

interface OwnProps {
  title: string;
  className: string;
  isActive: boolean;
  isIndented: boolean;
  renderPopover: () => React.ReactNode;
}

interface StateProps {
  isDefaultDisplayMode: boolean;
  isBookingMode: boolean;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state, _ownProps) => {
  return { isDefaultDisplayMode: state.displayMode === DisplayMode.Default, isBookingMode: !!state.bookingMode.booking };
};

class AgendaPeriodEventItemContainer extends PureComponent<StateProps & OwnProps> {
  get className() {
    const { className, isDefaultDisplayMode, isBookingMode } = this.props;

    return !isDefaultDisplayMode || isBookingMode ? `${className} filter-grayscale` : className;
  }

  render() {
    const { title, isActive, isIndented, renderPopover } = this.props;

    return (
      <AgendaPeriodEventItem
        title={title}
        className={this.className}
        isActive={isActive}
        isIndented={isIndented}
        renderPopover={renderPopover}
      />
    );
  }
}

export default connect(mapStateToProps)(AgendaPeriodEventItemContainer);
