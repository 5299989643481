import React, { Component } from 'react';
import LaunchbarQueryData from './LaunchbarQueryData';
import logo from './logo.png';
import invoke from 'lodash/invoke';
import classes from './Launchbar.module.css';
import Link from '../link';
import { Icon, HotkeysTarget2 } from '@blueprintjs/core';
import SettingsDialog from '../settings-dialog';
import LegendDialog from '../legend-dialog';
import Translation, { withTranslation, WithTranslation } from '../translation';
import { showSuccessToast, showErrorToast } from '../toaster';
import { encode } from 'js-base64';
import { State } from '../../reducers';
import { connect, MapStateToProps } from 'react-redux';
import applicationEnvironment from '../../utils/applicationEnvironment';

interface OwnProps {
  data: LaunchbarQueryData;
  parkId?: string;
  onHotkeysLinkClick: () => void;
}

interface StateProps {
  pathname: string;
  queries: any;
}

type LaunchbarProps = OwnProps & StateProps;

interface LaunchbarState {
  settingsDialogIsOpen: boolean;
  legendDialogIsOpen: boolean;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state, ownProps) => {
  return {
    ...ownProps,
    pathname: state.router.pathname,
    queries: state.router.queries,
  };
};

class Launchbar extends Component<LaunchbarProps & WithTranslation, LaunchbarState> {
  state = { settingsDialogIsOpen: false, legendDialogIsOpen: false };

  get bookingExpertsUrl() {
    const { parkId } = this.props;
    const { bookingexpertsUrl } = this.props.data.planboard;

    return parkId === undefined ? bookingexpertsUrl : `${bookingexpertsUrl}parks/${parkId}/dashboard`;
  }

  get parkCMSUrl() {
    return this.props.data.planboard.parkcmsUrl;
  }

  generateSupportLinkUrl = () => {
    const currentPath = this.props.pathname;
    const landingPageRegExp = /^\/planboard\/?$/;
    const parkPageRegExp = /^(\/planboard\/parks\/)(\d+)\/?$/;

    // We only generate support links for the planboard landing page and the park pages, without any sidebar
    if (landingPageRegExp.test(currentPath) || parkPageRegExp.test(currentPath)) {
      const { supportLinkBaseUrl } = this.props.data.planboard;

      // Create support link as an absolute URL because apparently you cannot create relative URLs...
      const supportLink = new URL(document.location.origin);

      // Interpolate park ID if present
      supportLink.pathname = currentPath.replace(parkPageRegExp, '$1~park_id~');

      // Add display_mode query parameter to support link if present
      const displayMode = this.props.queries.display_mode;

      if (displayMode) {
        supportLink.searchParams.append('display_mode', displayMode);
      }

      // Make support link a relative URL and encode it using URL safe Base 64 encoding
      const encodedSupportLink = encode(supportLink.href.substring(supportLink.origin.length), true);

      // Now, build the actual support link URL
      const supportLinkUrl = new URL(supportLinkBaseUrl);

      supportLinkUrl.pathname += encodedSupportLink;

      return supportLinkUrl.href;
    } else {
      return null;
    }
  };

  handleChatLinkClick = () => {
    invoke(window, 'Beacon', 'toggle');
  };

  handleLegendLinkClick = () => {
    this.setState({ legendDialogIsOpen: true });
  };

  handleLegendDialogClose = () => {
    this.setState({ legendDialogIsOpen: false });
  };

  handleSettingsLinkClick = () => {
    this.setState({ settingsDialogIsOpen: true });
  };

  handleSettingsDialogClose = () => {
    this.setState({ settingsDialogIsOpen: false });
  };

  handleChatHotkey = () => {
    invoke(window, 'Beacon', 'toggle');
  };

  handleLegendHotkey = () => {
    this.setState(({ legendDialogIsOpen }) => ({ legendDialogIsOpen: !legendDialogIsOpen }));
  };

  handleSettingsHotkey = () => {
    this.setState(({ settingsDialogIsOpen }) => ({ settingsDialogIsOpen: !settingsDialogIsOpen }));
  };

  renderLogo() {
    return (
      <a href="/" className="flex items-center px-2">
        <img src={logo} className={classes.logo} alt="Planboard logo" />
      </a>
    );
  }

  renderBookingExpertsLink() {
    return (
      <a href={this.bookingExpertsUrl} className={classes.link}>
        BEX PMS
      </a>
    );
  }

  renderBEXCMSLink() {
    return (
      <a href={this.parkCMSUrl} className={classes.link}>
        BEX CMS
      </a>
    );
  }

  renderPlanboardLink() {
    return (
      <Link to={process.env.PUBLIC_URL} className={classes['link--active']}>
        Planbord
      </Link>
    );
  }

  renderSpacer() {
    return <span className="flex-grow" />;
  }

  renderDivider() {
    return <span className="border-dotted border-bp4-gray3 border-l border-r m-2" style={{ width: 3 }} />;
  }

  renderEnvironmentBadge() {
    if (applicationEnvironment() === 'production') {
      return null;
    }

    return <span className={classes[`badge--${applicationEnvironment()}`]}>{applicationEnvironment()}</span>;
  }

  renderHotkeysLink() {
    const { t: translate } = this.props;

    return (
      <div className={classes['link--menu']} onClick={this.props.onHotkeysLinkClick}>
        <Icon icon="key-command" iconSize={12} />
        <span className="ml-2">{translate('Hotkeys')}</span>
      </div>
    );
  }

  renderLegendLink() {
    const { t: translate } = this.props;

    return (
      <div className={classes['link--menu']} onClick={this.handleLegendLinkClick}>
        <Icon icon="application" iconSize={12} />
        <span className="ml-2">{translate('Legend')}</span>
      </div>
    );
  }

  renderLegendDialog() {
    return <LegendDialog isOpen={this.state.legendDialogIsOpen} onClose={this.handleLegendDialogClose} />;
  }

  renderChatLink() {
    const { t: translate } = this.props;

    return (
      <div className={classes['link--menu']} onClick={this.handleChatLinkClick}>
        <Icon icon="chat" iconSize={12} />
        <span className="ml-2">{translate('Chat with support')}</span>
      </div>
    );
  }

  renderSettingsLink() {
    const { t: translate } = this.props;

    return (
      <div className={classes['link--menu']} onClick={this.handleSettingsLinkClick}>
        <Icon icon="cog" iconSize={12} />
        <span className="ml-2">{translate('Settings')}</span>
      </div>
    );
  }

  renderProfileLink() {
    const { user } = this.props.data;

    return (
      <a href={user.editUrl} className={classes['link--menu']}>
        <Icon icon="person" iconSize={12} />
        <span className="ml-2">{user.name}</span>
      </a>
    );
  }

  renderSettingsDialog() {
    return <SettingsDialog isOpen={this.state.settingsDialogIsOpen} onClose={this.handleSettingsDialogClose} />;
  }

  renderCopySupportLinkUrlLink(supportLinkUrl: string | null) {
    const { t: translate } = this.props;

    return (
      <button
        onClick={(e) => this.handleCopySupportLinkUrlLinkClick(supportLinkUrl, e)}
        title={translate('Copy support link')}
        className={classes['link--menu']}
      >
        <Icon icon="duplicate" iconSize={12} />
      </button>
    );
  }

  handleCopySupportLinkUrlLinkClick = (supportLinkUrl: string | null, e: React.MouseEvent) => {
    e.preventDefault();

    const { t: translate } = this.props;

    if (supportLinkUrl) {
      navigator.clipboard.writeText(supportLinkUrl).then(
        () => showSuccessToast({ message: translate('Support link copied!') }),
        () => showErrorToast({ message: translate('Support link could not be copied.') })
      );
    }
  };

  private hotkeys = [
    {
      label: <Translation>{(translate) => translate('Show chat')}</Translation>,
      combo: 'c',
      group: 'Menu',
      global: true,
      preventDefault: true,
      onKeyDown: this.handleChatHotkey,
    },
    {
      label: <Translation>{(translate) => translate('Show/hide hotkeys')}</Translation>,
      combo: '?',
      group: 'Menu',
      global: true,
      preventDefault: true,
    },
    {
      label: <Translation>{(translate) => translate('Show/hide legend')}</Translation>,
      combo: 'l',
      group: 'Menu',
      global: true,
      preventDefault: true,
      onKeyDown: this.handleLegendHotkey,
    },
    {
      label: <Translation>{(translate) => translate('Show/hide settings')}</Translation>,
      combo: 's',
      group: 'Menu',
      global: true,
      preventDefault: true,
      onKeyDown: this.handleSettingsHotkey,
    },
  ];

  render() {
    const { isAdminRole } = this.props.data.role;

    let supportLinkUrl: string | null = null;
    let shouldRenderCopySupportLinkUrlLink = false;

    if (isAdminRole) {
      supportLinkUrl = this.generateSupportLinkUrl();

      shouldRenderCopySupportLinkUrlLink = !!supportLinkUrl;
    }

    return (
      <HotkeysTarget2 hotkeys={this.hotkeys}>
        {({ handleKeyDown, handleKeyUp }) => (
          <nav className={classes.root} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
            {this.renderLogo()}
            {this.renderBookingExpertsLink()}
            {this.renderBEXCMSLink()}
            {this.renderPlanboardLink()}
            {this.renderEnvironmentBadge()}
            {this.renderSpacer()}
            {shouldRenderCopySupportLinkUrlLink && this.renderCopySupportLinkUrlLink(supportLinkUrl)}
            {shouldRenderCopySupportLinkUrlLink && this.renderDivider()}
            {this.renderChatLink()}
            {this.renderDivider()}
            {this.renderHotkeysLink()}
            {this.renderLegendLink()}
            {this.renderLegendDialog()}
            {this.renderSettingsLink()}
            {this.renderSettingsDialog()}
            {this.renderDivider()}
            {this.renderProfileLink()}
          </nav>
        )}
      </HotkeysTarget2>
    );
  }
}

export default connect(mapStateToProps)(withTranslation()(Launchbar));
