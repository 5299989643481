import { Component } from 'react';
import ParkSwitcherPlaceholder from './ParkSwitcherPlaceholder';
import ParkSwitcher from './ParkSwitcher';
import ParkSwitcherQuery from './ParkSwitcherQuery';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push as pushAction } from 'redux-first-routing';
import { SearchParams } from '../../config/planboard';

interface ParkSwitcherContainerProps {
  parkId: string;
  dispatch: Dispatch;
}

const EMPTY_ARRAY: [] = [];

class ParkSwitcherContainer extends Component<ParkSwitcherContainerProps> {
  handleChange = (nextPark: { id: string; organizationId: string }, previousPark?: { organizationId: string }) => {
    const { dispatch } = this.props;

    const previousSearchParams = new URLSearchParams(window.location.search);
    const previousDate = previousSearchParams.get(SearchParams.DATE);
    const previousDisplayMode = previousSearchParams.get(SearchParams.DISPLAY_MODE);

    const nextSearchParams = new URLSearchParams('');

    if (previousDate) {
      // Always keep date when switching parks
      nextSearchParams.set(SearchParams.DATE, previousDate);
    }

    if (previousDisplayMode) {
      // Always keep display mode when switching parks
      nextSearchParams.set(SearchParams.DISPLAY_MODE, previousDisplayMode);
    }

    // Keep filter panel query params as well when switching parks within the same organization
    if (previousPark && previousPark.organizationId === nextPark.organizationId) {
      const previousFilter = previousSearchParams.get(SearchParams.FILTER);
      const previousGuestGroup = previousSearchParams.get(SearchParams.GUEST_GROUP);
      const previousRentableTypes = previousSearchParams.get(SearchParams.RENTABLE_SEGMENTS);
      const previousLabels = previousSearchParams.get(SearchParams.LABELS);
      const previousAmenities = previousSearchParams.get(SearchParams.AMENITIES);

      if (previousFilter) {
        nextSearchParams.set(SearchParams.FILTER, previousFilter);
      }

      if (previousGuestGroup) {
        nextSearchParams.set(SearchParams.GUEST_GROUP, previousGuestGroup);
      }

      if (previousRentableTypes) {
        nextSearchParams.set(SearchParams.RENTABLE_SEGMENTS, previousRentableTypes);
      }

      if (previousLabels) {
        nextSearchParams.set(SearchParams.LABELS, previousLabels);
      }

      if (previousAmenities) {
        nextSearchParams.set(SearchParams.AMENITIES, previousAmenities);
      }
    }

    const search = nextSearchParams.toString();
    const to = { pathname: `${process.env.PUBLIC_URL}/parks/${nextPark.id}`, search };

    dispatch(pushAction(to));
  };

  render() {
    const { parkId } = this.props;

    return (
      <ParkSwitcherQuery>
        {({ data, loading }) => {
          if (loading) {
            return <ParkSwitcherPlaceholder />;
          } else {
            return <ParkSwitcher selectedParkId={parkId} parks={data ? data.parks : EMPTY_ARRAY} onChange={this.handleChange} />;
          }
        }}
      </ParkSwitcherQuery>
    );
  }
}

export default connect()(ParkSwitcherContainer);
