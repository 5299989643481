import React from 'react';
import TakeOverIcalAgendaPeriodForm from './TakeOverIcalAgendaPeriodForm';
import TakeOverIcalAgendaPeriodMutation, { TakeOverFn, Result } from './TakeOverIcalAgendaPeriodMutation';

interface TakeOverIcalAgendaPeriodFormContainerProps {
  icalAgendaPeriodId: string;
}

export default function TakeOverIcalAgendaPeriodFormContainer({ icalAgendaPeriodId }: TakeOverIcalAgendaPeriodFormContainerProps) {
  return (
    <TakeOverIcalAgendaPeriodMutation>
      {(takeOver: TakeOverFn, result: Result) => (
        <TakeOverIcalAgendaPeriodForm icalAgendaPeriodId={icalAgendaPeriodId} takeOver={takeOver} isLoading={result.loading} />
      )}
    </TakeOverIcalAgendaPeriodMutation>
  );
}
