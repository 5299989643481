import UAParser from 'ua-parser-js';

export default function isSupportedBrowser() {
  const supportedBrowsers = [
    { name: 'Chrome', minimumVersion: 56 },
    { name: 'Firefox', minimumVersion: 59 },
    { name: 'Safari', minimumVersion: undefined },
    { name: 'Edge', minimumVersion: 79 },
  ];

  const { browser } = new UAParser().getResult();

  return supportedBrowsers.some(({ name, minimumVersion }) => {
    return !!(browser.name === name && (minimumVersion === undefined || (browser.major && parseInt(browser.major, 10) >= minimumVersion)));
  });
}
