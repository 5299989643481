import { Boundary } from 'popper.js';

export const defaultPopoverProps = {
  minimal: true,
  targetClassName: 'w-full',
  modifiers: {
    flip: { boundariesElement: 'window' as Boundary, padding: 5 },
    preventOverflow: { boundariesElement: 'window' as Boundary },
  },
};
