import { Component } from 'react';

interface HelpScoutProps {
  user: {
    name: string;
    email: string;
    helpScoutBeaconId: string;
    helpScoutSignature: string;
  };
}

export default class HelpScout extends Component<HelpScoutProps> {
  componentDidMount() {
    const { name, email, helpScoutBeaconId, helpScoutSignature } = this.props.user;

    window.Beacon('init', helpScoutBeaconId);
    window.Beacon('config', { display: { style: 'manual' }, color: '#0ea060' });
    window.Beacon('identify', { name, email, signature: helpScoutSignature });
  }

  componentWillUnmount() {
    window.Beacon('destroy');
  }

  render() {
    return null;
  }
}
