import React, { PureComponent, CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import classes from './MoveActionCell.module.css';
import classNames from 'classnames';
import Translation from '../translation';

interface MoveActionCellProps {
  style: CSSProperties;
  isSelected: boolean;
  isBlocked: boolean;
  onClick: (e: React.MouseEvent) => void;
}

export default class MoveActionCell extends PureComponent<MoveActionCellProps> {
  // Stop this event from propagating to elements behind it (RentableCell)
  stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

  // tslint:disable-next-line member-ordering
  handleMouseMove = this.stopPropagation;

  // tslint:disable-next-line member-ordering
  handleMouseOver = this.stopPropagation;

  // tslint:disable-next-line member-ordering
  handleMouseOut = this.stopPropagation;

  // tslint:disable-next-line member-ordering
  handleMouseDown = this.stopPropagation;

  // tslint:disable-next-line member-ordering
  handleMouseUp = this.stopPropagation;

  render() {
    const { style, isSelected, isBlocked, onClick } = this.props;
    const className = classNames(classes.root, { [classes['root--selected']]: isSelected, [classes['root--blocked']]: isBlocked });

    return (
      <div
        className={className}
        style={style}
        onMouseMove={this.handleMouseMove}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onClick={isBlocked ? this.stopPropagation : onClick}
      >
        <div className={classes.item}>
          <FontAwesomeIcon icon={faPlus} className="text-xs mr-1" />
          <Translation>{(translate) => <span className="truncate">{translate('Select…')}</span>}</Translation>
        </div>
      </div>
    );
  }
}
