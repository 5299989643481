import React, { PureComponent, RefObject } from 'react';
import { Moment } from 'moment';
import Grid from './Grid';
import GridQueryData from './GridQueryData';

interface DefaultGridProps {
  gridRef: RefObject<Grid>;
  parkId: string;
  data: GridQueryData;
  isReloading: boolean;
  onDateChange: (date: Moment) => void;
}

export default class DefaultGrid extends PureComponent<DefaultGridProps> {
  render() {
    const { gridRef, ...props } = this.props;
    return <Grid ref={gridRef} {...props} />;
  }
}
