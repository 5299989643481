import React from 'react';
import { Translation as OriginalTranslation, Namespace, withTranslation as originalWithTranslation } from 'react-i18next';
import { TOptions, i18n } from 'i18next';
import { TranslationKey } from '../../config/i18n';

export type TranslateFunction = (key: TranslationKey, options?: TOptions) => string;

interface TranslationProps {
  children: (t: TranslateFunction, options: { i18n: i18n; lng: string }) => React.ReactNode;
  ns?: Namespace;
  i18n?: i18n;
}

interface I18n extends Omit<i18n, 't'> {
  t: TranslateFunction;
}

export interface WithTranslation {
  t: TranslateFunction;
  i18n: I18n;
}

/**
 * Mimics react-i18next's Translation component. Asserts `key` is a translation key.
 */
export default function Translation(props: TranslationProps) {
  return <OriginalTranslation {...props} />;
}

/**
 * Mimics react-i18next's withTranslation HOC (higher-order component). Asserts `key` is a translation key.
 */
export function withTranslation(
  ns?: Namespace
): <P extends WithTranslation>(component: React.ComponentType<P>) => React.ComponentType<Omit<P, keyof WithTranslation>> {
  return originalWithTranslation(ns) as any;
}
