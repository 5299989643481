import { DateRange } from 'moment-range';
import { CSSProperties } from 'react';
import memoize from 'lodash/memoize';
import { CELL_WIDTH } from '../grid';
import moment, { Moment } from 'moment';
import serializeDate from '../../utils/serializeDate';

interface CalculateRentableCellStyle {
  rentable: { activeFrom: Moment | string; activeTill: Moment | string | null };
  period: DateRange;
}

function calculateRentableCellStyle({ rentable, period }: CalculateRentableCellStyle): CSSProperties {
  const { activeFrom, activeTill } = rentable;

  const daysBefore = Math.max(0, -period.start.diff(activeFrom, 'days'));
  const daysAfter = activeTill && moment(activeTill).isBefore(period.end) ? Math.max(period.end.diff(activeTill, 'days') + 0.5, 0.5) : 0;

  return {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: daysBefore * CELL_WIDTH,
    right: daysAfter * CELL_WIDTH,
  };
}

const resolveCacheKey = ({ rentable, period }: CalculateRentableCellStyle) =>
  [
    serializeDate(rentable.activeFrom),
    rentable.activeTill && serializeDate(rentable.activeTill),
    serializeDate(period.start),
    serializeDate(period.end),
  ].join('-');

export default memoize(calculateRentableCellStyle, resolveCacheKey);
