export enum Layout {
  Nested = 'nested',
  OrderByObject = 'order_by_object',
  OrderByType = 'order_by_type',
  Flat = 'flat',
}

export default interface SettingsDialogQueryData {
  user: {
    id: string;
    locale: string;
    planboardStartDate: string | null;
    planboardLayout: Layout;
  };
}
