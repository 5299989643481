import formatReservation from './formatReservation';

interface BlockedByGroupedRentableTypeAgendaPeriod {
  blockingReservation: {
    booking: {
      referenceNr: string;
      bookingNr: string;
      firstName: string;
      lastName: string;
      company: string;
    };
  };
}

export default function formatBlockedByGroupedRentableTypeAgendaPeriod(
  blockedByGroupedRentableTypeAgendaPeriod: BlockedByGroupedRentableTypeAgendaPeriod
) {
  return formatReservation(blockedByGroupedRentableTypeAgendaPeriod.blockingReservation);
}
