import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import GridQueryData from './GridQueryData';
import deserializeIds from '../../utils/deserializeIds';
import { SLOW_QUERY_POLL_INTERVAL } from '../../config/planboard';
import deserializeObject from '../../utils/deserializeObject';
const gridQuery = loader('./gridQuery.graphql');

interface GuestGroup {
  [key: string]: string;
}

interface GridQueryProps {
  parkId: string;
  selectedGuestGroup: string;
  selectedRentableSegmentIds: string;
  selectedRentableTypeIds: string;
  selectedRentableIdentityLabelIds: string;
  selectedAmenityIds: string;
  availabilityFilterStartDate?: string;
  availabilityFilterEndDate?: string;
  searchQuery: string;
  onCompleted: (data: GridQueryData) => void;
  children: (result: QueryResult<GridQueryData, Variables>) => JSX.Element | null;
}

interface Variables {
  parkId: string;
  selectedGuestGroup: GuestGroup;
  selectedRentableSegmentIds: string[];
  selectedRentableTypeIds: string[];
  selectedRentableIdentityLabelIds: string[];
  selectedAmenityIds: string[];
  availabilityFilterStartDate?: string;
  availabilityFilterEndDate?: string;
  searchQuery: string;
}

export default function GridQuery({
  parkId,
  selectedGuestGroup,
  selectedRentableSegmentIds,
  selectedRentableTypeIds,
  selectedRentableIdentityLabelIds,
  selectedAmenityIds,
  availabilityFilterStartDate,
  availabilityFilterEndDate,
  searchQuery,
  onCompleted,
  children,
}: GridQueryProps) {
  const $selectedGuestGroup = deserializeObject(selectedGuestGroup);
  const $selectedRentableSegmentIds = deserializeIds(selectedRentableSegmentIds);
  const $selectedRentableTypeIds = deserializeIds(selectedRentableTypeIds);
  const $selectedRentableIdentityLabelIds = deserializeIds(selectedRentableIdentityLabelIds);
  const $selectedAmenityIds = deserializeIds(selectedAmenityIds);

  const variables = {
    parkId,
    selectedGuestGroup: $selectedGuestGroup,
    selectedRentableSegmentIds: $selectedRentableSegmentIds,
    selectedRentableTypeIds: $selectedRentableTypeIds,
    selectedRentableIdentityLabelIds: $selectedRentableIdentityLabelIds,
    selectedAmenityIds: $selectedAmenityIds,
    availabilityFilterStartDate,
    availabilityFilterEndDate,
    searchQuery,
  };

  return (
    <Query<GridQueryData, Variables>
      query={gridQuery}
      variables={variables}
      pollInterval={SLOW_QUERY_POLL_INTERVAL}
      fetchPolicy="cache-and-network"
      onCompleted={onCompleted}
    >
      {children}
    </Query>
  );
}
