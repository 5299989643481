import React, { Component } from 'react';
import { Menu, MenuDivider } from '@blueprintjs/core';
import ParkItem from './ParkItem';
import filterPark from '../../utils/filterPark';
import { ParkData } from './OrganizationListQueryData';
import Translation from '../translation';

interface StarredParkListProps {
  parks: ParkData[];
  query: string;
  onParkItemClick: (parkId: string) => void;
  onStarButtonClick: (parkId: string, isStarred: boolean) => void;
}

export default class StarredParksList extends Component<StarredParkListProps> {
  get filteredParks() {
    let { parks, query } = this.props;
    return parks.filter((park) => filterPark(query, park));
  }

  get hasFilteredParks() {
    let { parks, query } = this.props;
    return parks.some((park) => filterPark(query, park));
  }

  renderHeader() {
    return <Translation>{(translate) => <MenuDivider title={translate('Favorite parks')} />}</Translation>;
  }

  renderFilteredParks() {
    let { onParkItemClick, onStarButtonClick } = this.props;
    return this.filteredParks.map((park) => (
      <ParkItem key={park.id} park={park} isStarred onClick={onParkItemClick} onStarButtonClick={onStarButtonClick} />
    ));
  }

  renderDivider() {
    return <MenuDivider />;
  }

  renderNoResults() {
    return null;
  }

  render() {
    return this.hasFilteredParks ? (
      <Menu className="p-0">
        {this.renderHeader()}
        {this.renderFilteredParks()}
        {this.renderDivider()}
      </Menu>
    ) : (
      this.renderNoResults()
    );
  }
}
