enum Currency {
  EUR = '€',
  USD = '$',
  CHF = 'CHF',
  CZK = 'Kč',
  TRY = '₺',
  CVE = 'Esc',
}

export type CurrencyUnit = keyof typeof Currency;

export default function formatAmount(amount: number, currencyUnit: CurrencyUnit) {
  const currencySymbol = Currency[currencyUnit];

  const formattedAmount = amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${currencySymbol} ${formattedAmount}`;
}
