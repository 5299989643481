import React, { PureComponent } from 'react';
import Sidebar from '../sidebar';
import MemoSection from './MemoSection';
import SummarySection from './SummarySection';

interface AgendaPeriodSidebarProps {
  title: string;
  url: string;
  agendaPeriod: {
    memo?: string;
    startDate: string;
    endDate: string;
    rentableIdentity?: {
      id: string;
      name: string;
      rentableTypeName: string;
      url: string;
    };
  };
  renderBadge: () => React.ReactNode;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  onCloseButtonClick: () => void;
}

export default class AgendaPeriodSidebar extends PureComponent<AgendaPeriodSidebarProps> {
  render() {
    const { title, url, agendaPeriod, renderBadge, children, actions, onCloseButtonClick } = this.props;
    const { memo } = agendaPeriod;

    return (
      <Sidebar>
        <Sidebar.Header title={title} href={url} onCloseButtonClick={onCloseButtonClick} />
        <Sidebar.Content>
          <SummarySection agendaPeriod={agendaPeriod} renderBadge={renderBadge} />
          {memo && !children && <MemoSection memo={memo} />}
          {children}
        </Sidebar.Content>
        <Sidebar.Footer>{actions}</Sidebar.Footer>
      </Sidebar>
    );
  }
}
