import React from 'react';
import TOASTER from './Toaster';
import { Icon, Intent } from '@blueprintjs/core';

export default function showSuccessToast({ message }: { message: React.ReactNode }) {
  TOASTER.show({
    icon: <Icon icon="tick" iconSize={12} />,
    intent: Intent.SUCCESS,
    message,
  });
}
