import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import { BlockedByGroupedRentableTypeAgendaPeriodSidebarQueryData } from './BlockedByGroupedRentableTypeAgendaPeriodSidebarQueryData';
import { NORMAL_QUERY_POLL_INTERVAL } from '../../config/planboard';
const blockedByGroupedRentableTypeAgendaPeriodSidebarQuery = loader('./blockedByGroupedRentableTypeAgendaPeriodSidebarQuery.graphql');

interface BlockedByGroupedRentableTypeAgendaPeriodSidebarQueryProps {
  blockedByGroupedRentableTypeAgendaPeriodId: string;
  children: (result: QueryResult<BlockedByGroupedRentableTypeAgendaPeriodSidebarQueryData, Variables>) => JSX.Element | null;
}

interface Variables {
  blockedByGroupedRentableTypeAgendaPeriodId: string;
}

const BlockedByGroupedRentableTypeAgendaPeriodSidebarQuery = ({
  blockedByGroupedRentableTypeAgendaPeriodId,
  children,
}: BlockedByGroupedRentableTypeAgendaPeriodSidebarQueryProps) => (
  <Query<BlockedByGroupedRentableTypeAgendaPeriodSidebarQueryData, Variables>
    query={blockedByGroupedRentableTypeAgendaPeriodSidebarQuery}
    variables={{ blockedByGroupedRentableTypeAgendaPeriodId }}
    fetchPolicy="cache-and-network"
    pollInterval={NORMAL_QUERY_POLL_INTERVAL}
  >
    {children}
  </Query>
);

export default BlockedByGroupedRentableTypeAgendaPeriodSidebarQuery;
