import { PureComponent } from 'react';
import Grid from './Grid';
import { PERIODS } from '../../config/planboard';
import { RowType } from './GridQueryData';

interface CellProps {
  grid: Grid;
  rowIndex: number;
  columnIndex: number;
  isVisible: boolean;
  isScrolling: boolean;
  // Allow additional props to invalidate pure component
  [additionalProp: string]: any;
}

export default class Cell extends PureComponent<CellProps> {
  render(): React.ReactNode {
    const { grid, rowIndex, columnIndex, isScrolling, isVisible } = this.props;

    if (rowIndex === 0) {
      if (columnIndex === 0) {
        return grid.renderDateHeader();
      } else {
        const period = PERIODS[columnIndex - grid.fixedColumnCount];
        return grid.renderPeriodHeader(period);
      }
    } else {
      const row = grid.props.data.rows[rowIndex - grid.fixedRowCount];

      if (columnIndex === 0) {
        switch (row.type) {
          case RowType.RentableSegmentRow: {
            return grid.renderRentableSegmentHeader(row.rentableSegment);
          }

          case RowType.RentableTypeRow: {
            return grid.renderRentableTypeHeader(row.rentableType);
          }

          case RowType.RentableIdentitiesRow: {
            return grid.renderRentableIdentityHeaders(row.rentableIdentities);
          }

          case RowType.RentablesRow: {
            return grid.renderRentableHeaders(row.rentables);
          }
        }
      } else {
        const period = PERIODS[columnIndex - grid.fixedColumnCount];

        switch (row.type) {
          case RowType.RentableSegmentRow: {
            return grid.renderRentableSegmentCell(period, row.rentableSegment);
          }

          case RowType.RentableTypeRow: {
            return grid.renderRentableTypeCell(period, row.rentableType);
          }

          case RowType.RentableIdentitiesRow: {
            const cacheOnly = isScrolling || !isVisible;
            return grid.renderRentableIdentityCells(period, row.rentableIdentities, row.rentableTypeId, cacheOnly);
          }

          case RowType.RentablesRow: {
            const cacheOnly = isScrolling || !isVisible;
            return grid.renderRentableCells(period, row.rentables, cacheOnly);
          }
        }
      }
    }
  }
}
