import React from 'react';
import { connect } from 'react-redux';
import { push as pushLocationAction, replace as replaceLocationAction } from 'redux-first-routing';
import { Dispatch } from 'redux';

interface LinkProps {
  to: string;
  replace?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  dispatch: Dispatch;
  [key: string]: any;
}

// Copied from https://github.com/mksarge/redux-first-routing/blob/master/docs/recipes/building-your-own-connected-component.md#building-your-own-connected-component
function Link(props: LinkProps) {
  const { to, replace, onClick, children, dispatch, ...other } = props;

  const handleClick = (event: React.MouseEvent) => {
    // Ignore any click other than a left click
    if (
      (event.button && event.button !== 0) ||
      event.metaKey ||
      event.altKey ||
      event.ctrlKey ||
      event.shiftKey ||
      event.defaultPrevented === true
    ) {
      return;
    }

    // Prevent page reload
    event.preventDefault();

    // Execute onClick callback, if it exists
    if (onClick) {
      onClick(event);
    }

    // Dispatch the appropriate navigation action
    if (replace) {
      dispatch(replaceLocationAction(to));
    } else {
      dispatch(pushLocationAction(to));
    }
  };

  return (
    <a href={to} onClick={handleClick} {...other}>
      {children}
    </a>
  );
}

export default connect()(Link);
