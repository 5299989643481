import React from 'react';
import LocaleDate from '../locale-date';
import EventPopover from '../event-popover';
import formatReservation from '../../utils/formatReservation';
import ReservationChannelBadge from '../reservation-channel-badge';
import moment from 'moment';
import Translation from '../translation';
import { Intent, Tag } from '@blueprintjs/core';
import { TranslateFunction } from '../translation/Translation';

interface ReservationAgendaPeriodEventPopoverProps {
  reservationAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;

    reservation: {
      startDate: string;
      endDate: string;
      departureDate: string;
      lateCheckout: boolean;

      booking: {
        referenceNr: string;
        source: string;
        isOption: boolean;
        stateDescription: string;
        bookingNr: string;
        firstName: string;
        lastName: string;
        company: string;

        channel: {
          name: string;
        };
      };
    };
  };
}

function renderLateCheckoutDetail(translate: TranslateFunction) {
  return <EventPopover.Detail description={translate('Late check-out?')} value={<Tag intent={Intent.PRIMARY}>{translate('Yes')}</Tag>} />;
}

export default function ReservationAgendaPeriodEventPopover({ reservationAgendaPeriod }: ReservationAgendaPeriodEventPopoverProps) {
  const { reservation } = reservationAgendaPeriod;
  const { startDate, departureDate } = reservation;
  const lengthOfStay = moment(departureDate).diff(startDate, 'days');

  return (
    <Translation>
      {(translate) => (
        <EventPopover>
          <EventPopover.Header title={formatReservation(reservation)} />
          <EventPopover.Divider />
          <EventPopover.Details>
            <EventPopover.Detail description={translate('Status')} value={reservation.booking.stateDescription} />
            <EventPopover.Detail description={translate('Channel')} value={<ReservationChannelBadge reservation={reservation} />} />
          </EventPopover.Details>
          <EventPopover.Divider />
          <EventPopover.Details>
            <EventPopover.Detail
              description={translate('Duration')}
              value={lengthOfStay === 1 ? translate('1 night') : translate('{{lengthOfStay}} nights', { lengthOfStay })}
            />
            <EventPopover.Detail description={translate('Arrival')} value={<LocaleDate date={startDate} format="dddd D MMMM" />} />
            <EventPopover.Detail description={translate('Departure')} value={<LocaleDate date={departureDate} format="dddd D MMMM" />} />
            {reservation.lateCheckout && renderLateCheckoutDetail(translate)}
          </EventPopover.Details>
        </EventPopover>
      )}
    </Translation>
  );
}
