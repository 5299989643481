import Honeybadger from 'honeybadger-js';
import isSupportedBrowser from '../../utils/isSupportedBrowser';
import applicationEnvironment from '../../utils/applicationEnvironment';

Honeybadger.configure({
  apiKey: '16f00471',
  revision: process.env.REACT_APP_DEPLOY_REVISION,
  environment: applicationEnvironment(),
  disabled: !(process.env.NODE_ENV === 'production'),
  // To test this pattern:
  //   regex = /request: \[object Object\]/
  //   message = 'request: [object Object]'
  //   regex.test(message) // => true
  ignorePatterns: [/request: \[object Object\]/],
});

Honeybadger.beforeNotify(function (notice) {
  // Don't report errors in outdated browsers, or from other domains (like js.intercomcdn.com)
  return isSupportedBrowser() && notice?.stack?.includes('bookingexperts.nl');
});

export default Honeybadger;
