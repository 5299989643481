import React from 'react';
import StarredParkList from './StarredParkList';
import ListPlaceholder from './ListPlaceholder';
import StarredParkListQuery from './StarredParkListQuery';

interface StarredParkListContainerProps {
  parkIds: string[];
  query: string;
  onParkItemClick: (parkId: string) => void;
  onStarButtonClick: (parkId: string, isStarred: boolean) => void;
}

export default function StarredParkListContainer({ parkIds, query, onParkItemClick, onStarButtonClick }: StarredParkListContainerProps) {
  return (
    <StarredParkListQuery parkIds={parkIds}>
      {({ data, loading }) =>
        loading ? (
          <ListPlaceholder />
        ) : (
          <StarredParkList
            parks={data ? data.parks : []}
            query={query}
            onParkItemClick={onParkItemClick}
            onStarButtonClick={onStarButtonClick}
          />
        )
      }
    </StarredParkListQuery>
  );
}
