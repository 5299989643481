import React, { PureComponent, CSSProperties } from 'react';
import EventLink from '../event-link';
import NotEnoughStockAgendaPeriodEventItem from '../not-enough-stock-agenda-period-event-item';
import { SidebarType } from '../../actions/sidebarPanel';

interface NotEnoughStockAgendaPeriodEventLinkProps {
  notEnoughStockAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;
  };
  style: CSSProperties;
}

export default class NotEnoughStockAgendaPeriodEventLink extends PureComponent<NotEnoughStockAgendaPeriodEventLinkProps> {
  renderItem = ({ isActive, isIndented }: { isActive: boolean; isIndented: boolean }) => (
    <NotEnoughStockAgendaPeriodEventItem
      notEnoughStockAgendaPeriod={this.props.notEnoughStockAgendaPeriod}
      isActive={isActive}
      isIndented={isIndented}
    />
  );

  render() {
    return (
      <EventLink
        style={this.props.style}
        sidebarType={SidebarType.NotEnoughStockAgendaPeriod}
        sidebarId={this.props.notEnoughStockAgendaPeriod.id}
        renderItem={this.renderItem}
      />
    );
  }
}
