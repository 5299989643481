import React from 'react';
import intersperse from '../../utils/intersperse';

interface TodoLinksProps {
  todos: Array<{ id: string; description: string; url: string }>;
}

export default function TodoLinks({ todos }: TodoLinksProps) {
  const todoLinks = todos.map((todo) => (
    <a href={todo.url} key={todo.id} target="_blank" rel="noopener noreferrer">
      {todo.description}
    </a>
  ));

  return <React.Fragment>{intersperse(todoLinks, ', ')}</React.Fragment>;
}
