import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult, MutationFunction } from '@apollo/client/react';
import { loader } from 'graphql.macro';
const deleteMaintenanceAgendaPeriodMutation = loader('./deleteMaintenanceAgendaPeriodMutation.graphql');

export interface DeleteData {
  deleteMaintenanceAgendaPeriod: {
    id: string;
    parkId: string;
  };
}

interface DeleteVariables {
  maintenanceAgendaPeriodId: string;
}

export type Result = MutationResult<DeleteData>;
export type DeleteFn = MutationFunction<DeleteData, DeleteVariables>;

interface DeleteMaintenanceAgendaPeriodMutationProps {
  children: (deleteFn: DeleteFn, result: Result) => JSX.Element | null;
}

const REFETCH_QUERIES = ['rentableIdentityCellsQuery', 'rentableCellsQuery'];

export default function DeleteMaintenanceAgendaPeriodMutation({ children }: DeleteMaintenanceAgendaPeriodMutationProps) {
  return (
    <Mutation<DeleteData, DeleteVariables>
      mutation={deleteMaintenanceAgendaPeriodMutation}
      refetchQueries={REFETCH_QUERIES}
      awaitRefetchQueries
    >
      {children}
    </Mutation>
  );
}
