import React from 'react';
import ReactDOM from 'react-dom';
import './styles/application.css';
import './styles/vendor.css';
import App from './App';
import { FocusStyleManager } from '@blueprintjs/core';
import 'moment/locale/nl.js';
import 'moment/locale/de.js';
import './config/i18n';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<App />, document.getElementById('root'));

// See https://blueprintjs.com/docs/#core/accessibility.focus-management
FocusStyleManager.onlyShowFocusOnTabs();

// Prevent Chrome from translating anything on this page via Google Translate
document.body.classList.add('notranslate');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
