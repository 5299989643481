import React, { Component } from 'react';
import formatOwnerMaintenanceAgendaPeriod from '../../utils/formatOwnerMaintenanceAgendaPeriod';
import OwnerMaintenanceAgendaPeriodBadge from '../owner-maintenance-agenda-period-badge';
import AgendaPeriodSidebar from '../agenda-period-sidebar';
import Translation from '../translation';

interface OwnerMaintenanceAgendaPeriodSidebarProps {
  ownerMaintenanceAgendaPeriod: {
    memo: string;
    startDate: string;
    endDate: string;
    editUrl: string;
    rentableIdentity: {
      id: string;
      name: string;
      rentableTypeName: string;
      url: string;
    };
  };
  onCloseButtonClick: () => void;
}

export default class OwnerMaintenanceAgendaPeriodSidebar extends Component<OwnerMaintenanceAgendaPeriodSidebarProps> {
  render() {
    let { ownerMaintenanceAgendaPeriod, onCloseButtonClick } = this.props;

    return (
      <Translation>
        {(translate) => (
          <AgendaPeriodSidebar
            title={formatOwnerMaintenanceAgendaPeriod(ownerMaintenanceAgendaPeriod, translate)}
            url={ownerMaintenanceAgendaPeriod.editUrl}
            agendaPeriod={ownerMaintenanceAgendaPeriod}
            renderBadge={() => <OwnerMaintenanceAgendaPeriodBadge />}
            onCloseButtonClick={onCloseButtonClick}
          />
        )}
      </Translation>
    );
  }
}
