import React from 'react';
import MoveReservationForm from '../move-reservation-form';

interface MoveReservationPanelProps {
  reservationAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;

    reservation: {
      id: string;
      startDate: string;
      endDate: string;
      parkId: string;
      rentableId: string;
    };
  };
}

export default function MoveReservationPanel({ reservationAgendaPeriod }: MoveReservationPanelProps) {
  return <MoveReservationForm reservationAgendaPeriod={reservationAgendaPeriod} />;
}
