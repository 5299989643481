import React, { Component, RefObject } from 'react';
import { Button, MenuItem, HotkeysTarget2 } from '@blueprintjs/core';
import { Select, ItemRenderer } from '@blueprintjs/select';
import filterPark from '../../utils/filterPark';
import { defaultPopoverProps } from '../../config/popover';
import truncate from 'lodash/truncate';
import invoke from 'lodash/invoke';
import Translation, { WithTranslation, withTranslation } from '../translation';

interface ParkSwitcherProps {
  selectedParkId: string;
  parks: Park[];
  onChange: OnChange;
}

interface ParkSwitcherState {
  query: string;
}

interface Park {
  id: string;
  name: string;
  organizationId: string;
}

type OnChange = (nextPark: Park, previousPark?: Park) => void;

class ParkSwitcher extends Component<ParkSwitcherProps & WithTranslation, ParkSwitcherState> {
  state = { query: '' };
  private buttonRef: RefObject<Button> = React.createRef();

  get selectedPark() {
    const { selectedParkId, parks } = this.props;
    return parks.find((park) => park.id === selectedParkId);
  }

  get initialParks() {
    const { parks } = this.props;
    return parks.filter((park) => this.selectedPark && this.selectedPark.organizationId === park.organizationId);
  }

  handleItemSelect = (park: Park) => {
    this.props.onChange(park, this.selectedPark);
  };

  handleQueryChange = (query: string) => {
    this.setState({ query });
  };

  handleHotkey = () => {
    invoke(this.buttonRef.current, 'buttonRef.click');
  };

  renderNoResults = () => {
    const { t: translate } = this.props;
    return <MenuItem disabled text={translate('No results')} />;
  };

  renderPark: ItemRenderer<Park> = (park, { handleClick, modifiers }) => {
    const text = <div className="min-w-xxs">{park.name}</div>;
    return <MenuItem key={park.id} active={modifiers.active} label={park.id} text={text} onClick={handleClick} />;
  };

  private hotkeys = [
    {
      label: <Translation>{(translate) => translate('Show park menu')}</Translation>,
      combo: 'p',
      global: true,
      onKeyDown: this.handleHotkey,
    },
  ];

  render() {
    const { parks, t: translate } = this.props;
    const { query } = this.state;
    const items = query.length ? parks : this.initialParks;

    return (
      <HotkeysTarget2 hotkeys={this.hotkeys}>
        {({ handleKeyDown, handleKeyUp }) => (
          <Select
            items={items}
            query={query}
            activeItem={this.selectedPark}
            itemRenderer={this.renderPark}
            itemPredicate={filterPark}
            noResults={this.renderNoResults()}
            popoverProps={defaultPopoverProps}
            inputProps={{ placeholder: translate('Search by park name…') }}
            resetOnSelect
            onItemSelect={this.handleItemSelect}
            onQueryChange={this.handleQueryChange}
          >
            <Button alignText="left" rightIcon="caret-down" fill ref={this.buttonRef} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
              {this.selectedPark ? truncate(this.selectedPark.name, { omission: '…' }) : '…'}
            </Button>
          </Select>
        )}
      </HotkeysTarget2>
    );
  }
}

export default withTranslation()(ParkSwitcher);
