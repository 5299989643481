import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { apolloClient } from './config/apollo';
import { reduxStore } from './config/redux';
import { honeybadgerClient } from './config/honeybadger';
import Honeybadger from './components/honeybadger';
import HelpScout from './components/help-scout';
import Router from './components/router';
import isSupportedDevice from './utils/isSupportedDevice';
import isSupportedBrowser from './utils/isSupportedBrowser';
import UnsupportedDeviceWarning from './components/unsupported-device-warning';
import UnsupportedBrowserWarning from './components/unsupported-browser-warning';
import PlanboardVersionNotice from './components/planboard-version-notice';
import SettingsProvider from './components/settings-provider';
import { HotkeysProvider } from '@blueprintjs/core';

export default function App() {
  console.info(`Client version: ${process.env.REACT_APP_PLANBOARD_VERSION}`);

  return (
    <Honeybadger honeybadgerClient={honeybadgerClient} apolloClient={apolloClient}>
      <ApolloProvider client={apolloClient}>
        <ReduxProvider store={reduxStore}>
          <HotkeysProvider>
            <SettingsProvider>
              {!isSupportedBrowser() && <UnsupportedBrowserWarning />}
              {!isSupportedDevice() && <UnsupportedDeviceWarning />}
              <PlanboardVersionNotice>
                <Router />
              </PlanboardVersionNotice>
              <HelpScout />
            </SettingsProvider>
          </HotkeysProvider>
        </ReduxProvider>
      </ApolloProvider>
    </Honeybadger>
  );
}
