import React from 'react';
import BlockedByGroupedRentableTypeAgendaPeriodEventPopover from '../blocked-by-grouped-rentable-type-agenda-period-event-popover';
import formatBlockedByGroupedRentableTypeAgendaPeriod from '../../utils/formatBlockedByGroupedRentableTypeAgendaPeriod';
import AgendaPeriodEventItem from '../agenda-period-event-item/AgendaPeriodEventItem';

interface BlockedByGroupedRentableTypeAgendaPeriodEventItemProps {
  blockedByGroupedRentableTypeAgendaPeriod: {
    startDate: string;
    endDate: string;
    blockingReservation: {
      url: string;
      booking: {
        referenceNr: string;
        bookingNr: string;
        firstName: string;
        lastName: string;
        company: string;
      };
    };
  };
  isActive: boolean;
  isIndented: boolean;
}

export default function BlockedByGroupedRentableTypeAgendaPeriodEventItem({
  blockedByGroupedRentableTypeAgendaPeriod,
  isActive,
  isIndented,
}: BlockedByGroupedRentableTypeAgendaPeriodEventItemProps) {
  return (
    <AgendaPeriodEventItem
      title={formatBlockedByGroupedRentableTypeAgendaPeriod(blockedByGroupedRentableTypeAgendaPeriod)}
      renderPopover={() => (
        <BlockedByGroupedRentableTypeAgendaPeriodEventPopover
          blockedByGroupedRentableTypeAgendaPeriod={blockedByGroupedRentableTypeAgendaPeriod}
        />
      )}
      className="blocked-by-grouped-rentable-type-agenda-period"
      isActive={isActive}
      isIndented={isIndented}
    />
  );
}
