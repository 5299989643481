import React from 'react';
import { Button, Spinner } from '@blueprintjs/core';
import Translation from '../translation';

export default function ParkSwitcherPlaceholder() {
  return (
    <Button fill alignText="left" rightIcon={<Spinner size={12} />}>
      <span className="text-bp4-light-gray1">
        <Translation>{(translate) => translate('Loading…')}</Translation>
      </span>
    </Button>
  );
}
