import React from 'react';
import HelpScout from './HelpScout';
import HelpScoutQuery from './HelpScoutQuery';

export default function HelpScoutContainer() {
  return (
    <HelpScoutQuery>
      {({ data, loading, error }) => {
        if (error || loading || data === undefined || data.user === undefined) {
          return null;
        } else {
          return <HelpScout user={data.user} />;
        }
      }}
    </HelpScoutQuery>
  );
}
