import React, { CSSProperties, PureComponent } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import ReservationSelectionCell from './ReservationSelectionCell';
import { State } from '../../reducers';

interface OwnProps {
  style: CSSProperties;
  numberOfNights: number;
  isBlocked?: boolean;
}

interface StateProps {
  isBookingMode: boolean;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state, _ownProps) => {
  return { isBookingMode: !!state.bookingMode.booking };
};

class ReservationSelectionCellContainer extends PureComponent<OwnProps & StateProps> {
  render() {
    return <ReservationSelectionCell {...this.props} />;
  }
}

export default connect(mapStateToProps)(ReservationSelectionCellContainer);
