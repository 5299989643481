import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import { ParkData } from './OrganizationListQueryData';
const starredParkListQuery = loader('./starredParkListQuery.graphql');

interface StarredParkListQueryProps {
  parkIds: string[];
  children: (result: QueryResult<Data, Variables>) => JSX.Element | null;
}

interface Data {
  parks: ParkData[];
}

interface Variables {
  parkIds: string[];
}

export default function StarredParkListQuery({ parkIds, children }: StarredParkListQueryProps) {
  return (
    <Query<Data, Variables> query={starredParkListQuery} variables={{ parkIds }}>
      {children}
    </Query>
  );
}
