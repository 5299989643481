import React, { Component } from 'react';
import Sidebar, { LabelSection, TodoSection } from '../sidebar';
import formatRentableIdentity from '../../utils/formatRentableIdentity';
import SummarySection from './SummarySection';
import SetupSection from './SetupSection';
import MiscellaneousSection from './MiscellaneousSection';
import AmenitySection from './AmenitySection';
import { RentableIdentityData } from './RentableIdentitySidebarQueryData';

interface RentableIdentitySidebarProps {
  rentableIdentity: RentableIdentityData;
  onCloseButtonClick: () => void;
}

export default class RentableIdentitySidebar extends Component<RentableIdentitySidebarProps> {
  render() {
    const { rentableIdentity, onCloseButtonClick } = this.props;
    const { rentableIdentityLabels, blockingTodos } = rentableIdentity;
    const { currentRentable } = rentableIdentity;
    const amenities = currentRentable ? currentRentable.amenities : [];
    const rentableTypeAmenities = currentRentable ? currentRentable.rentableTypeAmenities : [];

    return (
      <Sidebar>
        <Sidebar.Header
          title={formatRentableIdentity(rentableIdentity)}
          href={rentableIdentity.url}
          onCloseButtonClick={onCloseButtonClick}
        />
        <Sidebar.Content>
          <SummarySection rentableIdentity={rentableIdentity} />
          <MiscellaneousSection rentableIdentity={rentableIdentity} />
          <SetupSection currentRentable={currentRentable} />
          <LabelSection labels={rentableIdentityLabels} />
          <AmenitySection amenities={amenities} rentableTypeAmenities={rentableTypeAmenities} />
          {blockingTodos.length > 0 && <TodoSection todos={blockingTodos} />}
          {/* Extra spacing */}
          <div className="h-12" />
        </Sidebar.Content>
      </Sidebar>
    );
  }
}
