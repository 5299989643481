import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import LaunchbarQueryData from './LaunchbarQueryData';
const launchbarQuery = loader('./launchbarQuery.graphql');

interface LaunchbarQueryProps {
  children: (result: QueryResult<LaunchbarQueryData>) => JSX.Element | null;
}

export default function LaunchbarQuery({ children }: LaunchbarQueryProps) {
  return <Query query={launchbarQuery}>{children}</Query>;
}
