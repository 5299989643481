import React, { PureComponent, CSSProperties } from 'react';
import RentableCell from './RentableCell';
import { DateRange } from 'moment-range';
import moment, { Moment } from 'moment';
import * as actions from '../../actions/reservationSelection';
import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../reducers';
import { CELL_WIDTH } from '../grid';
import isRegularClick from '../../utils/isRegularClick';
import { RentableData } from '../rentable-identity-cells';

interface OwnProps {
  rentable: RentableData;
  period: DateRange;
  style: CSSProperties;
}

interface StateProps {
  reservationSelectionHoverStartDate?: string;
  reservationSelectionStartDate?: string;
  reservationSelectionDraggingEndDate?: string;
  reservationSelectionEndDate?: string;
  canSetHoverRentable?: boolean;
  canClearHoverRentable?: boolean;
  canSetRentable?: boolean;
  canSetHoverStartDate?: boolean;
  canSetStartDate?: boolean;
}

type RentableCellContainerProps = ConnectedProps<typeof connector> & OwnProps;

interface RentableCellContainerState {
  isDragging: boolean;
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { hoverRentableId, rentableId, hoverStartDate, startDate, draggingEndDate, endDate } = state.reservationSelection;
  let stateProps: StateProps = {};

  // Pass along hover start date and dragging end date while selecting
  if (hoverRentableId !== undefined && hoverRentableId === ownProps.rentable.id) {
    stateProps = {
      ...stateProps,
      reservationSelectionHoverStartDate: hoverStartDate,
      reservationSelectionDraggingEndDate: draggingEndDate,
    };
  }

  // No rentable selected, can hover and start a selection anywhere
  if (rentableId === undefined) {
    stateProps = {
      ...stateProps,
      canSetHoverRentable: true,
      canClearHoverRentable: true,
      canSetRentable: true,
      canSetHoverStartDate: true,
      canSetStartDate: true,
    };
  }

  // Pass along start & end date if selection concerns this rentable id
  if (rentableId !== undefined && rentableId === ownProps.rentable.id) {
    stateProps = { ...stateProps, reservationSelectionStartDate: startDate, reservationSelectionEndDate: endDate };
  }

  return stateProps;
};

const mapDispatchToProps = {
  setReservationSelectionHoverRentable: actions.setReservationSelectionHoverRentable,
  releaseReservationSelectionHoverRentable: actions.releaseReservationSelectionHoverRentable,
  setReservationSelectionRentable: actions.setReservationSelectionRentable,
  setReservationSelectionHoverStartDate: actions.setReservationSelectionHoverStartDate,
  setReservationSelectionStartDate: actions.setReservationSelectionStartDate,
  setReservationSelectionDraggingEndDate: actions.setReservationSelectionDraggingEndDate,
  setReservationSelectionEndDate: actions.setReservationSelectionEndDate,
  clearReservationSelection: actions.clearReservationSelection,
};

class RentableCellContainer extends PureComponent<RentableCellContainerProps, RentableCellContainerState> {
  state: RentableCellContainerState = { isDragging: false };

  private draggingStartDate?: Moment;
  private draggingStartMouseX?: number;

  componentDidMount() {
    window.addEventListener('mousemove', this.handleWindowMouseMove);
    window.addEventListener('mouseup', this.handleWindowMouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleWindowMouseMove);
    window.removeEventListener('mouseup', this.handleWindowMouseUp);
  }

  handleMouseOver = () => {
    const { rentable, canSetHoverRentable, setReservationSelectionHoverRentable } = this.props;

    if (canSetHoverRentable) {
      setReservationSelectionHoverRentable(rentable.id);
    }
  };

  handleMouseOut = () => {
    const { rentable, canClearHoverRentable, releaseReservationSelectionHoverRentable } = this.props;

    if (canClearHoverRentable) {
      releaseReservationSelectionHoverRentable(rentable.id);
    }
  };

  handleMouseDown = (e: React.MouseEvent) => {
    const { rentable, canSetRentable, canSetStartDate, setReservationSelectionRentable, setReservationSelectionStartDate } = this.props;

    if (!isRegularClick(e)) {
      return;
    }

    if (canSetRentable) {
      setReservationSelectionRentable(rentable.id);
    }

    if (canSetStartDate) {
      const hoverDate = this.calculateHoverDate(e);

      this.draggingStartDate = hoverDate;
      this.draggingStartMouseX = e.clientX;

      window.document.body.style.userSelect = 'none';

      this.setState({ isDragging: true }, () => setReservationSelectionStartDate(hoverDate.clone().subtract(12, 'hour')));
    }
  };

  handleMouseMove = (e: React.MouseEvent) => {
    const {
      rentable,
      canSetHoverRentable,
      canSetHoverStartDate,
      setReservationSelectionHoverRentable,
      setReservationSelectionHoverStartDate,
    } = this.props;

    const hoverDate = this.calculateHoverDate(e);

    if (canSetHoverRentable) {
      setReservationSelectionHoverRentable(rentable.id);
    }

    if (canSetHoverStartDate) {
      setReservationSelectionHoverStartDate(hoverDate.clone().subtract(12, 'hour'));
    }
  };

  handleWindowMouseMove = (e: MouseEvent) => {
    const { setReservationSelectionDraggingEndDate } = this.props;
    const { isDragging } = this.state;

    if (isDragging && this.draggingStartMouseX && this.draggingStartDate) {
      const draggingEndDate = this.calculateDraggingEndDate(e, this.draggingStartDate, this.draggingStartMouseX);
      setReservationSelectionDraggingEndDate(draggingEndDate);
    }
  };

  handleWindowMouseUp = (e: MouseEvent) => {
    const { setReservationSelectionEndDate, clearReservationSelection } = this.props;

    if (!isRegularClick(e) || !this.draggingStartDate || !this.draggingStartMouseX) {
      return;
    } else {
      const draggingEndDate = this.calculateDraggingEndDate(e, this.draggingStartDate, this.draggingStartMouseX);

      if (draggingEndDate.isSameOrAfter(this.draggingStartDate)) {
        window.document.body.style.cursor = 'initial';

        this.draggingStartMouseX = undefined;
        this.draggingStartDate = undefined;

        setReservationSelectionEndDate(draggingEndDate);
      } else {
        clearReservationSelection();
      }

      this.setState({ isDragging: false });
    }
  };

  calculateHoverDate = (e: React.MouseEvent) => {
    const { period, rentable } = this.props;

    const boundingClientRect = e.currentTarget.getBoundingClientRect();
    const daysOffset = (e.clientX - boundingClientRect.left) / CELL_WIDTH;

    const componentStartDate = moment.max(moment(rentable.activeFrom), period.start);
    return componentStartDate.clone().add(daysOffset, 'days');
  };

  calculateDraggingEndDate = (e: MouseEvent, draggingStartDate: Moment, draggingStartMouseX: number) => {
    const daysOffset = (e.clientX - draggingStartMouseX) / CELL_WIDTH;
    return draggingStartDate.clone().add(daysOffset, 'days');
  };

  render() {
    const {
      style,
      rentable,
      period,
      reservationSelectionHoverStartDate,
      reservationSelectionStartDate,
      reservationSelectionDraggingEndDate,
      reservationSelectionEndDate,
    } = this.props;

    return (
      <RentableCell
        style={style}
        rentable={rentable}
        period={period}
        reservationSelectionHoverStartDate={reservationSelectionHoverStartDate}
        reservationSelectionStartDate={reservationSelectionStartDate}
        reservationSelectionDraggingEndDate={reservationSelectionDraggingEndDate}
        reservationSelectionEndDate={reservationSelectionEndDate}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onMouseMove={this.handleMouseMove}
        onMouseDown={this.handleMouseDown}
      />
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(RentableCellContainer);
