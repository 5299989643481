import React, { PureComponent } from 'react';
import { ButtonGroup, Button } from '@blueprintjs/core';
import { INITIAL_DATE } from '../../config/planboard';
import cs from 'classnames';
import classes from './DateControls.module.css';
import moment, { Moment } from 'moment';
import Translation from '../translation';

interface DateControlsProps {
  date: string;
  startDate: string | null;
  className: string;
  onChange: (date: Moment) => void;
}

export default class DateControls extends PureComponent<DateControlsProps> {
  get startDate() {
    return moment(this.props.startDate || INITIAL_DATE);
  }

  get resetButtonIsActive() {
    const { date } = this.props;
    return moment(date).isSame(this.startDate, 'date');
  }

  handleBackButtonClick = () => {
    const { date, onChange } = this.props;
    onChange(moment(date).add(-4, 'weeks'));
  };

  handleResetButtonClick = () => {
    const { onChange } = this.props;
    onChange(this.startDate);
  };

  handleForwardButtonClick = () => {
    const { date, onChange } = this.props;
    onChange(moment(date).add(4, 'weeks'));
  };

  render() {
    const { className } = this.props;

    return (
      <Translation>
        {(translate) => (
          <div className={cs(classes.root, className)}>
            <ButtonGroup fill>
              <Button
                small
                className="text-sm"
                icon="caret-left"
                title={translate('4 weeks backward')}
                onMouseDown={this.handleBackButtonClick}
              />
              <Button
                small
                className="text-sm"
                style={{ flexGrow: 2 }}
                active={this.resetButtonIsActive}
                onMouseDown={this.handleResetButtonClick}
                title={translate('Back to start date')}
              >
                {translate('Start date')}
              </Button>
              <Button
                small
                className="text-sm"
                icon="caret-right"
                title={translate('4 weeks forward')}
                onMouseDown={this.handleForwardButtonClick}
              />
            </ButtonGroup>
          </div>
        )}
      </Translation>
    );
  }
}
