import { LOCATION_CHANGE, locationChangeAction, State as LocationState } from 'redux-first-routing';
import { SearchParams } from '../config/planboard';
import deserializeDisplayMode from '../utils/deserializeDisplayMode';
import { DisplayMode } from '../actions/displayMode';

export type DisplayModeState = DisplayMode;

function deserializeStateFromLocation(location: Location | LocationState) {
  const searchParams = new URLSearchParams(location.search);
  return deserializeDisplayMode(searchParams.get(SearchParams.DISPLAY_MODE) || '');
}

export default function displayMode(
  state = deserializeStateFromLocation(window.location),
  action: locationChangeAction<string, string, string>
): DisplayModeState {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return deserializeStateFromLocation(action.payload);
    }

    default: {
      return state;
    }
  }
}
