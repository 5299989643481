import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import ReservationAgendaPeriodSidebarQueryData from './ReservationAgendaPeriodSidebarQueryData';
import { FAST_QUERY_POLL_INTERVAL } from '../../config/planboard';
const reservationAgendaPeriodSidebarQuery = loader('./reservationAgendaPeriodSidebarQuery.graphql');

interface Variables {
  reservationAgendaPeriodId: string;
}

interface ReservationAgendaPeriodSidebarQueryProps {
  reservationAgendaPeriodId: string;
  children: (result: QueryResult<ReservationAgendaPeriodSidebarQueryData, Variables>) => JSX.Element | null;
}

const ReservationAgendaPeriodSidebarQuery = ({ reservationAgendaPeriodId, children }: ReservationAgendaPeriodSidebarQueryProps) => (
  <Query<ReservationAgendaPeriodSidebarQueryData, Variables>
    query={reservationAgendaPeriodSidebarQuery}
    variables={{ reservationAgendaPeriodId }}
    fetchPolicy="cache-and-network"
    pollInterval={FAST_QUERY_POLL_INTERVAL}
  >
    {children}
  </Query>
);
export default ReservationAgendaPeriodSidebarQuery;
