import React, { PureComponent } from 'react';
import MultiSelectFilter, { MultiSelectFilterProps } from './MultiSelectFilter';
import { ItemListRenderer } from '@blueprintjs/select';
import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import flatMap from 'lodash/flatMap';
import Translation from '../translation';

type GroupedMultiSelectFilterProps<T> = MultiSelectFilterProps<T>;

interface Item {
  id: string;
  name: string;
  groupBy: { id: string; name: string };
}

export default class GroupedMultiSelectFilter<T extends Item> extends PureComponent<GroupedMultiSelectFilterProps<T>> {
  renderItemList: ItemListRenderer<T> = (listProps) => {
    const noResults = <Translation>{(translate) => <MenuItem disabled={true} text={translate('No results')} />}</Translation>;

    // Group items & retain original (server-side) sort order
    const groupedItems = sortBy(groupBy(listProps.filteredItems, 'groupBy.id'), (group) => listProps.filteredItems.indexOf(group[0]));

    // Renders a list of menu divider + menu items per grouped list of items
    const renderedItems = flatMap(groupedItems, (items, key) => {
      if (items.length === 0) {
        return [];
      } else {
        const dividerKey = `divider-${key}`;
        const divider = <MenuDivider key={dividerKey} title={items[0].groupBy.name} />;
        return [divider, ...items.map(listProps.renderItem).filter((item) => item != null)];
      }
    });

    const menuContent = renderedItems.length > 0 ? renderedItems : noResults;

    return <Menu ulRef={listProps.itemsParentRef}>{menuContent}</Menu>;
  };

  render() {
    return <MultiSelectFilter<T> itemListRenderer={this.renderItemList} {...this.props} />;
  }
}
