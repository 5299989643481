import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Tag } from '@blueprintjs/core';
import sortBy from 'lodash/sortBy';
import Translation from '../translation';

interface AmenitySectionProps {
  amenities: Array<{
    id: string;
    name: string;
  }>;
  rentableTypeAmenities: Array<{
    id: string;
    name: string;
  }>;
}

export default class AmenitySection extends Component<AmenitySectionProps> {
  renderRentableAmenities() {
    const { amenities } = this.props;
    return sortBy(amenities, 'name').map((amenity) => <Tag key={amenity.id} className="rounded-sm m-1 p-1" children={amenity.name} />);
  }

  renderRentableTypeAmenities() {
    const { rentableTypeAmenities } = this.props;
    return sortBy(rentableTypeAmenities, 'name').map((amenity) => (
      <Tag minimal key={amenity.id} className="rounded-sm m-1 p-1" children={amenity.name} />
    ));
  }

  renderAmenities() {
    return (
      <div className="flex flex-wrap -m-1">
        {this.renderRentableAmenities()}
        {this.renderRentableTypeAmenities()}
      </div>
    );
  }

  renderEmpty() {
    return <Translation>{(translate) => <span className="italic text-bp4-gray3">{translate('No amenities assigned')}</span>}</Translation>;
  }

  render() {
    const { amenities, rentableTypeAmenities } = this.props;
    const isEmpty = amenities.length + rentableTypeAmenities.length === 0;

    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Active amenities')}>{isEmpty ? this.renderEmpty() : this.renderAmenities()}</Sidebar.Section>
        )}
      </Translation>
    );
  }
}
