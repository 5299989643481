import React from 'react';
import TOASTER from './Toaster';
import { Intent } from '@blueprintjs/core';

export default function showProgressToast({ message }: { message: React.ReactNode }) {
  TOASTER.show({
    intent: Intent.PRIMARY,
    message,
  });
}
