import React, { PureComponent } from 'react';
import RentableHeader from '../rentable-header';

interface RentableHeadersProps {
  rentables: Array<{
    id: string;
    petsAllowed: boolean;
    title: string;

    rentableIdentity: {
      id: string;
      name: string;
      priority: number;
      blockedForExternalReservations: boolean;
      blockingTodosCount: number;
      url: string;
    };

    rentableType: {
      id: string;
      name: string;
    };
  }>;
}

export default class RentableHeaders extends PureComponent<RentableHeadersProps> {
  render() {
    return this.props.rentables.map((rentable) => <RentableHeader key={rentable.id} rentable={rentable} />);
  }
}
