interface IcalAgendaPeriod {
  memo: string;

  icalChannel: {
    name: string;
  };
}

export default function formatIcalAgendaPeriod(icalAgendaPeriod: IcalAgendaPeriod) {
  return `${icalAgendaPeriod.memo} — ${icalAgendaPeriod.icalChannel.name}`;
}
