import React, { PureComponent } from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { DateRange } from 'moment-range';
import { loader } from 'graphql.macro';
import RentableIdentityCellsQueryData from './RentableIdentityCellsQueryData';
import { DisplayMode } from '../../actions/displayMode';
import { NORMAL_QUERY_POLL_INTERVAL, USE_QUERY_BATCHING } from '../../config/planboard';
const rentableIdentityCellsQuery = loader('./rentableIdentityCellsQuery.graphql');

interface CacheOnlyResult {
  data?: RentableIdentityCellsQueryData;
  loading: boolean;
  error: undefined;
}

interface RentableIdentityCellsQueryProps {
  period: DateRange;
  ids: string[];
  rentableTypeId: string;
  parkId: string;
  displayMode: DisplayMode;
  cacheOnly: boolean;
  children: (result: QueryResult<RentableIdentityCellsQueryData, Variables> | CacheOnlyResult) => JSX.Element | null;
}

interface Variables {
  ids: string[];
  rentableTypeId: string;
  parkId: string;
  period: [string, string];
  isAlertsDisplayMode: boolean;
  isPresenceDisplayMode: boolean;
}

export default class RentableIdentityCellsQuery extends PureComponent<RentableIdentityCellsQueryProps> {
  get variables() {
    const { period, ids, rentableTypeId, parkId, displayMode } = this.props;

    const $period: [string, string] = [period.start.format('YYYY-MM-DD'), period.end.format('YYYY-MM-DD')];
    const isAlertsDisplayMode = displayMode === DisplayMode.Alerts;
    const isPresenceDisplayMode = displayMode === DisplayMode.Presence;

    return { ids, parkId, rentableTypeId, period: $period, isAlertsDisplayMode, isPresenceDisplayMode };
  }

  render() {
    const { children } = this.props;

    return (
      <Query<RentableIdentityCellsQueryData, Variables>
        query={rentableIdentityCellsQuery}
        variables={this.variables}
        pollInterval={NORMAL_QUERY_POLL_INTERVAL}
        context={{ batch: USE_QUERY_BATCHING }}
      >
        {children}
      </Query>
    );
  }
}
