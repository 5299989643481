import React from 'react';
import CheckoutForm from './CheckoutForm';
import CheckoutFormQuery from './CheckoutFormQuery';
import isEmpty from 'lodash/isEmpty';
import CheckOutReservationMutation from './CheckOutReservationMutation';
import UndoCheckOutReservationMutation from './UndoCheckOutReservationMutation';

interface CheckoutFormContainerProps {
  reservationId: string;
}

export default function CheckoutFormContainer({ reservationId }: CheckoutFormContainerProps) {
  return (
    <CheckoutFormQuery reservationId={reservationId}>
      {({ data, loading }) => {
        if (isEmpty(data) || data === undefined || loading) {
          return null;
        }

        return (
          <CheckOutReservationMutation>
            {(checkOut, { loading: isCheckingOut }) => (
              <UndoCheckOutReservationMutation>
                {(undoCheckOut, { loading: isUndoingCheckOut }) => (
                  <CheckoutForm
                    data={data}
                    checkOut={checkOut}
                    undoCheckOut={undoCheckOut}
                    isCheckingOut={isCheckingOut}
                    isUndoingCheckOut={isUndoingCheckOut}
                  />
                )}
              </UndoCheckOutReservationMutation>
            )}
          </CheckOutReservationMutation>
        );
      }}
    </CheckoutFormQuery>
  );
}
