import React, { Component } from 'react';
import Sidebar from '../sidebar';
import formatIcalAgendaPeriod from '../../utils/formatIcalAgendaPeriod';
import TakeOverIcalAgendaPeriodPanel from './TakeOverIcalAgendaPeriodPanel';
import IcalAgendaPeriodBadge from '../ical-agenda-period-badge';
import AgendaPeriodSidebar, { MemoSection } from '../agenda-period-sidebar';
import { withTranslation, WithTranslation } from '../translation';

interface IcalAgendaPeriodSidebarProps {
  icalAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;
    memo: string;
    url: string;
    icalChannel: {
      name: string;
      url: string;
    };
    rentableIdentity: {
      id: string;
      name: string;
      rentableTypeName: string;
      url: string;
    };
  };
  onCloseButtonClick: () => void;
}

class IcalAgendaPeriodSidebar extends Component<IcalAgendaPeriodSidebarProps & WithTranslation> {
  renderIcalChannel() {
    const { t: translate, icalAgendaPeriod } = this.props;
    const { icalChannel } = icalAgendaPeriod;

    return (
      <Sidebar.Section title={translate('iCal channel')}>
        <Sidebar.Table>
          <Sidebar.TableRow header={translate('Name')} data={<a href={icalChannel.url}>{icalChannel.name}</a>} />
        </Sidebar.Table>
      </Sidebar.Section>
    );
  }

  renderActions() {
    const { t: translate, icalAgendaPeriod } = this.props;

    return (
      <Sidebar.Section title={translate('Actions')}>
        <div className="p-1">
          <TakeOverIcalAgendaPeriodPanel icalAgendaPeriod={icalAgendaPeriod} />
        </div>
      </Sidebar.Section>
    );
  }

  render() {
    const { icalAgendaPeriod, onCloseButtonClick } = this.props;

    return (
      <AgendaPeriodSidebar
        title={formatIcalAgendaPeriod(icalAgendaPeriod)}
        url={icalAgendaPeriod.url}
        agendaPeriod={icalAgendaPeriod}
        renderBadge={() => <IcalAgendaPeriodBadge />}
        actions={this.renderActions()}
        onCloseButtonClick={onCloseButtonClick}
      >
        {this.renderIcalChannel()}
        {icalAgendaPeriod.memo && <MemoSection memo={icalAgendaPeriod.memo} />}
      </AgendaPeriodSidebar>
    );
  }
}

export default withTranslation()(IcalAgendaPeriodSidebar);
