import compact from 'lodash/compact';

interface RentableType {
  name: string;
  reference: string;
}

export default function formatRentableType(rentableType: RentableType) {
  return compact([rentableType.reference, rentableType.name]).join(' - ');
}
