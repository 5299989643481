import React from 'react';
import OwnerMaintenanceAgendaPeriodSidebar from './OwnerMaintenanceAgendaPeriodSidebar';
import OwnerMaintenanceAgendaPeriodSidebarQuery from './OwnerMaintenanceAgendaPeriodSidebarQuery';
import { AgendaPeriodSidebarPlaceholder } from '../agenda-period-sidebar';
import { SidebarErrorMessage } from '../sidebar';

interface OwnerMaintenanceAgendaPeriodSidebarContainerProps {
  ownerMaintenanceAgendaPeriodId: string;
  onCloseButtonClick: () => void;
}

export default function OwnerMaintenanceAgendaPeriodSidebarContainer({
  ownerMaintenanceAgendaPeriodId,
  ...rest
}: OwnerMaintenanceAgendaPeriodSidebarContainerProps) {
  return (
    <OwnerMaintenanceAgendaPeriodSidebarQuery ownerMaintenanceAgendaPeriodId={ownerMaintenanceAgendaPeriodId}>
      {({ data, loading, error }) => {
        if (error) {
          return <SidebarErrorMessage error={error} {...rest} />;
        }
        if (loading) {
          return <AgendaPeriodSidebarPlaceholder {...rest} />;
        } else if (data) {
          return <OwnerMaintenanceAgendaPeriodSidebar ownerMaintenanceAgendaPeriod={data.ownerMaintenanceAgendaPeriod} {...rest} />;
        } else {
          return null;
        }
      }}
    </OwnerMaintenanceAgendaPeriodSidebarQuery>
  );
}
