import React, { PureComponent } from 'react';
import GroupedMultiSelectFilter from './GroupedMultiSelectFilter';
import Translation from '../translation';
import formatRentableType from '../../utils/formatRentableType';

interface RentableType {
  id: string;
  reference: string;
  name: string;
  groupBy: { id: string; name: string };
}

interface RentableTypeFilterProps {
  rentableTypes: RentableType[];
  selectedRentableTypes: RentableType[];
  inputRef?: (input: HTMLInputElement | null) => void;
  onChange: (selectedRentableTypes: RentableType[]) => void;
}

export default class RentableTypeFilter extends PureComponent<RentableTypeFilterProps> {
  render() {
    const { rentableTypes, selectedRentableTypes, inputRef, onChange } = this.props;

    return (
      <Translation>
        {(translate) => (
          <GroupedMultiSelectFilter<RentableType>
            items={rentableTypes}
            selectedItems={selectedRentableTypes}
            placeholder={translate('Filter by type…')}
            formatItem={formatRentableType}
            inputRef={inputRef}
            onChange={onChange}
          />
        )}
      </Translation>
    );
  }
}
