import React, { PureComponent } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { State } from '../../reducers';
import PlanboardVersionNotice from './PlanboardVersionNotice';
import isServerVersionOutdated from '../../utils/isServerVersionOutdated';
import isClientVersionOutdated from '../../utils/isClientVersionOutdated';
import hasMajorVersionMismatch from '../../utils/hasMajorVersionMismatch';

interface OwnProps {
  children: React.ReactNode;
}

interface StateProps {
  serverVersion?: string;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state, _ownProps) => {
  return { serverVersion: state.planboardVersion.serverVersion };
};

type PlanboardVersionNoticeContainerProps = OwnProps & StateProps;

class PlanboardVersionNoticeContainer extends PureComponent<PlanboardVersionNoticeContainerProps> {
  render() {
    const { children, serverVersion } = this.props;
    const clientVersion = process.env.REACT_APP_PLANBOARD_VERSION;

    if (serverVersion && clientVersion) {
      const isServerOutdated = isServerVersionOutdated(serverVersion, clientVersion);
      const isClientOutdated = isClientVersionOutdated(serverVersion, clientVersion);
      const hasMajorMismatch = hasMajorVersionMismatch(serverVersion, clientVersion);

      if (isServerOutdated || isClientOutdated) {
        return (
          <PlanboardVersionNotice isServerVersionOutdated={isServerOutdated} hasMajorVersionMismatch={hasMajorMismatch}>
            {children}
          </PlanboardVersionNotice>
        );
      } else {
        return children;
      }
    } else {
      return children;
    }
  }
}

export default connect(mapStateToProps)(PlanboardVersionNoticeContainer);
