export default function applicationEnvironment() {
  const host = window.location.host;

  if (host === 'localhost' || host === 'app.local.bookingexperts.nl') {
    return 'development';
  }

  if (host.startsWith('app.bookingexperts.')) {
    return 'production';
  }

  return 'staging';
}
