import moment from 'moment';

export default function formatValue(value: any, format?: string, locale?: string) {
  // Allows for date formatting (i.e. translate("Finished on {{finishedAt, date | LL}}", { finishedAt }))
  const regexpMatch = format ? format.match(/date \| (.+)/) : null;

  if (regexpMatch) {
    // prettier-ignore
    return moment(value).locale(locale || 'en').format(regexpMatch[1]);
  }

  return value;
}
