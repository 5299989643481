import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { DefaultTooltip } from '../tooltip';
import LocaleDate from '../locale-date';
import classes from './WeekDate.module.css';
import moment, { Moment } from 'moment';

interface WeekDateProps {
  date: string;
  className: string;
  renderTooltip?: () => React.ReactNode;
  onClick?: (e: React.MouseEvent, date: Moment) => void;
}

export default class WeekDate extends PureComponent<WeekDateProps> {
  handleClick = (e: React.MouseEvent) => {
    const { onClick, date } = this.props;
    const fn = onClick || function () {};

    fn(e, moment(date));
  };

  renderDate() {
    const { date } = this.props;

    return (
      <div className={classes.date}>
        <div>
          <LocaleDate date={date} format="dd" />
        </div>
        <div>
          <LocaleDate date={date} format="DD" />
        </div>
      </div>
    );
  }

  renderTooltip = (children: JSX.Element) => {
    return (
      <DefaultTooltip className={classes.tooltip} render={this.props.renderTooltip} distance={8}>
        {children}
      </DefaultTooltip>
    );
  };

  render() {
    const { className, renderTooltip } = this.props;

    return (
      <div className={classNames(classes.root, className)} onClick={this.handleClick}>
        {renderTooltip ? this.renderTooltip(this.renderDate()) : this.renderDate()}
      </div>
    );
  }
}
