import React, { PureComponent } from 'react';
import RentableTypeHeader from './RentableTypeHeader';
import { connect, MapStateToProps } from 'react-redux';
import { State } from '../../reducers';

interface OwnProps {
  rentableType: {
    name: string;
    reference: string;
  };
}

interface StateProps {
  searchQuery: string;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state) => {
  return { searchQuery: state.searchQuery };
};

type RentableTypeHeaderContainerProps = OwnProps & StateProps;

class RentableTypeHeaderContainer extends PureComponent<RentableTypeHeaderContainerProps> {
  render() {
    const { rentableType, searchQuery } = this.props;

    return <RentableTypeHeader rentableType={rentableType} searchQuery={searchQuery} />;
  }
}

export default connect(mapStateToProps)(RentableTypeHeaderContainer);
