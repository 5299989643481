import React, { Component } from 'react';
import { Callout, Intent, Icon, AnchorButton } from '@blueprintjs/core';
import Translation from '../translation';
import { Trans } from 'react-i18next';

interface PlanboardVersionNoticeProps {
  children: React.ReactNode;
  isServerVersionOutdated: boolean;
  hasMajorVersionMismatch: boolean;
}

export default class PlanboardVersionNotice extends Component<PlanboardVersionNoticeProps> {
  forceReload = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.reload();
  };

  // tslint:disable-next-line member-ordering
  handleReloadLinkClick = this.forceReload;

  // tslint:disable-next-line member-ordering
  handleReloadButtonClick = this.forceReload;

  renderMessage() {
    if (this.props.isServerVersionOutdated) {
      return (
        <p className="flex-grow my-0 mx-2">
          <Translation>
            {(translate) => translate('A new version of the planboard is being deployed. Please wait until the update has finished.')}
          </Translation>
        </p>
      );
    } else {
      return (
        <Translation>
          {(translate) => (
            <p className="flex-grow my-0 mx-2">
              <Trans>
                A new version of the planboard is available.{' '}
                <a href="." onClick={this.handleReloadLinkClick}>
                  Reload the page
                </a>{' '}
                to be up to date again.
              </Trans>
            </p>
          )}
        </Translation>
      );
    }
  }

  renderReloadButton() {
    return (
      <AnchorButton
        href=""
        intent={Intent.PRIMARY}
        icon={<Icon icon="refresh" iconSize={12} />}
        small
        onClick={this.handleReloadButtonClick}
      />
    );
  }

  renderChildren() {
    if (this.props.hasMajorVersionMismatch) {
      return null;
    } else {
      return this.props.children;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Callout intent={Intent.PRIMARY} icon={null} className="flex items-center px-3 py-2">
          <Icon icon="tick" iconSize={12} className="h-3" />
          {this.renderMessage()}
          {this.renderReloadButton()}
        </Callout>
        {this.renderChildren()}
      </React.Fragment>
    );
  }
}
