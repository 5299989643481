import moment from 'moment';
import momentRange from '../../utils/momentRange';

export const TODAY = moment().startOf('date');
export const INITIAL_DATE = TODAY.clone().add(-1, 'weeks');

export const PERIODS = ((weeksBefore: number, weeksAfter: number) => {
  let periods = [];

  for (let i = -weeksBefore; i < weeksAfter; i = i + 4) {
    // prettier-ignore
    let start = TODAY.clone().add(i, 'weeks').startOf('isoWeek');

    // prettier-ignore
    let end = TODAY.clone().add(i + 3, 'weeks').endOf('isoWeek');

    periods.push(momentRange(start, end));
  }

  return periods;
})(52 * 3, 52 * 2);

export enum SearchParams {
  DATE = 'date',
  DISPLAY_MODE = 'display_mode',
  SIDEBAR = 'sidebar',
  FILTER = 'filter',
  GUEST_GROUP = 'guest_group',
  RENTABLE_SEGMENTS = 'rentable_segments',
  RENTABLE_TYPES = 'rentable_types',
  LABELS = 'labels',
  AMENITIES = 'amenities',
  SEARCH_QUERY = 'query',
  AVAILABILITY_FILTER = 'availability',
  BOOKING_ID = 'booking_id',
  BOOKING_NR = 'booking_nr',
  RENTABLE_ID = 'rentable_id',
}

export type SearchParamsMap = { [K in SearchParams]?: string };

export const FAST_QUERY_POLL_INTERVAL = 5 * 60 * 1000;
export const NORMAL_QUERY_POLL_INTERVAL = 15 * 60 * 1000;
export const SLOW_QUERY_POLL_INTERVAL = 30 * 60 * 1000;
export const USE_QUERY_BATCHING = false;
export const MAX_SEARCH_RESULTS = 3;
