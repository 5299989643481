import React, { PureComponent } from 'react';
import { MultiSelect, ItemRenderer, ItemPredicate, ItemListRenderer } from '@blueprintjs/select';
import { MenuItem, Button, Checkbox, ITagProps } from '@blueprintjs/core';
import { defaultPopoverProps } from '../../config/popover';
import Translation from '../translation';

interface Item {
  id: string;
  name: string | null;
}

export interface MultiSelectFilterProps<T> {
  items: T[];
  selectedItems: T[];
  placeholder: string;
  tagProps?: ITagProps | ((value: React.ReactNode, index: number) => ITagProps);
  inputRef?: (input: HTMLInputElement | null) => void;
  itemListRenderer?: ItemListRenderer<T>;
  formatItem?: (item: T) => string;
  onChange: (selectedItems: T[]) => void;
}

export default class MultiSelectFilter<T extends Item> extends PureComponent<MultiSelectFilterProps<T>> {
  static defaultProps = { tagProps: { minimal: true } };

  formatItem = (item: T) => {
    const { formatItem } = this.props;
    return formatItem ? formatItem(item) : item.name || '';
  };

  filterItem: ItemPredicate<T> = (query, item) => {
    if (query === '') {
      return true;
    }

    const normalizedQuery = query.toLowerCase();
    const normalizedName = this.formatItem(item).toLowerCase();

    const queryTokens = normalizedQuery.split(/\s+/);
    const itemTokens = normalizedName.split(/\s+/);

    return queryTokens.every((queryToken) => itemTokens.some((itemToken) => itemToken.includes(queryToken)));
  };

  handleTagRemove = (value: React.ReactNode, index: number) => {
    this.deselectItem(index);
  };

  getSelectedItemIndex(item: T) {
    return this.props.selectedItems.indexOf(item);
  }

  isItemSelected(item: T) {
    return this.getSelectedItemIndex(item) !== -1;
  }

  selectItem(item: T) {
    this.props.onChange([...this.props.selectedItems, item]);
  }

  deselectItem(index: number) {
    this.props.onChange(this.props.selectedItems.filter((_item, i) => i !== index));
  }

  handleItemSelect = (item: T) => {
    if (!this.isItemSelected(item)) {
      this.selectItem(item);
    } else {
      this.deselectItem(this.getSelectedItemIndex(item));
    }
  };

  handleClearButtonClick = () => {
    this.props.onChange([]);
  };

  renderItem: ItemRenderer<T> = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else {
      const icon = <Checkbox readOnly checked={this.isItemSelected(item)} className="m-0 mr-1 pointer-events-none" />;
      return <MenuItem key={item.id} active={modifiers.active} icon={icon} text={this.formatItem(item)} onClick={handleClick} />;
    }
  };

  renderTag(item: T) {
    return item.name;
  }

  renderClearButton() {
    return (
      <Translation>
        {(translate) => <Button icon="cross" small minimal title={translate('Reset filter')} onClick={this.handleClearButtonClick} />}
      </Translation>
    );
  }

  render() {
    const { items, selectedItems, tagProps, inputRef, placeholder, itemListRenderer } = this.props;
    const rightElement = selectedItems.length > 0 ? this.renderClearButton() : undefined;

    return (
      <MultiSelect
        {...defaultPopoverProps}
        items={items}
        selectedItems={selectedItems}
        itemListRenderer={itemListRenderer}
        itemRenderer={this.renderItem}
        itemPredicate={this.filterItem}
        itemsEqual="id"
        tagRenderer={this.renderTag}
        tagInputProps={{ tagProps, onRemove: this.handleTagRemove, rightElement, inputRef }}
        popoverProps={defaultPopoverProps}
        placeholder={placeholder}
        resetOnSelect={true}
        scrollToActiveItem={false}
        noResults={<Translation>{(translate) => <MenuItem disabled={true} text={translate('No results')} />}</Translation>}
        onItemSelect={this.handleItemSelect}
      />
    );
  }
}
