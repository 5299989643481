import React, { Component } from 'react';
import Sidebar from '../sidebar';
import formatRentableIdentity from '../../utils/formatRentableIdentity';
import LocaleDate from '../locale-date';
import ReservationChannelBadge from '../reservation-channel-badge';
import { Icon, Tag, Intent } from '@blueprintjs/core';
import Translation from '../translation';
import intersperse from '../../utils/intersperse';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatGuestTypeCount from '../../utils/formatGuestTypeCount';

interface SummarySectionProps {
  reservationAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;

    reservation: {
      startDate: string;
      endDate: string;
      departureDate: string;
      lateCheckout: boolean;
      fixedRentable: boolean;
      url: string;

      booking: {
        referenceNr: string;
        source: string;
        isOption: boolean;
        stateDescription: string;
        bookingNr: string;
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        company: string;

        channel: {
          id: string;
          name: string;
        };
      };

      numberOfGuests: {
        numberOfSeniors: number;
        numberOfAdults: number;
        numberOfAdolescents: number;
        numberOfChildren: number;
        numberOfBabies: number;
        numberOfPets: number;
      };

      rentableIdentity: {
        name: string;
        rentableTypeName: string;
        url: string;
      };
    };
  };
}

export default class SummarySection extends Component<SummarySectionProps> {
  renderStatusTableRow() {
    const { stateDescription } = this.props.reservationAgendaPeriod.reservation.booking;
    return <Translation>{(translate) => <Sidebar.TableRow header={translate('Status')} data={stateDescription} />}</Translation>;
  }

  renderChannelTableRow() {
    const { reservation } = this.props.reservationAgendaPeriod;

    return (
      <Translation>
        {(translate) => <Sidebar.TableRow header={translate('Channel')} data={<ReservationChannelBadge reservation={reservation} />} />}
      </Translation>
    );
  }

  renderRentableIdentityTableRow() {
    const { fixedRentable, rentableIdentity } = this.props.reservationAgendaPeriod.reservation;

    const data = (
      <span className="inline-flex items-center">
        <a href={rentableIdentity.url}>{formatRentableIdentity(rentableIdentity)}</a>
        {fixedRentable && <Icon icon="pin" iconSize={11} className="align-middle ml-1" />}
      </span>
    );

    return <Translation>{(translate) => <Sidebar.TableRow header={translate('Rental')} data={data} />}</Translation>;
  }

  renderArrivalDateTableRow() {
    const { startDate } = this.props.reservationAgendaPeriod.reservation;
    const data = <LocaleDate key="start-date" date={startDate} format="dddd D MMMM YYYY" />;

    return <Translation>{(translate) => <Sidebar.TableRow header={translate('Arrival date')} data={data} />}</Translation>;
  }

  renderDepartureDateTableRow() {
    const { departureDate } = this.props.reservationAgendaPeriod.reservation;
    const data = <LocaleDate key="end-date" date={departureDate} format="dddd D MMMM YYYY" />;

    return <Translation>{(translate) => <Sidebar.TableRow header={translate('Departure date')} data={data} />}</Translation>;
  }

  renderLateCheckoutTableRow() {
    return (
      <Translation>
        {(translate) => {
          const lateCheckoutTag = (
            <Tag intent={Intent.PRIMARY} style={{ marginTop: -1, marginBottom: -1 }}>
              {translate('Yes')}
            </Tag>
          );

          return (
            <Sidebar.TableRow icon={<FontAwesomeIcon icon={faClock} />} header={translate('Late check-out?')} data={lateCheckoutTag} />
          );
        }}
      </Translation>
    );
  }

  renderGuestGroupRow() {
    const GuestList = () => {
      const { numberOfSeniors, numberOfAdults, numberOfAdolescents, numberOfChildren, numberOfBabies, numberOfPets } =
        this.props.reservationAgendaPeriod.reservation.numberOfGuests;

      const descriptions: React.ReactNode[] = [];

      if (numberOfSeniors > 0) {
        descriptions.push(formatGuestTypeCount('seniors', numberOfSeniors));
      }

      if (numberOfAdults > 0) {
        descriptions.push(formatGuestTypeCount('adults', numberOfAdults));
      }

      if (numberOfAdolescents > 0) {
        descriptions.push(formatGuestTypeCount('adolescents', numberOfAdolescents));
      }

      if (numberOfChildren > 0) {
        descriptions.push(formatGuestTypeCount('children', numberOfChildren));
      }

      if (numberOfBabies > 0) {
        descriptions.push(formatGuestTypeCount('babies', numberOfBabies));
      }

      if (numberOfPets > 0) {
        descriptions.push(formatGuestTypeCount('pets', numberOfPets));
      }

      return <React.Fragment>{intersperse(descriptions, ', ')}</React.Fragment>;
    };

    return <Translation>{(translate) => <Sidebar.TableRow header={translate('Guest list')} data={<GuestList />} />}</Translation>;
  }

  maybeRenderLateCheckoutSectionContent() {
    if (this.props.reservationAgendaPeriod.reservation.lateCheckout) {
      return (
        <Sidebar.SectionContent>
          <Sidebar.Table>{this.renderLateCheckoutTableRow()}</Sidebar.Table>
        </Sidebar.SectionContent>
      );
    }

    return null;
  }

  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section>
            <Sidebar.SectionHeader>
              <Sidebar.SectionHeaderTitle title={translate('Summary')} />
            </Sidebar.SectionHeader>
            <Sidebar.SectionContent>
              <Sidebar.Table>
                {this.renderStatusTableRow()}
                {this.renderChannelTableRow()}
              </Sidebar.Table>
            </Sidebar.SectionContent>
            <Sidebar.SectionContent>
              <Sidebar.Table>
                {this.renderArrivalDateTableRow()}
                {this.renderDepartureDateTableRow()}
              </Sidebar.Table>
            </Sidebar.SectionContent>
            {this.maybeRenderLateCheckoutSectionContent()}
            <Sidebar.SectionContent>
              <Sidebar.Table>
                {this.renderRentableIdentityTableRow()}
                {this.renderGuestGroupRow()}
              </Sidebar.Table>
            </Sidebar.SectionContent>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}
