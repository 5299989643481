import React, { PureComponent, RefObject } from 'react';
import Grid from './Grid';
import Translation from '../translation';
import { RowType, RentableSegmentRowData, RentableTypeRowData } from './GridQueryData';
import { Layout } from '../settings-dialog/SettingsDialogQueryData';

interface PlaceholderGridProps {
  gridRef: RefObject<Grid>;
  parkId: string;
}

export default class PlaceholderGrid extends PureComponent<PlaceholderGridProps> {
  handleDateChange = () => {};

  render() {
    const { gridRef, ...props } = this.props;

    return (
      <Translation>
        {(translate) => {
          const user = { id: '1', planboardStartDate: null, planboardLayout: Layout.Nested };

          const rentableSegment = { id: '1', name: translate('Loading…') };
          const rentableType = { id: '1', name: '…', reference: '' };

          const row1: RentableSegmentRowData = { id: 'rentable_segment:1', type: RowType.RentableSegmentRow, rentableSegment };
          const row2: RentableTypeRowData = { id: 'rentableType:1', type: RowType.RentableTypeRow, rentableType };

          const placeholderData = { user, rows: [row1, row2] };

          return (
            <Grid
              {...props}
              ref={this.props.gridRef}
              data={placeholderData}
              isReloading={false}
              searchFormIsHidden={true}
              onDateChange={this.handleDateChange}
            />
          );
        }}
      </Translation>
    );
  }
}
