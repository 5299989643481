import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult, MutationFunction } from '@apollo/client/react';
import { loader } from 'graphql.macro';
const undoCheckInReservationMutation = loader('./undoCheckInReservationMutation.graphql');

interface UndoCheckInReservationVariables {
  reservationId: string;
}

export interface UndoCheckInReservationData {
  undoCheckInReservation: {
    id: string;
  };
}

export type UndoCheckInReservationFn = MutationFunction<UndoCheckInReservationData, UndoCheckInReservationVariables>;

export type Result = MutationResult<UndoCheckInReservationData>;

interface UndoCheckInReservationMutationProps {
  children: (undoCheckInReservation: UndoCheckInReservationFn, result: Result) => JSX.Element | null;
}

const REFETCH_QUERIES = ['checkoutFormQuery', 'moveReservationFormQuery'];

export default function UndoCheckInReservationMutation({ children }: UndoCheckInReservationMutationProps) {
  return (
    <Mutation<UndoCheckInReservationData, UndoCheckInReservationVariables>
      mutation={undoCheckInReservationMutation}
      refetchQueries={REFETCH_QUERIES}
      awaitRefetchQueries
    >
      {children}
    </Mutation>
  );
}
