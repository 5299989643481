import { ApolloError } from '@apollo/client/core';
import showErrorToast from './showErrorToast';

export default function showApolloErrorToast(apolloError: ApolloError) {
  const errorMessages = [];

  if (apolloError.networkError) {
    errorMessages.push(apolloError.networkError);
  } else if (apolloError.graphQLErrors) {
    for (const graphqlError of apolloError.graphQLErrors) {
      errorMessages.push(graphqlError.message);
    }
  } else if (apolloError.message) {
    errorMessages.push(apolloError.message);
  }

  showErrorToast({ message: errorMessages.join(', ') });
}
