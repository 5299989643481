export default function intersperse(enumerable, element) {
  if (!enumerable.length) return [];
  if (enumerable.length === 1) return enumerable.slice(0);

  var items = [enumerable[0]];
  for (var i = 1, len = enumerable.length; i < len; ++i) {
    items.push(element, enumerable[i]);
  }

  return items;
}
