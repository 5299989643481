import React from 'react';
import formatNotEnoughStockAgendaPeriod from '../../utils/formatNotEnoughStockAgendaPeriod';
import NotEnoughStockAgendaPeriodBadge from '../not-enough-stock-agenda-period-badge';
import { AgendaPeriodEventPopover } from '../agenda-period-event-popover';
import Translation from '../translation';

interface NotEnoughStockAgendaPeriodEventPopoverProps {
  notEnoughStockAgendaPeriod: {
    startDate: string;
    endDate: string;
  };
}

export default function NotEnoughStockAgendaPeriodEventPopover({
  notEnoughStockAgendaPeriod,
}: NotEnoughStockAgendaPeriodEventPopoverProps) {
  return (
    <Translation>
      {(translate) => (
        <AgendaPeriodEventPopover
          title={formatNotEnoughStockAgendaPeriod(notEnoughStockAgendaPeriod, translate)}
          startDate={notEnoughStockAgendaPeriod.startDate}
          endDate={notEnoughStockAgendaPeriod.endDate}
          renderBadge={() => <NotEnoughStockAgendaPeriodBadge />}
        />
      )}
    </Translation>
  );
}
