import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import SettingsDialogQueryData from './SettingsDialogQueryData';
const settingsDialogQuery = loader('./settingsDialogQuery.graphql');

interface SettingsDialogQueryProps {
  children: (result: QueryResult<SettingsDialogQueryData>) => JSX.Element | null;
}

export default function SettingsDialogQuery({ children }: SettingsDialogQueryProps) {
  return <Query query={settingsDialogQuery}>{children}</Query>;
}
