import { LOCATION_CHANGE, locationChangeAction, State as LocationState } from 'redux-first-routing';
import { SearchParams } from '../config/planboard';
import { SearchQuery } from '../actions/searchQuery';

export type SearchQueryState = SearchQuery;

function deserializeStateFromLocation(location: Location | LocationState) {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(SearchParams.SEARCH_QUERY) || '';
}

export default function searchQuery(
  state = deserializeStateFromLocation(window.location),
  action: locationChangeAction<string, string, string>
): SearchQueryState {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return deserializeStateFromLocation(action.payload);
    }

    default: {
      return state;
    }
  }
}
