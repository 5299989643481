import { ApolloClient, ApolloLink, HttpLink, DocumentNode } from '@apollo/client/core';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { ErrorLink } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { reduxStore } from '../../config/redux';
import { updateServerVersion } from '../../actions/planboardVersion';
import { invalidateSession, SessionData } from '../../actions/session';
import actionCableSocketLink from './action_cable_socket_link';
import cache from './cache';

const planboardVersionMiddleWare = new ApolloLink((operation, forward) => {
  return forward!(operation).map((response) => {
    const { headers } = operation.getContext().response;

    const serverVersion = headers.get('X-PLANBOARD-VERSION');
    const clientVersion = process.env.REACT_APP_PLANBOARD_VERSION;

    if (serverVersion && clientVersion && serverVersion !== clientVersion) {
      reduxStore.dispatch(updateServerVersion(serverVersion));
    }

    return response;
  });
});

// Migrated from apollo boost
// See https://www.apollographql.com/docs/react/advanced/boost-migration.html#basic-migration
const errorMiddleware = new ErrorLink(({ networkError, graphQLErrors }) => {
  if (networkError && 'statusCode' in networkError && 'result' in networkError) {
    switch (networkError.statusCode) {
      case 401: {
        reduxStore.dispatch(invalidateSession(401, networkError.result.data as SessionData));
        break;
      }

      case 403: {
        reduxStore.dispatch(invalidateSession(403, networkError.result.data as SessionData));
        break;
      }

      default: {
        // tslint:disable-next-line no-console
        console.error(`[Network error]: ${networkError}`);
      }
    }
  }

  if (graphQLErrors) {
    for (let { message, locations, path, extensions } of graphQLErrors) {
      switch ((extensions || {}).code) {
        case 401: {
          const sessionData = { message, redirectUrl: extensions.redirectUrl };
          reduxStore.dispatch(invalidateSession(401, sessionData as SessionData));
          break;
        }

        case 403: {
          const sessionData = { message, redirectUrl: extensions.redirectUrl };
          reduxStore.dispatch(invalidateSession(403, sessionData as SessionData));
          break;
        }

        default: {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        }
      }
    }
  }
});

const requestHeaders = {
  'X-PLANBOARD-VERSION': process.env.REACT_APP_PLANBOARD_VERSION,
};

const httpMiddlewares = [errorMiddleware, planboardVersionMiddleWare];

const normalHttpLink = ApolloLink.from([
  ...httpMiddlewares,
  new HttpLink({ uri: '/planboard/graphql', credentials: 'same-origin', headers: requestHeaders }),
]);

const batchHttpLink = ApolloLink.from([
  ...httpMiddlewares,

  new BatchHttpLink({
    uri: '/planboard/graphql',
    batchMax: 2,
    batchInterval: 10,
    credentials: 'same-origin',
    headers: requestHeaders,
  }),
]);

const splitHttpLink = ApolloLink.split((operation) => operation.getContext().batch === true, batchHttpLink, normalHttpLink);

const hasSubscriptionOperation = ({ query }: { query: DocumentNode }) => {
  const definition = getMainDefinition(query);

  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
};

const link = ApolloLink.split(hasSubscriptionOperation, actionCableSocketLink, splitHttpLink);

export default new ApolloClient({ link, cache });
