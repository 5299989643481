import React, { ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';

interface BadgeProps {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}

export default function Badge({ className, style, children }: BadgeProps) {
  return (
    <span className={classNames('rounded-sm', className)} style={style}>
      {children}
    </span>
  );
}
