import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import PeriodHeaderQueryData from './PeriodHeaderQueryData';
import { DateRange } from 'moment-range';
const periodHeaderQuery = loader('./periodHeaderQuery.graphql');

interface PeriodHeaderQueryProps {
  parkId: string;
  period: DateRange;
  children: (result: QueryResult<PeriodHeaderQueryData, Variables>) => JSX.Element | null;
}

interface Variables {
  parkId: string;
  period: string[];
}

export default function PeriodHeaderQuery({ parkId, period, children }: PeriodHeaderQueryProps) {
  let $period = [period.start, period.end].map((d) => d.format('YYYY-MM-DD'));
  let variables = { parkId, period: $period };

  return (
    <Query<PeriodHeaderQueryData, Variables> query={periodHeaderQuery} variables={variables}>
      {children}
    </Query>
  );
}
