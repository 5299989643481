import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import TargetRentableSelectQueryData from './TargetRentableSelectQueryData';
import serializeDate from '../../utils/serializeDate';
import { SLOW_QUERY_POLL_INTERVAL } from '../../config/planboard';
const targetRentableSelectQuery = loader('./targetRentableSelectQuery.graphql');

interface TargetRentableSelectQueryProps {
  sourceReservationAgendaPeriod: {
    parkId: string;
    startDate: string;
    endDate: string;
  };
  children: (result: QueryResult<TargetRentableSelectQueryData, Variables>) => JSX.Element | null;
}

interface Variables {
  parkId: string;
  startDate: string;
  endDate: string;
}

export default function TargetRentableSelectQuery({ sourceReservationAgendaPeriod, children }: TargetRentableSelectQueryProps) {
  const { parkId, startDate, endDate } = sourceReservationAgendaPeriod;

  const $startDate = serializeDate(startDate);
  const $endDate = serializeDate(endDate);

  const variables = {
    parkId,
    startDate: $startDate,
    endDate: $endDate,
  };

  return (
    <Query<TargetRentableSelectQueryData, Variables>
      query={targetRentableSelectQuery}
      variables={variables}
      pollInterval={SLOW_QUERY_POLL_INTERVAL}
    >
      {children}
    </Query>
  );
}
