import React, { PureComponent } from 'react';
import MultiSelectFilter from './MultiSelectFilter';
import { ITagProps } from '@blueprintjs/core';
import calculateLabelStyle from '../../utils/calculateLabelStyle';
import Translation from '../translation';

interface RentableIdentityLabel {
  id: string;
  name: string;
  foregroundColor: string;
  backgroundColor: string;
}

interface RentableIdentityLabelFilterProps {
  rentableIdentityLabels: RentableIdentityLabel[];
  selectedRentableIdentityLabels: RentableIdentityLabel[];
  inputRef?: (input: HTMLInputElement | null) => void;
  onChange: (selectedRentableIdentityLabels: RentableIdentityLabel[]) => void;
}

export default class RentableIdentityLabelFilter extends PureComponent<RentableIdentityLabelFilterProps> {
  getTagProps = (_value: React.ReactNode, index: number): ITagProps => {
    const rentableIdentityLabel = this.props.selectedRentableIdentityLabels[index];
    return { className: 'rounded-sm', style: calculateLabelStyle(rentableIdentityLabel) };
  };

  render() {
    const { rentableIdentityLabels, selectedRentableIdentityLabels, inputRef, onChange } = this.props;

    return (
      <Translation>
        {(translate) => (
          <MultiSelectFilter<RentableIdentityLabel>
            items={rentableIdentityLabels}
            selectedItems={selectedRentableIdentityLabels}
            placeholder={translate('Filter by label…')}
            tagProps={this.getTagProps}
            inputRef={inputRef}
            onChange={onChange}
          />
        )}
      </Translation>
    );
  }
}
