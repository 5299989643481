import Translation from '../components/translation';

export default function formatGuestTypeCount(key: string, count: number) {
  switch (key) {
    case 'seniors':
      return (
        <Translation key="seniors">
          {(translate) => translate(count === 1 ? `{{count}} senior` : `{{count}} seniors`, { count })}
        </Translation>
      );
    case 'adults':
      return (
        <Translation key="adults">{(translate) => translate(count === 1 ? `{{count}} adult` : `{{count}} adults`, { count })}</Translation>
      );
    case 'adolescents':
      return (
        <Translation key="adolescents">
          {(translate) => translate(count === 1 ? `{{count}} adolescent` : `{{count}} adolescents`, { count })}
        </Translation>
      );
    case 'children':
      return (
        <Translation key="children">
          {(translate) => translate(count === 1 ? `{{count}} child` : `{{count}} children`, { count })}
        </Translation>
      );
    case 'babies':
      return (
        <Translation key="babies">{(translate) => translate(count === 1 ? `{{count}} baby` : `{{count}} babies`, { count })}</Translation>
      );
    case 'pets':
      return <Translation key="pets">{(translate) => translate(count === 1 ? `{{count}} pet` : `{{count}} pets`, { count })}</Translation>;
    default:
      return null;
  }
}
