import React, { Component, RefObject } from 'react';
import moment from 'moment';
import sumBy from 'lodash/sumBy';
import { FormGroup, Callout, Intent, Button, Icon } from '@blueprintjs/core';
import { TimePicker } from '@blueprintjs/datetime';
import { showApolloErrorToast, showSuccessToast } from '../toaster';
import DateInput from '../date-input';
import { DefaultTooltip } from '../tooltip';
import SubmitButton from './SubmitButton';
import UndoButton from './UndoButton';
import formatAmount from '../../utils/formatAmount';
import CheckoutFormQueryData from './CheckoutFormQueryData';
import { UndoCheckOutReservationFn } from './UndoCheckOutReservationMutation';
import { CheckOutReservationFn } from './CheckOutReservationMutation';
import Translation from '../translation';
import { Trans } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { MapDispatchToProps, connect } from 'react-redux';
import { State } from '../../reducers';
import * as actions from '../../actions/sidebarPanel';
// tslint:disable-next-line:no-duplicate-imports
import { SidebarType } from '../../actions/sidebarPanel';

interface OwnProps {
  data: CheckoutFormQueryData;
  checkOut: CheckOutReservationFn;
  undoCheckOut: UndoCheckOutReservationFn;
  isCheckingOut: boolean;
  isUndoingCheckOut: boolean;
}

interface CheckoutFormState {
  hasDismissedUnpaidInvoicesWarning: boolean;
  hasCheckoutDateBeforeEndDate: boolean;
}

interface DispatchProps {
  openSidebarPanel: (sidebarType: SidebarType, id: string) => void;
  closeSidebarPanel: () => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, State> = {
  openSidebarPanel: actions.openSidebarPanel,
  closeSidebarPanel: actions.closeSidebarPanel,
};

type CheckoutFormProps = OwnProps & DispatchProps;

class CheckoutForm extends Component<CheckoutFormProps, CheckoutFormState> {
  state = { hasDismissedUnpaidInvoicesWarning: false, hasCheckoutDateBeforeEndDate: false };

  private dateInputRef: RefObject<any> = React.createRef();
  private timePickerRef: RefObject<TimePicker> = React.createRef();

  componentDidMount() {
    let { endDate: reservationEndDate } = this.props.data.reservation;
    let { checkoutDate } = this.props.data.reservation.checkoutForm;

    this.updateHasCheckoutDateBeforeEndDate({ reservationEndDate, checkoutDate });
  }

  updateHasCheckoutDateBeforeEndDate = ({
    reservationEndDate,
    checkoutDate,
  }: {
    reservationEndDate: string;
    checkoutDate: Date | string;
  }) => {
    let hasCheckoutDateBeforeEndDate = moment(checkoutDate).isBefore(reservationEndDate, 'days');
    return this.setState({ hasCheckoutDateBeforeEndDate });
  };

  get hasUnpaidAmountForGuest() {
    let { guestInvoices } = this.props.data.reservation.booking;
    let { hasDismissedUnpaidInvoicesWarning } = this.state;
    const foreignTotalOpen = sumBy(guestInvoices, (invoice) => parseFloat(invoice.foreignTotalOpen));

    return foreignTotalOpen > 0 && !hasDismissedUnpaidInvoicesWarning;
  }

  get hasWarnings() {
    return this.hasUnpaidAmountForGuest;
  }

  get canCheckOut() {
    let { booking, isCheckedIn, isCheckedOut } = this.props.data.reservation;
    return booking.isConfirmed && isCheckedIn && !isCheckedOut && !this.hasWarnings;
  }

  get canUndo() {
    let { isCheckedIn, isCheckedOut } = this.props.data.reservation;
    return isCheckedIn && isCheckedOut;
  }

  dismissUnpaidInvoicesWarning = () => {
    this.setState({ hasDismissedUnpaidInvoicesWarning: true });
  };

  handleDateInputChange = (checkoutDate: Date) => {
    let { endDate: reservationEndDate } = this.props.data.reservation;
    this.updateHasCheckoutDateBeforeEndDate({ reservationEndDate, checkoutDate });
  };

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let { id: reservationId } = this.props.data.reservation;
    let { checkOut } = this.props;

    let dateInput = this.dateInputRef.current!;
    let timePicker = this.timePickerRef.current!;

    let checkoutDate = moment(dateInput.state.value).format('YYYY-MM-DD');
    let checkoutHour = timePicker.state.hourText!;
    let checkoutMinute = timePicker.state.minuteText!;

    try {
      const result = await checkOut({ variables: { reservationId, checkoutDate, checkoutHour, checkoutMinute } });
      await this.reopenSidebar(result.data!.checkOutReservation);

      const message = (
        <Translation>
          {(translate) => translate('Reservation checked out at {{checkoutHour}}:{{checkoutMinute}}', { checkoutHour, checkoutMinute })}
        </Translation>
      );

      showSuccessToast({ message });
    } catch (e) {
      if (e instanceof ApolloError) {
        showApolloErrorToast(e);
      } else {
        throw e;
      }
    }
  };

  handleUndoCheckOutButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();

    let { id: reservationId } = this.props.data.reservation;
    let { undoCheckOut } = this.props;

    try {
      const result = await undoCheckOut({ variables: { reservationId } });
      await this.reopenSidebar(result.data!.undoCheckOutReservation);
      const message = <Translation>{(translate) => translate('Reservation checkout undone')}</Translation>;
      showSuccessToast({ message });
    } catch (e) {
      if (e instanceof ApolloError) {
        showApolloErrorToast(e);
      } else {
        throw e;
      }
    }
  };

  async reopenSidebar(reservation: { reservationAgendaPeriods: { id: string }[] }) {
    let { openSidebarPanel, closeSidebarPanel } = this.props;

    closeSidebarPanel();

    // Wait till the sidebar close animation has finished
    await new Promise((resolve) => setTimeout(resolve, 150));

    const reservationAgendaPeriod = reservation.reservationAgendaPeriods[0];

    if (reservationAgendaPeriod) {
      // Open the reservation agenda period that was just created in the sidebar
      openSidebarPanel(SidebarType.ReservationAgendaPeriod, reservationAgendaPeriod.id);
    }
  }

  renderCheckoutDateBeforeEndDateNotice() {
    return (
      <Translation>
        {(translate) => (
          <Callout icon={null} intent={Intent.PRIMARY}>
            {translate('Check out date is before the departure date. The reservation will be shortened automatically')}
          </Callout>
        )}
      </Translation>
    );
  }

  renderUnpaidInvoicesWarning() {
    const { guestInvoices, foreignCurrency } = this.props.data.reservation.booking;
    const formattedAmount = formatAmount(
      sumBy(guestInvoices, (unpaidInvoice) => parseFloat(unpaidInvoice.foreignTotalOpen)),
      foreignCurrency
    );

    // Assume the outstanding amount concerns first guest invoice
    // reasonable assumption since all modern reservations have only 1 invoice
    const newPaymentUrl = guestInvoices[0].newPaymentUrl;

    return (
      <Translation>
        {(translate) => (
          <Callout intent={Intent.WARNING} icon={null}>
            <div className="flex">
              <aside className="flex-grow">
                <Trans formattedAmount={formattedAmount}>
                  The guest has an outstanding amount of {{ formattedAmount }}. You may{' '}
                  <a href={newPaymentUrl} target="_blank" rel="noopener noreferrer">
                    manually add a payment
                  </a>{' '}
                  for the missing amount
                </Trans>
              </aside>
              <DefaultTooltip title={translate('Ignore this warning')}>
                <Button
                  minimal
                  className="rounded-full min-h-0 py-1 -mr-1"
                  icon={<Icon icon="cross" iconSize={11} className="mr-1" />}
                  intent={Intent.WARNING}
                  onClick={() => this.dismissUnpaidInvoicesWarning()}
                >
                  {translate('Ignore')}
                </Button>
              </DefaultTooltip>
            </div>
          </Callout>
        )}
      </Translation>
    );
  }

  render() {
    const { isCheckedIn, isCheckedOut } = this.props.data.reservation;
    const { checkoutDate, checkoutTime } = this.props.data.reservation.checkoutForm;
    const { isCheckingOut, isUndoingCheckOut } = this.props;

    const isPresent = isCheckedIn && !isCheckedOut;
    const isDisabled = !this.canCheckOut;

    return (
      <Translation>
        {(translate) => (
          <form onSubmit={this.handleSubmit} className="space-between-4">
            <main className="flex">
              <FormGroup label={<strong>{translate('Date')}</strong>} className="flex-grow mb-0" disabled={isDisabled}>
                <DateInput
                  defaultValue={moment(checkoutDate, moment.HTML5_FMT.DATE).toDate()}
                  disabled={isDisabled}
                  ref={this.dateInputRef}
                  onChange={this.handleDateInputChange}
                />
              </FormGroup>

              <FormGroup label={<strong>{translate('Time')}</strong>} className="flex-grow mb-0 ml-2" disabled={isDisabled}>
                <TimePicker
                  defaultValue={moment(checkoutTime, moment.HTML5_FMT.TIME_MS).toDate()}
                  disabled={isDisabled}
                  ref={this.timePickerRef}
                />
              </FormGroup>
            </main>

            <main className="space-between-4">
              {isPresent && this.state.hasCheckoutDateBeforeEndDate && this.renderCheckoutDateBeforeEndDateNotice()}
              {isPresent && this.hasUnpaidAmountForGuest && this.renderUnpaidInvoicesWarning()}
            </main>

            <footer className="flex">
              <SubmitButton isCheckingOut={isCheckingOut} isDisabled={isDisabled} />
              {this.canUndo && (
                <UndoButton isUndoingCheckOut={isUndoingCheckOut} onClick={this.handleUndoCheckOutButtonClick} className="ml-2" />
              )}
            </footer>
          </form>
        )}
      </Translation>
    );
  }
}

export default connect(null, mapDispatchToProps)(CheckoutForm);
