import React, { PureComponent } from 'react';
import Sidebar from '../sidebar';
import { Tag } from '@blueprintjs/core';
import sortBy from 'lodash/sortBy';
import Translation from '../translation';

interface AmenitySectionProps {
  amenities: Array<{
    id: string;
    name: string;
  }>;
}

export default class AmenitySection extends PureComponent<AmenitySectionProps> {
  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Desired amenities of the rental')}>
            <div className="flex flex-wrap -m-1">
              {sortBy(this.props.amenities, 'name').map((amenity) => (
                <Tag minimal key={amenity.id} className="rounded-sm m-1 p-1" children={amenity.name} />
              ))}
            </div>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}
