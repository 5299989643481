import { Dispatch, Action } from 'redux';
import { State } from '../reducers';
import { PUSH, push as pushLocationAction } from 'redux-first-routing';
import mergeSearchParam from '../utils/mergeSearchParam';
import deleteSearchParam from '../utils/deleteSearchParam';
import serializeIds from '../utils/serializeIds';
import { SearchParams } from '../config/planboard';
import serializeObject from '../utils/serializeObject';

export enum FilterToggle {
  OPEN = 'open',
  CLOSED = 'closed',
}

export const toggleFilterPanel = () => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
  const { filterPanel } = getState();

  if (filterPanel.isOpen) {
    closeFilterPanel()(dispatch, getState);
  } else {
    openFilterPanel()(dispatch, getState);
  }
};

export const openFilterPanel = () => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
  const { router } = getState();
  const search = mergeSearchParam(router.search, SearchParams.FILTER, FilterToggle.OPEN).toString();
  dispatch(pushLocationAction({ search }));
};

export const closeFilterPanel = () => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
  const router = getState().router;
  const search = mergeSearchParam(router.search, SearchParams.FILTER, FilterToggle.CLOSED).toString();

  dispatch(pushLocationAction({ search }));
};

export const resetFilterPanel = () => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
  let searchParams = new URLSearchParams(getState().router.search);

  searchParams = deleteSearchParam(searchParams, SearchParams.FILTER);
  searchParams = deleteSearchParam(searchParams, SearchParams.GUEST_GROUP);
  searchParams = deleteSearchParam(searchParams, SearchParams.RENTABLE_SEGMENTS);
  searchParams = deleteSearchParam(searchParams, SearchParams.RENTABLE_TYPES);
  searchParams = deleteSearchParam(searchParams, SearchParams.LABELS);
  searchParams = deleteSearchParam(searchParams, SearchParams.AMENITIES);

  dispatch(pushLocationAction({ search: searchParams.toString() }));
};

const updateFilterPanel = (key: string, ids: string[]) => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
  const router = getState().router;
  const searchParams = ids.length > 0 ? mergeSearchParam(router.search, key, serializeIds(ids)) : deleteSearchParam(router.search, key);

  dispatch(pushLocationAction({ search: searchParams.toString() }));
};

export const updateFilterPanelGuestGroup =
  (guestGroup: { [key: string]: string }) => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
    const router = getState().router;
    const searchParams = mergeSearchParam(router.search, SearchParams.GUEST_GROUP, serializeObject(guestGroup));

    dispatch(pushLocationAction({ search: searchParams.toString() }));
  };

export const updateFilterPanelRentableSegments =
  (selectedRentableSegmentIds: string[]) => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
    updateFilterPanel(SearchParams.RENTABLE_SEGMENTS, selectedRentableSegmentIds)(dispatch, getState);
  };

export const updateFilterPanelRentableTypes =
  (selectedRentableTypeIds: string[]) => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
    updateFilterPanel(SearchParams.RENTABLE_TYPES, selectedRentableTypeIds)(dispatch, getState);
  };

export const updateFilterPanelRentableIdentityLabels =
  (ids: string[]) => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
    updateFilterPanel(SearchParams.LABELS, ids)(dispatch, getState);
  };

export const updateFilterPanelAmenities =
  (selectedAmenityIds: string[]) => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
    updateFilterPanel(SearchParams.AMENITIES, selectedAmenityIds)(dispatch, getState);
  };
