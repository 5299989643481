import React from 'react';
import CheckinForm from '../checkin-form';

interface CheckinReservationPanelProps {
  reservation: { id: string };
}

export default function CheckinReservationPanel({ reservation }: CheckinReservationPanelProps) {
  return <CheckinForm reservationId={reservation.id} />;
}
