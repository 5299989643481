import React, { PureComponent } from 'react';
import classes from './RentableSegmentCell.module.css';
import Translation from '../translation';

interface RentableSegmentCellProps {
  rentableSegment: { name: string };
  onMouseDown: (e: React.MouseEvent) => void;
}

export default class RentableSegmentCell extends PureComponent<RentableSegmentCellProps> {
  renderTitle() {
    return <span className={classes.title}>{this.props.rentableSegment.name}</span>;
  }

  render() {
    return (
      <Translation>
        {(translate) => (
          <header className={classes.root} onMouseDown={this.props.onMouseDown} title={translate('Grab to drag forward or backward')}>
            {this.renderTitle()}
          </header>
        )}
      </Translation>
    );
  }
}
