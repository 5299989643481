import React, { PureComponent } from 'react';
import GroupedMultiSelectFilter from './GroupedMultiSelectFilter';
import Translation from '../translation';

interface Amenity {
  id: string;
  name: string;
  groupBy: { id: string; name: string };
}

interface AmenityFilterProps {
  amenities: Amenity[];
  selectedAmenities: Amenity[];
  inputRef?: (input: HTMLInputElement | null) => void;
  onChange: (selectedAmenities: Amenity[]) => void;
}

export default class AmenityFilter extends PureComponent<AmenityFilterProps> {
  render() {
    const { amenities, selectedAmenities, inputRef, onChange } = this.props;

    return (
      <Translation>
        {(translate) => (
          <GroupedMultiSelectFilter<Amenity>
            items={amenities}
            selectedItems={selectedAmenities}
            placeholder={translate('Filter by amenity…')}
            inputRef={inputRef}
            onChange={onChange}
          />
        )}
      </Translation>
    );
  }
}
