import React, { PureComponent } from 'react';
import classes from './RentableSegmentHeader.module.css';

interface RentableSegmentHeaderProps {
  rentableSegment: {
    name: string;
  };
}

export default class RentableSegmentHeader extends PureComponent<RentableSegmentHeaderProps> {
  renderTitle() {
    const { rentableSegment } = this.props;
    return <span className={classes.title}>{rentableSegment.name}</span>;
  }

  render() {
    return <header className={classes.root}>{this.renderTitle()}</header>;
  }
}
