import React from 'react';
import Badge from '../badge';
import classNames from 'classnames';

interface LegendItemProps {
  badgeClassName: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

export default function LegendItem({ badgeClassName, icon, children }: LegendItemProps) {
  return (
    <div className="flex items-center">
      <Badge className={classNames(badgeClassName, 'w-4 h-4 block inline-flex items-center justify-center')}>{icon}</Badge>
      <span className="ml-4">{children}</span>
    </div>
  );
}
