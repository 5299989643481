import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Callout, Intent } from '@blueprintjs/core';
import Translation from '../translation';

interface SetupSectionProps {
  currentRentable: null | {
    activeFrom: string;
    activeTill: string | null;
  };
}

export default class SetupSection extends Component<SetupSectionProps> {
  renderForRentalCallout(forRentalSince: string) {
    return (
      <Callout intent={Intent.SUCCESS} icon={null}>
        <Translation>
          {(translate) => translate('This rental is being rented since {{forRentalSince, date | L}}', { forRentalSince })}
        </Translation>
      </Callout>
    );
  }

  renderInactiveCallout() {
    return (
      <Callout intent={Intent.NONE}>
        <Translation>{(translate) => translate('This rental is inactive')}</Translation>
      </Callout>
    );
  }

  renderCallout() {
    const { currentRentable } = this.props;

    if (currentRentable) {
      return this.renderForRentalCallout(currentRentable.activeFrom);
    } else {
      return this.renderInactiveCallout();
    }
  }

  render() {
    return <Translation>{(translate) => <Sidebar.Section title={translate('Setup')}>{this.renderCallout()}</Sidebar.Section>}</Translation>;
  }
}
