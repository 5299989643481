import React from 'react';
import { Icon, Intent } from '@blueprintjs/core';
import LoadingButton from '../loading-button';
import Translation from '../translation';

interface SubmitButtonProps {
  isCheckingOut: boolean;
  isDisabled: boolean;
}

export default function SubmitButton({ isCheckingOut, isDisabled }: SubmitButtonProps) {
  return (
    <LoadingButton
      type="submit"
      isLoading={isCheckingOut}
      isDisabled={isCheckingOut || isDisabled}
      intent={Intent.PRIMARY}
      icon={<Icon icon="log-out" iconSize={12} />}
    >
      <Translation>{(translate) => translate('Check out')}</Translation>
    </LoadingButton>
  );
}
