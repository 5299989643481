import React from 'react';
import Translation from '../translation';
import { DefaultTooltip } from '../tooltip';

interface FlagProps {
  locale: string;
}

enum FlagTitle {
  cs = 'Czech',
  da = 'Danish',
  de = 'German',
  en = 'English',
  es = 'Spanish',
  fr = 'French',
  nl = 'Dutch',
  pt = 'Portuguese',
  tr = 'Turkish',
}

enum FlagEmoji {
  cs = '🇨🇿',
  da = '🇩🇰',
  de = '🇩🇪',
  en = '🇺🇸󠁧󠁢󠁥󠁮󠁧󠁿',
  es = '🇪🇸',
  fr = '🇫🇷',
  nl = '🇳🇱',
  pt = '🇵🇹',
  tr = '🇹🇷',
}

export default function Flag({ locale }: FlagProps) {
  if (!(locale in FlagTitle) || !(locale in FlagEmoji)) {
    return null;
  }

  const title = FlagTitle[locale as keyof typeof FlagTitle];
  const emoji = FlagEmoji[locale as keyof typeof FlagEmoji];

  return (
    <Translation>
      {(translate) => (
        <DefaultTooltip title={translate(title)} className="p-2 -my-2 cursor-default">
          {emoji}
        </DefaultTooltip>
      )}
    </Translation>
  );
}
