import { LOCATION_CHANGE, locationChangeAction, State as LocationState } from 'redux-first-routing';
import { SearchParams, TODAY } from '../config/planboard';
import serializeDate from '../utils/serializeDate';

export type CurrentDateState = string;

function deserializeStateFromLocation(location: Location | LocationState) {
  const dateQueryParam = new URLSearchParams(location.search).get(SearchParams.DATE);
  return dateQueryParam ? dateQueryParam : serializeDate(TODAY);
}

export default function currentDate(
  state = deserializeStateFromLocation(window.location),
  action: locationChangeAction<string, string, string>
): CurrentDateState {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return deserializeStateFromLocation(action.payload);
    }

    default: {
      return state;
    }
  }
}
