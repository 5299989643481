import React, { Component } from 'react';
import { Callout, Intent, Button, H6, Icon } from '@blueprintjs/core';
import Translation from '../../components/translation';

interface BookingModeNoticeProps {
  booking: {
    id: string;
    bookingNr: string;
  };
  onCancelButtonClick: () => void;
}

export default class BookingModeNotice extends Component<BookingModeNoticeProps> {
  renderCancelButton() {
    return (
      <Translation>
        {(translate) => (
          <Button rightIcon="small-cross" minimal small intent={Intent.DANGER} className="ml-auto" onClick={this.props.onCancelButtonClick}>
            {translate('Cancel')}
          </Button>
        )}
      </Translation>
    );
  }

  render() {
    const { bookingNr } = this.props.booking;

    return (
      <Translation>
        {(translate) => (
          <div className="bg-white">
            <Callout
              icon={<Icon icon="small-plus" iconSize={24} className="my-0" />}
              className="flex items-center border-t border-b"
              intent={Intent.SUCCESS}
            >
              <H6 className="ml-1 my-0">{translate('Add reservation to booking ({{bookingNr}})', { bookingNr })}</H6>
              {this.renderCancelButton()}
            </Callout>
          </div>
        )}
      </Translation>
    );
  }
}
