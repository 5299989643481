import React from 'react';
import CheckoutForm from '../checkout-form';

interface CheckoutReservationPanelProps {
  reservation: { id: string };
}

export default function CheckoutReservationPanel({ reservation }: CheckoutReservationPanelProps) {
  return <CheckoutForm reservationId={reservation.id} />;
}
