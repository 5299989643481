import React, { PureComponent } from 'react';
import MoveReservationForm from './MoveReservationForm';
import MoveReservationFormQuery from './MoveReservationFormQuery';
import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../reducers';
import * as actions from '../../actions/moveAction';
import isEmpty from 'lodash/isEmpty';
import MoveReservationFormQueryData from './MoveReservationFormQueryData';
import MoveReservationMutation from './MoveReservationMutation';

interface OwnProps {
  reservationAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;

    reservation: {
      id: string;
      startDate: string;
      endDate: string;
      parkId: string;
      rentableId: string;
    };
  };
}

const mapStateToProps = (state: State) => {
  const { moveAction } = state;

  if (moveAction.state === 'final') {
    return { targetRentableId: moveAction.targetRentableId };
  } else {
    return { targetRentableId: undefined };
  }
};

const mapDispatchToProps = {
  setMoveActionTargetRentable: actions.setMoveActionTargetRentable,
  clearMoveActionTargetRentable: actions.clearMoveActionTargetRentable,
};

type MoveReservationFormContainerProps = ConnectedProps<typeof connector> & OwnProps;

class MoveReservationFormContainer extends PureComponent<MoveReservationFormContainerProps> {
  private lastData?: MoveReservationFormQueryData;

  handleTargetRentableSelectChange = (rentableId?: string) => {
    const { setMoveActionTargetRentable, clearMoveActionTargetRentable } = this.props;

    if (rentableId) {
      setMoveActionTargetRentable({ id: rentableId });
    } else {
      clearMoveActionTargetRentable();
    }
  };

  handleQueryCompleted = (data: MoveReservationFormQueryData) => {
    this.lastData = isEmpty(data) ? undefined : (data as MoveReservationFormQueryData);
  };

  render() {
    const { reservationAgendaPeriod, targetRentableId } = this.props;

    return (
      <MoveReservationFormQuery
        reservationAgendaPeriodId={reservationAgendaPeriod.id}
        startDate={reservationAgendaPeriod.startDate}
        endDate={reservationAgendaPeriod.endDate}
        targetRentableId={targetRentableId}
        onCompleted={this.handleQueryCompleted}
      >
        {({ data, loading }) => (
          <MoveReservationMutation>
            {(moveReservation, { loading: isSubmitting }) => {
              const props = {
                onTargetRentableSelectChange: this.handleTargetRentableSelectChange,
                moveReservation,
                isSubmitting,
              };

              if (!loading && !isEmpty(data)) {
                return <MoveReservationForm data={data!} {...props} />;
              }

              if (loading && !isEmpty(this.lastData)) {
                return <MoveReservationForm data={this.lastData!} {...props} />;
              }

              return null;
            }}
          </MoveReservationMutation>
        )}
      </MoveReservationFormQuery>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MoveReservationFormContainer);
