import React, { PureComponent, RefObject } from 'react';
import {
  AutoSizer,
  Grid as VirtualizedGrid,
  defaultCellRangeRenderer,
  Index,
  GridCellRenderer,
  GridCellRangeRenderer,
  ScrollParams,
} from 'react-virtualized';
import moment, { Moment } from 'moment';
import debounce from 'lodash/debounce';
import CellStyle from './CellStyle';
import Cell from './Cell';
import { INITIAL_DATE, SearchParams, PERIODS } from '../../config/planboard';
import DateHeader from '../date-header';
import PeriodHeader from '../period-header';
import RentableSegmentHeader from '../rentable-segment-header';
import RentableSegmentCell from '../rentable-segment-cell';
import RentableTypeHeader from '../rentable-type-header';
import RentableTypeCell from '../rentable-type-cell';
import RentableIdentityHeaders from '../rentable-identity-headers';
import RentableIdentityCells from '../rentable-identity-cells';
import RentableHeaders from '../rentable-headers';
import RentableCells from '../rentable-cells';
import classes from './Grid.module.css';
import { DateRange } from 'moment-range';
import GridQueryData, { RentableSegmentData, RentableTypeData, RentableIdentityData, RentableData, RowType } from './GridQueryData';
import isRegularClick from '../../utils/isRegularClick';
import TodayMarker from './TodayMarker';
import SearchForm from './SearchQueryFormContainer';
import AvailabilityFilterMarker from './AvailabilityFilterMarkerContainer';
import MoveActionOverlay from './MoveActionOverlayContainer';
import isEqual from 'lodash/isEqual';
import memoizedMap from '../../utils/memoizedMap';
import classNames from 'classnames';
import { Layout } from '../settings-dialog/SettingsDialogQueryData';

export const CELL_WIDTH = 32;
const ROW_HEIGHT = 32;
const FIXED_HEADER_HEIGHT = 80;
const FIXED_COLUMN_WIDTH = 256;
const COLUMN_WIDTH = CELL_WIDTH * 7 * 4;
const RENTABLE_SEGMENT_ROW_HEIGHT = 20;
const RENTABLE_TYPE_ROW_HEIGHT = 20;
const RENTABLE_IDENTITY_ROW_HEIGHT = ROW_HEIGHT;
const RENTABLE_ROW_HEIGHT = ROW_HEIGHT;

export type GridRef = React.RefObject<Grid>;

interface GridProps {
  parkId: string;
  data: GridQueryData;
  isReloading: boolean;
  searchFormIsHidden?: boolean;
  onDateChange: (date: Moment) => void;
}

interface GridState {
  scrollLeft?: number;
  scrollTop?: number;
  isDragging: boolean;
}

const normalizeScrollOffset = (scrollOffset: number) => Math.max(0, scrollOffset);

export default class Grid extends PureComponent<GridProps, GridState> {
  state: GridState = { isDragging: false };

  private virtualGridRef: RefObject<VirtualizedGrid> = React.createRef();

  private draggingStartScrollX?: number;
  private draggingStartMouseX?: number;
  private requestAnimationFrameId: number = window.requestAnimationFrame(() => {});

  get fixedRowCount() {
    return 1;
  }

  get rowCount() {
    return this.fixedRowCount + this.props.data.rows.length;
  }

  get fixedColumnCount() {
    return 1;
  }

  get columnCount() {
    return this.fixedColumnCount + PERIODS.length;
  }

  get overscanRowCount() {
    const { planboardLayout } = this.props.data.user;

    // Decide the number of overscan rows to render depending on the layout that is chosen.
    // Take into account that a RentablesRow / RentableIdentitiesRow itself contains about 25 rows
    // (while RentableTypeRow and RentableSegmentRow only contain one)
    switch (planboardLayout) {
      case Layout.Nested:
        return 3;
      case Layout.Flat:
      case Layout.OrderByType:
      case Layout.OrderByObject:
        return 1;
      default:
        return 1;
    }
  }

  componentDidMount() {
    this.scrollToPositionFromQueryParams();

    window.addEventListener('mousemove', this.handleWindowMouseMove);
    window.addEventListener('mouseup', this.handleWindowMouseUp);
  }

  componentDidUpdate(prevProps: GridProps, _prevState: GridState) {
    if (!isEqual(this.props.data.rows, prevProps.data.rows)) {
      this.virtualGridRef.current!.recomputeGridSize();

      // Make sure the grid is at the correct scroll position again after the data has changed, fixes / related to:
      // - https://github.com/bookingexperts/support/issues/5538
      // - https://github.com/bookingexperts/support/issues/5538
      // - https://github.com/bookingexperts/support/issues/6209
      this.requestAnimationFrameId = window.requestAnimationFrame(() => this.scrollToPositionFromQueryParams());
    }
  }

  componentWillUnmount() {
    this.debouncedHandleScroll.cancel();

    window.removeEventListener('mousemove', this.handleWindowMouseMove);
    window.removeEventListener('mouseup', this.handleWindowMouseUp);

    window.cancelAnimationFrame(this.requestAnimationFrameId);
  }

  handleWeekHeaderMouseDown = (e: React.MouseEvent) => this.handleDragging(e);
  handleRentableSegmentHeaderMouseDown = (e: React.MouseEvent) => this.handleDragging(e);
  handleRentableTypeCellMouseDown = (e: React.MouseEvent) => this.handleDragging(e);

  handleDragging = (e: React.MouseEvent) => {
    if (isRegularClick(e)) {
      this.draggingStartScrollX = this.virtualGridRef.current!.state.scrollLeft;
      this.draggingStartMouseX = e.clientX;

      window.document.body.style.cursor = 'grabbing';
      window.document.body.style.userSelect = 'none';

      this.setState({ isDragging: true });
    }
  };

  handleWindowMouseMove = (e: Event) => {
    const { isDragging } = this.state;

    if (isDragging && this.draggingStartScrollX && this.draggingStartMouseX) {
      this.scrollTo({ scrollLeft: this.draggingStartScrollX + (this.draggingStartMouseX - (e as MouseEvent).clientX) });
    }
  };

  handleWindowMouseUp = (_e: Event) => {
    window.document.body.style.cursor = 'initial';
    window.document.body.style.userSelect = 'initial';

    this.draggingStartMouseX = undefined;
    this.draggingStartScrollX = undefined;

    this.setState({ isDragging: false });
  };

  handleDateChange = (date: Moment) => {
    this.scrollToDate(date);
    this.props.onDateChange(date);
  };

  handleScroll = ({ scrollLeft }: ScrollParams) => {
    // Ignore this, most like means react-virtualized is recalculating grid dimensions
    // (... or user actually scrolled all the way to the left)
    if (scrollLeft === 0) {
      return;
    }

    this.props.onDateChange(this.calculateDate({ scrollLeft }));
  };

  // tslint:disable-next-line member-ordering
  debouncedHandleScroll = debounce(this.handleScroll, 300, { leading: true });

  scrollTo = ({ scrollLeft, scrollTop }: { scrollLeft?: number; scrollTop?: number }) => {
    const newScrollState = {
      ...(scrollLeft === undefined ? {} : { scrollLeft: normalizeScrollOffset(scrollLeft) }),
      ...(scrollTop === undefined ? {} : { scrollTop: normalizeScrollOffset(scrollTop) }),
    };

    this.setState(newScrollState, () => this.setState({ scrollLeft: undefined, scrollTop: undefined }));
  };

  scrollToDate = (date: Moment) => {
    this.scrollTo({ scrollLeft: this.calculateScrollLeft({ date }) });
  };

  scrollToPositionFromQueryParams = () => {
    this.scrollToDateFromQueryParam();
    this.scrollToRentableFromQueryParam();
  };

  scrollToDateFromQueryParam = () => {
    const { planboardStartDate } = this.props.data.user;

    const dateQueryParam = new URLSearchParams(window.location.search).get(SearchParams.DATE);
    const initialDate = dateQueryParam ? moment(dateQueryParam, 'YYYY-MM-DD') : moment(planboardStartDate || INITIAL_DATE);

    this.scrollToDate(initialDate);
  };

  scrollToRentableFromQueryParam = () => {
    const { rows } = this.props.data;

    const rentableIdQueryParam = new URLSearchParams(window.location.search).get(SearchParams.RENTABLE_ID);

    if (rentableIdQueryParam) {
      const { RentableIdentitiesRow, RentablesRow } = RowType;

      // Spacing before & above
      const rowsBefore = 3;

      let scrollTop = 0;

      for (let index = 0; index < rows.length; index++) {
        const row = rows[index];

        const findRentableIdentity = ({ rentableIds }: { rentableIds: string[] }) => rentableIds.includes(rentableIdQueryParam);
        const findRentable = ({ id }: { id: string }) => rentableIdQueryParam === id;

        // If rentable identities row is found, calculate additional offset within that row and scroll to correct position
        if (row.type === RentableIdentitiesRow && row.rentableIdentities.find(findRentableIdentity)) {
          scrollTop += RENTABLE_IDENTITY_ROW_HEIGHT * (row.rentableIdentities.findIndex(findRentableIdentity) - rowsBefore);

          return this.scrollTo({ scrollTop });
        }

        // If rentables row is found, calculate additional offset within that row and scroll to correct position
        if (row.type === RentablesRow && row.rentables.find(findRentable)) {
          scrollTop += RENTABLE_ROW_HEIGHT * (row.rentables.findIndex(findRentable) - rowsBefore);

          return this.scrollTo({ scrollTop });
        }

        // Otherwise continue measuring rows
        scrollTop += this.calculateRowHeight({ index: index + this.fixedRowCount });
      }
    }
  };

  calculateRowHeight = ({ index }: Index) => {
    if (index === 0) {
      return FIXED_HEADER_HEIGHT;
    } else {
      const row = this.props.data.rows[index - this.fixedRowCount];

      if (row.type === RowType.RentableSegmentRow) {
        return RENTABLE_SEGMENT_ROW_HEIGHT;
      }

      if (row.type === RowType.RentableTypeRow) {
        return RENTABLE_TYPE_ROW_HEIGHT;
      }

      if (row.type === RowType.RentableIdentitiesRow) {
        return RENTABLE_IDENTITY_ROW_HEIGHT * row.rentableIdentities.length;
      }

      if (row.type === RowType.RentablesRow) {
        return RENTABLE_ROW_HEIGHT * row.rentables.length;
      }

      return ROW_HEIGHT;
    }
  };

  calculateColumnWidth = ({ index }: Index) => {
    if (index === 0) {
      return FIXED_COLUMN_WIDTH;
    } else {
      return COLUMN_WIDTH;
    }
  };

  calculateScrollLeft = ({ date }: { date: Moment }) => {
    const marginLeft = 2;
    const firstPeriod = PERIODS[0];

    return CELL_WIDTH * date.diff(firstPeriod.start, 'days', true) + marginLeft;
  };

  calculateOffset = (params: { date: Moment }) => {
    return this.calculateScrollLeft(params) + FIXED_COLUMN_WIDTH;
  };

  calculateDate = ({ scrollLeft }: { scrollLeft: number }) => {
    const marginLeft = 2;
    const firstPeriod = PERIODS[0];

    return firstPeriod.start.clone().add(Math.round((scrollLeft - marginLeft) / CELL_WIDTH), 'days');
  };

  renderCell: GridCellRenderer = ({ key, style, rowIndex, columnIndex, isVisible, isScrolling }) => {
    return (
      <CellStyle
        key={key}
        style={style}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        isVisible={isVisible}
        isScrolling={isScrolling}
        render={this.renderCellChildren}
      />
    );
  };

  renderCellChildren = (props: { rowIndex: number; columnIndex: number; isVisible: boolean; isScrolling: boolean }) => {
    const { rowIndex, columnIndex, isVisible, isScrolling } = props;

    return (
      <Cell
        grid={this}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        isVisible={isVisible}
        isScrolling={isScrolling}
        // Rerender if rows change
        rows={this.props.data.rows}
      />
    );
  };

  renderCellRange: GridCellRangeRenderer = (props) => {
    const todayMarker = this.renderTodayMarker();
    const availabilityFilterStartDateMarker = this.renderAvailabilityFilterStartDateMarker();
    const availabilityFilterEndDateMarker = this.renderAvailabilityFilterEndDateMarker();
    const moveActionOverlay = this.renderMoveActionOverlay();

    const topRightGridStyle = {
      height: FIXED_HEADER_HEIGHT,
    };

    const stickyTopRightArea = (
      <div key="top-right-area" style={topRightGridStyle} className={classes['top-right-area']}>
        {this.renderTopRightCellRange(props)}
      </div>
    );

    const topLeftStyle = {
      top: FIXED_HEADER_HEIGHT,
      width: FIXED_COLUMN_WIDTH,
      height: FIXED_HEADER_HEIGHT,
      transform: `translateY(-${FIXED_HEADER_HEIGHT}px)`,
    };

    const stickyTopLeftArea = (
      <div key="top-left-area" style={topLeftStyle} className={classes['top-left-area']}>
        {this.renderTopLeftCellRange(props)}
      </div>
    );

    const midLeftStyle = {
      width: FIXED_COLUMN_WIDTH,
      transform: `translateY(-${FIXED_HEADER_HEIGHT * 2}px)`,
      marginBottom: -(FIXED_HEADER_HEIGHT * 2),
    };

    const stickyMidLeftArea = (
      <div key="mid-left-area" style={midLeftStyle} className={classes['mid-left-area']}>
        {this.renderMidLeftCellRange(props)}
      </div>
    );

    const bottomLeftStyle = {
      width: FIXED_COLUMN_WIDTH,
    };

    const stickyBottomLeftArea = !this.props.searchFormIsHidden && (
      <div key="bottom-left-area" style={bottomLeftStyle} className={classes['bottom-left-area']}>
        {this.renderBottomLeftCell()}
      </div>
    );

    const bottomRightCells = this.renderBottomRightCellRange(props);

    return [
      stickyTopRightArea,
      stickyTopLeftArea,
      stickyMidLeftArea,
      stickyBottomLeftArea,
      ...bottomRightCells,
      todayMarker,
      availabilityFilterStartDateMarker,
      availabilityFilterEndDateMarker,
      moveActionOverlay,
    ];
  };

  renderTopRightCellRange: GridCellRangeRenderer = (props) => {
    const { visibleColumnIndices, columnStartIndex, columnStopIndex } = props;

    return defaultCellRangeRenderer({
      ...props,
      visibleRowIndices: { start: 0, stop: 0 },
      visibleColumnIndices: { start: Math.max(1, visibleColumnIndices.start), stop: Math.max(1, visibleColumnIndices.stop) },
      rowStartIndex: 0,
      rowStopIndex: 0,
      columnStartIndex: Math.max(1, columnStartIndex),
      columnStopIndex: Math.max(1, columnStopIndex),
    });
  };

  renderTopLeftCellRange: GridCellRangeRenderer = (props) => {
    return defaultCellRangeRenderer({
      ...props,
      visibleRowIndices: { start: 0, stop: 0 },
      visibleColumnIndices: { start: 0, stop: 0 },
      rowStartIndex: 0,
      rowStopIndex: 0,
      columnStartIndex: 0,
      columnStopIndex: 0,
    });
  };

  renderMidLeftCellRange: GridCellRangeRenderer = (props) => {
    const { visibleRowIndices, rowStartIndex, rowStopIndex } = props;

    if (this.rowCount - this.fixedRowCount === 0) {
      return [];
    }

    return defaultCellRangeRenderer({
      ...props,
      visibleRowIndices: { start: Math.max(1, visibleRowIndices.start), stop: Math.max(1, visibleRowIndices.stop) },
      visibleColumnIndices: { start: 0, stop: 0 },
      rowStartIndex: Math.max(1, rowStartIndex),
      rowStopIndex: Math.max(1, rowStopIndex),
      columnStartIndex: 0,
      columnStopIndex: 0,
    });
  };

  renderBottomLeftCell() {
    return <SearchForm />;
  }

  renderBottomRightCellRange: GridCellRangeRenderer = (props) => {
    const { visibleRowIndices, visibleColumnIndices, rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex } = props;

    if (this.rowCount - this.fixedRowCount === 0) {
      return [];
    }

    return defaultCellRangeRenderer({
      ...props,
      visibleRowIndices: { start: Math.max(1, visibleRowIndices.start), stop: Math.max(1, visibleRowIndices.stop) },
      visibleColumnIndices: { start: Math.max(1, visibleColumnIndices.start), stop: Math.max(1, visibleColumnIndices.stop) },
      rowStartIndex: Math.max(1, rowStartIndex),
      rowStopIndex: Math.max(1, rowStopIndex),
      columnStartIndex: Math.max(1, columnStartIndex),
      columnStopIndex: Math.max(1, columnStopIndex),
    });
  };

  renderTodayMarker() {
    return <TodayMarker key="today" calculateOffset={this.calculateOffset} />;
  }

  renderAvailabilityFilterStartDateMarker() {
    return <AvailabilityFilterMarker key="availability-filter-start" isStart={true} calculateOffset={this.calculateOffset} />;
  }

  renderAvailabilityFilterEndDateMarker() {
    return <AvailabilityFilterMarker key="availability-filter-end" isEnd={true} calculateOffset={this.calculateOffset} />;
  }

  renderMoveActionOverlay() {
    return <MoveActionOverlay key="move-action-overlay" calculateOffset={this.calculateOffset} />;
  }

  renderDateHeader() {
    // No need to pass current date, it can be fetched by DateHeader from the Redux store (more efficient performance wise)
    return <DateHeader startDate={this.props.data.user.planboardStartDate} scrollToDate={this.scrollToDate} />;
  }

  renderPeriodHeader(period: DateRange) {
    const parkId = this.props.parkId;

    return <PeriodHeader parkId={parkId} period={period} onWeekHeaderMouseDown={this.handleWeekHeaderMouseDown} />;
  }

  renderRentableSegmentHeader(rentableSegment: RentableSegmentData) {
    return <RentableSegmentHeader rentableSegment={rentableSegment} />;
  }

  renderRentableSegmentCell(_period: DateRange, rentableSegment: RentableSegmentData) {
    return <RentableSegmentCell rentableSegment={rentableSegment} onMouseDown={this.handleRentableSegmentHeaderMouseDown} />;
  }

  renderRentableTypeHeader(rentableType: RentableTypeData) {
    return <RentableTypeHeader rentableType={rentableType} />;
  }

  renderRentableTypeCell(_period: DateRange, rentableType: RentableTypeData) {
    return <RentableTypeCell rentableType={rentableType} onMouseDown={this.handleRentableTypeCellMouseDown} />;
  }

  renderRentableIdentityHeaders(rentableIdentities: RentableIdentityData[]) {
    return <RentableIdentityHeaders rentableIdentities={rentableIdentities} />;
  }

  renderRentableIdentityCells(period: DateRange, rentableIdentities: RentableIdentityData[], rentableTypeId: string, cacheOnly: boolean) {
    const parkId = this.props.parkId;

    // Make sure the same array instance of ids is returned if ids are the same (to prevent rerendering RentableIdentityCells)
    const ids = memoizedMap(rentableIdentities, (rentableIdentity) => rentableIdentity.id);

    return <RentableIdentityCells period={period} ids={ids} parkId={parkId} rentableTypeId={rentableTypeId} cacheOnly={cacheOnly} />;
  }

  renderRentableHeaders(rentables: RentableData[]) {
    return <RentableHeaders rentables={rentables} />;
  }

  renderRentableCells(period: DateRange, rentables: RentableData[], cacheOnly: boolean) {
    const parkId = this.props.parkId;

    // Make sure the same array instance of ids is returned if ids are the same (to prevent rerendering RentableCells)
    const ids = memoizedMap(rentables, (rentable) => rentable.id);

    return <RentableCells period={period} ids={ids} parkId={parkId} cacheOnly={cacheOnly} />;
  }

  render() {
    const { isReloading } = this.props;
    const { scrollLeft, scrollTop, isDragging } = this.state;
    const className = classNames(classes.root, { [classes['root--reloading']]: isReloading });

    return (
      <div className={className}>
        <AutoSizer>
          {({ height, width }) => (
            <VirtualizedGrid
              ref={this.virtualGridRef}
              cellRenderer={this.renderCell}
              cellRangeRenderer={this.renderCellRange}
              tabIndex={null}
              width={width}
              height={height}
              rowCount={this.rowCount}
              columnCount={this.columnCount}
              rowHeight={this.calculateRowHeight}
              columnWidth={this.calculateColumnWidth}
              estimatedColumnSize={COLUMN_WIDTH}
              estimatedRowSize={ROW_HEIGHT}
              overscanRowCount={this.overscanRowCount}
              scrollToAlignment="start"
              scrollLeft={scrollLeft}
              scrollTop={scrollTop}
              isScrollingOptOut
              // Override isScrolling when dragging/dragging to a a different date
              {...(isDragging ? { isScrolling: true } : {})}
              onScroll={this.debouncedHandleScroll}
              // Rerender if rows change
              rows={this.props.data.rows}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}
