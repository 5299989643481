import React, { Component } from 'react';
import formatBlockedByGroupedRentableTypeAgendaPeriod from '../../utils/formatBlockedByGroupedRentableTypeAgendaPeriod';
import { BlockedByGroupedRentableTypeAgendaPeriodData } from './BlockedByGroupedRentableTypeAgendaPeriodSidebarQueryData';
import ReservationSection from './ReservationSection';
import BlockedByGroupedRentableTypeAgendaPeriodBadge from '../blocked-by-grouped-rentable-type-agenda-period-badge';
import AgendaPeriodSidebar from '../agenda-period-sidebar';

interface BlockedByGroupedRentableTypePeriodSidebarProps {
  blockedByGroupedRentableTypeAgendaPeriod: BlockedByGroupedRentableTypeAgendaPeriodData;
  onCloseButtonClick: () => void;
}

export default class BlockedByGroupedRentableTypePeriodSidebar extends Component<BlockedByGroupedRentableTypePeriodSidebarProps> {
  render() {
    const { blockedByGroupedRentableTypeAgendaPeriod, onCloseButtonClick } = this.props;

    return (
      <AgendaPeriodSidebar
        title={formatBlockedByGroupedRentableTypeAgendaPeriod(blockedByGroupedRentableTypeAgendaPeriod)}
        url={blockedByGroupedRentableTypeAgendaPeriod.blockingReservation.url}
        agendaPeriod={blockedByGroupedRentableTypeAgendaPeriod}
        renderBadge={() => <BlockedByGroupedRentableTypeAgendaPeriodBadge />}
        onCloseButtonClick={onCloseButtonClick}
      >
        <ReservationSection reservation={blockedByGroupedRentableTypeAgendaPeriod.blockingReservation} />
      </AgendaPeriodSidebar>
    );
  }
}
