import { Component } from 'react';
import Sidebar, { TodoSection, LabelSection } from '../sidebar';
import formatReservation from '../../utils/formatReservation';
import SummarySection from './SummarySection';
import PresenceSection from './PresenceSection';
import ContactDetailsSection from './ContactDetailsSection';
import ActionSection from './ActionSection';
import MemoSection from './MemoSection';
import { ReservationAgendaPeriodData } from './ReservationAgendaPeriodSidebarQueryData';
import OwnerDetailsSection from './OwnerDetailsSection';
import AmenitySection from './AmenitySection';
import PaymentStatusSection from './PaymentStatusSection';
import GuestMemoSection from './GuestMemoSection';
import ExtrasSection from './ExtrasSection';
import PackageSection from './PackageSection';
import BookingSection from './BookingSection';
import { Booking } from '../../actions/bookingMode';

interface ReservationAgendaPeriodSidebarProps {
  reservationAgendaPeriod: ReservationAgendaPeriodData;
  activeBooking?: Booking;
  onNewReservationButtonClick: (booking: Booking) => void;
  onReservationChange: (reservationAgendaPeriodId: string) => void;
  onCloseButtonClick: () => void;
}

export default class ReservationAgendaPeriodSidebar extends Component<ReservationAgendaPeriodSidebarProps> {
  get hasContactDetails() {
    const { firstName, lastName, company, email, phone } = this.props.reservationAgendaPeriod.reservation.booking;
    return firstName || lastName || company || email || phone;
  }

  get isOwnerReservation() {
    const { source } = this.props.reservationAgendaPeriod.reservation.booking;
    return source === 'owner';
  }

  get packages() {
    const { packages } = this.props.reservationAgendaPeriod.reservation;
    return packages;
  }

  get extras() {
    const { extraOrderItems } = this.props.reservationAgendaPeriod.reservation;

    return extraOrderItems;
  }

  render() {
    const { reservationAgendaPeriod, activeBooking, onCloseButtonClick, onNewReservationButtonClick, onReservationChange } = this.props;
    const { reservation } = reservationAgendaPeriod;
    const { isCheckedIn, reservationLabels, amenities, todos, booking } = reservation;
    const { customerLabels } = booking;

    return (
      <Sidebar>
        <Sidebar.Header title={formatReservation(reservation)} href={reservation.url} onCloseButtonClick={onCloseButtonClick} />
        <Sidebar.Content>
          {
            <BookingSection
              reservation={reservation}
              activeBooking={activeBooking}
              onNewReservationButtonClick={onNewReservationButtonClick}
              onReservationChange={onReservationChange}
            />
          }
          <SummarySection reservationAgendaPeriod={reservationAgendaPeriod} />
          {this.hasContactDetails && !this.isOwnerReservation && <ContactDetailsSection reservation={reservation} />}
          {this.isOwnerReservation && <OwnerDetailsSection owner={booking.owner!} />}
          {isCheckedIn && <PresenceSection reservation={reservation} />}
          <MemoSection booking={booking} />
          <GuestMemoSection reservation={reservation} />
          {reservationLabels.length + customerLabels.length > 0 && <LabelSection labels={[...reservationLabels, ...customerLabels]} />}
          {this.extras.length > 0 && <ExtrasSection extras={this.extras} />}
          {this.packages.length > 0 && <PackageSection packages={this.packages} />}
          <PaymentStatusSection reservation={reservation} />
          {amenities.length > 0 && <AmenitySection amenities={amenities} />}
          {todos.length > 0 && <TodoSection todos={todos} />}
        </Sidebar.Content>
        <Sidebar.Footer>
          <ActionSection reservationAgendaPeriod={reservationAgendaPeriod} />
        </Sidebar.Footer>
      </Sidebar>
    );
  }
}
