import React, { PureComponent, CSSProperties } from 'react';
import EventLink from '../event-link';
import MaintenanceAgendaPeriodEventItem from '../maintenance-agenda-period-event-item';
import { SidebarType } from '../../actions/sidebarPanel';

interface MaintenanceAgendaPeriodEventLinkProps {
  maintenanceAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;
    memo: string;
    editUrl: string;
  };
  style: CSSProperties;
}

export default class MaintenanceAgendaPeriodEventLink extends PureComponent<MaintenanceAgendaPeriodEventLinkProps> {
  renderItem = ({ isActive, isIndented }: { isActive: boolean; isIndented: boolean }) => (
    <MaintenanceAgendaPeriodEventItem
      maintenanceAgendaPeriod={this.props.maintenanceAgendaPeriod}
      isActive={isActive}
      isIndented={isIndented}
    />
  );

  render() {
    return (
      <EventLink
        url={this.props.maintenanceAgendaPeriod.editUrl}
        style={this.props.style}
        sidebarType={SidebarType.MaintenanceAgendaPeriod}
        sidebarId={this.props.maintenanceAgendaPeriod.id}
        renderItem={this.renderItem}
      />
    );
  }
}
