import React, { PureComponent } from 'react';
import MoveActionCell from './MoveActionCell';
import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../reducers';
import isSidebarOpened from '../../utils/isSidebarOpened';
import * as actions from '../../actions/moveAction';
import { DateRange } from 'moment-range';
import calculateCellStyle from './calculateCellStyle';
import moment from 'moment';
import momentRange from '../../utils/momentRange';
import { SidebarType } from '../../actions/sidebarPanel';

interface OwnProps {
  period: DateRange;
  rentable: {
    id: string;
    activeFrom: string;
    activeTill: string | null;
  };
  isBlockedByAgendaPeriod: (period: DateRange) => boolean;
}

interface StateProps {
  isVisible: boolean;
  isSelected: boolean;
  startDate?: string;
  endDate?: string;
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const { moveAction, sidebarPanel } = state;
  const { rentable, period } = ownProps;

  if (
    (moveAction.state === 'active' || moveAction.state === 'final') &&
    isSidebarOpened(sidebarPanel, SidebarType.ReservationAgendaPeriod, moveAction.reservationAgendaPeriodId)
  ) {
    const { startDate, endDate } = moveAction;

    const moveActionRange = momentRange(moment(startDate), moment(endDate));
    const overlapsPeriod = moveActionRange.overlaps(period);

    const isVisible = overlapsPeriod && moveAction.rentableId !== rentable.id;
    const isSelected = moveAction.state === 'final' && moveAction.targetRentableId === rentable.id;

    return { isVisible, isSelected, startDate, endDate };
  } else {
    return { isVisible: false, isSelected: false };
  }
};

const mapDispatchToProps = {
  toggleMoveActionTargetRentable: actions.toggleMoveActionTargetRentable,
};

type MoveActionCellContainerProps = ConnectedProps<typeof connector> & OwnProps;

class MoveActionCellContainer extends PureComponent<MoveActionCellContainerProps> {
  handleClick = () => {
    this.props.toggleMoveActionTargetRentable(this.props.rentable);
  };

  render() {
    const { period, rentable, isVisible, isSelected, isBlockedByAgendaPeriod, startDate, endDate } = this.props;

    if (isVisible && startDate && endDate) {
      const style = calculateCellStyle({ period, rentable, startDate, endDate });

      const moveActionRange = momentRange(moment(startDate), moment(endDate));
      const rentableRange = momentRange(moment(rentable.activeFrom), moment(rentable.activeTill || moveActionRange.end));
      const isBlocked = isBlockedByAgendaPeriod(moveActionRange) || !rentableRange.contains(moveActionRange);

      return <MoveActionCell style={style} onClick={this.handleClick} isSelected={isSelected} isBlocked={isBlocked} />;
    } else {
      return null;
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MoveActionCellContainer);
