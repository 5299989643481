import React from 'react';
import BookingModeNotice from './BookingModeNotice';
import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../reducers';
import * as actions from '../../actions/bookingMode';

const mapStateToProps = (state: State) => {
  return { booking: state.bookingMode.booking };
};

const mapDispatchToProps = {
  deactivateBookingMode: actions.deactivateBookingMode,
};

type BookingModeNoticeContainerProps = ConnectedProps<typeof connector>;

function BookingModeNoticeContainer({ booking, deactivateBookingMode }: BookingModeNoticeContainerProps) {
  return booking ? <BookingModeNotice booking={booking} onCancelButtonClick={deactivateBookingMode} /> : null;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(BookingModeNoticeContainer);
