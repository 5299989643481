import React, { PureComponent, CSSProperties } from 'react';
import ReservationAgendaPeriodEventLink from '../reservation-agenda-period-event-link';
import IcalAgendaPeriodEventLink from '../ical-agenda-period-event-link';
import MaintenanceAgendaPeriodEventLink from '../maintenance-agenda-period-event-link';
import OwnerMaintenanceAgendaPeriodEventLink from '../owner-maintenance-agenda-period-event-link';
import NotEnoughStockAgendaPeriodEventLink from '../not-enough-stock-agenda-period-event-link';
import BlockedByGroupedRentableTypeAgendaPeriodEventLink from '../blocked-by-grouped-rentable-type-agenda-period-event-link';
import ReservationSelectionCellContainer from './ReservationSelectionCellContainer';
import MoveActionCellContainer from './MoveActionCellContainer';
import calculateCellStyle from './calculateCellStyle';
import { DateRange } from 'moment-range';
import moment from 'moment';
import momentRange from '../../utils/momentRange';
import {
  RentableData,
  IcalAgendaPeriodData,
  MaintenanceAgendaPeriodData,
  OwnerMaintenanceAgendaPeriodData,
  NotEnoughStockAgendaPeriodData,
  BlockedByGroupedRentableTypeAgendaPeriodData,
  ReservationAgendaPeriodData,
  AgendaPeriodType,
  AgendaPeriodData,
} from '../rentable-identity-cells';

interface RentableCellProps {
  period: DateRange;
  rentable: RentableData;
  style: CSSProperties;
  reservationSelectionHoverStartDate?: string;
  reservationSelectionStartDate?: string;
  reservationSelectionDraggingEndDate?: string;
  reservationSelectionEndDate?: string;
  onMouseOver: (e: React.MouseEvent) => void;
  onMouseOut: (e: React.MouseEvent) => void;
  onMouseMove: (e: React.MouseEvent) => void;
  onMouseDown: (e: React.MouseEvent) => void;
}

function isIcalAgendaPeriod(agendaPeriod: AgendaPeriodData): agendaPeriod is IcalAgendaPeriodData {
  return agendaPeriod.__typename === AgendaPeriodType.IcalAgendaPeriod;
}

function isMaintenanceAgendaPeriod(agendaPeriod: AgendaPeriodData): agendaPeriod is MaintenanceAgendaPeriodData {
  return agendaPeriod.__typename === AgendaPeriodType.MaintenanceAgendaPeriod;
}

function isOwnerMaintenanceAgendaPeriod(agendaPeriod: AgendaPeriodData): agendaPeriod is OwnerMaintenanceAgendaPeriodData {
  return agendaPeriod.__typename === AgendaPeriodType.OwnerMaintenanceAgendaPeriod;
}

function isNotEnoughStockAgendaPeriod(agendaPeriod: AgendaPeriodData): agendaPeriod is NotEnoughStockAgendaPeriodData {
  return agendaPeriod.__typename === AgendaPeriodType.NotEnoughStockAgendaPeriod;
}

function isBlockedByGroupedRentableTypeAgendaPeriod(
  agendaPeriod: AgendaPeriodData
): agendaPeriod is BlockedByGroupedRentableTypeAgendaPeriodData {
  return agendaPeriod.__typename === AgendaPeriodType.BlockedByGroupedRentableTypeAgendaPeriod;
}

function isReservationAgendaPeriod(agendaPeriod: AgendaPeriodData): agendaPeriod is ReservationAgendaPeriodData {
  return agendaPeriod.__typename === AgendaPeriodType.ReservationAgendaPeriod;
}

export default class RentableCell extends PureComponent<RentableCellProps> {
  // Reservations visible on planboard (also see ReservationQuery.where_on_planboard/1)
  // Even though this is already handled server side, we re-apply the filter here because
  // of live updates (for example: reservation may get cancelled) and mutations (rentable id changes when moving/swapping)
  get reservationAgendaPeriodsVisibleOnPlanboard() {
    const { id, agendaPeriods } = this.props.rentable;

    return agendaPeriods.filter(isReservationAgendaPeriod).filter((reservationAgendaPeriod) => {
      const { booking, rentableId } = reservationAgendaPeriod.reservation;
      return !booking.isCancelled && rentableId === id;
    });
  }

  renderReservationAgendaPeriod(reservationAgendaPeriod: ReservationAgendaPeriodData) {
    const { period, rentable } = this.props;
    const { startDate, endDate } = reservationAgendaPeriod;
    const style = calculateCellStyle({ period, rentable, startDate, endDate });

    return (
      <ReservationAgendaPeriodEventLink key={reservationAgendaPeriod.id} reservationAgendaPeriod={reservationAgendaPeriod} style={style} />
    );
  }

  renderReservationAgendaPeriods() {
    return this.reservationAgendaPeriodsVisibleOnPlanboard.map(this.renderReservationAgendaPeriod.bind(this));
  }

  renderIcalAgendaPeriod(icalAgendaPeriod: IcalAgendaPeriodData) {
    const { period, rentable } = this.props;
    const { startDate, endDate } = icalAgendaPeriod;
    const style = calculateCellStyle({ period, rentable, startDate, endDate });

    return <IcalAgendaPeriodEventLink key={icalAgendaPeriod.id} icalAgendaPeriod={icalAgendaPeriod} style={style} />;
  }

  renderIcalAgendaPeriods() {
    const { agendaPeriods } = this.props.rentable;
    return agendaPeriods.filter(isIcalAgendaPeriod).map(this.renderIcalAgendaPeriod.bind(this));
  }

  renderMaintenanceAgendaPeriod(maintenanceAgendaPeriod: MaintenanceAgendaPeriodData) {
    const { period, rentable } = this.props;
    const { startDate, endDate } = maintenanceAgendaPeriod;
    const style = calculateCellStyle({ period, rentable, startDate, endDate });

    return (
      <MaintenanceAgendaPeriodEventLink key={maintenanceAgendaPeriod.id} maintenanceAgendaPeriod={maintenanceAgendaPeriod} style={style} />
    );
  }

  renderMaintenanceAgendaPeriods() {
    const { agendaPeriods } = this.props.rentable;
    return agendaPeriods.filter(isMaintenanceAgendaPeriod).map(this.renderMaintenanceAgendaPeriod.bind(this));
  }

  renderOwnerMaintenanceAgendaPeriod(ownerMaintenanceAgendaPeriod: OwnerMaintenanceAgendaPeriodData) {
    const { period, rentable } = this.props;
    const { startDate, endDate } = ownerMaintenanceAgendaPeriod;
    const style = calculateCellStyle({ period, rentable, startDate, endDate });

    return (
      <OwnerMaintenanceAgendaPeriodEventLink
        key={ownerMaintenanceAgendaPeriod.id}
        ownerMaintenanceAgendaPeriod={ownerMaintenanceAgendaPeriod}
        style={style}
      />
    );
  }

  renderOwnerMaintenanceAgendaPeriods() {
    const { agendaPeriods } = this.props.rentable;

    return agendaPeriods.filter(isOwnerMaintenanceAgendaPeriod).map(this.renderOwnerMaintenanceAgendaPeriod.bind(this));
  }

  renderNotEnoughStockAgendaPeriod(notEnoughStockAgendaPeriod: NotEnoughStockAgendaPeriodData) {
    const { period, rentable } = this.props;
    const { startDate, endDate } = notEnoughStockAgendaPeriod;
    const style = calculateCellStyle({ period, rentable, startDate, endDate });

    return (
      <NotEnoughStockAgendaPeriodEventLink
        key={notEnoughStockAgendaPeriod.id}
        notEnoughStockAgendaPeriod={notEnoughStockAgendaPeriod}
        style={style}
      />
    );
  }

  renderNotEnoughStockAgendaPeriods() {
    const { agendaPeriods } = this.props.rentable;
    return agendaPeriods.filter(isNotEnoughStockAgendaPeriod).map(this.renderNotEnoughStockAgendaPeriod.bind(this));
  }

  renderBlockedByGroupedRentableTypeAgendaPeriod(blockedByGroupedRentableTypeAgendaPeriod: BlockedByGroupedRentableTypeAgendaPeriodData) {
    const { period, rentable } = this.props;
    const { startDate, endDate } = blockedByGroupedRentableTypeAgendaPeriod;
    const style = calculateCellStyle({ period, rentable, startDate, endDate });

    return (
      <BlockedByGroupedRentableTypeAgendaPeriodEventLink
        key={blockedByGroupedRentableTypeAgendaPeriod.id}
        blockedByGroupedRentableTypeAgendaPeriod={blockedByGroupedRentableTypeAgendaPeriod}
        style={style}
      />
    );
  }

  renderBlockedByGroupedRentableTypeAgendaPeriods() {
    const { agendaPeriods } = this.props.rentable;
    return agendaPeriods
      .filter(isBlockedByGroupedRentableTypeAgendaPeriod)
      .map(this.renderBlockedByGroupedRentableTypeAgendaPeriod.bind(this));
  }

  renderReservationSelectionCell() {
    const {
      period,
      rentable,
      reservationSelectionHoverStartDate,
      reservationSelectionDraggingEndDate,
      reservationSelectionStartDate,
      reservationSelectionEndDate,
    } = this.props;

    if (reservationSelectionHoverStartDate) {
      const startDate = moment(reservationSelectionStartDate || reservationSelectionHoverStartDate);
      const endDate = moment(reservationSelectionEndDate || reservationSelectionDraggingEndDate || startDate.clone().add(1, 'day'));
      const numberOfNights = moment(endDate).diff(moment(startDate), 'days');

      const style = calculateCellStyle({ period, rentable, startDate, endDate });

      return <ReservationSelectionCellContainer style={style} numberOfNights={numberOfNights} />;
    } else {
      return null;
    }
  }

  isBlockedByAgendaPeriod = (dateRange: DateRange) => {
    const { agendaPeriods } = this.props.rentable;

    return agendaPeriods.some((agendaPeriod: AgendaPeriodData) => {
      const blockedDateRange = momentRange(moment(agendaPeriod.startDate), moment(agendaPeriod.endDate));
      return !isReservationAgendaPeriod(agendaPeriod) && blockedDateRange.overlaps(dateRange);
    });
  };

  renderMoveActionCellContainer() {
    const { period, rentable } = this.props;

    return <MoveActionCellContainer period={period} rentable={rentable} isBlockedByAgendaPeriod={this.isBlockedByAgendaPeriod} />;
  }

  render() {
    const { style, onMouseOver, onMouseOut, onMouseMove, onMouseDown } = this.props;

    return (
      <div style={style} onMouseOver={onMouseOver} onMouseOut={onMouseOut} onMouseMove={onMouseMove} onMouseDown={onMouseDown}>
        {this.renderReservationAgendaPeriods()}
        {this.renderIcalAgendaPeriods()}
        {this.renderMaintenanceAgendaPeriods()}
        {this.renderOwnerMaintenanceAgendaPeriods()}
        {this.renderNotEnoughStockAgendaPeriods()}
        {this.renderBlockedByGroupedRentableTypeAgendaPeriods()}
        {this.renderReservationSelectionCell()}
        {this.renderMoveActionCellContainer()}
      </div>
    );
  }
}
