import React from 'react';
import formatIcalAgendaPeriod from '../../utils/formatIcalAgendaPeriod';
import IcalAgendaPeriodBadge from '../ical-agenda-period-badge';
import { AgendaPeriodEventPopover } from '../agenda-period-event-popover';

interface IcalAgendaPeriodEventPopoverProps {
  icalAgendaPeriod: {
    startDate: string;
    endDate: string;
    memo: string;
    icalChannel: {
      name: string;
    };
  };
}

export default function IcalAgendaPeriodEventPopover({ icalAgendaPeriod }: IcalAgendaPeriodEventPopoverProps) {
  return (
    <AgendaPeriodEventPopover
      title={formatIcalAgendaPeriod(icalAgendaPeriod)}
      startDate={icalAgendaPeriod.startDate}
      endDate={icalAgendaPeriod.endDate}
      renderBadge={() => <IcalAgendaPeriodBadge />}
    />
  );
}
