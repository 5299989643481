import React from 'react';
import ParkOverview from '../park-overview';
import Page from '../page';

export default function IndexPage() {
  return (
    <Page>
      <Page.Launchbar />

      <Page.Container className="mx-auto max-w-xl mt-4 md:mt-8 px-4 md:px-8 mb-8 md:pb-8">
        <ParkOverview />
      </Page.Container>
    </Page>
  );
}
