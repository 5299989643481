import React from 'react';
import { Tooltip as Tippy } from 'react-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/themes/light.css';

export interface TooltipProps {
  title?: string;
  render?: () => React.ReactNode;
  placement?: string;
  distance?: number;
  offset?: number;
  duration?: number | [number, number];
  size?: 'small' | 'regular' | 'big';
  interactive?: boolean;
  arrow?: boolean;
  arrowSize?: 'small' | 'regular' | 'big';
  theme?: string;
  className?: string;
  children: React.ReactNode;
}

export default function Tooltip(props: TooltipProps) {
  let defaultProps = {
    popperOptions: {
      modifiers: {
        flip: { boundariesElement: 'window', padding: 5 },
        preventOverflow: { boundariesElement: 'window' },
      },
    },
  };

  return (
    <Tippy {...defaultProps} {...props}>
      {props.children}
    </Tippy>
  );
}
