import { ApolloClient } from '@apollo/client/core';
import { loader } from 'graphql.macro';
const rentableSegmentUpdatedSubscription = loader('./rentableSegmentUpdatedSubscription.graphql');
const agendaPeriodUpdatedSubscription = loader('./agendaPeriodUpdatedSubscription.graphql');
const rentableTypeUpdatedSubscription = loader('./rentableTypeUpdatedSubscription.graphql');
const invoiceUpdatedSubscription = loader('./invoiceUpdatedSubscription.graphql');
const rentableIdentityUpdatedSubscription = loader('./rentableIdentityUpdatedSubscription.graphql');
const rentableUpdatedSubscription = loader('./rentableUpdatedSubscription.graphql');
const reservationUpdatedSubscription = loader('./reservationUpdatedSubscription.graphql');
const todoUpdatedSubscription = loader('./todoUpdatedSubscription.graphql');

export default class RealtimeUpdates {
  private client: ApolloClient<any>;
  private subscriptions: any[] = [];

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  start(parkId: string) {
    const observer = {};

    this.subscriptions = [
      this.client.subscribe({ query: rentableSegmentUpdatedSubscription, variables: { parkId } }).subscribe(observer),
      this.client.subscribe({ query: agendaPeriodUpdatedSubscription, variables: { parkId } }).subscribe(observer),
      this.client.subscribe({ query: rentableTypeUpdatedSubscription, variables: { parkId } }).subscribe(observer),
      this.client.subscribe({ query: invoiceUpdatedSubscription, variables: { parkId } }).subscribe(observer),
      this.client.subscribe({ query: rentableIdentityUpdatedSubscription, variables: { parkId } }).subscribe(observer),
      this.client.subscribe({ query: rentableUpdatedSubscription, variables: { parkId } }).subscribe(observer),
      this.client.subscribe({ query: reservationUpdatedSubscription, variables: { parkId } }).subscribe(observer),
      this.client.subscribe({ query: todoUpdatedSubscription, variables: { parkId } }).subscribe(observer),
    ];
  }

  stop() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  restart(parkId: string) {
    this.stop();
    this.start(parkId);
  }
}
