import React, { PureComponent, ReactNode } from 'react';
import Sidebar from '../sidebar';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCircle } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import { DefaultTooltip } from '../tooltip';
import LocaleDate from '../locale-date';
import Translation from '../translation';
import renderGraphQLDateTime from '../../utils/renderGraphQLDateTime';

interface Todo {
  id: string;
  description: string;
  quantity: string;
  date: string;
  finishedAt: string | null;
  isBlocker: boolean;
  isDue: boolean;
  url: string;

  reservation?: null | {
    startDate: string;
    endDate: string;
  };
}

interface TodoSectionProps {
  todos: Todo[];
}

export default class TodoSection extends PureComponent<TodoSectionProps> {
  renderLink({ url, text }: { url: string; text: ReactNode }) {
    return (
      <a href={url} className="-m-1 p-1 block text-inherit">
        {text}
      </a>
    );
  }

  renderIsFinished(todo: Todo) {
    const { finishedAt, isBlocker } = todo;

    const renderTitle = () => (
      <Translation>
        {(translate) =>
          finishedAt
            ? translate('Finished on {{finishedAtFormatted}}', { finishedAtFormatted: renderGraphQLDateTime(finishedAt, 'LL') })
            : translate(isBlocker ? 'Not yet completed (blocking)' : 'Not yet completed')
        }
      </Translation>
    );

    return (
      <DefaultTooltip render={renderTitle} distance={8} className="-m-1 p-1 block">
        <FontAwesomeIcon icon={finishedAt ? (isBlocker ? faCheckCircle : faCheckSquare) : isBlocker ? faCircle : faSquare} />
      </DefaultTooltip>
    );
  }

  renderQuantity(todo: Todo) {
    return this.renderLink({ url: todo.url, text: `${Number(todo.quantity)}x` });
  }

  renderDescription(todo: Todo) {
    return this.renderLink({ url: todo.url, text: todo.description });
  }

  renderDate(todo: Todo) {
    if (todo.reservation && moment(todo.date).isSame(todo.reservation.startDate, 'date')) {
      return this.renderLink({ url: todo.url, text: <Translation>{(translate) => translate('at arrival')}</Translation> });
    } else if (todo.reservation && moment(todo.date).isSame(todo.reservation.endDate, 'date')) {
      return this.renderLink({ url: todo.url, text: <Translation>{(translate) => translate('at departure')}</Translation> });
    } else {
      return this.renderLink({ url: todo.url, text: <LocaleDate date={todo.date} format="LL" /> });
    }
  }

  renderTodo(todo: Todo) {
    const className = classNames('hover:underline', {
      'text-bp4-gray1': true,
      'text-bp4-gray3 line-through': todo.finishedAt,
    });

    const dateClassName = classNames('text-right whitespace-no-wrap', {
      'text-red-900': todo.isDue,
    });

    return (
      <tr key={todo.id} className={className}>
        <th className="p-1 align-top w-px">{this.renderIsFinished(todo)}</th>
        <td className="p-1 align-top w-px">{this.renderQuantity(todo)}</td>
        <td className="p-1 align-top">{this.renderDescription(todo)}</td>
        <td className={classNames('p-1 align-top', dateClassName)}>{this.renderDate(todo)}</td>
      </tr>
    );
  }

  renderTable() {
    return (
      <table className="w-full-2 -m-1">
        <tbody>{this.props.todos.map(this.renderTodo.bind(this))}</tbody>
      </table>
    );
  }

  render() {
    return <Translation>{(translate) => <Sidebar.Section title={translate('Todos')}>{this.renderTable()}</Sidebar.Section>}</Translation>;
  }
}
