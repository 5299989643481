import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Tag } from '@blueprintjs/core';
import Translation from '../translation';

interface Package {
  id: string;
  name: string;
}

interface PackageSectionProps {
  packages: Package[];
}

export default class PackageSection extends Component<PackageSectionProps> {
  renderPackage({ id, name }: Package) {
    return (
      <Tag key={id} className="bg-amber-700 rounded-sm m-1 p-1">
        {name}
      </Tag>
    );
  }

  renderPackages() {
    const { packages } = this.props;
    return <div className="flex flex-wrap -m-1">{packages.map(this.renderPackage)}</div>;
  }

  render() {
    return (
      <Translation>{(translate) => <Sidebar.Section title={translate('Packages')}>{this.renderPackages()}</Sidebar.Section>}</Translation>
    );
  }
}
