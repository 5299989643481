import React, { Component, RefObject } from 'react';
import moment from 'moment';
import { FormGroup, Callout, Intent } from '@blueprintjs/core';
import { TimePicker } from '@blueprintjs/datetime';
import { showApolloErrorToast, showSuccessToast } from '../toaster';
import DateInput from '../date-input';
import SubmitButton from './SubmitButton';
import UndoButton from './UndoButton';
import Translation from '../translation';
import CheckinFormQueryData from './CheckinFormQueryData';
import { CheckInReservationFn } from './CheckInReservationMutation';
import { UndoCheckInReservationFn } from './UndoCheckInReservationMutation';
import { Trans } from 'react-i18next';
import TodoLinks from './TodoLinks';
import { ApolloError } from '@apollo/client';

interface CheckinFormProps {
  data: CheckinFormQueryData;
  checkIn: CheckInReservationFn;
  undoCheckIn: UndoCheckInReservationFn;
  isCheckingIn: boolean;
  isUndoingCheckIn: boolean;
}

export default class CheckinForm extends Component<CheckinFormProps> {
  private dateInputRef: RefObject<any> = React.createRef();
  private timePickerRef: RefObject<TimePicker> = React.createRef();

  get hasUnfinishedTodos() {
    const { unfinishedTodos } = this.props.data.reservation;
    return unfinishedTodos.length > 0;
  }

  get hasBlockingRentableIdentityTodos() {
    const { blockingTodos } = this.props.data.reservation.rentableIdentity;
    return blockingTodos.length > 0;
  }

  get hasWarnings() {
    return this.hasBlockingRentableIdentityTodos;
  }

  get canCheckIn() {
    const { booking, isCheckedIn } = this.props.data.reservation;
    return booking.isConfirmed && !isCheckedIn && !this.hasWarnings;
  }

  get canUndo() {
    const { isCheckedIn, isCheckedOut } = this.props.data.reservation;
    return isCheckedIn && !isCheckedOut;
  }

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { id: reservationId } = this.props.data.reservation;
    const { checkIn } = this.props;

    let dateInput = this.dateInputRef.current!;
    let timePicker = this.timePickerRef.current!;

    let checkinDate = moment(dateInput.state.value).format('YYYY-MM-DD');
    let checkinHour = timePicker.state.hourText!;
    let checkinMinute = timePicker.state.minuteText!;

    try {
      await checkIn({ variables: { reservationId, checkinDate, checkinHour, checkinMinute } });

      const message = (
        <Translation>
          {(translate) => translate('Reservation checked in at {{checkinHour}}:{{checkinMinute}}', { checkinHour, checkinMinute })}
        </Translation>
      );

      showSuccessToast({ message });
    } catch (e) {
      if (e instanceof ApolloError) {
        showApolloErrorToast(e);
      } else {
        throw e;
      }
    }
  };

  handleUndoCheckInButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();

    const { id: reservationId } = this.props.data.reservation;
    const { undoCheckIn } = this.props;

    try {
      await undoCheckIn({ variables: { reservationId } });

      const message = <Translation>{(translate) => translate('Reservation checkin undone')}</Translation>;

      showSuccessToast({ message });
    } catch (e) {
      if (e instanceof ApolloError) {
        showApolloErrorToast(e);
      } else {
        throw e;
      }
    }
  };

  renderTodoLink(todo: { id: string; description: string; url: string }) {
    return (
      <a href={todo.url} key={todo.id} target="_blank" rel="noopener noreferrer">
        {todo.description}
      </a>
    );
  }

  renderUnfinishedTodoNotice() {
    const { unfinishedTodos } = this.props.data.reservation;
    const count = unfinishedTodos.length;

    return (
      <Callout icon={null} intent={Intent.PRIMARY}>
        {count === 1 ? (
          <Trans>
            There is 1 unfinished todo that needs to happen before the day of arrival (<TodoLinks todos={unfinishedTodos} />)
          </Trans>
        ) : (
          <Trans count={count}>
            There are {{ count }} unfinished todos that need to happen before the day of arrival (<TodoLinks todos={unfinishedTodos} />)
          </Trans>
        )}
      </Callout>
    );
  }

  renderBlockingRentableIdentityTodosWarning() {
    const { blockingTodos } = this.props.data.reservation.rentableIdentity;
    const count = blockingTodos.length;

    return (
      <Callout intent={Intent.WARNING} icon={null}>
        {count === 1 ? (
          <Trans>
            There is 1 blocking unfinished todo on the rental (<TodoLinks todos={blockingTodos} />
            ). Please make sure to complete this task first
          </Trans>
        ) : (
          <Trans count={count}>
            There are {{ count }} blocking unfinished todos on the rental (<TodoLinks todos={blockingTodos} />
            ). Please make sure to complete these tasks first
          </Trans>
        )}
      </Callout>
    );
  }

  render() {
    const { isCheckedIn } = this.props.data.reservation;
    const { checkinDate, checkinTime } = this.props.data.reservation.checkinForm;
    const { isCheckingIn, isUndoingCheckIn } = this.props;
    const isDisabled = !this.canCheckIn;

    return (
      <Translation>
        {(translate) => (
          <form onSubmit={this.handleSubmit} className="space-between-4">
            <main className="flex">
              <FormGroup label={<strong>{translate('Date')}</strong>} className="flex-grow mb-0" disabled={isDisabled}>
                <DateInput
                  defaultValue={moment(checkinDate, moment.HTML5_FMT.DATE).toDate()}
                  disabled={isDisabled}
                  ref={this.dateInputRef}
                />
              </FormGroup>

              <FormGroup label={<strong>{translate('Time')}</strong>} className="flex-grow mb-0 ml-2" disabled={isDisabled}>
                <TimePicker
                  defaultValue={moment(checkinTime, moment.HTML5_FMT.TIME_MS).toDate()}
                  disabled={isDisabled}
                  ref={this.timePickerRef}
                />
              </FormGroup>
            </main>

            <main className="space-between-4">
              {!isCheckedIn && this.hasUnfinishedTodos && this.renderUnfinishedTodoNotice()}
              {!isCheckedIn && this.hasBlockingRentableIdentityTodos && this.renderBlockingRentableIdentityTodosWarning()}
            </main>

            <footer className="flex">
              <SubmitButton isCheckingIn={isCheckingIn} isDisabled={isDisabled} />
              {this.canUndo && (
                <UndoButton isUndoingCheckIn={isUndoingCheckIn} onClick={this.handleUndoCheckInButtonClick} className="ml-2" />
              )}
            </footer>
          </form>
        )}
      </Translation>
    );
  }
}
