import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import MoveReservationFormQueryData from './MoveReservationFormQueryData';
import serializeDate from '../../utils/serializeDate';
import { SLOW_QUERY_POLL_INTERVAL } from '../../config/planboard';
const moveReservationFormQuery = loader('./moveReservationFormQuery.graphql');

interface MoveReservationFormQueryProps {
  reservationAgendaPeriodId: string;
  startDate: string;
  endDate: string;
  targetRentableId?: string;
  onCompleted: (data: MoveReservationFormQueryData) => void;
  children: (result: QueryResult<MoveReservationFormQueryData, Variables>) => JSX.Element | null;
}

interface Variables {
  reservationAgendaPeriodId: string;
  startDate: string;
  endDate: string;
  hasTargetRentableId: boolean;
  targetRentableId?: string;
}

export default function MoveReservationFormQuery({
  reservationAgendaPeriodId,
  startDate,
  endDate,
  targetRentableId,
  onCompleted,
  children,
}: MoveReservationFormQueryProps) {
  const $startDate = serializeDate(startDate);
  const $endDate = serializeDate(endDate);
  const $hasTargetRentableId = targetRentableId !== undefined;

  const variables = {
    reservationAgendaPeriodId,
    startDate: $startDate,
    endDate: $endDate,
    hasTargetRentableId: $hasTargetRentableId,
    targetRentableId,
  };

  return (
    <Query<MoveReservationFormQueryData, Variables>
      query={moveReservationFormQuery}
      variables={variables}
      fetchPolicy="no-cache"
      pollInterval={SLOW_QUERY_POLL_INTERVAL}
      onCompleted={onCompleted}
    >
      {children}
    </Query>
  );
}
