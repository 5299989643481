import ReservationAgendaPeriodSidebar from './ReservationAgendaPeriodSidebar';
import ReservationAgendaPeriodSidebarQuery from './ReservationAgendaPeriodSidebarQuery';
import ReservationAgendaPeriodSidebarPlaceholder from './ReservationAgendaPeriodSidebarPlaceholder';
import { SidebarErrorMessage } from '../sidebar';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../actions/sidebarPanel';
import * as bookingModeActions from '../../actions/bookingMode';
import { State } from '../../reducers';
// tslint:disable-next-line:no-duplicate-imports
import { SidebarType } from '../../actions/sidebarPanel';
// tslint:disable-next-line:no-duplicate-imports
import { Booking } from '../../actions/bookingMode';

interface OwnProps {
  reservationAgendaPeriodId: string;
  onCloseButtonClick: () => void;
}

const mapStateToProps = (state: State) => {
  return { activeBooking: state.bookingMode.booking };
};

const mapDispatchToProps = {
  openSidebarPanel: actions.openSidebarPanel,
  activateBookingMode: bookingModeActions.activateBookingMode,
};

type ReservationAgendaPeriodSidebarContainerProps = OwnProps & ConnectedProps<typeof connector>;

function ReservationAgendaPeriodSidebarContainer({
  reservationAgendaPeriodId,
  activeBooking,
  activateBookingMode,
  openSidebarPanel,
  ...rest
}: ReservationAgendaPeriodSidebarContainerProps) {
  const handleNewReservationButtonClick = (booking: Booking) => {
    activateBookingMode(booking);
  };

  const handleReservationChange = (id: string) => {
    openSidebarPanel(SidebarType.ReservationAgendaPeriod, id);
  };

  return (
    <ReservationAgendaPeriodSidebarQuery reservationAgendaPeriodId={reservationAgendaPeriodId}>
      {({ data, loading, error }) => {
        if (error) {
          return <SidebarErrorMessage error={error} {...rest} />;
        } else if (loading) {
          return <ReservationAgendaPeriodSidebarPlaceholder {...rest} />;
        } else if (data) {
          return (
            <ReservationAgendaPeriodSidebar
              reservationAgendaPeriod={data.reservationAgendaPeriod}
              activeBooking={activeBooking}
              onNewReservationButtonClick={handleNewReservationButtonClick}
              onReservationChange={handleReservationChange}
              {...rest}
            />
          );
        } else {
          return null;
        }
      }}
    </ReservationAgendaPeriodSidebarQuery>
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ReservationAgendaPeriodSidebarContainer);
