import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult, MutationFunction } from '@apollo/client/react';
import { loader } from 'graphql.macro';
const moveReservationMutation = loader('./moveReservationMutation.graphql');

export interface MoveReservationData {
  moveReservation: {
    id: string;
    reservationAgendaPeriods: Array<{ id: string }>;
  };
}

interface MoveReservationVariables {
  reservationId: string;
  targetRentableId: string;
  createNewInvoice: boolean;
}

export type Result = MutationResult<MoveReservationData>;
export type MoveReservationFn = MutationFunction<MoveReservationData, MoveReservationVariables>;

interface MoveReservationMutationProps {
  children: (moveReservation: MoveReservationFn, result: Result) => JSX.Element | null;
}

const REFETCH_QUERIES = ['rentableIdentityCellsQuery', 'rentableCellsQuery'];

export default function MoveReservationMutation({ children }: MoveReservationMutationProps) {
  return (
    <Mutation<MoveReservationData, MoveReservationVariables>
      mutation={moveReservationMutation}
      refetchQueries={REFETCH_QUERIES}
      awaitRefetchQueries
    >
      {children}
    </Mutation>
  );
}
