import React, { PureComponent } from 'react';
import { Moment } from 'moment';
import { connect, MapStateToProps } from 'react-redux';
import { State } from '../../reducers';
import DateHeader from './DateHeader';

interface OwnProps {
  startDate: string | null;
  scrollToDate: (date: Moment) => void;
}

interface StateProps {
  date: string;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State, _ownProps: OwnProps) => {
  return { date: state.currentDate };
};

type DateHeaderContainerProps = StateProps & OwnProps;

class DateHeaderContainer extends PureComponent<DateHeaderContainerProps> {
  render() {
    const { date, startDate, scrollToDate } = this.props;
    return <DateHeader date={date} startDate={startDate} onChange={scrollToDate} />;
  }
}

export default connect(mapStateToProps)(DateHeaderContainer);
