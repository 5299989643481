import React, { PureComponent } from 'react';
import compact from 'lodash/compact';
import { Position } from 'popper.js';
import { MenuItem, HotkeysTarget2 } from '@blueprintjs/core';
import { Suggest, IItemRendererProps } from '@blueprintjs/select';
import LoadingIndicator from './LoadingIndicator';
import ResetButton from './ResetButton';
import KeyComboButton from './KeyComboButton';
import { defaultPopoverProps } from '../../config/popover';
import { ReservationAgendaPeriodData } from './SearchBoxQueryData';
import formatRentableIdentity from '../../utils/formatRentableIdentity';
import Translation, { withTranslation, WithTranslation } from '../translation';
import invoke from 'lodash/invoke';

interface SearchBoxProps {
  isLoading: boolean;
  matchingReservations: ReservationAgendaPeriodData[];
  onFocus: () => void;
  onBlur: () => void;
  onReset: () => void;
  onReservationAgendaPeriodSelect: (reservation: ReservationAgendaPeriodData) => void;
  onSearchQueryChange: (searchQuery: string) => void;
}

interface SearchBoxState {
  searchQuery: string;
}

const initialState = { searchQuery: '' };

class SearchBox extends PureComponent<SearchBoxProps & WithTranslation, SearchBoxState> {
  state: SearchBoxState = initialState;
  private suggestRef: React.RefObject<Suggest<ReservationAgendaPeriodData>> = React.createRef();

  handleSearchQueryChange = (searchQuery: string) => {
    this.setState({ searchQuery }, () => this.props.onSearchQueryChange(searchQuery));
  };

  handleResetButtonClick = () => {
    this.setState(initialState, () => this.props.onReset());
  };

  handleHotkey = () => {
    invoke(this.suggestRef.current, 'input.focus');
  };

  renderItem(reservationAgendaPeriod: ReservationAgendaPeriodData, { handleClick, modifiers }: IItemRendererProps) {
    const { reservation } = reservationAgendaPeriod;
    const { id, startDate, endDate, rentableIdentity, booking } = reservation;
    const { referenceNr, bookingNr, firstName, lastName, company } = booking;
    const formattedRentableIdentity = formatRentableIdentity(rentableIdentity);

    const text = (
      <Translation>
        {(translate) => (
          <section>
            <header className="flex">
              <main className="mr-2 font-semibold">
                {compact([firstName, lastName, company ? `(${company})` : null, bookingNr]).join(' ')}
              </main>
              <aside className="ml-auto">{referenceNr}</aside>
            </header>

            <main className="text-bp4-gray1 mt-1 max-w-sm truncate">
              {translate('{{formattedRentableIdentity}} from {{startDate, date | LL}} till {{endDate, date | LL}}', {
                formattedRentableIdentity,
                startDate,
                endDate,
              })}
            </main>
          </section>
        )}
      </Translation>
    );

    return <MenuItem key={id} active={modifiers.active} disabled={modifiers.disabled} text={text} onClick={handleClick} />;
  }

  renderNoResults() {
    return <Translation>{(translate) => <MenuItem disabled text={translate('No results')} />}</Translation>;
  }

  private hotkeys = [
    {
      label: <Translation>{(translate) => translate('Search reservation')}</Translation>,
      combo: '/',
      global: true,
      onKeyDown: this.handleHotkey,
      preventDefault: true,
    },
  ];

  render() {
    const { isLoading, matchingReservations, onFocus, onBlur, onReservationAgendaPeriodSelect } = this.props;
    const { searchQuery } = this.state;

    let rightElement;

    if (isLoading) {
      rightElement = <LoadingIndicator />;
    } else if (searchQuery.length > 0) {
      rightElement = <ResetButton onClick={this.handleResetButtonClick} />;
    } else {
      rightElement = <KeyComboButton />;
    }

    const inputProps = {
      onBlur,
      onFocus,
      rightElement,
    };

    return (
      <HotkeysTarget2 hotkeys={this.hotkeys}>
        {({ handleKeyDown, handleKeyUp }) => (
          <Translation>
            {(translate) => (
              <Suggest
                ref={this.suggestRef}
                query={searchQuery}
                inputProps={{
                  ...inputProps,
                  placeholder: translate('Search reservation… (by booking number, name, email, or company name)'),
                  onKeyDown: handleKeyDown,
                  onKeyUp: handleKeyUp,
                }}
                inputValueRenderer={() => searchQuery}
                popoverProps={{ ...defaultPopoverProps, position: 'bottom-right' as Position, targetClassName: 'block' }}
                items={matchingReservations}
                defaultSelectedItem={matchingReservations[0]}
                itemRenderer={this.renderItem}
                noResults={this.renderNoResults()}
                onQueryChange={this.handleSearchQueryChange}
                onItemSelect={onReservationAgendaPeriodSelect}
              />
            )}
          </Translation>
        )}
      </HotkeysTarget2>
    );
  }
}

export default withTranslation()(SearchBox);
