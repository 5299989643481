import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducer as rootReducer } from '../../reducers';
import { routerMiddleware as createRouterMiddleware, createBrowserHistory, startListener } from 'redux-first-routing';
import { composeWithDevTools } from 'redux-devtools-extension';

// Create the history object
const history = createBrowserHistory();

// Build the middleware, which intercepts navigation actions and calls the corresponding history method
// See https://github.com/mksarge/redux-first-routing#usage
const routerMiddleware = createRouterMiddleware(history as any);

const middleware = [routerMiddleware, thunk];
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

// Start the history listener, which automatically dispatches actions to keep the store in sync with the history
startListener(history as any, store);

export default store;
