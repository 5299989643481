import React, { PureComponent } from 'react';
import formatRentableIdentity from '../../utils/formatRentableIdentity';
import TargetRentableSelectQueryData, {
  RentableSegmentData,
  RentableTypeData,
  RentableIdentityData,
} from './TargetRentableSelectQueryData';
import { HTMLSelect } from '@blueprintjs/core';
import momentRange from '../../utils/momentRange';
import moment from 'moment';
import Translation from '../translation';
import formatRentableType from '../../utils/formatRentableType';

interface TargetRentableSelectProps {
  sourceReservationAgendaPeriod: { startDate: string; endDate: string; rentableId: string };
  targetRentable?: { id: string };
  data: TargetRentableSelectQueryData;
  onChange: (rentableId?: string) => void;
}

const EMPTY_ID = '-1';

export default class TargetRentableSelect extends PureComponent<TargetRentableSelectProps> {
  handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const targetRentableId = e.target.value === EMPTY_ID ? undefined : e.target.value;
    this.props.onChange(targetRentableId);
  };

  renderSelect(rentableSegments: RentableSegmentData[]) {
    const { targetRentable } = this.props;

    return (
      <Translation>
        {(translate) => (
          <HTMLSelect value={targetRentable === undefined ? EMPTY_ID : targetRentable.id} fill onChange={this.handleChange}>
            <option value={EMPTY_ID}>{translate('Select rental…')}</option>
            {rentableSegments.map((rentableSegment) => rentableSegment.rentableTypes.map(this.renderOptGroup.bind(this)))}
          </HTMLSelect>
        )}
      </Translation>
    );
  }

  renderOptGroup(rentableType: RentableTypeData) {
    return (
      <optgroup key={rentableType.id} label={formatRentableType(rentableType)}>
        {rentableType.rentableIdentities.map(this.renderOption.bind(this))}
      </optgroup>
    );
  }

  renderOption(rentableIdentity: RentableIdentityData) {
    const { sourceReservationAgendaPeriod } = this.props;
    const { activeRentable } = rentableIdentity;

    let isEnabled;
    const value = activeRentable ? activeRentable.id : undefined;

    if (activeRentable === null) {
      isEnabled = false;
    } else {
      const isBlockedByOtherAgendaPeriods = activeRentable.otherAgendaPeriodsCount > 0;
      const blockedRange = momentRange(moment(sourceReservationAgendaPeriod.startDate), moment(sourceReservationAgendaPeriod.endDate));
      const rentableRange = momentRange(moment(activeRentable.activeFrom), moment(activeRentable.activeTill || blockedRange.end));

      isEnabled =
        !isBlockedByOtherAgendaPeriods &&
        rentableRange.contains(blockedRange) &&
        activeRentable.id !== sourceReservationAgendaPeriod.rentableId;
    }

    return (
      <option key={rentableIdentity.id} value={value} disabled={!isEnabled}>
        {formatRentableIdentity(rentableIdentity)}
      </option>
    );
  }

  render() {
    return this.renderSelect(this.props.data.rentableSegments);
  }
}
