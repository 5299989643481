import React, { PureComponent } from 'react';
import { FetchResult } from '@apollo/client/core';
import { Icon, Intent, FormGroup, Callout, Label, Radio, RadioGroup, Button } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog } from '@fortawesome/free-solid-svg-icons';
import formatRentableIdentity from '../../utils/formatRentableIdentity';
import MoveReservationFormQueryData from './MoveReservationFormQueryData';
import TargetRentableSelectContainer from './TargetRentableSelectContainer';
import formatReservation from '../../utils/formatReservation';
import { MoveReservationData, MoveReservationFn } from './MoveReservationMutation';
import { showApolloErrorToast, showSuccessToast, showProgressToast } from '../toaster';
import LoadingButton from '../loading-button';
import Translation from '../translation';
import { Trans } from 'react-i18next';
import { DefaultTooltip } from '../tooltip';
import * as actions from '../../actions/sidebarPanel';
// tslint:disable-next-line:no-duplicate-imports
import { SidebarType } from '../../actions/sidebarPanel';
import { MapDispatchToProps, connect } from 'react-redux';
import { State } from '../../reducers';
import { ApolloError } from '@apollo/client';

interface OwnProps {
  data: MoveReservationFormQueryData;
  moveReservation: MoveReservationFn;
  isSubmitting: boolean;
  onTargetRentableSelectChange: (targetRentableId?: string) => void;
}

interface DispatchProps {
  openSidebarPanel: (sidebarType: SidebarType, id: string) => void;
  closeSidebarPanel: () => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, State> = {
  openSidebarPanel: actions.openSidebarPanel,
  closeSidebarPanel: actions.closeSidebarPanel,
};

type MoveReservationFormProps = OwnProps & DispatchProps;

interface MoveReservationFormState {
  hasDismissedFixedRentableWarning: boolean;
  createNewInvoice: CreateNewInvoice;
}

enum CreateNewInvoice {
  YES = '1',
  NO = '0',
}

class MoveReservationForm extends PureComponent<MoveReservationFormProps, MoveReservationFormState> {
  state: MoveReservationFormState = { createNewInvoice: CreateNewInvoice.YES, hasDismissedFixedRentableWarning: false };

  handleFixedRentableWarningButtonClick = () => {
    this.setState({ hasDismissedFixedRentableWarning: true });
  };

  handleCreateNewInvoiceChange = (e: React.FormEvent) => {
    this.setState({ createNewInvoice: (e.target as HTMLInputElement).value as CreateNewInvoice });
  };

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { moveReservation, closeSidebarPanel, openSidebarPanel } = this.props;
    const { sourceReservationAgendaPeriod, targetRentable } = this.props.data;

    if (targetRentable === undefined) {
      return;
    }

    const variables = {
      reservationId: sourceReservationAgendaPeriod.reservation.id,
      targetRentableId: targetRentable.id,
      createNewInvoice: this.state.createNewInvoice === CreateNewInvoice.YES,
    };

    closeSidebarPanel();

    // Wait till the sidebar close animation has finished
    await new Promise((resolve) => setTimeout(resolve, 150));

    const progressMessage = <Translation>{(translate) => translate('The reservation is being moved, please stand by...')}</Translation>;

    showProgressToast({ message: progressMessage });

    try {
      const result = await moveReservation({ variables });
      const reservation = (result as FetchResult<MoveReservationData>).data!.moveReservation;
      const formattedTarget = formatRentableIdentity(targetRentable.rentableIdentity);

      const reservationAgendaPeriod = reservation.reservationAgendaPeriods[0];

      if (reservationAgendaPeriod) {
        // Open the reservation agenda period that was just created in the sidebar
        openSidebarPanel(SidebarType.ReservationAgendaPeriod, reservationAgendaPeriod.id);
      }

      const successMessage = (
        <Translation>{(translate) => translate(`The reservation has been moved to {{formattedTarget}}`, { formattedTarget })}</Translation>
      );

      showSuccessToast({ message: successMessage });
    } catch (e) {
      if (e instanceof ApolloError) {
        showApolloErrorToast(e);
      } else {
        throw e;
      }
    }
  };

  get hasFixedRentableWarning() {
    const { reservation: sourceReservation } = this.props.data.sourceReservationAgendaPeriod;
    const { hasDismissedFixedRentableWarning } = this.state;

    return sourceReservation.fixedRentable && !hasDismissedFixedRentableWarning;
  }

  get isMove() {
    const { targetRentable } = this.props.data;
    return targetRentable !== undefined && targetRentable.blockingReservationAgendaPeriods.length === 0;
  }

  get isSwap() {
    const { targetRentable } = this.props.data;
    return targetRentable !== undefined && targetRentable.blockingReservationAgendaPeriods.length > 0;
  }

  get canMove() {
    return this.isMove && !this.hasFixedRentableWarning;
  }

  get canSwap() {
    return (
      this.isSwap &&
      !this.hasFixedRentableWarning &&
      this.blockingFixedRentableReservations.length === 0 &&
      this.blockingCheckedInReservations.length === 0
    );
  }

  renderCreateNewInvoiceFormGroup() {
    return (
      <Translation>
        {(translate) => (
          <RadioGroup onChange={this.handleCreateNewInvoiceChange} selectedValue={this.state.createNewInvoice}>
            <Label>
              <strong>{translate('Would you like to create a new invoice?')}</strong>
            </Label>

            <Radio label={translate('Yes, create new (draft) invoice (if changed)')} value={CreateNewInvoice.YES} />
            <Radio label={translate('No, keep current invoice')} value={CreateNewInvoice.NO} />
          </RadioGroup>
        )}
      </Translation>
    );
  }

  renderFixedRentableWarning() {
    return (
      <Translation>
        {(translate) => (
          <Callout className="flex items-center" icon={null} intent={Intent.WARNING}>
            <Icon icon="pin" iconSize={11} className="pt-1 mr-2" />
            <main className="flex-grow">{translate('Caution: this is a preference booking')}</main>

            <DefaultTooltip title={translate('Ignore this warning')}>
              <Button
                minimal
                className="rounded-full min-h-0 py-1 -mr-1"
                icon={<Icon icon="cross" iconSize={11} className="mr-1" />}
                intent={Intent.WARNING}
                onClick={this.handleFixedRentableWarningButtonClick}
              >
                {translate('Ignore')}
              </Button>
            </DefaultTooltip>
          </Callout>
        )}
      </Translation>
    );
  }

  renderIsCheckedInNotice() {
    return (
      <Translation>
        {(translate) => (
          <Callout icon={null} intent={Intent.PRIMARY}>
            {translate('Caution: guest has already checked in')}
          </Callout>
        )}
      </Translation>
    );
  }

  get missingAmenities() {
    const { targetRentable } = this.props.data;
    const { reservation: sourceReservation } = this.props.data.sourceReservationAgendaPeriod;

    const targetAmenities = targetRentable ? [...targetRentable.amenities, ...targetRentable.rentableTypeAmenities] : [];
    const targetAmenityIds = targetAmenities.map(({ id }) => id);

    return sourceReservation.amenities.filter((amenity) => !targetAmenityIds.includes(amenity.id));
  }

  renderMissingAmenitiesNotice() {
    const formattedMissingAmenities = this.missingAmenities.map(({ name }) => name).join(', ');

    return (
      <Callout icon={null} intent={Intent.PRIMARY}>
        <Trans formattedMissingAmenities={formattedMissingAmenities}>
          Caution: selected rental is missing a couple of desired amenities (<em>{{ formattedMissingAmenities }}</em>)
        </Trans>
      </Callout>
    );
  }

  renderPetsAllowedNotice() {
    return (
      <Translation>
        {(translate) => (
          <Callout className="flex" icon={null} intent={Intent.PRIMARY}>
            <FontAwesomeIcon icon={faDog} className="pt-1 mr-2" />
            <main>{translate('Caution: pets are not allowed for the selected rental')}</main>
          </Callout>
        )}
      </Translation>
    );
  }

  renderPetsNotAllowedNotice() {
    return (
      <Translation>
        {(translate) => (
          <Callout className="flex" icon={null} intent={Intent.PRIMARY}>
            <FontAwesomeIcon icon={faDog} className="pt-1 mr-2" />
            <main>{translate('Caution: pets are allowed for the selected rental')}</main>
          </Callout>
        )}
      </Translation>
    );
  }

  get blockingFixedRentableReservations() {
    return this.blockingReservations.filter((blockingReservation) => blockingReservation.fixedRentable);
  }

  renderBlockingReservationFixedRentableWarning() {
    const formattedReservations = this.blockingFixedRentableReservations.map(formatReservation).join(', ');

    return (
      <Callout intent={Intent.WARNING} icon={null} className="flex">
        <Icon icon="pin" iconSize={11} className="pt-1 mr-2" />
        <main>
          <Trans formattedReservations={formattedReservations}>
            Reservation cannot be swapped with a preference booking (<em>{{ formattedReservations }}</em>)
          </Trans>
        </main>
      </Callout>
    );
  }

  get blockingCheckedInReservations() {
    return this.blockingReservations.filter((blockingReservation) => blockingReservation.isCheckedIn);
  }

  renderBlockingReservationCheckedInWarning() {
    const formattedReservations = this.blockingCheckedInReservations.map(formatReservation).join(', ');

    return (
      <Callout intent={Intent.WARNING} icon={null}>
        <Trans formattedReservations={formattedReservations}>
          Reservation cannot be swapped with a reservation that has already been checked in (<em>{{ formattedReservations }}</em>)
        </Trans>
      </Callout>
    );
  }

  get blockingReservations() {
    const { targetRentable } = this.props.data;
    return targetRentable ? targetRentable.blockingReservationAgendaPeriods.map((ap) => ap.reservation) : [];
  }

  render() {
    const { isSubmitting, onTargetRentableSelectChange } = this.props;
    const { targetRentable, sourceReservationAgendaPeriod } = this.props.data;
    const { reservation: sourceReservation } = sourceReservationAgendaPeriod;

    return (
      <Translation>
        {(translate) => (
          <form className="space-between-4" onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label>
                <strong>{translate('Rental')}</strong>
              </Label>
              <TargetRentableSelectContainer
                sourceReservationAgendaPeriod={sourceReservationAgendaPeriod}
                targetRentable={targetRentable}
                onChange={onTargetRentableSelectChange}
              />
            </FormGroup>

            {this.canMove && this.renderCreateNewInvoiceFormGroup()}

            {this.hasFixedRentableWarning && this.renderFixedRentableWarning()}
            {sourceReservation.isCheckedIn && this.renderIsCheckedInNotice()}

            {this.missingAmenities.length > 0 && this.renderMissingAmenitiesNotice()}
            {sourceReservation.forPets === true && targetRentable && !targetRentable.petsAllowed && this.renderPetsAllowedNotice()}
            {sourceReservation.forPets === false && targetRentable && targetRentable.petsAllowed && this.renderPetsNotAllowedNotice()}

            {this.blockingFixedRentableReservations.length > 0 && this.renderBlockingReservationFixedRentableWarning()}
            {this.blockingCheckedInReservations.length > 0 && this.renderBlockingReservationCheckedInWarning()}

            <footer className="flex -mx-1 mb-1">
              <LoadingButton
                type="submit"
                className="m-1"
                isLoading={isSubmitting && this.isMove}
                isDisabled={isSubmitting || !this.canMove}
                intent={Intent.PRIMARY}
                icon={<Icon icon="arrows-vertical" iconSize={12} />}
              >
                {translate('Move')}
              </LoadingButton>

              <LoadingButton
                type="submit"
                className="m-1"
                isLoading={isSubmitting && this.isSwap}
                isDisabled={isSubmitting || !this.canSwap}
                intent={Intent.PRIMARY}
                icon={<Icon icon="arrows-vertical" iconSize={12} />}
              >
                {translate('Swap')}
              </LoadingButton>
            </footer>
          </form>
        )}
      </Translation>
    );
  }
}

export default connect(null, mapDispatchToProps)(MoveReservationForm);
