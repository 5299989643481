import moment, { Moment } from 'moment';
import { CELL_WIDTH } from '../grid';
import { DateRange } from 'moment-range';
import { CSSProperties } from 'react';
import { Property } from 'csstype';
import memoize from 'lodash/memoize';
import serializeDate from '../../utils/serializeDate';

interface CalculateCellStyle {
  period: DateRange;
  rentable: { activeFrom: string };
  startDate: Moment | string;
  endDate: Moment | string;
}

function calculateCellStyle({ period, rentable, startDate, endDate }: CalculateCellStyle): CSSProperties {
  const position: Property.Position = 'absolute';
  const top = 0;
  const bottom = 0;

  const left = moment(rentable.activeFrom).isAfter(period.start)
    ? -moment(rentable.activeFrom).diff(startDate, 'days') * CELL_WIDTH + CELL_WIDTH / 2
    : -period.start.diff(startDate, 'days') * CELL_WIDTH + CELL_WIDTH / 2;

  const width = moment(endDate).diff(startDate, 'days') * CELL_WIDTH;

  return { position, top, bottom, left, width };
}

const resolveCacheKey = ({ period, rentable, startDate, endDate }: CalculateCellStyle) =>
  [
    serializeDate(period.start),
    serializeDate(period.end),
    serializeDate(rentable.activeFrom),
    serializeDate(startDate),
    serializeDate(endDate),
  ].join('-');

export default memoize(calculateCellStyle, resolveCacheKey);
