import React, { Component } from 'react';
import Sidebar from '../sidebar';
import compact from 'lodash/compact';
import Translation from '../translation';
import Flag from './Flag';

interface OwnerDetailsSectionProps {
  owner: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    locale: string;
  };
}

export default class OwnerDetailsSection extends Component<OwnerDetailsSectionProps> {
  renderNameTableRow() {
    const { firstName, lastName, locale } = this.props.owner;

    if (!firstName && !lastName) {
      return null;
    }

    const data = (
      <span>
        {<Flag locale={locale} />}
        {compact([firstName, lastName]).join(' ')}
      </span>
    );

    return <Translation>{(translate) => <Sidebar.TableRow header={translate('Name')} data={data} />}</Translation>;
  }

  renderEmailTableRow() {
    const { email } = this.props.owner;

    return (
      <Translation>
        {(translate) => (email ? <Sidebar.TableRow header={translate('Email')} data={<a href={`mailto:${email}`}>{email}</a>} /> : null)}
      </Translation>
    );
  }

  renderPhoneTableRow() {
    const { phone } = this.props.owner;
    return <Translation>{(translate) => (phone ? <Sidebar.TableRow header={translate('Telephone')} data={phone} /> : null)}</Translation>;
  }

  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Owner')}>
            <Sidebar.Table>
              {this.renderNameTableRow()}
              {this.renderEmailTableRow()}
              {this.renderPhoneTableRow()}
            </Sidebar.Table>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}
