import { Component } from 'react';
import debounce from 'lodash/debounce';
import SearchBox from './SearchBox';
import SearchBoxQuery from './SearchBoxQuery';
import { connect, ConnectedProps } from 'react-redux';
import { GridRef } from '../grid';
import * as actions from '../../actions/sidebarPanel';
import * as filterPanelActions from '../../actions/filterPanel';
import * as searchQueryActions from '../../actions/searchQuery';
import { ReservationAgendaPeriodData } from './SearchBoxQueryData';
// tslint:disable-next-line:no-duplicate-imports
import { SidebarType } from '../../actions/sidebarPanel';
import { SearchParams, SearchParamsMap, MAX_SEARCH_RESULTS } from '../../config/planboard';

interface OwnProps {
  parkId: string;
  gridRef: GridRef;
  onFocus: () => void;
  onBlur: () => void;
}

interface SearchBoxContainerState {
  searchQuery: string;
}

const initialState = { searchQuery: '' };
const emptyArray: [] = [];

const mapDispatchToProps = {
  openSidebarPanel: actions.openSidebarPanel,
  resetFilterPanel: filterPanelActions.resetFilterPanel,
  resetSearchQuery: searchQueryActions.resetSearchQuery,
};

type SearchBoxContainerProps = ConnectedProps<typeof connector> & OwnProps;

class SearchBoxContainer extends Component<SearchBoxContainerProps, SearchBoxContainerState> {
  state: SearchBoxContainerState = initialState;

  handleSearchQueryChange = debounce((searchQuery) => {
    this.setState({ searchQuery });
  }, 150);

  handleReset = () => {
    this.setState(initialState);
  };

  handleReservationAgendaPeriodSelect = (reservationAgendaPeriod: ReservationAgendaPeriodData) => {
    const { openSidebarPanel, resetFilterPanel, resetSearchQuery } = this.props;
    const { startDate, rentableId } = reservationAgendaPeriod.reservation;
    const grid = this.props.gridRef.current!;

    resetFilterPanel();
    resetSearchQuery();

    const additionalSearchParams: SearchParamsMap = {
      [SearchParams.DATE]: startDate,
      [SearchParams.RENTABLE_ID]: rentableId,
    };

    openSidebarPanel(SidebarType.ReservationAgendaPeriod, reservationAgendaPeriod.id, additionalSearchParams);

    // Wait for above state changes to take effect
    window.requestAnimationFrame(() => {
      if (grid.isReloading) {
        // Add event listener to be invoked after reloading
        grid.onceReloaded(() => grid.scrollToPositionFromQueryParams());
      } else {
        // Or else jump to reservation immediately
        grid.scrollToPositionFromQueryParams();
      }
    });
  };

  render() {
    const limit = MAX_SEARCH_RESULTS;
    let { parkId, ...props } = this.props;
    let { searchQuery } = this.state;

    return (
      <SearchBoxQuery parkId={parkId} searchQuery={searchQuery} limit={limit}>
        {({ data, loading }) => (
          <SearchBox
            isLoading={loading}
            matchingReservations={data && !loading ? data.reservationAgendaPeriods : emptyArray}
            onSearchQueryChange={this.handleSearchQueryChange}
            onReservationAgendaPeriodSelect={this.handleReservationAgendaPeriodSelect}
            onReset={this.handleReset}
            {...props}
          />
        )}
      </SearchBoxQuery>
    );
  }
}

const connector = connect(null, mapDispatchToProps);
export default connector(SearchBoxContainer);
