import React from 'react';
import formatMaintenanceAgendaPeriod from '../../utils/formatMaintenanceAgendaPeriod';
import MaintenanceAgendaPeriodBadge from '../maintenance-agenda-period-badge';
import { AgendaPeriodEventPopover } from '../agenda-period-event-popover';
import Translation from '../translation';

interface MaintenanceAgendaPeriodEventPopoverProps {
  maintenanceAgendaPeriod: {
    startDate: string;
    endDate: string;
    memo: string;
  };
}

export default function MaintenanceAgendaPeriodEventPopover({ maintenanceAgendaPeriod }: MaintenanceAgendaPeriodEventPopoverProps) {
  return (
    <Translation>
      {(translate) => (
        <AgendaPeriodEventPopover
          title={formatMaintenanceAgendaPeriod(maintenanceAgendaPeriod, translate)}
          startDate={maintenanceAgendaPeriod.startDate}
          endDate={maintenanceAgendaPeriod.endDate}
          renderBadge={() => <MaintenanceAgendaPeriodBadge />}
        />
      )}
    </Translation>
  );
}
