import React from 'react';
import moment from 'moment';
import ReservationAgendaPeriodEventPopover from '../reservation-agenda-period-event-popover';
import EventItem from '../event-item';
import formatReservation from '../../utils/formatReservation';
import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';
import { DefaultTooltip } from '../tooltip';
import { DisplayMode } from '../../actions/displayMode';
import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './ReservationAgendaPeriodEventItem.module.css';
import Translation from '../translation';
import generateReservationClassName from '../../utils/generateReservationClassName';
import { Booking } from '../../actions/bookingMode';
import renderGraphQLDateTime from '../../utils/renderGraphQLDateTime';

interface ReservationAgendaPeriodEventItemProps {
  reservationAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;

    reservation: {
      startDate: string;
      endDate: string;
      departureDate: string;
      lateCheckout: boolean;
      fixedRentable: boolean;

      booking: {
        id: string;
        referenceNr: string;
        source: string;
        isOption: boolean;
        stateDescription: string;
        bookingNr: string;
        firstName: string;
        lastName: string;
        company: string;

        // Alerts display mode
        hasUninvoicedAmountForGuest?: boolean;
        isConfirmed?: boolean;
        isTemporary?: boolean;
        hasUnpaidAmountForGuest?: boolean;
        hasPastDeadlineAmountForGuest?: boolean;

        channel: {
          name: string;
        };
      };

      // Alerts display mode
      hasUnfinishedTodos?: boolean;

      // Presence display mode fields
      checkedInOn?: string;
      checkedOutOn?: string;
      isCheckedIn?: boolean;
      isCheckedOut?: boolean;
    };
  };
  isActive: boolean;
  isIndented: boolean;
  displayMode: DisplayMode;
  activeBooking?: Booking;
}

export default class ReservationAgendaPeriodEventItem extends React.Component<ReservationAgendaPeriodEventItemProps> {
  get isIndented() {
    const { isIndented, displayMode } = this.props;
    return displayMode === DisplayMode.Default ? isIndented : false;
  }

  get displayModeClassName() {
    const { displayMode, reservationAgendaPeriod } = this.props;

    if (displayMode === DisplayMode.Alerts) {
      const { hasUnfinishedTodos, booking } = reservationAgendaPeriod.reservation;
      const { hasUninvoicedAmountForGuest, hasUnpaidAmountForGuest, hasPastDeadlineAmountForGuest } = booking;

      const isWarning = hasUninvoicedAmountForGuest || hasUnpaidAmountForGuest;
      const isAlert = hasUnfinishedTodos || (!hasUninvoicedAmountForGuest && hasPastDeadlineAmountForGuest);

      return isAlert ? classes.alert : isWarning ? classes.warning : 'filter-grayscale';
    }

    if (displayMode === DisplayMode.Presence) {
      const { isCheckedIn, isCheckedOut } = reservationAgendaPeriod.reservation;

      return isCheckedIn ? (isCheckedOut ? classes['checked-out'] : classes['checked-in']) : 'filter-grayscale';
    }

    return null;
  }

  get bookingModeClassName() {
    const { reservationAgendaPeriod, activeBooking } = this.props;
    const { booking } = reservationAgendaPeriod.reservation;

    return activeBooking && activeBooking.id !== booking.id ? 'filter-grayscale' : null;
  }

  get hasDateRangeExtended() {
    const { endDate } = this.props.reservationAgendaPeriod.reservation;
    const { departureDate } = this.props.reservationAgendaPeriod.reservation;

    return moment(departureDate).isAfter(endDate);
  }

  get icons() {
    const { fixedRentable } = this.props.reservationAgendaPeriod.reservation;

    return (
      <span className={classes.icons}>
        {this.displayModeIcons}
        {fixedRentable ? this.renderFixedRentableIcon() : null}
      </span>
    );
  }

  get displayModeIcons() {
    const { displayMode, reservationAgendaPeriod } = this.props;

    if (displayMode === DisplayMode.Presence) {
      const { isCheckedIn, isCheckedOut } = reservationAgendaPeriod.reservation;
      return isCheckedIn ? (isCheckedOut ? this.renderCheckedOutIcon() : this.renderCheckedInIcon()) : null;
    }

    if (displayMode === DisplayMode.Alerts) {
      const { hasUnfinishedTodos, booking } = reservationAgendaPeriod.reservation;
      const { hasUninvoicedAmountForGuest, isConfirmed, isTemporary, hasUnpaidAmountForGuest, hasPastDeadlineAmountForGuest } = booking;

      return (
        <React.Fragment>
          {!isConfirmed && isTemporary ? this.renderTemporaryReservationIcon() : undefined}
          {!isConfirmed && !isTemporary ? this.renderUnconfirmedReservationIcon() : undefined}
          {isConfirmed && hasUninvoicedAmountForGuest ? this.renderUnconfirmedChangeIcon() : undefined}
          {!hasUninvoicedAmountForGuest && hasUnpaidAmountForGuest ? this.renderUnpaidInvoicesIcon() : undefined}
          {!hasUninvoicedAmountForGuest && hasPastDeadlineAmountForGuest ? this.renderPastDeadlineInvoicesIcon() : undefined}
          {hasUnfinishedTodos ? this.renderUnfinishedTodosIcon() : undefined}
        </React.Fragment>
      );
    }

    return null;
  }

  renderCheckedInIcon() {
    const { checkedInOn } = this.props.reservationAgendaPeriod.reservation;

    const renderTooltip = () => (
      <Translation>
        {(translate) =>
          translate('Checked in on {{formattedCheckinDate}}', { formattedCheckinDate: renderGraphQLDateTime(checkedInOn, 'D MMM LT') })
        }
      </Translation>
    );

    return (
      <DefaultTooltip render={renderTooltip} distance={8} className={classes.tooltip}>
        <Icon icon="user" iconSize={11} />
      </DefaultTooltip>
    );
  }

  renderCheckedOutIcon() {
    const { checkedOutOn } = this.props.reservationAgendaPeriod.reservation;

    const renderTooltip = () => (
      <Translation>
        {(translate) =>
          translate('Checked out on {{formattedCheckoutDate}}', { formattedCheckoutDate: renderGraphQLDateTime(checkedOutOn, 'D MMM LT') })
        }
      </Translation>
    );

    return (
      <DefaultTooltip render={renderTooltip} distance={8} className={classes.tooltip}>
        <Icon icon="user" iconSize={11} />
      </DefaultTooltip>
    );
  }

  renderUnfinishedTodosIcon() {
    const renderTooltip = () => <Translation>{(translate) => translate('Action required: unfinished todos')}</Translation>;

    return (
      <DefaultTooltip render={renderTooltip} distance={8} className={classes.tooltip}>
        <Icon icon="tick" iconSize={11} />
      </DefaultTooltip>
    );
  }

  renderUnconfirmedChangeIcon() {
    const renderTooltip = () => <Translation>{(translate) => translate('Action required: change must be confirmed')}</Translation>;

    return (
      <DefaultTooltip render={renderTooltip} distance={8} className={classes.tooltip}>
        <FontAwesomeIcon icon={faPaperPlane} />
      </DefaultTooltip>
    );
  }

  renderTemporaryReservationIcon() {
    const renderTooltip = () => <Translation>{(translate) => translate('Caution: guest is busy creating reservation')}</Translation>;

    return (
      <DefaultTooltip render={renderTooltip} distance={8} className={classes.tooltip}>
        <FontAwesomeIcon icon={faSpinner} />
      </DefaultTooltip>
    );
  }

  renderUnconfirmedReservationIcon() {
    const renderTooltip = () => <Translation>{(translate) => translate('Action required: reservation must be confirmed')}</Translation>;

    return (
      <DefaultTooltip render={renderTooltip} distance={8} className={classes.tooltip}>
        <FontAwesomeIcon icon={faPaperPlane} />
      </DefaultTooltip>
    );
  }

  renderUnpaidInvoicesIcon() {
    const renderTooltip = () => <Translation>{(translate) => translate('Caution: outstanding amount')}</Translation>;

    return (
      <DefaultTooltip render={renderTooltip} distance={8} className={classes.tooltip}>
        <Icon icon="euro" iconSize={11} />
      </DefaultTooltip>
    );
  }

  renderPastDeadlineInvoicesIcon() {
    const renderTooltip = () => <Translation>{(translate) => translate('Action required: payment deadline expired')}</Translation>;

    return (
      <DefaultTooltip render={renderTooltip} distance={8} className={classes.tooltip}>
        <Icon icon="time" iconSize={11} />
      </DefaultTooltip>
    );
  }

  renderFixedRentableIcon() {
    const renderTooltip = () => <Translation>{(translate) => translate('Guest has chosen specifically for this rental')}</Translation>;

    return (
      <DefaultTooltip render={renderTooltip} distance={8} className={classes.tooltip}>
        <Icon icon="pin" iconSize={11} />
      </DefaultTooltip>
    );
  }

  render() {
    const { reservationAgendaPeriod, isActive, activeBooking } = this.props;
    const { booking } = reservationAgendaPeriod.reservation;

    const className = classNames(
      this.displayModeClassName,
      this.bookingModeClassName,
      generateReservationClassName({ ...reservationAgendaPeriod.reservation, hasDateRangeExtended: this.hasDateRangeExtended })
    );

    return (
      <EventItem
        title={formatReservation(reservationAgendaPeriod.reservation)}
        renderPopover={() => <ReservationAgendaPeriodEventPopover reservationAgendaPeriod={reservationAgendaPeriod} />}
        className={className}
        isActive={isActive}
        isIndented={this.isIndented}
        isBooking={activeBooking ? activeBooking.id === booking.id : false}
        icon={this.icons}
      />
    );
  }
}
