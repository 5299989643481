import React, { PureComponent } from 'react';
import { Button, Icon, ButtonGroup, IconName, HotkeysTarget2 } from '@blueprintjs/core';
import { DisplayMode } from '../../actions/displayMode';
import Translation, { TranslateFunction } from '../translation';

interface DisplayModeSwitcherProps {
  displayMode: DisplayMode;
  translate: TranslateFunction;
  onChange: (displayMode: DisplayMode) => void;
}

enum DisplayModeText {
  Default = 'Default mode',
  Alerts = 'Payments & todos',
  Presence = 'Presence',
}

const BUTTONS = [
  { displayMode: DisplayMode.Default, icon: 'eye-on' as IconName, text: DisplayModeText.Default },
  { displayMode: DisplayMode.Alerts, icon: 'euro' as IconName, text: DisplayModeText.Alerts },
  { displayMode: DisplayMode.Presence, icon: 'user' as IconName, text: DisplayModeText.Presence },
];

export default class DisplayModeSwitcher extends PureComponent<DisplayModeSwitcherProps> {
  handleDefaultDisplayModeHotkey = () => {
    this.props.onChange(DisplayMode.Default);
  };

  handleAlertsDisplayModeHotkey = () => {
    this.props.onChange(DisplayMode.Alerts);
  };

  handlePresenceDisplayModeHotkey = () => {
    this.props.onChange(DisplayMode.Presence);
  };

  renderButton(button: { displayMode: DisplayMode; icon: IconName; text: string }) {
    const { displayMode, translate, onChange } = this.props;

    const icon = <Icon icon={button.icon} />;
    const isActive = displayMode === button.displayMode;
    const handleClick = () => onChange(button.displayMode);

    return (
      <Button key={button.displayMode} icon={icon} active={isActive} onClick={handleClick}>
        {translate(button.text as DisplayModeText)}
      </Button>
    );
  }

  private hotkeys = [
    {
      label: <Translation>{(translate) => translate('Show default mode')}</Translation>,
      group: 'Weergavemodus',
      combo: 'shift+1',
      allowInInput: true,
      preventDefault: true,
      global: true,
      onKeyDown: this.handleDefaultDisplayModeHotkey,
    },
    {
      label: <Translation>{(translate) => translate('Show payments & todos')}</Translation>,
      group: 'Weergavemodus',
      combo: 'shift+2',
      allowInInput: true,
      preventDefault: true,
      global: true,
      onKeyDown: this.handleAlertsDisplayModeHotkey,
    },
    {
      label: <Translation>{(translate) => translate('Show presence')}</Translation>,
      group: 'Weergavemodus',
      combo: 'shift+3',
      allowInInput: true,
      preventDefault: true,
      global: true,
      onKeyDown: this.handlePresenceDisplayModeHotkey,
    },
  ];

  render() {
    return (
      <HotkeysTarget2 hotkeys={this.hotkeys}>
        {({ handleKeyDown, handleKeyUp }) => (
          <ButtonGroup tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} className="bp4-dark">
            {BUTTONS.map(this.renderButton.bind(this))}
          </ButtonGroup>
        )}
      </HotkeysTarget2>
    );
  }
}
