import React, { PureComponent } from 'react';
import DateControls from './DateControls';
import DatePicker from './DatePicker';
import { Moment } from 'moment';
import classes from './DateHeader.module.css';

interface DateHeaderProps {
  date: string;
  startDate: string | null;
  onChange: (date: Moment) => void;
}

export default class DateHeader extends PureComponent<DateHeaderProps> {
  render() {
    const { date, startDate, onChange } = this.props;

    return (
      <header className={classes.root}>
        <DateControls date={date} startDate={startDate} className={classes['date-controls']} onChange={onChange} />
        <DatePicker value={date} className={classes['date-picker']} onChange={onChange} />
      </header>
    );
  }
}
