import { DisplayMode } from '../actions/displayMode';

export default function serializeDisplayMode(displayMode: DisplayMode) {
  switch (displayMode) {
    case DisplayMode.Default: {
      return 'default';
    }

    case DisplayMode.Alerts: {
      return 'alerts';
    }

    case DisplayMode.Presence: {
      return 'presence';
    }
  }
}
