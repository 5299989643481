import React, { CSSProperties, ReactNode, PureComponent } from 'react';
import EventLink from './EventLink';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import * as actions from '../../actions/sidebarPanel';
import { State } from '../../reducers';
import { DisplayMode } from '../../actions/displayMode';
import isSidebarOpened from '../../utils/isSidebarOpened';

interface OwnProps {
  url?: string;
  style: CSSProperties;
  sidebarType: actions.SidebarType;
  sidebarId: string;
  renderItem: (props: { isActive: boolean; isIndented: boolean; displayMode: DisplayMode }) => ReactNode;
}

interface StateProps {
  displayMode: DisplayMode;
  isActive: boolean;
}

interface DispatchProps {
  toggleSidebarPanel: (sidebarType: actions.SidebarType, id: string) => void;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state, ownProps) => {
  return {
    displayMode: state.displayMode,
    isActive: isSidebarOpened(state.sidebarPanel, ownProps.sidebarType, ownProps.sidebarId),
  };
};

interface DispatchProps {
  toggleSidebarPanel: (sidebarType: actions.SidebarType, id: string) => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, State> = {
  toggleSidebarPanel: actions.toggleSidebarPanel,
};

type EventLinkContainerProps = OwnProps & StateProps & DispatchProps;

class EventLinkContainer extends PureComponent<EventLinkContainerProps> {
  handleClick = () => {
    const { sidebarType, sidebarId, toggleSidebarPanel } = this.props;
    toggleSidebarPanel(sidebarType, sidebarId);
  };

  render() {
    const { toggleSidebarPanel: _toggleSidebarPanel, ...props } = this.props;
    return <EventLink {...props} onClick={this.handleClick} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventLinkContainer);
