import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Icon } from '@blueprintjs/core';
import Translation from '../translation';
import renderGraphQLDateTime from '../../utils/renderGraphQLDateTime';

interface PresenceSectionProps {
  reservation: {
    checkedInOn: string;
    checkedOutOn: string;
    isCheckedIn: boolean;
    isCheckedOut: boolean;
  };
}

export default class PresenceSection extends Component<PresenceSectionProps> {
  renderCheckedInOnTableRow() {
    const { isCheckedIn, checkedInOn } = this.props.reservation;

    return (
      <Translation>
        {(translate) => (
          <Sidebar.TableRow
            icon={<Icon icon="log-in" iconSize={12} />}
            header={translate('Checked in on')}
            data={isCheckedIn ? renderGraphQLDateTime(checkedInOn, 'D MMM LT') : '—'}
          />
        )}
      </Translation>
    );
  }

  renderCheckedOutOnTableRow() {
    const { isCheckedOut, checkedOutOn } = this.props.reservation;

    return (
      <Translation>
        {(translate) => (
          <Sidebar.TableRow
            icon={<Icon icon="log-out" iconSize={12} />}
            header={translate('Checked out on')}
            data={isCheckedOut ? renderGraphQLDateTime(checkedOutOn, 'D MMM LT') : '—'}
          />
        )}
      </Translation>
    );
  }

  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Presence')}>
            <Sidebar.Table>
              {this.renderCheckedInOnTableRow()}
              {this.renderCheckedOutOnTableRow()}
            </Sidebar.Table>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}
