import { State } from '../reducers';
import { Dispatch, Action } from 'redux';
import moment, { Moment } from 'moment';
import { replace as replaceLocationAction, REPLACE } from 'redux-first-routing';
import serializeDate from '../utils/serializeDate';
import mergeSearchParam from '../utils/mergeSearchParam';
import { SearchParams } from '../config/planboard';

/**
 * Updates the current date shown on the planboard.
 */
export const updateCurrentDate = (date: Moment) => (dispatch: Dispatch<Action<typeof REPLACE>>, getState: () => State) => {
  const router = getState().router;

  // Only dispatch action if date actually changed
  if (!moment(router.queries.date).isSame(date, 'date')) {
    const search = mergeSearchParam(router.search, SearchParams.DATE, serializeDate(date)).toString();
    dispatch(replaceLocationAction({ search }));
  }
};
