import React from 'react';
import classes from './Placeholder.module.css';

export default function Placeholder() {
  return (
    <div className={classes.root}>
      <div className={classes.placeholder} />
    </div>
  );
}
