import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult, MutationFunction } from '@apollo/client/react';
import { loader } from 'graphql.macro';
const takeOverIcalAgendaPeriodMutation = loader('./takeOverIcalAgendaPeriodMutation.graphql');

export interface TakeOverData {
  takeOverIcalAgendaPeriod: {
    id: string;
    parkId: string;
    reservationAgendaPeriods: Array<{ id: string }>;
  };
}

interface TakeOverVariables {
  icalAgendaPeriodId: string;
}

export type Result = MutationResult<TakeOverData>;
export type TakeOverFn = MutationFunction<TakeOverData, TakeOverVariables>;

interface TakeOverIcalAgendaPeriodMutationProps {
  children: (takeOver: TakeOverFn, result: Result) => JSX.Element | null;
}

const REFETCH_QUERIES = ['rentableIdentityCellsQuery', 'rentableCellsQuery'];

export default function TakeOverIcalAgendaPeriodMutation({ children }: TakeOverIcalAgendaPeriodMutationProps) {
  return (
    <Mutation<TakeOverData, TakeOverVariables>
      mutation={takeOverIcalAgendaPeriodMutation}
      refetchQueries={REFETCH_QUERIES}
      awaitRefetchQueries
    >
      {children}
    </Mutation>
  );
}
