import React, { PureComponent, MouseEvent } from 'react';
import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';
import { DefaultTooltip } from '../tooltip';
import Translation from '../translation';

interface StarButtonProps {
  className?: string;
  isStarred: boolean;
  onClick: (isStarred: boolean) => void;
}

export default class FavoriteButton extends PureComponent<StarButtonProps> {
  static defaultProps = { isStarred: false };

  handleClick = (e: MouseEvent) => {
    this.props.onClick(this.props.isStarred);

    e.stopPropagation();
    e.preventDefault();
  };

  renderTooltipTitle() {
    const { isStarred } = this.props;

    return (
      <Translation>{(translate) => (isStarred ? translate('Remove park from favorites') : translate('Add park to favorites'))}</Translation>
    );
  }

  renderIcon() {
    let { isStarred } = this.props;

    let className = classNames('-m-2', 'p-2', {
      'text-amber-500': isStarred,
      'text-gray-300': !isStarred,
    });

    return <Icon className={className} icon="star" onClick={this.handleClick} />;
  }

  render() {
    return (
      <DefaultTooltip render={() => this.renderTooltipTitle()} className="flex">
        {this.renderIcon()}
      </DefaultTooltip>
    );
  }
}
