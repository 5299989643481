import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import FilterPanelQueryData from './FilterPanelQueryData';
import { NORMAL_QUERY_POLL_INTERVAL } from '../../config/planboard';
const filterPanelQuery = loader('./filterPanelQuery.graphql');

interface FilterPanelQueryProps {
  parkId: string;
  cacheOnly: boolean;
  children: (result: QueryResult<FilterPanelQueryData, Variables>) => JSX.Element | null;
}

interface Variables {
  parkId: string;
}

export default function FilterPanelQuery({ parkId, cacheOnly, children }: FilterPanelQueryProps) {
  const fetchPolicy = cacheOnly ? 'cache-only' : 'cache-and-network';
  const pollInterval = cacheOnly ? undefined : NORMAL_QUERY_POLL_INTERVAL;

  return (
    <Query<FilterPanelQueryData, Variables>
      query={filterPanelQuery}
      variables={{ parkId }}
      fetchPolicy={fetchPolicy}
      pollInterval={pollInterval}
    >
      {children}
    </Query>
  );
}
