import React, { PureComponent } from 'react';
import cs from 'classnames';
import ParkSwitcher from '../park-switcher';
import FilterPanelToggleButton from '../filter-panel-toggle-button';
import DisplayModeSwitcher from '../display-mode-switcher';
import SearchBox from '../search-box';
import classes from './Toolbar.module.css';
import { GridRef } from '../grid';

interface ToolbarProps {
  parkId: string;
  gridRef: GridRef;
}

interface ToolbarState {
  searchBoxHasFocus: boolean;
}

export default class Toolbar extends PureComponent<ToolbarProps, ToolbarState> {
  state: ToolbarState = { searchBoxHasFocus: false };

  handleSearchBoxFocus = () => {
    this.setState({ searchBoxHasFocus: true });
  };

  handleSearchBoxBlur = () => {
    this.setState({ searchBoxHasFocus: false });
  };

  render() {
    let { searchBoxHasFocus } = this.state;
    let { parkId, gridRef } = this.props;

    let className = cs('py-2 mx-2 ml-auto', classes['search-box'], {
      [classes['search-box--focus']]: searchBoxHasFocus,
    });

    return (
      <header className={classes.root}>
        <header className="p-2 flex-no-shrink" style={{ width: '256px' }}>
          <ParkSwitcher parkId={this.props.parkId} />
        </header>

        <main className="ml-2 py-2 flex-no-shrink">
          <FilterPanelToggleButton />
        </main>

        <main className="mx-2 py-2 flex-no-shrink">
          <DisplayModeSwitcher />
        </main>

        <aside className={className}>
          <SearchBox parkId={parkId} gridRef={gridRef} onFocus={this.handleSearchBoxFocus} onBlur={this.handleSearchBoxBlur} />
        </aside>
      </header>
    );
  }
}
