import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import cs from 'classnames';
import classes from './ExternalLink.module.css';

interface ExternalLinkProps {
  href: string;
  className?: string;
  icon?: boolean;
  children: React.ReactNode;
}

export default function ExternalLink({ href, className, icon, children, ...htmlProps }: ExternalLinkProps) {
  return (
    // See https://developers.google.com/web/tools/lighthouse/audits/noopener
    <a {...htmlProps} href={href} className={cs(classes.root, className)} target="_blank" rel="noopener noreferrer">
      {children}
      {icon && <FontAwesomeIcon icon={faExternalLinkAlt} className={classes.icon} />}
    </a>
  );
}
