import React, { PureComponent } from 'react';
import classes from './DefaultWeekDate.module.css';
import WeekDate from './WeekDate';

interface DefaultWeekDateProps {
  date: string;
}

export default class DefaultWeekDate extends PureComponent<DefaultWeekDateProps> {
  render() {
    return <WeekDate className={classes.root} {...this.props} />;
  }
}
