import React from 'react';
import SettingsDialog from './SettingsDialog';
import UpdateSettingsMutation, { UpdateSettingsFn, Result } from './UpdateSettingsMutation';
import SettingsDialogQuery from './SettingsDialogQuery';

interface SettingsDialogContainerProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SettingsDialogContainer({ isOpen, onClose }: SettingsDialogContainerProps) {
  return (
    <SettingsDialogQuery>
      {({ data, loading, error }) => {
        if (error) {
          return null;
        }
        if (loading) {
          return null;
        }

        if (data === undefined) {
          return null;
        }

        // This makes sure the component gets rerendered when data changes, see:
        // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
        const key = [data.user.locale, data.user.planboardLayout, data.user.planboardStartDate].join('-');

        return (
          <UpdateSettingsMutation>
            {(updateSettings: UpdateSettingsFn, result: Result) => (
              <SettingsDialog
                key={key}
                isOpen={isOpen}
                isSaving={result.loading}
                data={data}
                updateSettings={updateSettings}
                onClose={onClose}
              />
            )}
          </UpdateSettingsMutation>
        );
      }}
    </SettingsDialogQuery>
  );
}
