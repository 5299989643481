import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import ParkSwitcherQueryData from './ParkSwitcherQueryData';
const parkSwitcherQuery = loader('./parkSwitcherQuery.graphql');

interface ParkSwitcherProps {
  children: (result: QueryResult<ParkSwitcherQueryData, {}>) => JSX.Element | null;
}

export default function ParkSwitcherQuery({ children }: ParkSwitcherProps) {
  return <Query query={parkSwitcherQuery}>{children}</Query>;
}
