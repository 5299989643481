import React, { Component } from 'react';
import Sidebar from '../sidebar';
import formatRentableIdentity from '../../utils/formatRentableIdentity';
import ReservationChannelBadge from '../reservation-channel-badge';
import { Icon } from '@blueprintjs/core';
import Translation from '../translation';

interface SummarySectionProps {
  reservation: {
    fixedRentable: boolean;
    url: string;

    booking: {
      source: string;
      isOption: boolean;

      channel: {
        name: string;
      };
    };

    rentableIdentity: {
      name: string;
      rentableTypeName: string;
      url: string;
    };
  };
}

export default class SummarySection extends Component<SummarySectionProps> {
  renderTypeTableRow() {
    const { reservation } = this.props;
    return (
      <Translation>
        {(translate) => <Sidebar.TableRow header={translate('Channel')} data={<ReservationChannelBadge reservation={reservation} />} />}
      </Translation>
    );
  }

  renderRentableIdentityTableRow() {
    const { fixedRentable, rentableIdentity } = this.props.reservation;

    const data = (
      <span className="inline-flex items-center">
        <a href={rentableIdentity.url}>{formatRentableIdentity(rentableIdentity)}</a>
        {fixedRentable && <Icon icon="pin" iconSize={11} className="align-middle ml-1" />}
      </span>
    );

    return <Translation>{(translate) => <Sidebar.TableRow header={translate('Rental')} data={data} />}</Translation>;
  }

  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Reservation')}>
            <Sidebar.Table>
              {this.renderTypeTableRow()}
              {this.renderRentableIdentityTableRow()}
            </Sidebar.Table>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}
