import React, { PureComponent } from 'react';
import Grid, { GridRef } from '../grid';
import Toolbar from '../toolbar';
import RealtimeUpdates from './RealtimeUpdates';
import classes from './PlanboardPage.module.css';
import SidebarPanel from '../sidebar-panel';
import ReservationSelectionDialog from '../reservation-selection-dialog';
import FilterPanel from '../filter-panel';
import Page from '../page';
import { apolloClient } from '../../config/apollo';
import { HotkeysTarget2 } from '@blueprintjs/core';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Translation, { WithTranslation, withTranslation } from '../translation';
import { goForward as goForwardAction, goBack as goBackAction } from 'redux-first-routing';
import BookingModeNotice from '../booking-mode-notice';

interface PlanboardPageProps {
  parkId: string;
  dispatch: Dispatch;
}

class PlanboardPage extends PureComponent<PlanboardPageProps & WithTranslation> {
  private gridRef: GridRef = React.createRef();
  private realtimeUpdates = new RealtimeUpdates(apolloClient);

  componentDidMount() {
    this.realtimeUpdates.start(this.props.parkId);
  }

  componentDidUpdate() {
    this.realtimeUpdates.restart(this.props.parkId);
  }

  componentWillUnmount() {
    this.realtimeUpdates.stop();
  }

  handleUndoInteractionHotkey = () => {
    this.props.dispatch(goBackAction());
  };

  handleRedoInteractionHotkey = () => {
    this.props.dispatch(goForwardAction());
  };

  renderReservationSelectionDialog() {
    return <ReservationSelectionDialog parkId={this.props.parkId} />;
  }

  renderToolbar() {
    return <Toolbar parkId={this.props.parkId} gridRef={this.gridRef} />;
  }

  renderFilterPanel() {
    return <FilterPanel parkId={this.props.parkId} />;
  }

  renderBookingNotice() {
    return <BookingModeNotice />;
  }

  renderGrid() {
    return <Grid parkId={this.props.parkId} ref={this.gridRef} />;
  }

  renderSidebarPanel() {
    return <SidebarPanel />;
  }

  private hotkeys = [
    {
      label: <Translation>{(translate) => translate('Undo interaction')}</Translation>,
      combo: 'backspace',
      global: true,
      onKeyDown: this.handleUndoInteractionHotkey,
      preventDefault: true,
    },
    {
      label: <Translation>{(translate) => translate('Redo interaction')}</Translation>,
      combo: 'shift+backspace',
      global: true,
      onKeyDown: this.handleRedoInteractionHotkey,
      preventDefault: true,
    },
  ];

  render() {
    return (
      <HotkeysTarget2 hotkeys={this.hotkeys}>
        {({ handleKeyDown, handleKeyUp }) => (
          <Page className={classes.root} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
            <Page.Launchbar parkId={this.props.parkId} />

            {this.renderReservationSelectionDialog()}
            {this.renderToolbar()}
            {this.renderFilterPanel()}
            {this.renderBookingNotice()}

            <Page.Container className={classes.main}>
              {this.renderGrid()}
              {this.renderSidebarPanel()}
            </Page.Container>
          </Page>
        )}
      </HotkeysTarget2>
    );
  }
}

export default connect()(withTranslation()(PlanboardPage));
