import React from 'react';
import Page from '../page';
import Translation from '../translation';

export default function NotFoundPage() {
  return (
    <Translation>
      {(translate) => (
        <Page>
          <Page.Launchbar />
          <Page.Container className="mx-auto max-w-xl mt-4 md:mt-8 px-4 md:px-8 mb-8 md:pb-8">
            <div className="bg-white p-4 h-full border-b space-between-2">
              <div className="text-red-900 bg-red-50 rounded-sm  p-2">{translate('This page does not exist')}</div>
            </div>
          </Page.Container>
        </Page>
      )}
    </Translation>
  );
}
