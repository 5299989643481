import {
  SET_RESERVATION_SELECTION_HOVER_RENTABLE,
  CLEAR_RESERVATION_SELECTION_HOVER_RENTABLE,
  SET_RESERVATION_SELECTION_RENTABLE,
  SET_RESERVATION_SELECTION_HOVER_START_DATE,
  SET_RESERVATION_SELECTION_DRAGGING_END_DATE,
  SET_RESERVATION_SELECTION_START_DATE,
  SET_RESERVATION_SELECTION_END_DATE,
  CLEAR_RESERVATION_SELECTION,
  SetReservationSelectionHoverRentable,
  ClearReservationSelectionHoverRentable,
  SetReservationSelectionRentable,
  SetReservationSelectionHoverStartDate,
  SetReservationSelectionStartDate,
  SetReservationSelectionDraggingEndDate,
  SetReservationSelectionEndDate,
  ClearReservationSelection,
} from '../actions/reservationSelection';
import serializeDate from '../utils/serializeDate';

export interface ReservationSelectionState {
  hoverRentableId?: string;
  rentableId?: string;
  hoverStartDate?: string;
  startDate?: string;
  draggingEndDate?: string;
  endDate?: string;
}

type Actions =
  | SetReservationSelectionHoverRentable
  | ClearReservationSelectionHoverRentable
  | SetReservationSelectionRentable
  | SetReservationSelectionHoverStartDate
  | SetReservationSelectionStartDate
  | SetReservationSelectionDraggingEndDate
  | SetReservationSelectionEndDate
  | ClearReservationSelection;

const initialState: ReservationSelectionState = {};

export default function reservationSelection(state = initialState, action: Actions): ReservationSelectionState {
  switch (action.type) {
    case SET_RESERVATION_SELECTION_HOVER_RENTABLE: {
      return { ...state, hoverRentableId: action.hoverRentableId };
    }

    case CLEAR_RESERVATION_SELECTION_HOVER_RENTABLE: {
      return { ...state, hoverRentableId: undefined };
    }

    case SET_RESERVATION_SELECTION_RENTABLE: {
      return { ...state, rentableId: action.rentableId };
    }

    case SET_RESERVATION_SELECTION_HOVER_START_DATE: {
      return { ...state, hoverStartDate: serializeDate(action.hoverStartDate) };
    }

    case SET_RESERVATION_SELECTION_START_DATE: {
      return { ...state, startDate: serializeDate(action.startDate) };
    }

    case SET_RESERVATION_SELECTION_DRAGGING_END_DATE: {
      return { ...state, draggingEndDate: serializeDate(action.draggingEndDate) };
    }

    case SET_RESERVATION_SELECTION_END_DATE: {
      return { ...state, endDate: serializeDate(action.endDate) };
    }

    case CLEAR_RESERVATION_SELECTION: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
