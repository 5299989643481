import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import { SearchBoxQueryData } from './SearchBoxQueryData';
import { FAST_QUERY_POLL_INTERVAL } from '../../config/planboard';
const searchBoxQuery = loader('./searchBoxQuery.graphql');

interface SearchBoxQueryProps {
  parkId: string;
  searchQuery: string;
  limit: number;
  children: (result: QueryResult<SearchBoxQueryData, {}>) => JSX.Element | null;
}

export default function SearchBoxQuery({ parkId, searchQuery, limit, children }: SearchBoxQueryProps) {
  return (
    <Query
      query={searchBoxQuery}
      variables={{ parkId, searchQuery, limit }}
      fetchPolicy="network-only"
      pollInterval={FAST_QUERY_POLL_INTERVAL}
    >
      {children}
    </Query>
  );
}
