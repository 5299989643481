import React, { FormEvent, Component } from 'react';
import { Icon, Intent } from '@blueprintjs/core';
import { FetchResult } from '@apollo/client/core';
import LoadingButton from '../loading-button';
import { showSuccessToast, showApolloErrorToast } from '../toaster';
import { MapDispatchToProps, connect } from 'react-redux';
import { State } from '../../reducers';
import * as actions from '../../actions/sidebarPanel';
import { TakeOverFn, TakeOverData } from './TakeOverIcalAgendaPeriodMutation';
// tslint:disable-next-line:no-duplicate-imports
import { SidebarType } from '../../actions/sidebarPanel';
import Translation from '../translation';
import { ApolloError } from '@apollo/client';

interface OwnProps {
  icalAgendaPeriodId: string;
  takeOver: TakeOverFn;
  isLoading: boolean;
}

interface DispatchProps {
  openSidebarPanel: (sidebarType: SidebarType, id: string) => void;
  closeSidebarPanel: () => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, State> = {
  openSidebarPanel: actions.openSidebarPanel,
  closeSidebarPanel: actions.closeSidebarPanel,
};

type TakeOverIcalAgendaPeriodFormProps = OwnProps & DispatchProps;

class TakeOverIcalAgendaPeriodForm extends Component<TakeOverIcalAgendaPeriodFormProps> {
  handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const { takeOver, icalAgendaPeriodId, openSidebarPanel, closeSidebarPanel } = this.props;

    try {
      const result = await takeOver({ variables: { icalAgendaPeriodId } });
      const reservation = (result as FetchResult<TakeOverData>).data!.takeOverIcalAgendaPeriod;

      closeSidebarPanel();

      // Wait till the sidebar close animation has finished
      await new Promise((resolve) => setTimeout(resolve, 150));

      const reservationAgendaPeriod = reservation.reservationAgendaPeriods[0];

      if (reservationAgendaPeriod) {
        // Open the reservation that was just created in the sidebar
        openSidebarPanel(SidebarType.ReservationAgendaPeriod, reservationAgendaPeriod.id);
      }

      const message = (
        <Translation>{(translate) => translate('The iCal blocked period has been converted into a reservation')}</Translation>
      );

      showSuccessToast({ message });
    } catch (e) {
      if (e instanceof ApolloError) {
        showApolloErrorToast(e);
      } else {
        throw e;
      }
    }
  };

  render() {
    let { isLoading } = this.props;

    return (
      <Translation>
        {(translate) => (
          <form onSubmit={this.handleFormSubmit}>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              isDisabled={isLoading}
              intent={Intent.PRIMARY}
              icon={<Icon icon="import" iconSize={12} />}
            >
              {translate('Convert to reservation')}
            </LoadingButton>
          </form>
        )}
      </Translation>
    );
  }
}

export default connect(null, mapDispatchToProps)(TakeOverIcalAgendaPeriodForm);
