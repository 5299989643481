import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import FilterPanel from './FilterPanel';
import FilterPanelQuery from './FilterPanelQuery';
import ErrorMessage from '../error-message';
import { State } from '../../reducers';
import isEmpty from 'lodash/isEmpty';
import * as actions from '../../actions/filterPanel';
import getStatusCode from '../../utils/getStatusCode';

interface OwnProps {
  parkId: string;
}

type FilterPanelContainerProps = ConnectedProps<typeof connector> & OwnProps;

const mapStateToProps = (state: State) => {
  const {
    isOpen,
    selectedGuestGroup,
    selectedRentableSegmentIds,
    selectedRentableTypeIds,
    selectedRentableIdentityLabelIds,
    selectedAmenityIds,
  } = state.filterPanel;

  return {
    isOpen,
    selectedGuestGroup,
    selectedRentableSegmentIds,
    selectedRentableTypeIds,
    selectedRentableIdentityLabelIds,
    selectedAmenityIds,
  };
};

const mapDispatchToProps = {
  updateFilterPanelGuestGroup: actions.updateFilterPanelGuestGroup,
  updateFilterPanelRentableSegments: actions.updateFilterPanelRentableSegments,
  updateFilterPanelRentableTypes: actions.updateFilterPanelRentableTypes,
  updateFilterPanelRentableIdentityLabels: actions.updateFilterPanelRentableIdentityLabels,
  updateFilterPanelAmenities: actions.updateFilterPanelAmenities,
  openFilterPanel: actions.openFilterPanel,
};

const PLACEHOLDER_DATA = {
  guestTypes: [],
  rentableSegments: [],
  rentableTypes: [],
  rentableIdentityLabels: [],
  amenities: [],
};

function FilterPanelContainer({
  parkId,
  isOpen,
  selectedGuestGroup,
  selectedRentableSegmentIds,
  selectedRentableTypeIds,
  selectedRentableIdentityLabelIds,
  selectedAmenityIds,
  updateFilterPanelGuestGroup,
  updateFilterPanelRentableSegments,
  updateFilterPanelRentableTypes,
  updateFilterPanelRentableIdentityLabels,
  updateFilterPanelAmenities,
  openFilterPanel,
}: FilterPanelContainerProps) {
  return (
    <FilterPanelQuery parkId={parkId} cacheOnly={!isOpen}>
      {({ data, error }) => {
        if (error) {
          if (!isOpen || getStatusCode(error) === 500) {
            // Don't render error message when server error occurs since it will (most likely)
            // already be shown by the <GridContainer /> component.
            return null;
          }

          return <ErrorMessage error={error} />;
        }

        return (
          <FilterPanel
            {...(!isEmpty(data) ? data!.filterPanel : PLACEHOLDER_DATA)}
            isOpen={isOpen}
            selectedGuestGroup={selectedGuestGroup}
            selectedRentableSegmentIds={selectedRentableSegmentIds}
            selectedRentableTypeIds={selectedRentableTypeIds}
            selectedRentableIdentityLabelIds={selectedRentableIdentityLabelIds}
            selectedAmenityIds={selectedAmenityIds}
            onGuestGroupFilterChange={updateFilterPanelGuestGroup}
            onRentableSegmentFilterChange={updateFilterPanelRentableSegments}
            onRentableTypeFilterChange={updateFilterPanelRentableTypes}
            onRentableIdentityLabelFilterChange={updateFilterPanelRentableIdentityLabels}
            onAmenityFilterChange={updateFilterPanelAmenities}
            onHotkey={openFilterPanel}
          />
        );
      }}
    </FilterPanelQuery>
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(FilterPanelContainer);
