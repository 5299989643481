import { LOCATION_CHANGE, locationChangeAction, State as LocationState } from 'redux-first-routing';
import { SearchParams } from '../config/planboard';
import { FilterToggle } from '../actions/filterPanel';

export interface FilterPanelState {
  isOpen: boolean;
  selectedGuestGroup: string;
  selectedRentableSegmentIds: string;
  selectedRentableTypeIds: string;
  selectedRentableIdentityLabelIds: string;
  selectedAmenityIds: string;
}

function deserializeStateFromLocation(location: Location | LocationState) {
  const searchParams = new URLSearchParams(location.search);

  const isOpen = (searchParams.get(SearchParams.FILTER) || FilterToggle.OPEN) === FilterToggle.OPEN;
  const selectedGuestGroup = searchParams.get(SearchParams.GUEST_GROUP) || '';
  const selectedRentableSegmentIds = searchParams.get(SearchParams.RENTABLE_SEGMENTS) || '';
  const selectedRentableTypeIds = searchParams.get(SearchParams.RENTABLE_TYPES) || '';
  const selectedRentableIdentityLabelIds = searchParams.get(SearchParams.LABELS) || '';
  const selectedAmenityIds = searchParams.get(SearchParams.AMENITIES) || '';

  return {
    isOpen,
    selectedGuestGroup,
    selectedRentableSegmentIds,
    selectedRentableTypeIds,
    selectedRentableIdentityLabelIds,
    selectedAmenityIds,
  };
}

export default function filterPanel(
  state = deserializeStateFromLocation(window.location),
  action: locationChangeAction<string, string, string>
): FilterPanelState {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return deserializeStateFromLocation(action.payload);
    }

    default: {
      return state;
    }
  }
}
