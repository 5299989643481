import React from 'react';
import { H6, Classes, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';

export default function OrganizationListPlaceholder() {
  return (
    <Menu className="p-0 border-0">
      <header className={Classes.MENU_HEADER}>
        <H6 className={`${Classes.SKELETON} w-1/3`}>...</H6>
      </header>

      <MenuItem
        text={<div className={`${Classes.SKELETON} w-1/3`}>...</div>}
        icon={<span className={Classes.SKELETON}>......</span>}
        labelElement={<span className={Classes.SKELETON}>......</span>}
      />

      <MenuDivider />
    </Menu>
  );
}
