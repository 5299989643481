import { State } from '../reducers';
import { Dispatch } from 'redux';
import { Moment } from 'moment';

export const CLEAR_RESERVATION_SELECTION_HOVER_RENTABLE = 'CLEAR_RESERVATION_SELECTION_HOVER_RENTABLE';
export const SET_RESERVATION_SELECTION_HOVER_RENTABLE = 'SET_RESERVATION_SELECTION_HOVER_RENTABLE';
export const SET_RESERVATION_SELECTION_RENTABLE = 'SET_RESERVATION_SELECTION_RENTABLE';
export const SET_RESERVATION_SELECTION_HOVER_START_DATE = 'SET_RESERVATION_SELECTION_HOVER_START_DATE';
export const SET_RESERVATION_SELECTION_DRAGGING_END_DATE = 'SET_RESERVATION_SELECTION_DRAGGING_END_DATE';
export const SET_RESERVATION_SELECTION_START_DATE = 'SET_RESERVATION_SELECTION_START_DATE';
export const SET_RESERVATION_SELECTION_END_DATE = 'SET_RESERVATION_SELECTION_END_DATE';
export const CLEAR_RESERVATION_SELECTION = 'CLEAR_RESERVATION_SELECTION';

// tslint:disable-next-line max-classes-per-file
export class SetReservationSelectionHoverRentable {
  readonly type = SET_RESERVATION_SELECTION_HOVER_RENTABLE;
  constructor(public hoverRentableId: string) {}
}

// tslint:disable-next-line max-classes-per-file
export class ClearReservationSelectionHoverRentable {
  readonly type = CLEAR_RESERVATION_SELECTION_HOVER_RENTABLE;
}

// tslint:disable-next-line max-classes-per-file
export class SetReservationSelectionRentable {
  readonly type = SET_RESERVATION_SELECTION_RENTABLE;
  constructor(public rentableId: string) {}
}

// tslint:disable-next-line max-classes-per-file
export class SetReservationSelectionHoverStartDate {
  readonly type = SET_RESERVATION_SELECTION_HOVER_START_DATE;
  constructor(public hoverStartDate: Moment) {}
}

// tslint:disable-next-line max-classes-per-file
export class SetReservationSelectionDraggingEndDate {
  readonly type = SET_RESERVATION_SELECTION_DRAGGING_END_DATE;
  constructor(public draggingEndDate: Moment) {}
}

// tslint:disable-next-line max-classes-per-file
export class SetReservationSelectionStartDate {
  readonly type = SET_RESERVATION_SELECTION_START_DATE;
  constructor(public startDate: Moment) {}
}

// tslint:disable-next-line max-classes-per-file
export class SetReservationSelectionEndDate {
  readonly type = SET_RESERVATION_SELECTION_END_DATE;
  constructor(public endDate: Moment) {}
}

// tslint:disable-next-line max-classes-per-file
export class ClearReservationSelection {
  readonly type = CLEAR_RESERVATION_SELECTION;
}

function isSameDate(date1: Moment, date2?: string) {
  return date2 && date1.isSame(date2, 'date');
}

function isAfterDate(date1: Moment, date2?: string) {
  return date2 && date1.isAfter(date2, 'date');
}

export const setReservationSelectionHoverRentable =
  (hoverRentableId: string) => (dispatch: Dispatch<SetReservationSelectionHoverRentable>, getState: () => State) => {
    // Performance optimiziation: don't dispatch action if already set
    if (hoverRentableId !== getState().reservationSelection.hoverRentableId) {
      dispatch({ type: SET_RESERVATION_SELECTION_HOVER_RENTABLE, hoverRentableId });
    }
  };

export const releaseReservationSelectionHoverRentable =
  (hoverRentableId: string) => (dispatch: Dispatch<ClearReservationSelectionHoverRentable>, getState: () => State) => {
    // Only clear if matches currently active hoverRentableId
    if (getState().reservationSelection.hoverRentableId === hoverRentableId) {
      dispatch({ type: CLEAR_RESERVATION_SELECTION_HOVER_RENTABLE });
    }
  };

export const setReservationSelectionRentable =
  (rentableId: string) => (dispatch: Dispatch<SetReservationSelectionRentable>, _getState: () => State) => {
    dispatch({ type: SET_RESERVATION_SELECTION_RENTABLE, rentableId });
  };

export const setReservationSelectionHoverStartDate =
  (hoverStartDate: Moment) => (dispatch: Dispatch<SetReservationSelectionHoverStartDate>, getState: () => State) => {
    if (!isSameDate(hoverStartDate, getState().reservationSelection.hoverStartDate)) {
      dispatch({ type: SET_RESERVATION_SELECTION_HOVER_START_DATE, hoverStartDate });
    }
  };

export const setReservationSelectionDraggingEndDate =
  (draggingEndDate: Moment) => (dispatch: Dispatch<SetReservationSelectionDraggingEndDate>, getState: () => State) => {
    const { reservationSelection } = getState();

    if (
      !isSameDate(draggingEndDate, reservationSelection.draggingEndDate) &&
      isAfterDate(draggingEndDate, reservationSelection.hoverStartDate)
    ) {
      dispatch({ type: SET_RESERVATION_SELECTION_DRAGGING_END_DATE, draggingEndDate });
    }
  };

export const setReservationSelectionStartDate =
  (startDate: Moment) => (dispatch: Dispatch<SetReservationSelectionStartDate>, getState: () => State) => {
    if (!isSameDate(startDate, getState().reservationSelection.startDate)) {
      dispatch({ type: SET_RESERVATION_SELECTION_START_DATE, startDate });
    }
  };

export const setReservationSelectionEndDate =
  (endDate: Moment) => (dispatch: Dispatch<SetReservationSelectionEndDate>, getState: () => State) => {
    const { reservationSelection } = getState();

    if (!isSameDate(endDate, reservationSelection.endDate) && isAfterDate(endDate, reservationSelection.startDate)) {
      dispatch({ type: SET_RESERVATION_SELECTION_END_DATE, endDate });
    }
  };

/**
 * Clears the selection (start date, end date, hover start date, hover end date).
 */
export const clearReservationSelection = () => (dispatch: Dispatch<ClearReservationSelection>, _getState: () => State) => {
  dispatch({ type: CLEAR_RESERVATION_SELECTION });
};
