import React, { PureComponent } from 'react';
import classes from './HolidayWeekDate.module.css';
import WeekDate from './WeekDate';

interface HolidayWeekDateProps {
  date: string;
  holiday: { name: string };
}

export default class HolidayWeekDate extends PureComponent<HolidayWeekDateProps> {
  render() {
    const { holiday, ...props } = this.props;
    return <WeekDate className={classes.root} renderTooltip={() => holiday.name} {...props} />;
  }
}
