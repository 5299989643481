import React, { FormEvent, Component } from 'react';
import { Icon, Intent } from '@blueprintjs/core';
import LoadingButton from '../loading-button';
import { showSuccessToast, showApolloErrorToast } from '../toaster';
import { MapDispatchToProps, connect } from 'react-redux';
import { State } from '../../reducers';
import * as actions from '../../actions/sidebarPanel';
import { DeleteFn } from './DeleteMaintenanceAgendaPeriodMutation';
// tslint:disable-next-line:no-duplicate-imports
import { SidebarType } from '../../actions/sidebarPanel';
import Translation from '../translation';
import { ApolloError } from '@apollo/client';

interface OwnProps {
  maintenanceAgendaPeriodId: string;
  deleteFn: DeleteFn;
  isLoading: boolean;
}

interface DispatchProps {
  openSidebarPanel: (sidebarType: SidebarType, id: string) => void;
  closeSidebarPanel: () => void;
}

interface DispatchProps {
  openSidebarPanel: (sidebarType: SidebarType, id: string) => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, State> = {
  openSidebarPanel: actions.openSidebarPanel,
  closeSidebarPanel: actions.closeSidebarPanel,
};

type DeleteMaintenanceAgendaPeriodFormProps = OwnProps & DispatchProps;

class DeleteMaintenanceAgendaPeriodForm extends Component<DeleteMaintenanceAgendaPeriodFormProps> {
  handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const { deleteFn, maintenanceAgendaPeriodId, closeSidebarPanel } = this.props;

    try {
      await deleteFn({ variables: { maintenanceAgendaPeriodId } });

      closeSidebarPanel();

      // Wait till the sidebar close animation has finished
      await new Promise((resolve) => setTimeout(resolve, 150));

      const message = <Translation>{(translate) => translate('The blocked period has been deleted')}</Translation>;

      showSuccessToast({ message });
    } catch (e) {
      if (e instanceof ApolloError) {
        showApolloErrorToast(e);
      } else {
        throw e;
      }
    }
  };

  render() {
    let { isLoading } = this.props;

    return (
      <Translation>
        {(translate) => (
          <form onSubmit={this.handleFormSubmit}>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              isDisabled={isLoading}
              intent={Intent.PRIMARY}
              icon={<Icon icon="trash" iconSize={12} />}
            >
              {translate('Delete')}
            </LoadingButton>
          </form>
        )}
      </Translation>
    );
  }
}

export default connect(null, mapDispatchToProps)(DeleteMaintenanceAgendaPeriodForm);
