import React from 'react';
import OrganizationList from './OrganizationList';
import ListPlaceholder from './ListPlaceholder';
import OrganizationListQuery from './OrganizationListQuery';
import isEmpty from 'lodash/isEmpty';
import ErrorMessage from '../error-message';

interface OrganizationListContainerProps {
  query: string;
  starredParkIds: string[];
  onParkItemClick: (parkId: string) => void;
  onStarButtonClick: (parkId: string, isStarred: boolean) => void;
}

export default function OrganizationListContainer({
  query,
  starredParkIds,
  onParkItemClick,
  onStarButtonClick,
}: OrganizationListContainerProps) {
  return (
    <OrganizationListQuery>
      {({ data, loading, error }) => {
        if (loading) {
          return <ListPlaceholder />;
        }

        if (error) {
          return <ErrorMessage error={error} className="border-none py-0 px-0" />;
        }

        if (data && !isEmpty(data)) {
          return (
            <OrganizationList
              organizations={data.organizations}
              starredParkIds={starredParkIds}
              query={query}
              onParkItemClick={onParkItemClick}
              onStarButtonClick={onStarButtonClick}
            />
          );
        }

        return null;
      }}
    </OrganizationListQuery>
  );
}
