import React, { Component, FormEvent } from 'react';
import Sidebar from '../../components/sidebar';
import { Button, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import Translation from '../../components/translation';
import formatRentableIdentity from '../../utils/formatRentableIdentity';
import { Trans } from 'react-i18next';
import LocaleDate from '../../components/locale-date';
import { Booking } from '../../actions/bookingMode';
import { DefaultTooltip } from '../tooltip';

interface Reservation {
  id: string;
  startDate: string;
  endDate: string;
  rentableIdentity: {
    name: string;
    rentableTypeName: string;
    url: string;
  };
  reservationAgendaPeriods: Array<{
    id: string;
  }>;
}

interface BookingSectionProps {
  reservation: {
    id: string;
    booking: {
      id: string;
      bookingNr: string;
      reservations: Reservation[];
      maxNumberOfReservations: number;
    };
  };
  activeBooking?: Booking;
  onNewReservationButtonClick: (booking: Booking) => void;
  onReservationChange: (reservationAgendaPeriodId: string) => void;
}

export default class BookingSection extends Component<BookingSectionProps> {
  renderReservation({ id, startDate, endDate, rentableIdentity }: Reservation) {
    const formattedStartDate = <LocaleDate date={startDate} format="dd D MMM" />;
    const formattedEndDate = <LocaleDate date={endDate} format="dd D MMM" />;

    return (
      <Radio key={id} value={id}>
        {formatRentableIdentity(rentableIdentity)}
        {' - '}
        <Trans>
          {formattedStartDate} till {formattedEndDate}
        </Trans>
      </Radio>
    );
  }

  renderNewReservationButton() {
    const {
      activeBooking,
      reservation: {
        booking: { id: bookingId, reservations, maxNumberOfReservations: maxNumberOfReservationsPerBooking },
      },
    } = this.props;

    if (reservations.length < maxNumberOfReservationsPerBooking) {
      const disabled = activeBooking && activeBooking.id === bookingId;

      return (
        <Translation>
          {(translate) => (
            <Button disabled={disabled} minimal intent={Intent.SUCCESS} onClick={this.handleNewReservationButtonClick}>
              {translate('+ Add reservation')}
            </Button>
          )}
        </Translation>
      );
    } else {
      const renderTooltipText = () => (
        <Translation>
          {(translate) =>
            translate(
              'Maximum amount of reservations for this booking ({{maxNumberOfReservationsPerBooking}}) has been reached.',
              { maxNumberOfReservationsPerBooking } // prettier-ignore
            )
          }
        </Translation>
      );

      return (
        <DefaultTooltip render={renderTooltipText} distance={8} className="-m-1 p-1 block">
          <Translation>
            {(translate) => (
              <Button disabled={true} minimal intent={Intent.SUCCESS}>
                {translate('+ Add reservation')}
              </Button>
            )}
          </Translation>
        </DefaultTooltip>
      );
    }
  }

  renderReservations() {
    const { reservation } = this.props;

    return (
      <RadioGroup onChange={this.handleReservationChange} selectedValue={reservation.id} className="-mb-2">
        {reservation.booking.reservations.map(this.renderReservation)}
      </RadioGroup>
    );
  }

  renderHeader() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar.SectionHeader>
            <div className="flex items-center justify-between">
              <Sidebar.SectionHeaderTitle title={translate('Booking')} />
              {this.renderNewReservationButton()}
            </div>
          </Sidebar.SectionHeader>
        )}
      </Translation>
    );
  }

  handleNewReservationButtonClick = () => {
    this.props.onNewReservationButtonClick(this.props.reservation.booking);
  };

  handleReservationChange = (event: FormEvent<HTMLInputElement>) => {
    const reservationId = event.currentTarget.value;
    const reservation = this.props.reservation.booking.reservations.find(({ id }) => id === reservationId);

    if (reservation) {
      this.props.onReservationChange(reservation.reservationAgendaPeriods[0].id);
    }
  };

  render() {
    return (
      <Sidebar.Section>
        {this.renderHeader()}
        <Sidebar.SectionContent> {this.renderReservations()}</Sidebar.SectionContent>
      </Sidebar.Section>
    );
  }
}
