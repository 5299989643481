import { UPDATE_SERVER_VERSION, UpdateServerVersion } from '../actions/planboardVersion';

export interface PlanboardVersionState {
  serverVersion?: string;
}

export default function planboardVersion(state = {}, action: UpdateServerVersion): PlanboardVersionState {
  switch (action.type) {
    case UPDATE_SERVER_VERSION: {
      return { serverVersion: action.serverVersion };
    }

    default: {
      return state;
    }
  }
}
