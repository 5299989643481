import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult, MutationFunction } from '@apollo/client/react';
import { loader } from 'graphql.macro';
const checkInReservationMutation = loader('./checkInReservationMutation.graphql');

interface CheckInReservationVariables {
  reservationId: string;
  checkinDate: string;
  checkinHour: string;
  checkinMinute: string;
}

export interface CheckInReservationData {
  checkInReservation: {
    id: string;
  };
}

export type CheckInReservationFn = MutationFunction<CheckInReservationData, CheckInReservationVariables>;
export type Result = MutationResult<CheckInReservationData>;

interface CheckInReservationMutationProps {
  children: (checkInReservation: CheckInReservationFn, result: Result) => JSX.Element | null;
}

const REFETCH_QUERIES = ['checkoutFormQuery', 'moveReservationFormQuery'];

export default function CheckInReservationMutation({ children }: CheckInReservationMutationProps) {
  return (
    <Mutation<CheckInReservationData, CheckInReservationVariables>
      mutation={checkInReservationMutation}
      refetchQueries={REFETCH_QUERIES}
      awaitRefetchQueries
    >
      {children}
    </Mutation>
  );
}
