import React, { PureComponent } from 'react';
import { Button, ButtonGroup, Icon, HotkeysTarget2 } from '@blueprintjs/core';
import Translation, { withTranslation, WithTranslation } from '../translation';

interface FilterPanelToggleButtonProps {
  isOpen: boolean;
  numberOfActiveFilters: number;
  onClick: (e: React.MouseEvent) => void;
  onHotkey: () => void;
  onResetButtonClick: (e: React.MouseEvent) => void;
}

class FilterPanelToggleButton extends PureComponent<FilterPanelToggleButtonProps & WithTranslation> {
  renderText() {
    const { numberOfActiveFilters, t: translate } = this.props;

    const text =
      numberOfActiveFilters === 0 ? translate('Filters') : translate('Filters ({{numberOfActiveFilters}})', { numberOfActiveFilters });

    return <span className="truncate">{text}</span>;
  }

  renderIcon() {
    return <Icon icon="filter-list" />;
  }

  renderResetButton() {
    const { onResetButtonClick, t: translate } = this.props;
    return <Button icon="cross" onClick={onResetButtonClick} title={translate('Reset rental filters')} />;
  }

  private hotkeys = [
    {
      label: <Translation>{(translate) => translate('Show/hide rental filters')}</Translation>,
      combo: 'f',
      global: true,
      onKeyDown: this.props.onHotkey,
    },
  ];

  render() {
    const { isOpen, numberOfActiveFilters, t: translate, onClick } = this.props;

    return (
      <HotkeysTarget2 hotkeys={this.hotkeys}>
        {({ handleKeyDown, handleKeyUp }) => (
          <ButtonGroup onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} className="bp4-dark">
            <Button
              active={isOpen}
              alignText="left"
              title={translate('Show/hide rental filters')}
              onClick={onClick}
              icon={this.renderIcon()}
              fill
            >
              {this.renderText()}
            </Button>
            {numberOfActiveFilters > 0 && this.renderResetButton()}
          </ButtonGroup>
        )}
      </HotkeysTarget2>
    );
  }
}

export default withTranslation()(FilterPanelToggleButton);
