import React, { Component } from 'react';
import formatMaintenanceAgendaPeriod from '../../utils/formatMaintenanceAgendaPeriod';
import MaintenanceAgendaPeriodBadge from '../maintenance-agenda-period-badge';
import AgendaPeriodSidebar from '../agenda-period-sidebar';
import { withTranslation, WithTranslation } from '../translation';
import DeleteMaintenanceAgendaPeriodForm from '../delete-maintenance-agenda-period-form';
import Sidebar from '../sidebar';

interface MaintenanceAgendaPeriodSidebarProps {
  maintenanceAgendaPeriod: {
    id: string;
    memo: string;
    startDate: string;
    endDate: string;
    editUrl: string;
    rentableIdentity: {
      id: string;
      name: string;
      rentableTypeName: string;
      url: string;
    };
  };
  onCloseButtonClick: () => void;
}

class MaintenanceAgendaPeriodSidebar extends Component<MaintenanceAgendaPeriodSidebarProps & WithTranslation> {
  renderActions() {
    const { t: translate, maintenanceAgendaPeriod } = this.props;

    return (
      <Sidebar.Section title={translate('Actions')}>
        <div className="p-1">
          <DeleteMaintenanceAgendaPeriodForm maintenanceAgendaPeriodId={maintenanceAgendaPeriod.id} />
        </div>
      </Sidebar.Section>
    );
  }

  render() {
    const { t: translate, maintenanceAgendaPeriod, onCloseButtonClick } = this.props;

    return (
      <AgendaPeriodSidebar
        title={formatMaintenanceAgendaPeriod(maintenanceAgendaPeriod, translate)}
        url={maintenanceAgendaPeriod.editUrl}
        agendaPeriod={maintenanceAgendaPeriod}
        renderBadge={() => <MaintenanceAgendaPeriodBadge />}
        actions={this.renderActions()}
        onCloseButtonClick={onCloseButtonClick}
      />
    );
  }
}

export default withTranslation()(MaintenanceAgendaPeriodSidebar);
