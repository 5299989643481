import React, { PureComponent } from 'react';
import classes from './MoveActionOverlay.module.css';
import moment, { Moment } from 'moment';

interface MoveActionOverlayProps {
  startDate: string;
  endDate: string;
  calculateOffset: (args: { date: Moment }) => number;
  onClick: (e: React.MouseEvent) => void;
}

export default class MoveActionOverlay extends PureComponent<MoveActionOverlayProps> {
  render() {
    const { startDate, endDate, calculateOffset, onClick } = this.props;

    const leftMarkerStyle = { left: 0, width: calculateOffset({ date: moment(startDate).add(12, 'hour') }) };
    const rightMarkerStyle = { left: calculateOffset({ date: moment(endDate).add(12, 'hour') }), right: 0 };

    return (
      <div className={classes.root} onClick={onClick}>
        <div className={classes['marker--left']} style={leftMarkerStyle} title="Klik om te sluiten" />
        <div className={classes['marker--right']} style={rightMarkerStyle} title="Klik om te sluiten" />
      </div>
    );
  }
}
