import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import OrganizationListQueryData from './OrganizationListQueryData';
const organizationListQuery = loader('./organizationListQuery.graphql');

interface OrganizationListQueryProps {
  children: (result: QueryResult<OrganizationListQueryData, {}>) => JSX.Element | null;
}

export default function OrganizationListQuery({ children }: OrganizationListQueryProps) {
  return <Query query={organizationListQuery}>{children}</Query>;
}
