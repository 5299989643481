import React, { PureComponent } from 'react';
import MultiSelectFilter from './MultiSelectFilter';
import Translation from '../translation';

interface RentableSegment {
  id: string;
  name: string;
}

interface RentableSegmentFilterProps {
  rentableSegments: RentableSegment[];
  selectedRentableSegments: RentableSegment[];
  inputRef?: (input: HTMLInputElement | null) => void;
  onChange: (selectedRentableSegments: RentableSegment[]) => void;
}

export default class RentableSegmentFilter extends PureComponent<RentableSegmentFilterProps> {
  render() {
    const { rentableSegments, selectedRentableSegments, inputRef, onChange } = this.props;

    return (
      <Translation>
        {(translate) => (
          <MultiSelectFilter<RentableSegment>
            items={rentableSegments}
            selectedItems={selectedRentableSegments}
            inputRef={inputRef}
            placeholder={translate('Filter by segment…')}
            onChange={onChange}
          />
        )}
      </Translation>
    );
  }
}
