import React from 'react';

interface CellStyleProps {
  style: React.CSSProperties;
  rowIndex: number;
  columnIndex: number;
  isVisible: boolean;
  isScrolling: boolean;
  render: (props: { rowIndex: number; columnIndex: number; isVisible: boolean; isScrolling: boolean }) => React.ReactChild;
}

// This more memory-efficient than inlining <div ... /> in Grid.tsx
// Furthermore don't use PureComponent here because this.props.children would cause shouldComponentUpdate
// to return true pretty much all the time. See for example this article: https://blog.cloudboost.io/react-purecomponents-children-979e3da15ba8
export default function CellStyle({ style, rowIndex, columnIndex, isVisible, isScrolling, render }: CellStyleProps) {
  return <div style={style}>{render({ rowIndex, columnIndex, isVisible, isScrolling })}</div>;
}
