import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import ReservationSelectionDialogQueryData from './ReservationSelectionDialogQueryData';
import moment from 'moment';
import deserializeObject from '../../utils/deserializeObject';
const reservationSelectionDialogQuery = loader('./reservationSelectionDialogQuery.graphql');

interface GuestGroup {
  [key: string]: string;
}

interface Variables {
  rentableId: string;
  startDate: string;
  endDate: string;
  guestGroup: GuestGroup;
  bookingId?: string;
  parkId: string;
}

interface ReservationSelectionDialogQueryProps {
  rentableId: string;
  startDate: string;
  endDate: string;
  guestGroup: string;
  bookingId?: string;
  children: (result: QueryResult<ReservationSelectionDialogQueryData, Variables>) => JSX.Element | null;
  parkId: string;
  onCompleted?: (data: ReservationSelectionDialogQueryData) => void;
}

export default function ReservationSelectionDialogQuery({
  rentableId,
  startDate,
  endDate,
  guestGroup,
  bookingId,
  parkId,
  children,
  onCompleted,
}: ReservationSelectionDialogQueryProps) {
  let $startDate = moment(startDate).format('YYYY-MM-DD');
  let $endDate = moment(endDate).format('YYYY-MM-DD');
  let $guestGroup = deserializeObject(guestGroup);

  return (
    <Query<ReservationSelectionDialogQueryData, Variables>
      query={reservationSelectionDialogQuery}
      variables={{ rentableId, startDate: $startDate, endDate: $endDate, guestGroup: $guestGroup, bookingId, parkId }}
      onCompleted={onCompleted}
    >
      {children}
    </Query>
  );
}
