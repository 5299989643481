import React, { PureComponent } from 'react';
import classes from './SidebarPanel.module.css';
import classNames from 'classnames';
import RentableIdentitySidebar from '../rentable-identity-sidebar';
import ReservationAgendaPeriodSidebar from '../reservation-agenda-period-sidebar';
import IcalAgendaPeriodSidebar from '../ical-agenda-period-sidebar';
import MaintenanceAgendaPeriodSidebar from '../maintenance-agenda-period-sidebar';
import OwnerMaintenanceAgendaPeriodSidebar from '../owner-maintenance-agenda-period-sidebar';
import NotEnoughStockAgendaPeriodSidebar from '../not-enough-stock-agenda-period-sidebar';
import BlockedByGroupedRentableTypeAgendaPeriodSidebar from '../blocked-by-grouped-rentable-type-agenda-period-sidebar';
import { SidebarType } from '../../actions/sidebarPanel';
import { SidebarErrorMessage } from '../sidebar';
import Translation from '../translation';

interface SidebarPanelProps {
  isOpen: boolean;
  type?: SidebarType;
  id?: string;
  closeSidebarPanel: () => void;
  deactivateMoveAction: () => void;
}

export default class SidebarPanel extends PureComponent<SidebarPanelProps> {
  handleCloseButtonClick = () => {
    const { closeSidebarPanel, deactivateMoveAction } = this.props;
    closeSidebarPanel();
    deactivateMoveAction();
  };

  renderSidebar() {
    const { isOpen, type, id } = this.props;
    const sidebarProps = { onCloseButtonClick: this.handleCloseButtonClick };

    if (isOpen && id !== undefined) {
      switch (type) {
        case SidebarType.ReservationAgendaPeriod: {
          return <ReservationAgendaPeriodSidebar reservationAgendaPeriodId={id} {...sidebarProps} />;
        }

        case SidebarType.RentableIdentity: {
          return <RentableIdentitySidebar rentableIdentityId={id} {...sidebarProps} />;
        }

        case SidebarType.IcalAgendaPeriod: {
          return <IcalAgendaPeriodSidebar icalAgendaPeriodId={id} {...sidebarProps} />;
        }

        case SidebarType.MaintenanceAgendaPeriod: {
          return <MaintenanceAgendaPeriodSidebar maintenanceAgendaPeriodId={id} {...sidebarProps} />;
        }

        case SidebarType.OwnerMaintenanceAgendaPeriod: {
          return <OwnerMaintenanceAgendaPeriodSidebar ownerMaintenanceAgendaPeriodId={id} {...sidebarProps} />;
        }

        case SidebarType.NotEnoughStockAgendaPeriod: {
          return <NotEnoughStockAgendaPeriodSidebar notEnoughStockAgendaPeriodId={id} {...sidebarProps} />;
        }

        case SidebarType.BlockedByGroupedRentableTypeAgendaPeriod: {
          return <BlockedByGroupedRentableTypeAgendaPeriodSidebar blockedByGroupedRentableTypeAgendaPeriodId={id} {...sidebarProps} />;
        }

        default: {
          return (
            <Translation>
              {(translate) => <SidebarErrorMessage error={new Error(translate('Failed to load sidebar content.'))} {...sidebarProps} />}
            </Translation>
          );
        }
      }
    }

    return null;
  }

  render() {
    const className = classNames(classes.root, { [classes['root--open']]: this.props.isOpen });
    return <aside className={className}>{this.renderSidebar()}</aside>;
  }
}
