import {
  ACTIVATE_MOVE_ACTION,
  ActivateMoveAction,
  SET_MOVE_ACTION_TARGET_RENTABLE,
  SetMoveActionTargetRentable,
  TOGGLE_MOVE_ACTION_TARGET_RENTABLE,
  ToggleMoveActionTargetRentable,
  CLEAR_MOVE_ACTION_TARGET_RENTABLE,
  ClearMoveActionTargetRentable,
  DEACTIVATE_MOVE_ACTION,
  DeactivateMoveAction,
} from '../actions/moveAction';
import serializeDate from '../utils/serializeDate';

interface InactiveMoveActionState {
  state: 'inactive';
}

interface ActiveMoveActionState {
  state: 'active';
  reservationAgendaPeriodId: string;
  rentableId: string;
  startDate: string;
  endDate: string;
}

interface FinalMoveActionState {
  state: 'final';
  reservationAgendaPeriodId: string;
  rentableId: string;
  startDate: string;
  endDate: string;
  targetRentableId: string;
}

export type MoveActionState = InactiveMoveActionState | ActiveMoveActionState | FinalMoveActionState;

const initialState: MoveActionState = { state: 'inactive' };

type Actions =
  | ActivateMoveAction
  | SetMoveActionTargetRentable
  | ToggleMoveActionTargetRentable
  | ClearMoveActionTargetRentable
  | DeactivateMoveAction;

export default function moveAction(state = initialState, action: Actions): MoveActionState {
  switch (action.type) {
    case ACTIVATE_MOVE_ACTION: {
      const reservationAgendaPeriodId = action.reservationAgendaPeriod.id;
      const rentableId = action.reservationAgendaPeriod.rentableId;
      const startDate = serializeDate(action.reservationAgendaPeriod.startDate);
      const endDate = serializeDate(action.reservationAgendaPeriod.endDate);

      return { state: 'active', reservationAgendaPeriodId, rentableId, startDate, endDate };
    }

    case SET_MOVE_ACTION_TARGET_RENTABLE: {
      if (state.state === 'active' || state.state === 'final') {
        return { ...state, state: 'final', targetRentableId: action.targetRentable.id };
      } else {
        return state;
      }
    }

    case TOGGLE_MOVE_ACTION_TARGET_RENTABLE: {
      if (state.state === 'final' && state.targetRentableId === action.targetRentable.id) {
        const { targetRentableId, ...newState } = state;
        return { ...newState, state: 'active' };
      } else if (state.state === 'active' || state.state === 'final') {
        return { ...state, state: 'final', targetRentableId: action.targetRentable.id };
      } else {
        return state;
      }
    }

    case CLEAR_MOVE_ACTION_TARGET_RENTABLE: {
      if (state.state === 'final') {
        const { targetRentableId, ...newState } = state;
        return { ...newState, state: 'active' };
      } else {
        return state;
      }
    }

    case DEACTIVATE_MOVE_ACTION: {
      return { state: 'inactive' };
    }

    default: {
      return state;
    }
  }
}
