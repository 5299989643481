import React, { PureComponent } from 'react';
import { Intent, Button, Spinner } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';

interface LoadingButtonProps {
  type?: 'reset' | 'button' | 'submit';
  intent?: Intent;
  icon?: IconName | JSX.Element;
  className?: string;
  isLoading: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
  [additionalProp: string]: any;
}

// TODO: Use loading button from Blueprint? See https://blueprintjs.com/docs/#core/components/button
export default class LoadingButton extends PureComponent<LoadingButtonProps> {
  render() {
    const { isLoading, isDisabled, icon, children, ...props } = this.props;

    return (
      <Button icon={isLoading ? <Spinner size={12} /> : icon} disabled={isDisabled === undefined ? isLoading : isDisabled} {...props}>
        {children}
      </Button>
    );
  }
}
