import React, { PureComponent } from 'react';
import classes from './InactivePeriodCell.module.css';

interface InactivePeriodCellProps {
  style: React.CSSProperties;
}

export default class InactivePeriodCell extends PureComponent<InactivePeriodCellProps> {
  render() {
    let { style } = this.props;
    return <div className={classes.root} style={style} title="Niet actief in deze periode" />;
  }
}
