import React, { Component } from 'react';
import formatNotEnoughStockAgendaPeriod from '../../utils/formatNotEnoughStockAgendaPeriod';
import NotEnoughStockAgendaPeriodBadge from '../not-enough-stock-agenda-period-badge';
import AgendaPeriodSidebar from '../agenda-period-sidebar';
import Translation from '../translation';

interface NotEnoughStockAgendaPeriodSidebarProps {
  notEnoughStockAgendaPeriod: {
    memo: string;
    startDate: string;
    endDate: string;
    editUrl: string;
    rentableIdentity: {
      id: string;
      name: string;
      rentableTypeName: string;
      url: string;
    };
  };
  onCloseButtonClick: () => void;
}

export default class NotEnoughStockAgendaPeriodSidebar extends Component<NotEnoughStockAgendaPeriodSidebarProps> {
  render() {
    let { notEnoughStockAgendaPeriod, onCloseButtonClick } = this.props;

    return (
      <Translation>
        {(translate) => (
          <AgendaPeriodSidebar
            title={formatNotEnoughStockAgendaPeriod(notEnoughStockAgendaPeriod, translate)}
            url={notEnoughStockAgendaPeriod.editUrl}
            agendaPeriod={notEnoughStockAgendaPeriod}
            renderBadge={() => <NotEnoughStockAgendaPeriodBadge />}
            onCloseButtonClick={onCloseButtonClick}
          />
        )}
      </Translation>
    );
  }
}
