import React, { PureComponent } from 'react';
import cs from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faDog } from '@fortawesome/free-solid-svg-icons';
import { DefaultTooltip } from '../tooltip';
import classes from './RentableIdentityHeader.module.css';
import isRegularClick from '../../utils/isRegularClick';
import Translation from '../translation';
import formatPriorityDescription from '../../utils/formatPriorityDescription';
import formatPriorityName from '../../utils/formatPriorityName';
import Highlighter from 'react-highlight-words';

interface RentableIdentityHeaderProps {
  rentableIdentity: {
    id: string;
    name: string;
    priority: number;
    blockedForExternalReservations: boolean;
    blockingTodosCount: number;
    petsAllowed: boolean;
    url: string;
  };
  title?: string;
  isActive: boolean;
  searchQuery: string;
  onClick: () => void;
}

export default class RentableIdentityHeader extends PureComponent<RentableIdentityHeaderProps> {
  get className() {
    const { isActive } = this.props;
    const { blockedForExternalReservations } = this.props.rentableIdentity;

    return cs(classes.root, {
      [classes['root--active']]: isActive,
      [classes['root--blocked-for-external-reservations']]: blockedForExternalReservations,
    });
  }

  get title() {
    const { rentableIdentity, title } = this.props;
    return title ? title : rentableIdentity.name;
  }

  get highlightedTitle() {
    return (
      <Highlighter
        textToHighlight={this.title}
        searchWords={[this.props.searchQuery.trim()]}
        autoEscape
        highlightClassName="text-brown-900 bg-amber-100 rounded-sm"
      />
    );
  }

  handleClick = (e: React.MouseEvent) => {
    if (isRegularClick(e)) {
      this.props.onClick();
      e.preventDefault();
    }
  };

  renderPriority() {
    const { priority } = this.props.rentableIdentity;

    return (
      <Translation>
        {(translate) => (
          <div className={classes.priority} title={formatPriorityDescription(priority, translate)}>
            {formatPriorityName(priority)}
          </div>
        )}
      </Translation>
    );
  }

  renderTitle() {
    return (
      <span className={classes.title} title={this.title}>
        {this.props.searchQuery ? this.highlightedTitle : this.title}
      </span>
    );
  }

  renderPetsAllowed() {
    return (
      <Translation>
        {(translate) => (
          <DefaultTooltip className={classes.status} title={translate('Pets allowed')} distance={0}>
            <FontAwesomeIcon icon={faDog} className="text-brown-500 w-4 text-center" />
          </DefaultTooltip>
        )}
      </Translation>
    );
  }

  renderBlockingTodosCountIcon() {
    const { blockingTodosCount } = this.props.rentableIdentity;

    const renderTitle = () => (
      <Translation>
        {(translate) =>
          blockingTodosCount === 1
            ? translate('1 blocking unfinished todo')
            : translate('{{blockingTodosCount}} blocking unfinished todos', { blockingTodosCount })
        }
      </Translation>
    );

    return (
      <DefaultTooltip className={classes.status} render={renderTitle} distance={0}>
        <span className="text-brown-900 bg-yellow-400 rounded-sm w-4 text-center text-base font-bold">!</span>
      </DefaultTooltip>
    );
  }

  renderBlockedForFrontOfficeReservationsIcon() {
    return (
      <Translation>
        {(translate) => (
          <DefaultTooltip className={classes.status} title={translate('Does not accept new external reservations')}>
            <FontAwesomeIcon icon={faEyeSlash} />
          </DefaultTooltip>
        )}
      </Translation>
    );
  }

  renderStatus() {
    const { rentableIdentity } = this.props;

    return (
      <aside className={classes.statuses}>
        {rentableIdentity.petsAllowed && this.renderPetsAllowed()}
        {rentableIdentity.blockingTodosCount > 0 && this.renderBlockingTodosCountIcon()}
        {rentableIdentity.blockedForExternalReservations && this.renderBlockedForFrontOfficeReservationsIcon()}
      </aside>
    );
  }

  render() {
    return (
      <a className={this.className} href={this.props.rentableIdentity.url} onClick={this.handleClick}>
        {this.renderPriority()}
        {this.renderTitle()}
        {this.renderStatus()}
      </a>
    );
  }
}
