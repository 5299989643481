import React from 'react';

const EventPopover = ({ children }: { children: React.ReactNode }) => (
  <div className="text-left text-base rounded-sm overflow-hidden" style={{ minWidth: 280 }}>
    {children}
  </div>
);

EventPopover.Header = ({ title }: { title: string }) => (
  <header className="flex items-center px-3 py-2 max-w-xs bg-gray-200">
    <h1 className="m-0 p-0 text-base flex-grow truncate">{title}</h1>
  </header>
);

EventPopover.Details = ({ children }: { children: React.ReactNode }) => (
  <main className="py-2 px-3 bg-gray-50 space-between-2">{children}</main>
);

EventPopover.Divider = () => <div className="border-b border-gray-300 border-solid" />;

EventPopover.Detail = ({ description, value }: { description: React.ReactNode; value: React.ReactNode }) => (
  <section className="flex">
    <span className="font-medium flex-no-shrink text-gray-600" style={{ width: '40%' }}>
      {description}
    </span>
    <span className="flex-grow truncate">{value}</span>
  </section>
);

export default EventPopover;
