import {
  SET_AVAILABILITY_FILTER_HOVER_START_DATE,
  SET_AVAILABILITY_FILTER_HOVER_END_DATE,
  CLEAR_AVAILABILITY_FILTER_HOVER_START_DATE,
  CLEAR_AVAILABILITY_FILTER_HOVER_END_DATE,
  SetAvailabilityFilterHoverStartDate,
  SetAvailabilityFilterHoverEndDate,
  ClearAvailabilityFilterHoverStartDate,
  ClearAvailabilityFilterHoverEndDate,
} from '../actions/availabilityFilter';

import serializeDate from '../utils/serializeDate';
import { LOCATION_CHANGE, locationChangeAction, State as LocationState } from 'redux-first-routing';
import { SearchParams } from '../config/planboard';

export interface AvailabilityFilterState {
  hoverStartDate?: string;
  hoverEndDate?: string;
  startDate?: string;
  endDate?: string;
}

type Actions =
  | locationChangeAction<string, string, string>
  | SetAvailabilityFilterHoverStartDate
  | SetAvailabilityFilterHoverEndDate
  | ClearAvailabilityFilterHoverStartDate
  | ClearAvailabilityFilterHoverEndDate;

function deserializeStateFromLocation(location: Location | LocationState): AvailabilityFilterState {
  const queryParam = new URLSearchParams(location.search).get(SearchParams.AVAILABILITY_FILTER) || '';
  const [startDate, endDate] = queryParam.split('..');

  return {
    startDate: startDate || undefined,
    hoverStartDate: startDate || undefined,
    endDate: endDate || undefined,
    hoverEndDate: endDate || undefined,
  };
}

export default function availabilityFilter(
  state = deserializeStateFromLocation(window.location),
  action: Actions
): AvailabilityFilterState {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return deserializeStateFromLocation(action.payload);
    }

    case SET_AVAILABILITY_FILTER_HOVER_START_DATE: {
      return { ...state, hoverStartDate: serializeDate(action.hoverStartDate) };
    }

    case SET_AVAILABILITY_FILTER_HOVER_END_DATE: {
      return { ...state, hoverEndDate: serializeDate(action.hoverEndDate) };
    }

    case CLEAR_AVAILABILITY_FILTER_HOVER_START_DATE: {
      return { ...state, hoverStartDate: undefined };
    }

    case CLEAR_AVAILABILITY_FILTER_HOVER_END_DATE: {
      return { ...state, hoverEndDate: undefined };
    }

    default: {
      return state;
    }
  }
}
