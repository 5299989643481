import { LOCATION_CHANGE, locationChangeAction, State as LocationState } from 'redux-first-routing';
import { Booking } from '../actions/bookingMode';

export interface BookingModeState {
  booking?: Booking;
}

function deserializeStateFromLocation(location: Location | LocationState): BookingModeState {
  const searchParams = new URLSearchParams(location.search);

  const id = searchParams.get('booking_id');
  const bookingNr = searchParams.get('booking_nr');

  return id && bookingNr ? { booking: { id, bookingNr } } : {};
}

export default function bookingMode(
  state = deserializeStateFromLocation(window.location),
  action: locationChangeAction<string, string, string>
): BookingModeState {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return deserializeStateFromLocation(action.payload);
    }

    default: {
      return state;
    }
  }
}
