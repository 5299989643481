import { Dispatch, Action } from 'redux';
import { State } from '../reducers';
import { PUSH, push as pushLocationAction } from 'redux-first-routing';
import mergeSearchParam from '../utils/mergeSearchParam';
import deleteSearchParam from '../utils/deleteSearchParam';
import { SearchParams } from '../config/planboard';

export interface Booking {
  id: string;
  bookingNr: string;
}

export const activateBookingMode = (booking: Booking) => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
  let searchParams = new URLSearchParams(getState().router.search);

  searchParams = mergeSearchParam(searchParams, SearchParams.BOOKING_ID, booking.id);
  searchParams = mergeSearchParam(searchParams, SearchParams.BOOKING_NR, booking.bookingNr);

  dispatch(pushLocationAction({ search: searchParams.toString() }));
};

export const deactivateBookingMode = () => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
  let searchParams = new URLSearchParams(getState().router.search);

  searchParams = deleteSearchParam(searchParams, SearchParams.BOOKING_ID);
  searchParams = deleteSearchParam(searchParams, SearchParams.BOOKING_NR);

  dispatch(pushLocationAction({ search: searchParams.toString() }));
};
