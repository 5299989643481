import React, { Component, ReactNode } from 'react';
import { Button } from '@blueprintjs/core';
import ExternalLink from '../external-link';
import classes from './Sidebar.module.css';
import Translation from '../translation';

interface SidebarProps {
  children: ReactNode;
}

interface SidebarHeaderProps {
  title: string;
  href: string;
  onCloseButtonClick: () => void;
}

interface SidebarContentProps {
  children: ReactNode;
}

interface SidebarFooterProps {
  children: ReactNode;
}

interface SidebarSectionProps {
  children: ReactNode;
  title?: string;
}

interface SidebarSectionHeaderProps {
  children: ReactNode;
}

interface SidebarSectionHeaderTitleProps {
  title: string;
}

interface SidebarSectionContentProps {
  children: ReactNode;
}

interface SidebarTableProps {
  children: ReactNode;
}

interface SidebarTableRowProps {
  icon?: ReactNode;
  header: ReactNode;
  data: ReactNode;
}

export const Header = ({ title, href, onCloseButtonClick }: SidebarHeaderProps) => (
  <header className="sticky pin-t z-10 flex-no-shrink flex items-center bg-bp4-gray5 border-l border-b border-bp4-gray4 -ml-px">
    <main className="flex flex-grow justify-between overflow-hidden">
      <ExternalLink href={href} className="truncate w-full" icon={true}>
        <h1 className="text-lg font-medium m-0 truncate p-3">{title}</h1>
      </ExternalLink>
    </main>

    <aside className="mr-2">
      <Translation>
        {(translate) => <Button text={translate('Close')} small rightIcon="small-cross" onClick={onCloseButtonClick} />}
      </Translation>
    </aside>
  </header>
);

export const Content = ({ children }: SidebarContentProps) => (
  <main className="flex-no-shrink flex-grow overflow-x-hidden bg-bp4-light-gray5">{children}</main>
);

export const Footer = ({ children }: SidebarFooterProps) => <footer className="mt-auto">{children}</footer>;

export const Section = ({ children, title }: SidebarSectionProps) => (
  <section>
    {title !== undefined && <SectionHeader key="header" children={<SectionHeaderTitle title={title} />} />}
    {title !== undefined && <SectionContent key="content" children={children} />}
    {title === undefined && children}
  </section>
);

export const SectionHeader = ({ children }: SidebarSectionHeaderProps) => <header className={classes['section-header']}>{children}</header>;

export const SectionHeaderTitle = ({ title }: SidebarSectionHeaderTitleProps) => (
  <h1 className="py-2 px-3 text-base font-normal m-0">{title}</h1>
);

export const SectionContent = ({ children }: SidebarSectionContentProps) => <main className={classes['section-content']}>{children}</main>;

export const Table = ({ children }: SidebarTableProps) => (
  <table className={classes.table}>
    <tbody>{children}</tbody>
  </table>
);

export const TableRow = ({ icon, header, data }: SidebarTableRowProps) => (
  <tr>
    {icon ? <th className={classes.table__icon}>{icon}</th> : <th className="p-0" />}
    <th className={classes.table__header}>{header}</th>
    <td className={classes.table__data}>{data}</td>
  </tr>
);

export default class Sidebar extends Component<SidebarProps> {
  public static Header = Header;
  public static Content = Content;
  public static Footer = Footer;

  public static Section = Section;
  public static SectionHeader = SectionHeader;
  public static SectionHeaderTitle = SectionHeaderTitle;
  public static SectionContent = SectionContent;

  public static Table = Table;
  public static TableRow = TableRow;

  render() {
    return <div className={classes.root}>{this.props.children}</div>;
  }
}
