import React, { PureComponent } from 'react';
import classes from './TodayWeekDate.module.css';
import WeekDate from './WeekDate';
import Translation from '../translation';

interface TodayWeekDateProps {
  date: string;
}

export default class TodayWeekDate extends PureComponent<TodayWeekDateProps> {
  render() {
    return (
      <WeekDate
        className={classes.root}
        renderTooltip={() => <Translation>{(translate) => translate('Today')}</Translation>}
        {...this.props}
      />
    );
  }
}
