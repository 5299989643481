import React, { Component } from 'react';
import Launchbar from '../launchbar';

interface PageProps {
  children: React.ReactNode;
  className?: string;
  [key: string]: any;
}

interface MainProps {
  children: React.ReactNode;
  className?: string;
}

export default class Page extends Component<PageProps> {
  public static Launchbar = (props: { parkId?: string }) => <Launchbar {...props} />;
  public static Container = ({ children, className }: MainProps) => <main className={className}>{children}</main>;

  render() {
    return (
      <div className={this.props.className} {...this.props}>
        {this.props.children}
      </div>
    );
  }
}
