import React, { PureComponent } from 'react';
import classes from './RentableTypeHeader.module.css';
import formatRentableType from '../../utils/formatRentableType';
import Highlighter from 'react-highlight-words';

interface RentableTypeHeaderProps {
  rentableType: {
    name: string;
    reference: string;
  };
  searchQuery: string;
}

export default class RentableTypeHeader extends PureComponent<RentableTypeHeaderProps> {
  get title() {
    return formatRentableType(this.props.rentableType);
  }

  get highlightedTitle() {
    return (
      <Highlighter
        textToHighlight={this.title}
        searchWords={[this.props.searchQuery.trim()]}
        autoEscape
        highlightClassName="text-brown-900 bg-amber-100 rounded-sm"
      />
    );
  }

  renderTitle() {
    return <span className={classes.title}>{this.props.searchQuery ? this.highlightedTitle : this.title}</span>;
  }

  render() {
    return <header className={classes.root}>{this.renderTitle()}</header>;
  }
}
