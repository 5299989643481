import React from 'react';
import IcalAgendaPeriodSidebar from './IcalAgendaPeriodSidebar';
import IcalAgendaPeriodSidebarQuery from './IcalAgendaPeriodSidebarQuery';
import { AgendaPeriodSidebarPlaceholder } from '../agenda-period-sidebar';
import { SidebarErrorMessage } from '../sidebar';

interface IcalAgendaPeriodSidebarContainerProps {
  icalAgendaPeriodId: string;
  onCloseButtonClick: () => void;
}

export default function IcalAgendaPeriodSidebarContainer({ icalAgendaPeriodId, ...rest }: IcalAgendaPeriodSidebarContainerProps) {
  return (
    <IcalAgendaPeriodSidebarQuery icalAgendaPeriodId={icalAgendaPeriodId}>
      {({ data, loading, error }) => {
        if (error) {
          return <SidebarErrorMessage error={error} {...rest} />;
        }
        if (loading) {
          return <AgendaPeriodSidebarPlaceholder {...rest} />;
        } else if (data) {
          return <IcalAgendaPeriodSidebar icalAgendaPeriod={data.icalAgendaPeriod} {...rest} />;
        } else {
          return null;
        }
      }}
    </IcalAgendaPeriodSidebarQuery>
  );
}
