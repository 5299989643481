import React, { PureComponent } from 'react';
import PlanboardPage from '../planboard-page';

interface PlanboardRouteProps {
  parkId: string;
}

export default class PlanboardRoute extends PureComponent<PlanboardRouteProps> {
  render() {
    return <PlanboardPage parkId={this.props.parkId} />;
  }
}
