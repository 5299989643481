import React from 'react';
import Tooltip, { TooltipProps } from './Tooltip';

type PopoverTooltipProps = TooltipProps & {
  children: React.ReactNode;
};

export default function PopoverTooltip(props: PopoverTooltipProps) {
  // interactive=true to allow users to hover over the popover & interact with it
  return <Tooltip arrow interactive distance={8} duration={[150, 0]} theme="light light-popover" {...props} />;
}
