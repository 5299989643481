import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import RentableIdentitySidebarQueryData from './RentableIdentitySidebarQueryData';
import { FAST_QUERY_POLL_INTERVAL } from '../../config/planboard';
const rentableIdentitySidebarQuery = loader('./rentableIdentitySidebarQuery.graphql');

interface Variables {
  rentableIdentityId: string;
}

interface RentableIdentitySidebarQueryProps {
  rentableIdentityId: string;
  children: (result: QueryResult<RentableIdentitySidebarQueryData, Variables>) => JSX.Element | null;
}

const RentableIdentitySidebarQuery = ({ rentableIdentityId, children }: RentableIdentitySidebarQueryProps) => (
  <Query<RentableIdentitySidebarQueryData, Variables>
    query={rentableIdentitySidebarQuery}
    variables={{ rentableIdentityId }}
    pollInterval={FAST_QUERY_POLL_INTERVAL}
  >
    {children}
  </Query>
);
export default RentableIdentitySidebarQuery;
