import React, { PureComponent } from 'react';
import AvailabilityFilterMarker from './AvailabilityFilterMarker';
import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../reducers';
import { Moment } from 'moment';
import * as actions from '../../actions/availabilityFilter';

interface OwnProps {
  isStart?: boolean;
  isEnd?: boolean;
  calculateOffset: (args: { date: Moment }) => number;
}

const mapStateToProps = (state: State) => {
  const { hoverStartDate, hoverEndDate, startDate, endDate } = state.availabilityFilter;

  return {
    availabilityFilterHoverStartDate: hoverStartDate,
    availabilityFilterHoverEndDate: hoverEndDate,
    availabilityFilterStartDate: startDate,
    availabilityFilterEndDate: endDate,
  };
};

const mapDispatchToProps = {
  clearAvailabilityFilter: actions.clearAvailabilityFilter,
};

type AvailabilityFilterMarkerContainerProps = ConnectedProps<typeof connector> & OwnProps;

class AvailabilityFilterMarkerContainer extends PureComponent<AvailabilityFilterMarkerContainerProps> {
  handleClick = () => {
    this.props.clearAvailabilityFilter();
  };

  render() {
    const {
      isStart,
      isEnd,
      availabilityFilterHoverStartDate,
      availabilityFilterHoverEndDate,
      availabilityFilterStartDate,
      availabilityFilterEndDate,
      ...props
    } = this.props;

    if (isStart && (availabilityFilterStartDate || availabilityFilterHoverStartDate)) {
      return (
        <AvailabilityFilterMarker
          date={availabilityFilterStartDate! || availabilityFilterHoverStartDate!}
          isStart={true}
          onClick={this.handleClick}
          {...props}
        />
      );
    }

    if (isEnd && (availabilityFilterEndDate || availabilityFilterHoverEndDate)) {
      return (
        <AvailabilityFilterMarker
          date={availabilityFilterEndDate! || availabilityFilterHoverEndDate!}
          isEnd={true}
          onClick={this.handleClick}
          {...props}
        />
      );
    }

    return null;
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(AvailabilityFilterMarkerContainer);
