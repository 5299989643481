import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import CheckinFormQueryData from './CheckinFormQueryData';
const checkinFormQuery = loader('./checkinFormQuery.graphql');

interface CheckinFormQueryProps {
  reservationId: string;
  children: (result: QueryResult<CheckinFormQueryData, Variables>) => JSX.Element | null;
}

interface Variables {
  reservationId: string;
}

export default function CheckinFormQuery({ reservationId, children }: CheckinFormQueryProps) {
  return (
    <Query<CheckinFormQueryData, Variables> query={checkinFormQuery} variables={{ reservationId }}>
      {children}
    </Query>
  );
}
