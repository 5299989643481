import { CSSProperties } from 'react';

interface Label {
  foregroundColor: string;
  backgroundColor: string;
}

export default function calculateLabelStyle(label: Label): CSSProperties {
  const { foregroundColor: color, backgroundColor } = label;

  return {
    color,
    backgroundColor,
    backgroundBlendMode: 'overlay',
    backgroundImage: 'linear-gradient(hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 50%, 0.2))',
    boxShadow: 'inset 1px 1px hsla(0, 0%, 100%, 0.1)',
  };
}
