import React from 'react';
import { AnchorButton, Callout } from '@blueprintjs/core';
import { withTranslation, WithTranslation } from '../translation';
import classNames from 'classnames';
import getStatusCode from '../../utils/getStatusCode';

interface ErrorMessageProps {
  error: Error;
  className?: string;
}

class ErrorMessage extends React.Component<ErrorMessageProps & WithTranslation> {
  renderReloadButton() {
    const { t: translate } = this.props;

    return <AnchorButton href="">{translate('Reload page')}</AnchorButton>;
  }

  renderNotFound() {
    const { t: translate } = this.props;

    return (
      <div>
        <Callout icon={null}>{translate('This object no longer exists. It might have been deleted recently.')}</Callout>
      </div>
    );
  }

  renderError() {
    const { error } = this.props;
    const { t: translate } = this.props;

    return (
      <div className="space-between-2">
        <div className="text-red-900 bg-red-50 rounded-sm p-2">
          {getStatusCode(error) === 500
            ? translate(
                'Sorry, something went wrong. We have been automatically notified of the problem and will try to resolve it as quickly as possible.'
              )
            : error.message}
        </div>
        {this.renderReloadButton()}
      </div>
    );
  }

  render() {
    const { error, className } = this.props;

    return (
      <div className={classNames('bg-white p-4 w-full border-b space-between-2', className)}>
        {getStatusCode(error) === 404 ? this.renderNotFound() : this.renderError()}
      </div>
    );
  }
}

export default withTranslation()(ErrorMessage);
