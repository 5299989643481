import React, { PureComponent } from 'react';
import classes from './TodayMarker.module.css';
import { Moment } from 'moment';
import { TODAY } from '../../config/planboard';

interface TodayMarkerProps {
  calculateOffset: (args: { date: Moment }) => number;
}

export default class TodayMarker extends PureComponent<TodayMarkerProps> {
  render() {
    const left = this.props.calculateOffset({ date: TODAY.clone().set({ hour: 12 }) });
    const style = { left };

    return <div className={classes.root} style={style} />;
  }
}
