import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import { NORMAL_QUERY_POLL_INTERVAL } from '../../config/planboard';
const ownerMaintenanceAgendaPeriodSidebarQuery = loader('./ownerMaintenanceAgendaPeriodSidebarQuery.graphql');

interface Data {
  ownerMaintenanceAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;
    memo: string;
    editUrl: string;
    rentableIdentity: {
      id: string;
      name: string;
      rentableTypeName: string;
      url: string;
    };
  };
}

interface Variables {
  ownerMaintenanceAgendaPeriodId: string;
}

interface OwnerMaintenanceAgendaPeriodSidebarQueryProps {
  ownerMaintenanceAgendaPeriodId: string;
  children: (result: QueryResult<Data, Variables>) => JSX.Element | null;
}

const OwnerMaintenanceAgendaPeriodSidebarQuery = ({
  ownerMaintenanceAgendaPeriodId,
  children,
}: OwnerMaintenanceAgendaPeriodSidebarQueryProps) => (
  <Query<Data, Variables>
    query={ownerMaintenanceAgendaPeriodSidebarQuery}
    variables={{ ownerMaintenanceAgendaPeriodId }}
    fetchPolicy="cache-and-network"
    pollInterval={NORMAL_QUERY_POLL_INTERVAL}
  >
    {children}
  </Query>
);

export default OwnerMaintenanceAgendaPeriodSidebarQuery;
