import { TranslateFunction } from '../components/translation';

export default function formatPriorityDescription(priority: number, translate: TranslateFunction) {
  switch (priority) {
    case 0:
      return translate('Low');
    case 1:
      return translate('Medium');
    case 2:
      return translate('High');
  }

  return undefined;
}
