import React from 'react';
import NotEnoughStockAgendaPeriodEventPopover from '../not-enough-stock-agenda-period-event-popover';
import formatNotEnoughStockAgendaPeriod from '../../utils/formatNotEnoughStockAgendaPeriod';
import AgendaPeriodEventItem from '../agenda-period-event-item/AgendaPeriodEventItem';
import Translation from '../translation';

interface NotEnoughStockAgendaPeriodEventItemProps {
  notEnoughStockAgendaPeriod: {
    startDate: string;
    endDate: string;
  };
  isActive: boolean;
  isIndented: boolean;
}

export default function NotEnoughStockAgendaPeriodEventItem({
  notEnoughStockAgendaPeriod,
  isActive,
  isIndented,
}: NotEnoughStockAgendaPeriodEventItemProps) {
  return (
    <Translation>
      {(translate) => (
        <AgendaPeriodEventItem
          title={formatNotEnoughStockAgendaPeriod(notEnoughStockAgendaPeriod, translate)}
          renderPopover={() => <NotEnoughStockAgendaPeriodEventPopover notEnoughStockAgendaPeriod={notEnoughStockAgendaPeriod} />}
          className="not-enough-stock-agenda-period"
          isActive={isActive}
          isIndented={isIndented}
        />
      )}
    </Translation>
  );
}
