import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import { NORMAL_QUERY_POLL_INTERVAL } from '../../config/planboard';
const maintenanceAgendaPeriodSidebarQuery = loader('./maintenanceAgendaPeriodSidebarQuery.graphql');

interface Data {
  maintenanceAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;
    memo: string;
    editUrl: string;
    rentableIdentity: {
      id: string;
      name: string;
      rentableTypeName: string;
      url: string;
    };
  };
}

interface Variables {
  maintenanceAgendaPeriodId: string;
}

interface MaintenanceAgendaPeriodSidebarQueryProps {
  maintenanceAgendaPeriodId: string;
  children: (result: QueryResult<Data, Variables>) => JSX.Element | null;
}

const MaintenanceAgendaPeriodSidebarQuery = ({ maintenanceAgendaPeriodId, children }: MaintenanceAgendaPeriodSidebarQueryProps) => (
  <Query<Data, Variables>
    query={maintenanceAgendaPeriodSidebarQuery}
    variables={{ maintenanceAgendaPeriodId }}
    fetchPolicy="cache-and-network"
    pollInterval={NORMAL_QUERY_POLL_INTERVAL}
  >
    {children}
  </Query>
);

export default MaintenanceAgendaPeriodSidebarQuery;
