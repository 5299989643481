import React from 'react';
import MaintenanceAgendaPeriodEventPopover from '../maintenance-agenda-period-event-popover';
import formatMaintenanceAgendaPeriod from '../../utils/formatMaintenanceAgendaPeriod';
import AgendaPeriodEventItem from '../agenda-period-event-item/AgendaPeriodEventItem';
import Translation from '../translation';

interface MaintenanceAgendaPeriodEventItemProps {
  maintenanceAgendaPeriod: {
    startDate: string;
    endDate: string;
    memo: string;
  };
  isActive: boolean;
  isIndented: boolean;
}

export default function MaintenanceAgendaPeriodEventItem({
  maintenanceAgendaPeriod,
  isActive,
  isIndented,
}: MaintenanceAgendaPeriodEventItemProps) {
  return (
    <Translation>
      {(translate) => (
        <AgendaPeriodEventItem
          title={formatMaintenanceAgendaPeriod(maintenanceAgendaPeriod, translate)}
          renderPopover={() => <MaintenanceAgendaPeriodEventPopover maintenanceAgendaPeriod={maintenanceAgendaPeriod} />}
          className="maintenance-agenda-period"
          isActive={isActive}
          isIndented={isIndented}
        />
      )}
    </Translation>
  );
}
