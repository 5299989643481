import React, { PureComponent } from 'react';
import Sidebar from '../sidebar';
import { Intent, Callout } from '@blueprintjs/core';
import Translation from '../translation';

interface MemoSectionProps {
  memo: string;
}

export default class MemoSection extends PureComponent<MemoSectionProps> {
  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Memo')}>
            <Callout intent={Intent.PRIMARY} icon={null}>
              {this.props.memo}
            </Callout>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}
