import React, { Component } from 'react';
import { HTMLTable, AnchorButton, Classes, Dialog, Callout, Intent, H4, Button, Icon, IconSize } from '@blueprintjs/core';
import moment from 'moment';
import Translation from '../translation';
import { Booking } from '../../actions/bookingMode';
import classNames from 'classnames';
import deserializeObject from '../../utils/deserializeObject';
import GuestGroupFilter from '../filter-panel/GuestGroupFilter';
import { GuestTypeData } from '../filter-panel/FilterPanelQueryData';

interface ReservationSelectionDialogProps {
  rentable: {
    id: string;
    price: string;
    reservableWarnings: string[];
    newBlockedPeriodsUrl: string;
    rentableIdentity: {
      id: string;
      name: string;
      rentableTypeName: string;
      newReservationUrl: string;
      newBlockedPeriodUrl: string;
      url: string;
    };
  };
  startDate: string;
  endDate: string;
  guestGroup: string;
  activeBooking?: Booking;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose: (e?: React.SyntheticEvent) => void;
  onClosed: () => void;
  onGuestGroupChange: (selectedGuestGroup: { [key: string]: string }) => void;
  guestTypes: GuestTypeData[];
}

export default class ReservationSelectionDialog extends Component<ReservationSelectionDialogProps> {
  private guestGroupFilterInput: HTMLInputElement | null = null;

  get formattedRentableIdentity() {
    let { rentableIdentity } = this.props.rentable;
    return `${rentableIdentity.rentableTypeName} - ${rentableIdentity.name}`;
  }

  handleClick = (e: React.MouseEvent) => {
    // Prevent propagating click event to planboard which would reset the selection & close the dialog
    e.stopPropagation();
  };

  renderHeader() {
    const { activeBooking } = this.props;
    return (
      <Translation>
        {(translate) => (
          <div className={Classes.DIALOG_HEADER}>
            <H4 className={activeBooking ? 'text-bp3-green1' : ''}>
              {activeBooking
                ? translate('New reservation for booking ({{booking}})', { booking: activeBooking.bookingNr })
                : translate('New booking or blocked period')}
            </H4>
            <Button
              aria-label="Close"
              className={Classes.DIALOG_CLOSE_BUTTON}
              icon={<Icon icon="small-cross" iconSize={IconSize.LARGE} />}
              minimal={true}
              onClick={this.props.onClose}
            />
          </div>
        )}
      </Translation>
    );
  }

  renderRentableIdentityTableRow() {
    let { url } = this.props.rentable.rentableIdentity;

    return (
      <Translation>
        {(translate) => (
          <tr>
            <td className="shadow-none">
              <strong>{translate('Rental')}</strong>
            </td>
            <td className="shadow-none">
              <a href={url} target="_blank" rel="noopener noreferrer">
                {this.formattedRentableIdentity}
              </a>
            </td>
          </tr>
        )}
      </Translation>
    );
  }

  renderArrivalDateTableRow() {
    const { startDate } = this.props;

    return (
      <Translation>
        {(translate) => (
          <tr>
            <td>
              <strong>{translate('Arrival date')}</strong>
            </td>
            <td>{translate('{{startDate, date | dddd D MMMM}}', { startDate })}</td>
          </tr>
        )}
      </Translation>
    );
  }

  renderDepartureDateTableRow() {
    const { endDate } = this.props;

    return (
      <Translation>
        {(translate) => (
          <tr>
            <td>
              <strong>{translate('Departure date')}</strong>
            </td>
            <td>{translate('{{endDate, date | dddd D MMMM}}', { endDate })}</td>
          </tr>
        )}
      </Translation>
    );
  }

  renderLengthOfStayTableRow() {
    const { startDate, endDate } = this.props;
    let lengthOfStay = moment(endDate).diff(startDate, 'days');

    return (
      <Translation>
        {(translate) => (
          <tr>
            <td>
              <strong>{translate('Duration')}</strong>
            </td>
            <td>{lengthOfStay === 1 ? translate('1 night') : translate('{{lengthOfStay}} nights', { lengthOfStay })}</td>
          </tr>
        )}
      </Translation>
    );
  }

  renderGuestGroupFilter() {
    const { guestGroup, guestTypes } = this.props;

    return (
      <Translation>
        {(translate) => (
          <tr>
            <td>
              <strong>{translate('Guest group')}</strong>
            </td>
            <td className="w-2/3">
              <GuestGroupFilter
                guestTypes={guestTypes}
                selectedGuestGroup={deserializeObject(guestGroup)}
                inputRef={this.handleGuestGroupFilterInputRef}
                onChange={this.handleGuestGroupFilterChange}
              />
            </td>
          </tr>
        )}
      </Translation>
    );
  }

  handleGuestGroupFilterInputRef = (input: HTMLInputElement | null) => {
    this.guestGroupFilterInput = input;
  };

  handleGuestGroupFilterChange = (selectedGuestGroup: { [key: string]: string }) => {
    this.props.onGuestGroupChange(selectedGuestGroup);
  };

  renderPriceTableRow() {
    let { price } = this.props.rentable;

    return (
      <Translation>
        {(translate) => (
          <tr>
            <td>
              <strong>{translate('Price')}</strong>
            </td>
            <td>{price}</td>
          </tr>
        )}
      </Translation>
    );
  }

  renderBookingRow(booking: Booking) {
    return (
      <Translation>
        {(translate) => (
          <tr>
            <td className="shadow-none">
              <strong>{translate('Booking')}</strong>
            </td>
            <td className="shadow-none text-bp3-green1">{booking.bookingNr}</td>
          </tr>
        )}
      </Translation>
    );
  }

  renderBody() {
    const { activeBooking } = this.props;
    let { reservableWarnings } = this.props.rentable;

    return (
      <div className={Classes.DIALOG_BODY}>
        {reservableWarnings.map((issue, index) => (
          <Callout intent={Intent.WARNING} key={index} style={{ marginBottom: 15 }}>
            {issue}
          </Callout>
        ))}
        <HTMLTable striped className="w-full bg-bp4-light-gray5 mt-2">
          <tbody>
            {activeBooking && this.renderBookingRow(activeBooking)}
            {this.renderRentableIdentityTableRow()}
            {this.renderArrivalDateTableRow()}
            {this.renderDepartureDateTableRow()}
            {this.renderLengthOfStayTableRow()}
            {this.renderGuestGroupFilter()}
            {this.renderPriceTableRow()}
          </tbody>
        </HTMLTable>
      </div>
    );
  }

  renderFooter() {
    const { activeBooking, isLoading } = this.props;
    let { newBlockedPeriodsUrl } = this.props.rentable;
    let { newBlockedPeriodUrl, newReservationUrl } = this.props.rentable.rentableIdentity;

    return (
      <Translation>
        {(translate) => (
          <div className={Classes.DIALOG_FOOTER}>
            <div className={classNames(Classes.DIALOG_FOOTER_ACTIONS, 'justify-start gap-2')}>
              {!activeBooking && (
                <AnchorButton
                  intent={Intent.PRIMARY}
                  href={newReservationUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-0"
                  disabled={isLoading}
                >
                  {translate('Create reservation')}
                </AnchorButton>
              )}
              {activeBooking && (
                <AnchorButton
                  intent={Intent.PRIMARY}
                  href={newReservationUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-0"
                  disabled={isLoading}
                >
                  {translate('Create reservation')}
                </AnchorButton>
              )}

              {!activeBooking && (
                <AnchorButton href={newBlockedPeriodUrl} target="_blank" rel="noopener noreferrer" className="ml-0">
                  {translate('Block')}
                </AnchorButton>
              )}
              {!activeBooking && (
                <AnchorButton href={newBlockedPeriodsUrl} target="_blank" rel="noopener noreferrer" className="ml-0">
                  {translate('Block multiple')}
                </AnchorButton>
              )}
            </div>
          </div>
        )}
      </Translation>
    );
  }

  render() {
    const { isOpen, onClose, onClosed } = this.props;

    return (
      <Dialog isOpen={isOpen} onClose={onClose} onClosed={onClosed} style={{ width: 480 }} className="bg-white">
        <div onClick={this.handleClick}>
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </Dialog>
    );
  }
}
