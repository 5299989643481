import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Tag } from '@blueprintjs/core';
import Translation from '../translation';

interface Extra {
  id: string;
  name: string;
  humanQuantity: string;
}

interface ExtrasSectionProps {
  extras: Extra[];
}

export default class ExtrasSection extends Component<ExtrasSectionProps> {
  renderExtra({ id, name, humanQuantity }: Extra) {
    return (
      <Tag key={id} className="rounded-sm m-1 p-1" minimal>
        {name} ({humanQuantity})
      </Tag>
    );
  }

  renderExtras() {
    const { extras } = this.props;
    return <div className="flex flex-wrap -m-1">{extras.map(this.renderExtra)}</div>;
  }

  render() {
    return <Translation>{(translate) => <Sidebar.Section title={translate('Extras')}>{this.renderExtras()}</Sidebar.Section>}</Translation>;
  }
}
