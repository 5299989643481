import React, { PureComponent } from 'react';
import { Tabs, Tab } from '@blueprintjs/core';
import CheckinReservationPanel from './CheckinReservationPanel';
import CheckoutReservationPanel from './CheckoutReservationPanel';
import MoveReservationPanel from './MoveReservationPanel';
import Sidebar from '../sidebar';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../actions/moveAction';
import { State } from '../../reducers';
import Translation from '../translation';

interface OwnProps {
  reservationAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;
    rentableId: string;
    reservation: {
      id: string;
      rentableId: string;
      parkId: string;
      startDate: string;
      endDate: string;
      isCheckedIn: boolean;

      rentableIdentity: {
        id: string;
      };
    };
  };
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { moveAction } = state;

  return {
    moveTabIsSelected: moveAction.state !== 'inactive' && moveAction.reservationAgendaPeriodId === ownProps.reservationAgendaPeriod.id,
  };
};

const mapDispatchToProps = {
  activateMoveAction: actions.activateMoveAction,
  deactivateMoveAction: actions.deactivateMoveAction,
};

type ActionSectionProps = ConnectedProps<typeof connector> & OwnProps;

interface ActionSectionState {
  selectedTabId: TabId;
}

enum TabId {
  Checkin = 'checkin',
  Checkout = 'checkout',
  Move = 'move',
}

class ActionSection extends PureComponent<ActionSectionProps, ActionSectionState> {
  constructor(props: ActionSectionProps) {
    super(props);
    this.state = { selectedTabId: this.props.reservationAgendaPeriod.reservation.isCheckedIn ? TabId.Checkout : TabId.Checkin };
  }

  get selectedTabId() {
    // Always return move tab when performing a move, otherwise fallback to user selected tab
    return this.props.moveTabIsSelected ? TabId.Move : this.state.selectedTabId;
  }

  handleChange = (newTabId: TabId) => {
    const { reservationAgendaPeriod, activateMoveAction, deactivateMoveAction } = this.props;

    if (newTabId === TabId.Move) {
      activateMoveAction(reservationAgendaPeriod);
    } else {
      this.setState({ selectedTabId: newTabId }, () => deactivateMoveAction());
    }
  };

  renderCheckinReservationPanel() {
    return <CheckinReservationPanel reservation={this.props.reservationAgendaPeriod.reservation} />;
  }

  renderCheckoutReservationPanel() {
    return <CheckoutReservationPanel reservation={this.props.reservationAgendaPeriod.reservation} />;
  }

  renderMoveReservationPanel() {
    return <MoveReservationPanel reservationAgendaPeriod={this.props.reservationAgendaPeriod} />;
  }

  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Actions')}>
            <Tabs className="pb-1" selectedTabId={this.selectedTabId} renderActiveTabPanelOnly onChange={this.handleChange}>
              <Tab id={TabId.Checkin} title={translate('Check in')} panel={this.renderCheckinReservationPanel()} />
              <Tab id={TabId.Checkout} title={translate('Check out')} panel={this.renderCheckoutReservationPanel()} />
              <Tab id={TabId.Move} title={translate('Move')} panel={this.renderMoveReservationPanel()} />
            </Tabs>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ActionSection);
