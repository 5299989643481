import React from 'react';
import Badge from '../badge';
import generateReservationClassName from '../../utils/generateReservationClassName';

interface ReservationChannelBadgeProps {
  reservation: {
    booking: {
      source: string;
      isOption: boolean;

      channel: {
        name: string;
      };
    };
  };
}

export default function ReservationChannelBadge({ reservation }: ReservationChannelBadgeProps) {
  const className = generateReservationClassName({ ...reservation, hasDateRangeExtended: false });
  return <Badge className={className}>{reservation.booking.channel.name}</Badge>;
}
