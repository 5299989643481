import React, { Component } from 'react';
import { Button, Classes, Dialog, Intent, Icon } from '@blueprintjs/core';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import className from 'classnames';
import LegendItem from './LegendItem';
import { withTranslation, WithTranslation } from '../translation';

interface LegendDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

class LegendDialog extends Component<LegendDialogProps & WithTranslation> {
  renderDefaultModeLegend() {
    const { t: translate } = this.props;

    return (
      <div>
        <strong>{translate('Default mode')}</strong>
        <section className="flex justify-between mt-4">
          <div className="space-between-4 w-1/2">
            <LegendItem badgeClassName="bg-green-300">{translate('Backoffice reservation')}</LegendItem>
            <LegendItem badgeClassName="bg-green-100">{translate('Backoffice option')}</LegendItem>
            <LegendItem badgeClassName="bg-cyan-300">{translate('Front office reservation')}</LegendItem>
            <LegendItem badgeClassName="bg-cyan-100">{translate('Front office option')}</LegendItem>
            <LegendItem badgeClassName="bg-purple-300">{translate('Touroperator reservation')}</LegendItem>
            <LegendItem badgeClassName="bg-purple-100">{translate('Touroperator option')}</LegendItem>
            <LegendItem badgeClassName="bg-orange-300">{translate('Owner reservation')}</LegendItem>
            <LegendItem badgeClassName="bg-brown-300">{translate('Imported reservation')}</LegendItem>
          </div>

          <div className="space-between-4 w-1/2">
            <LegendItem badgeClassName="bg-red-300">{translate('iCal blocked period')}</LegendItem>
            <LegendItem badgeClassName="bg-gray-400">{translate('Blocked period')}</LegendItem>
            <LegendItem badgeClassName="bg-gray-400">{translate('Blocked by owner')}</LegendItem>
            <LegendItem badgeClassName="bg-gray-400">{translate('Blocked by grouped rentableType')}</LegendItem>
            <LegendItem badgeClassName="bg-gray-400">{translate('Not enough stock available')}</LegendItem>
          </div>
        </section>
      </div>
    );
  }

  renderPaymentsAndTasksModeLegend() {
    const { t: translate } = this.props;
    const unpaidIcon = <Icon icon="euro" iconSize={11} className="text-bp4-dark-gray3" />;
    const unsentInvoiceIcon = <FontAwesomeIcon icon={faPaperPlane} className="text-xs text-bp4-dark-gray3" />;
    const unfinishedTasksIcon = <Icon icon="tick" iconSize={12} className="text-bp4-dark-gray3" />;
    const pastDeadlineIcon = <Icon icon="time" iconSize={10} className="text-bp4-dark-gray3" />;

    return (
      <div>
        <strong>{translate('Payments & todos')}</strong>
        <section className="flex justify-between mt-4">
          <div className="space-between-4 w-1/2">
            <LegendItem badgeClassName="bg-orange-300" icon={unpaidIcon}>
              {translate('Outstanding amount')}
            </LegendItem>
            <LegendItem badgeClassName="bg-orange-300" icon={unsentInvoiceIcon}>
              {translate('Unsent invoice')}
            </LegendItem>
          </div>

          <div className="space-between-4 w-1/2">
            <LegendItem badgeClassName="bg-red-300" icon={unfinishedTasksIcon}>
              {translate('Unfinished todos')}
            </LegendItem>
            <LegendItem badgeClassName="bg-red-300" icon={pastDeadlineIcon}>
              {translate('Payment deadline expired')}
            </LegendItem>
          </div>
        </section>
      </div>
    );
  }

  renderPresenceModeLegend() {
    const { t: translate } = this.props;
    const checkedInIcon = <Icon icon="user" iconSize={11} className="text-bp4-dark-gray3" />;
    const checkedOutIcon = <Icon icon="user" iconSize={11} className="text-bp4-dark-gray3" />;

    return (
      <div>
        <strong>{translate('Presence')}</strong>
        <section className="flex justify-between mt-4">
          <div className="space-between-4 w-1/2">
            <LegendItem badgeClassName="bg-green-300 filter-grayscale">{translate('Not checked in')}</LegendItem>
            <LegendItem badgeClassName="bg-blue-gray-300" icon={checkedOutIcon}>
              {translate('Checked out')}
            </LegendItem>
          </div>
          <div className="space-between-4 w-1/2">
            <LegendItem badgeClassName="bg-light-blue-300" icon={checkedInIcon}>
              {translate('Checked in')}
            </LegendItem>
          </div>
        </section>
      </div>
    );
  }

  renderBody() {
    return (
      <div className={className(Classes.DIALOG_BODY, 'space-between-8')}>
        {this.renderDefaultModeLegend()}
        {this.renderPaymentsAndTasksModeLegend()}
        {this.renderPresenceModeLegend()}
      </div>
    );
  }

  renderFooter() {
    const { t: translate, onClose } = this.props;

    return (
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={Intent.PRIMARY} onClick={onClose}>
            {translate('Close')}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { isOpen, t: translate, onClose } = this.props;

    return (
      <Dialog title={translate('Legend')} isOpen={isOpen} onClose={onClose} className="text-base" style={{ width: 580 }}>
        {this.renderBody()}
        {this.renderFooter()}
      </Dialog>
    );
  }
}

export default withTranslation()(LegendDialog);
