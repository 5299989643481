import React from 'react';
import Badge from '../badge';
import Translation from '../translation';

export default function NotEnoughStockAgendaPeriodBadge() {
  return (
    <Translation>
      {(translate) => <Badge className="not-enough-stock-agenda-period">{translate('Not enough stock available')}</Badge>}
    </Translation>
  );
}
