import React, { PureComponent } from 'react';
import moment, { Moment } from 'moment';
import DateInput from '../date-input';

interface DatePickerProps {
  value: string;
  className: string;
  onChange: (date: Moment) => void;
}

export default class DatePicker extends PureComponent<DatePickerProps> {
  handleDateInputChange = (date: Date) => {
    const { onChange } = this.props;
    onChange(moment(date));
  };

  render() {
    const { value } = this.props;

    return (
      <div className={this.props.className}>
        {/* TODO: Set minDate and maxDate to the same period range as the Grid renders */}
        <DateInput value={moment(value).toDate()} onChange={this.handleDateInputChange} />
      </div>
    );
  }
}
