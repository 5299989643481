import React, { Component } from 'react';
import { ApolloClient, ApolloError } from '@apollo/client/core';
import { loader } from 'graphql.macro';
import HoneybadgerQueryData from './HoneybadgerQueryData';
import HoneybadgerClient from 'honeybadger-js';
import ErrorMessage from '../error-message';
import UnsupportedBrowserWarning from '../unsupported-browser-warning';
import isSupportedBrowser from '../../utils/isSupportedBrowser';
import UnsupportedDeviceWarning from '../unsupported-device-warning';
import isSupportedDevice from '../../utils/isSupportedDevice';
import { reduxStore } from '../../config/redux';
const honeybadgerQuery = loader('./honeybadgerQuery.graphql');

interface HoneybadgerProps {
  honeybadgerClient: typeof HoneybadgerClient;
  apolloClient: ApolloClient<any>;
  children: React.ReactNode;
}

interface HoneybadgerNormalState {
  errorOccurred: false;
}

interface HoneybadgerErrorState {
  errorOccurred: true;
  error: Error;
  info: object;
}

type HoneybadgerState = HoneybadgerNormalState | HoneybadgerErrorState;

export default class Honeybadger extends Component<HoneybadgerProps, HoneybadgerState> {
  static getDerivedStateFromError(error: Error) {
    return { errorOccurred: true, error };
  }

  state: HoneybadgerState = { errorOccurred: false };

  componentDidMount() {
    this.setContext();
    this.setUserContext();
  }

  setContext = () => {
    const { honeybadgerClient } = this.props;
    const context = { referrer: document.referrer };

    honeybadgerClient.setContext(context);
  };

  setUserContext = async () => {
    const { honeybadgerClient, apolloClient } = this.props;

    try {
      const queryResult = await apolloClient.query<HoneybadgerQueryData>({ query: honeybadgerQuery });
      const { id, email, name } = queryResult.data.user;
      honeybadgerClient.setContext({ user_id: id, user_email: email, user_name: name });
    } catch (error) {
      // Allow this to fail if user is not logged in
      if (!isAuthError(error as Error)) throw error;
    }
  };

  componentDidCatch(error: Error, info: object) {
    const { honeybadgerClient } = this.props;

    this.setState({ errorOccurred: true, error, info });

    const additionalContext = {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      redux: reduxStore.getState(),
    };

    // Do not report auth errors to Honeybadger
    if (!isAuthError(error)) {
      honeybadgerClient.notify(error, { context: { ...info, ...additionalContext } });
    }

    if (process.env.NODE_ENV === 'test') {
      // Let tests fail if error occurs
      throw error;
    }
  }

  render() {
    if (this.state.errorOccurred) {
      return (
        <div>
          {!isSupportedBrowser() && <UnsupportedBrowserWarning />}
          {!isSupportedDevice() && <UnsupportedDeviceWarning />}
          <ErrorMessage error={this.state.error} />
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

function isAuthError(error: Error) {
  return (
    error instanceof ApolloError && error.graphQLErrors.some((graphQLError) => [401, 403].includes(graphQLError.extensions?.code as number))
  );
}
