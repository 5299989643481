import deburr from 'lodash/deburr';

interface Park {
  id: string;
  name: string;
}

export default function filterPark(query: string, park: Park): boolean {
  if (query === '') {
    return true;
  }

  let normalizedQuery = query.toLowerCase();
  let normalizedParkName = deburr(park.name).toLowerCase();

  let queryTokens = normalizedQuery.split(/\s+/);
  let parkTokens = [...normalizedParkName.split(/\s+/), park.id];

  return queryTokens.every((queryToken) => parkTokens.some((parkToken) => parkToken.includes(queryToken)));
}
