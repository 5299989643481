import React, { PureComponent } from 'react';
import classes from './WeekHeader.module.css';
import Translation from '../translation';

interface WeekHeaderProps {
  startDate: string;
  endDate: string;
  onMouseDown: (e: React.MouseEvent) => void;
}

export default class WeekHeader extends PureComponent<WeekHeaderProps> {
  renderTitle() {
    const { startDate } = this.props;

    return (
      <h1 className={classes.title}>
        <Translation>{(translate) => translate('{{startDate, date | MMM YYYY}}', { startDate })}</Translation>
      </h1>
    );
  }

  renderSubtitle() {
    const { endDate } = this.props;

    return (
      <h2 className={classes.subtitle}>
        <Translation>{(translate) => translate('{{endDate, date | [Week] ww}}', { endDate })}</Translation>
      </h2>
    );
  }

  render() {
    let { onMouseDown } = this.props;

    return (
      <Translation>
        {(translate) => (
          <header className={classes.root} onMouseDown={onMouseDown} title={translate('Grab to drag forward or backward')}>
            {this.renderTitle()}
            {this.renderSubtitle()}
          </header>
        )}
      </Translation>
    );
  }
}
