import React from 'react';
import DeleteMaintenanceAgendaPeriodForm from './DeleteMaintenanceAgendaPeriodForm';
import DeleteMaintenanceAgendaPeriodMutation, { DeleteFn, Result } from './DeleteMaintenanceAgendaPeriodMutation';

interface DeleteMaintenanceAgendaPeriodFormContainerProps {
  maintenanceAgendaPeriodId: string;
}

export default function DeleteMaintenanceAgendaPeriodFormContainer({
  maintenanceAgendaPeriodId,
}: DeleteMaintenanceAgendaPeriodFormContainerProps) {
  return (
    <DeleteMaintenanceAgendaPeriodMutation>
      {(deleteFn: DeleteFn, result: Result) => (
        <DeleteMaintenanceAgendaPeriodForm
          maintenanceAgendaPeriodId={maintenanceAgendaPeriodId}
          deleteFn={deleteFn}
          isLoading={result.loading}
        />
      )}
    </DeleteMaintenanceAgendaPeriodMutation>
  );
}
