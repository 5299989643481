import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Intent, Callout } from '@blueprintjs/core';
import Translation from '../translation';

interface MemoSectionProps {
  booking: { memo: string };
}

export default class MemoSection extends Component<MemoSectionProps> {
  renderMemo() {
    const { memo } = this.props.booking;

    return (
      <Callout intent={Intent.PRIMARY} icon={null}>
        <div dangerouslySetInnerHTML={{ __html: memo }} />
      </Callout>
    );
  }

  render() {
    const { memo } = this.props.booking;

    return (
      <Translation>
        {(translate) => (memo ? <Sidebar.Section title={translate('Notes')}>{this.renderMemo()}</Sidebar.Section> : null)}
      </Translation>
    );
  }
}
