import React, { Component } from 'react';
import { NonIdealState } from '@blueprintjs/core';
import filterPark from '../../utils/filterPark';
import OrganizationItem from './OrganizationItem';
import { OrganizationData } from './OrganizationListQueryData';
import Translation from '../translation';

const filterOrganization = (query: string, organization: OrganizationData) => {
  return organization.parks.some((park) => filterPark(query, park));
};

interface OrganizationListProps {
  organizations: OrganizationData[];
  starredParkIds: string[];
  query: string;
  onParkItemClick: (parkId: string) => void;
  onStarButtonClick: (parkId: string, isStarred: boolean) => void;
}

export default class OrganizationList extends Component<OrganizationListProps> {
  get filteredOrganizations() {
    let { organizations, query } = this.props;
    return organizations.filter((organization) => filterOrganization(query, organization));
  }

  get hasFilteredOrganizations() {
    let { organizations, query } = this.props;
    return organizations.some((organization) => filterOrganization(query, organization));
  }

  renderFilteredOrganizations() {
    let { starredParkIds, query, onParkItemClick, onStarButtonClick } = this.props;

    return this.filteredOrganizations.map((organization) => (
      <OrganizationItem
        key={organization.id}
        organization={organization}
        starredParkIds={starredParkIds}
        query={query}
        onParkItemClick={onParkItemClick}
        onStarButtonClick={onStarButtonClick}
      />
    ));
  }

  renderNoResults() {
    return (
      <Translation>
        {(translate) => <NonIdealState className="items-start p-1">{translate('No parks found for this search query.')}</NonIdealState>}
      </Translation>
    );
  }

  render() {
    return this.hasFilteredOrganizations ? this.renderFilteredOrganizations() : this.renderNoResults();
  }
}
