export default function formatPriorityName(priority: number) {
  switch (priority) {
    case 0:
      return 'L';
    case 1:
      return 'M';
    case 2:
      return 'H';
  }

  return undefined;
}
