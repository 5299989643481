import React, { PureComponent } from 'react';
import { Moment } from 'moment';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { GridRef } from './Grid';
import GridQuery from './GridQuery';
import * as actions from '../../actions/currentDate';
import ErrorMessage from '../error-message';
import { State } from '../../reducers';
import PlaceholderGrid from './PlaceholderGrid';
import DefaultGrid from './DefaultGrid';
import GridQueryData from './GridQueryData';
import isEmpty from 'lodash/isEmpty';
import { EventEmitter } from 'events';
import invoke from 'lodash/invoke';

export type GridContainerRef = React.RefObject<GridContainer>;

interface OwnProps {
  parkId: string;
}

interface StateProps {
  selectedGuestGroup: string;
  selectedRentableSegmentIds: string;
  selectedRentableTypeIds: string;
  selectedRentableIdentityLabelIds: string;
  selectedAmenityIds: string;
  availabilityFilterStartDate?: string;
  availabilityFilterEndDate?: string;
  searchQuery: string;
}

interface DispatchProps {
  updateCurrentDate: (date: Moment) => void;
}

type GridContainerProps = OwnProps & StateProps & DispatchProps;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state, _ownProps) => {
  const { selectedGuestGroup, selectedRentableSegmentIds, selectedRentableTypeIds, selectedRentableIdentityLabelIds, selectedAmenityIds } =
    state.filterPanel;
  const { startDate: availabilityFilterStartDate, endDate: availabilityFilterEndDate } = state.availabilityFilter;
  const { searchQuery } = state;

  return {
    selectedGuestGroup,
    selectedRentableSegmentIds,
    selectedRentableTypeIds,
    selectedRentableIdentityLabelIds,
    selectedAmenityIds,
    availabilityFilterStartDate,
    availabilityFilterEndDate,
    searchQuery,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = { updateCurrentDate: actions.updateCurrentDate };

class GridContainer extends PureComponent<GridContainerProps> {
  private lastData?: GridQueryData;
  private eventEmitter: EventEmitter = new EventEmitter();
  private gridRef: GridRef = React.createRef();

  get isReloading() {
    return this.gridRef.current && this.gridRef.current.props.isReloading;
  }

  handleQueryCompleted = (data: GridQueryData) => {
    this.lastData = isEmpty(data) ? undefined : (data as GridQueryData);
    this.eventEmitter.emit('reloaded');
  };

  onceReloaded = (listener: () => void) => {
    this.eventEmitter.once('reloaded', listener);
  };

  scrollToPositionFromQueryParams = () => {
    invoke(this.gridRef.current, 'scrollToPositionFromQueryParams');
  };

  render() {
    const {
      parkId,
      updateCurrentDate,
      selectedGuestGroup,
      selectedRentableSegmentIds,
      selectedRentableTypeIds,
      selectedRentableIdentityLabelIds,
      selectedAmenityIds,
      availabilityFilterStartDate,
      availabilityFilterEndDate,
      searchQuery,
    } = this.props;

    return (
      <GridQuery
        parkId={parkId}
        selectedGuestGroup={selectedGuestGroup}
        selectedRentableSegmentIds={selectedRentableSegmentIds}
        selectedRentableTypeIds={selectedRentableTypeIds}
        selectedRentableIdentityLabelIds={selectedRentableIdentityLabelIds}
        selectedAmenityIds={selectedAmenityIds}
        availabilityFilterStartDate={availabilityFilterStartDate}
        availabilityFilterEndDate={availabilityFilterEndDate}
        searchQuery={searchQuery}
        onCompleted={this.handleQueryCompleted}
      >
        {({ data, loading, error }) => {
          if (error) {
            return <ErrorMessage error={error} className="self-start" />;
          }

          if (loading && isEmpty(this.lastData)) {
            return <PlaceholderGrid gridRef={this.gridRef} parkId={parkId} />;
          }

          if (loading && !isEmpty(this.lastData)) {
            return (
              <DefaultGrid gridRef={this.gridRef} parkId={parkId} data={this.lastData!} isReloading onDateChange={updateCurrentDate} />
            );
          }

          if (data) {
            return <DefaultGrid gridRef={this.gridRef} parkId={parkId} data={data} isReloading={false} onDateChange={updateCurrentDate} />;
          }

          return null;
        }}
      </GridQuery>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null as any, { forwardRef: true } as any)(GridContainer);
