import React from 'react';
import { Button, Spinner } from '@blueprintjs/core';

export default function TargetRentableSelectPlaceholder() {
  return (
    <Button fill alignText="left" rightIcon={<Spinner size={12} />} disabled>
      <span className="text-bp4-light-gray1">Bezig met laden…</span>
    </Button>
  );
}
