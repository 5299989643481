import React, { PureComponent } from 'react';
import { DateRange } from 'moment-range';
import RentableIdentityCell from '../rentable-identity-cell';
import RentableCellsQueryData from './RentableCellsQueryData';
import memoize from 'lodash/memoize';

interface RentableCellProps {
  id: string;
  data: RentableCellsQueryData;
  period: DateRange;
}

export default class RentableRentableIdentityCell extends PureComponent<RentableCellProps> {
  // Returns memoized rentable identity to prevent unecessary <RentableIdentityCell /> rerenders
  buildRentableIdentity = memoize((data: RentableCellsQueryData, id: string) => {
    const rentables = data.rentables.filter((rentable) => rentable.id === id);

    return { id, rentables };
  });

  render() {
    const { period, data, id } = this.props;
    return <RentableIdentityCell rentableIdentity={this.buildRentableIdentity(data, id)} period={period} />;
  }
}
