import React from 'react';
import BlockedByGroupedRentableTypeAgendaPeriodSidebar from './BlockedByGroupedRentableTypeAgendaPeriodSidebar';
import BlockedByGroupedRentableTypeAgendaPeriodSidebarQuery from './BlockedByGroupedRentableTypeAgendaPeriodSidebarQuery';
import { AgendaPeriodSidebarPlaceholder } from '../agenda-period-sidebar';
import { SidebarErrorMessage } from '../sidebar';

interface BlockedByGroupedRentableTypeAgendaPeriodSidebarContainerProps {
  blockedByGroupedRentableTypeAgendaPeriodId: string;
  onCloseButtonClick: () => void;
}

export default function BlockedByGroupedRentableTypeAgendaPeriodSidebarContainer({
  blockedByGroupedRentableTypeAgendaPeriodId,
  ...rest
}: BlockedByGroupedRentableTypeAgendaPeriodSidebarContainerProps) {
  return (
    <BlockedByGroupedRentableTypeAgendaPeriodSidebarQuery
      blockedByGroupedRentableTypeAgendaPeriodId={blockedByGroupedRentableTypeAgendaPeriodId}
    >
      {({ data, loading, error }) => {
        if (error) {
          return <SidebarErrorMessage error={error} {...rest} />;
        }
        if (loading) {
          return <AgendaPeriodSidebarPlaceholder {...rest} />;
        } else if (data) {
          return (
            <BlockedByGroupedRentableTypeAgendaPeriodSidebar
              blockedByGroupedRentableTypeAgendaPeriod={data.blockedByGroupedRentableTypeAgendaPeriod}
              {...rest}
            />
          );
        } else {
          return null;
        }
      }}
    </BlockedByGroupedRentableTypeAgendaPeriodSidebarQuery>
  );
}
