import React, { PureComponent } from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { DateRange } from 'moment-range';
import { loader } from 'graphql.macro';
import RentableCellsQueryData from './RentableCellsQueryData';
import { DisplayMode } from '../../actions/displayMode';
import { NORMAL_QUERY_POLL_INTERVAL, USE_QUERY_BATCHING } from '../../config/planboard';
const rentableCellsQuery = loader('./rentableCellsQuery.graphql');

interface CacheOnlyResult {
  data?: RentableCellsQueryData;
  loading: boolean;
  error: undefined;
}

interface RentableCellsQueryProps {
  period: DateRange;
  ids: string[];
  parkId: string;
  displayMode: DisplayMode;
  cacheOnly: boolean;
  children: (result: QueryResult<RentableCellsQueryData, Variables> | CacheOnlyResult) => JSX.Element | null;
}

interface Variables {
  ids: string[];
  parkId: string;
  period: [string, string];
  isAlertsDisplayMode: boolean;
  isPresenceDisplayMode: boolean;
}

export default class RentableCellsQuery extends PureComponent<RentableCellsQueryProps> {
  get variables() {
    const { period, ids, parkId, displayMode } = this.props;

    const $period: [string, string] = [period.start.format('YYYY-MM-DD'), period.end.format('YYYY-MM-DD')];
    const isAlertsDisplayMode = displayMode === DisplayMode.Alerts;
    const isPresenceDisplayMode = displayMode === DisplayMode.Presence;

    return { ids, parkId, period: $period, isAlertsDisplayMode, isPresenceDisplayMode };
  }

  render() {
    const { children } = this.props;

    return (
      <Query<RentableCellsQueryData, Variables>
        query={rentableCellsQuery}
        variables={this.variables}
        pollInterval={NORMAL_QUERY_POLL_INTERVAL}
        context={{ batch: USE_QUERY_BATCHING }}
      >
        {children}
      </Query>
    );
  }
}
