import React from 'react';
import { Icon, Intent } from '@blueprintjs/core';
import LoadingButton from '../loading-button';
import Translation from '../translation';

interface SubmitButtonProps {
  isCheckingIn: boolean;
  isDisabled: boolean;
}

export default function SubmitButton({ isCheckingIn, isDisabled }: SubmitButtonProps) {
  return (
    <LoadingButton
      type="submit"
      isLoading={isCheckingIn}
      isDisabled={isCheckingIn || isDisabled}
      intent={Intent.PRIMARY}
      icon={<Icon icon="log-in" iconSize={12} />}
    >
      <Translation>{(translate) => translate('Check in')}</Translation>
    </LoadingButton>
  );
}
