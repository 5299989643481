import React, { Component } from 'react';
import { Callout, Intent, Icon } from '@blueprintjs/core';
import Translation from '../translation';

export default class UnsupportedDeviceWarning extends Component {
  render() {
    return (
      <Translation>
        {(translate) => (
          <Callout intent={Intent.WARNING} icon={<Icon icon="warning-sign" />}>
            {translate(
              'The device you are using is not supported by us, because the screen is too small. Please open the planboard on a larger screen.'
            )}
          </Callout>
        )}
      </Translation>
    );
  }
}
