import React, { PureComponent } from 'react';
import RentableIdentityHeader from '../rentable-identity-header';

interface RentableHeaderProps {
  rentable: {
    id: string;
    petsAllowed: boolean;
    title: string;

    rentableIdentity: {
      id: string;
      name: string;
      priority: number;
      blockedForExternalReservations: boolean;
      blockingTodosCount: number;
      url: string;
    };

    rentableType: {
      id: string;
      name: string;
    };
  };
}

export default class RentableHeader extends PureComponent<RentableHeaderProps> {
  render() {
    const { rentable } = this.props;

    const rentableIdentity = {
      ...rentable.rentableIdentity,
      petsAllowed: rentable.petsAllowed,
    };

    return <RentableIdentityHeader rentableIdentity={rentableIdentity} title={rentable.title} />;
  }
}
