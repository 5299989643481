import { DisplayMode } from '../actions/displayMode';

export default function deserializeDisplayMode(input: string): DisplayMode {
  switch (input) {
    case 'default': {
      return DisplayMode.Default;
    }

    case 'alerts': {
      return DisplayMode.Alerts;
    }

    case 'presence': {
      return DisplayMode.Presence;
    }

    default: {
      return DisplayMode.Default;
    }
  }
}
