import React, { PureComponent } from 'react';
import Sidebar from '../sidebar';
import LocaleDate from '../locale-date';
import formatRentableIdentity from '../../utils/formatRentableIdentity';
import Translation from '../translation';

interface SummarySectionProps {
  agendaPeriod: {
    memo?: string;
    startDate: string;
    endDate: string;
    rentableIdentity?: {
      id: string;
      name: string;
      rentableTypeName: string;
      url: string;
    };
  };
  renderBadge: () => React.ReactNode;
}

export default class SummarySection extends PureComponent<SummarySectionProps> {
  render() {
    const { renderBadge } = this.props;
    const { startDate, endDate, rentableIdentity } = this.props.agendaPeriod;

    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Summary')}>
            <Sidebar.Table>
              <Sidebar.TableRow header={translate('Channel')} data={renderBadge()} />
              <Sidebar.TableRow header={translate('Start date')} data={<LocaleDate date={startDate} format="LL" />} />
              <Sidebar.TableRow header={translate('End date')} data={<LocaleDate date={endDate} format="LL" />} />
              {rentableIdentity && (
                <Sidebar.TableRow
                  header={translate('Rental')}
                  data={<a href={rentableIdentity.url}>{formatRentableIdentity(rentableIdentity)}</a>}
                />
              )}
            </Sidebar.Table>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}
