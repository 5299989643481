import { State } from '../reducers';
import { Dispatch, Action } from 'redux';
import { push as pushLocationAction, PUSH } from 'redux-first-routing';
import mergeSearchParam from '../utils/mergeSearchParam';
import { SearchParams } from '../config/planboard';
import deleteSearchParam from '../utils/deleteSearchParam';

export type SearchQuery = string;

/**
 * Updates the current search query
 */
export const updateSearchQuery = (searchQuery: string) => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
  const router = getState().router;
  const search = mergeSearchParam(router.search, SearchParams.SEARCH_QUERY, searchQuery).toString();

  dispatch(pushLocationAction({ search }));
};

/**
 * Resets the search query back to its initial state
 */
export const resetSearchQuery = () => (dispatch: Dispatch<Action<typeof PUSH>>, getState: () => State) => {
  let searchParams = new URLSearchParams(getState().router.search);

  searchParams = deleteSearchParam(searchParams, SearchParams.SEARCH_QUERY);

  dispatch(pushLocationAction({ search: searchParams.toString() }));
};
