import React, { PureComponent } from 'react';
import { Spring, animated } from 'react-spring';
import classes from './FilterPanel.module.css';
import { FormGroup, HotkeysTarget2, HotkeyConfig } from '@blueprintjs/core';
import GuestGroupFilter from './GuestGroupFilter';
import RentableSegmentFilter from './RentableSegmentFilter';
import RentableIdentityLabelFilter from './RentableIdentityLabelFilter';
import RentableTypeFilter from './RentableTypeFilter';
import { GuestTypeData, RentableSegmentData, RentableTypeData, RentableIdentityLabeldata, AmenityData } from './FilterPanelQueryData';
import AmenityFilter from './AmenityFilter';
import deserializeIds from '../../utils/deserializeIds';
import classNames from 'classnames';
import Translation, { withTranslation, WithTranslation } from '../translation';
import invoke from 'lodash/invoke';

// Polyfill for browsers that don't support Object.entries (such as Chrome < 54)
// See https://github.com/bookingexperts/support/issues/6934#issuecomment-477955503
import 'es7-object-polyfill';
import deserializeObject from '../../utils/deserializeObject';

interface FilterPanelProps {
  isOpen: boolean;
  guestTypes: GuestTypeData[];
  rentableSegments: RentableSegmentData[];
  rentableTypes: RentableTypeData[];
  rentableIdentityLabels: RentableIdentityLabeldata[];
  amenities: AmenityData[];
  selectedGuestGroup: string;
  selectedRentableSegmentIds: string;
  selectedRentableTypeIds: string;
  selectedRentableIdentityLabelIds: string;
  selectedAmenityIds: string;
  onGuestGroupFilterChange: (selectedGuestGroup: { [key: string]: string }) => void;
  onRentableSegmentFilterChange: (selectedRentableSegmentIds: string[]) => void;
  onRentableTypeFilterChange: (selectedRentableTypeIds: string[]) => void;
  onRentableIdentityLabelFilterChange: (selectedRentableIdentityLabelIds: string[]) => void;
  onAmenityFilterChange: (selectedAmenityIds: string[]) => void;
  onHotkey: () => void;
}

class FilterPanel extends PureComponent<FilterPanelProps & WithTranslation> {
  private guestGroupFilterInput: HTMLInputElement | null = null;
  private rentableSegmentFilterInput: HTMLInputElement | null = null;
  private rentableTypeFilterinput: HTMLInputElement | null = null;
  private rentableIdentityLabelFilterInput: HTMLInputElement | null = null;
  private amenityFilterInput: HTMLInputElement | null = null;

  handleGuestGroupFilterInputRef = (input: HTMLInputElement | null) => {
    this.guestGroupFilterInput = input;
  };

  handleRentableSegmentFilterInputRef = (input: HTMLInputElement | null) => {
    this.rentableSegmentFilterInput = input;
  };

  handleRentableTypeFilterinputRef = (input: HTMLInputElement | null) => {
    this.rentableTypeFilterinput = input;
  };

  handleRentableIdentityLabelFilterInputRef = (input: HTMLInputElement | null) => {
    this.rentableIdentityLabelFilterInput = input;
  };

  handleAmenityFilterInputRef = (input: HTMLInputElement | null) => {
    this.amenityFilterInput = input;
  };

  handleGuestGroupFilterChange = (selectedGuestGroup: { [key: string]: string }) => {
    this.props.onGuestGroupFilterChange(selectedGuestGroup);
  };

  handleRentableSegmentFilterChange = (selectedRentableSegments: Array<{ id: string }>) => {
    this.props.onRentableSegmentFilterChange(selectedRentableSegments.map((selectedRentableSegment) => selectedRentableSegment.id));
  };

  handleRentableTypeFilterChange = (selectedRentableTypes: Array<{ id: string }>) => {
    this.props.onRentableTypeFilterChange(selectedRentableTypes.map((selectedRentableType) => selectedRentableType.id));
  };

  handleRentableIdentityLabelFilterChange = (selectedRentableIdentityLabels: Array<{ id: string }>) => {
    this.props.onRentableIdentityLabelFilterChange(
      selectedRentableIdentityLabels.map((selectedRentableIdentityLabel) => selectedRentableIdentityLabel.id)
    );
  };

  handleAmenityFilterChange = (selectedAmenities: Array<{ id: string }>) => {
    this.props.onAmenityFilterChange(selectedAmenities.map((selectedAmenity) => selectedAmenity.id));
  };

  getSelectedItems = <Item extends { id: string }>(items: Item[] | null, selectedIds: string[]): Item[] => {
    return items ? items.filter((item) => selectedIds.includes(item.id)) : [];
  };

  handleGuestGroupFilterHotkey = () => {
    this.props.onHotkey();
    invoke(this.guestGroupFilterInput, 'focus');
    invoke(this.guestGroupFilterInput, 'click');
  };

  handleRentableSegmentFilterHotkey = () => {
    this.props.onHotkey();
    invoke(this.rentableSegmentFilterInput, 'focus');
    invoke(this.rentableSegmentFilterInput, 'click');
  };

  handleRentableTypeFilterHotkey = () => {
    this.props.onHotkey();
    invoke(this.rentableTypeFilterinput, 'focus');
    invoke(this.rentableTypeFilterinput, 'click');
  };

  handleRentableIdentityLabelFilterHotkey = () => {
    this.props.onHotkey();
    invoke(this.rentableIdentityLabelFilterInput, 'focus');
    invoke(this.rentableIdentityLabelFilterInput, 'click');
  };

  handleAmenitiesFilterHotkey = () => {
    this.props.onHotkey();
    invoke(this.amenityFilterInput, 'focus');
    invoke(this.amenityFilterInput, 'click');
  };

  renderGuestGroupFilter() {
    const { guestTypes, selectedGuestGroup } = this.props;

    return (
      <GuestGroupFilter
        guestTypes={guestTypes}
        selectedGuestGroup={deserializeObject(selectedGuestGroup)}
        inputRef={this.handleGuestGroupFilterInputRef}
        onChange={this.handleGuestGroupFilterChange}
      />
    );
  }

  renderRentableSegmentFilter() {
    const { rentableSegments, selectedRentableSegmentIds } = this.props;
    const selectedRentableSegments = this.getSelectedItems(rentableSegments, deserializeIds(selectedRentableSegmentIds));

    return (
      <RentableSegmentFilter
        rentableSegments={rentableSegments}
        selectedRentableSegments={selectedRentableSegments}
        inputRef={this.handleRentableSegmentFilterInputRef}
        onChange={this.handleRentableSegmentFilterChange}
      />
    );
  }

  renderRentableTypeFilter() {
    const { rentableTypes, selectedRentableTypeIds, selectedRentableSegmentIds } = this.props;

    const filteredRentableTypes = rentableTypes.filter((rentableType) => {
      // Only display rentableTypes belonging to selected rental segments
      return selectedRentableSegmentIds.length === 0 || selectedRentableSegmentIds.includes(rentableType.rentableSegmentId);
    });

    const selectedRentableTypes = this.getSelectedItems(rentableTypes, deserializeIds(selectedRentableTypeIds));

    return (
      <RentableTypeFilter
        rentableTypes={filteredRentableTypes}
        selectedRentableTypes={selectedRentableTypes}
        inputRef={this.handleRentableTypeFilterinputRef}
        onChange={this.handleRentableTypeFilterChange}
      />
    );
  }

  renderRentableIdentityLabelFilter() {
    const { rentableIdentityLabels, selectedRentableIdentityLabelIds } = this.props;
    const selectedRentableIdentityLabels = this.getSelectedItems(rentableIdentityLabels, deserializeIds(selectedRentableIdentityLabelIds));

    return (
      <RentableIdentityLabelFilter
        rentableIdentityLabels={rentableIdentityLabels}
        selectedRentableIdentityLabels={selectedRentableIdentityLabels}
        inputRef={this.handleRentableIdentityLabelFilterInputRef}
        onChange={this.handleRentableIdentityLabelFilterChange}
      />
    );
  }

  renderAmenityFilter() {
    const { amenities, selectedAmenityIds } = this.props;
    const selectedAmenities = this.getSelectedItems(amenities, deserializeIds(selectedAmenityIds));
    return (
      <AmenityFilter
        amenities={amenities}
        selectedAmenities={selectedAmenities}
        inputRef={this.handleAmenityFilterInputRef}
        onChange={this.handleAmenityFilterChange}
      />
    );
  }

  private hotkeys: HotkeyConfig[] = [
    {
      label: <Translation>{(translate) => translate('Select guest group')}</Translation>,
      group: 'Filters',
      combo: '1',
      global: true,
      onKeyDown: this.handleGuestGroupFilterHotkey,
      preventDefault: true,
    },
    {
      label: <Translation>{(translate) => translate('Select segment')}</Translation>,
      group: 'Filters',
      combo: '2',
      global: true,
      onKeyDown: this.handleRentableSegmentFilterHotkey,
      preventDefault: true,
    },
    {
      label: <Translation>{(translate) => translate('Select type')}</Translation>,
      group: 'Filters',
      combo: '3',
      global: true,
      onKeyDown: this.handleRentableTypeFilterHotkey,
      preventDefault: true,
    },
    {
      label: <Translation>{(translate) => translate('Select labels')}</Translation>,
      group: 'Filters',
      combo: '4',
      global: true,
      onKeyDown: this.handleRentableIdentityLabelFilterHotkey,
      preventDefault: true,
    },
    {
      label: <Translation>{(translate) => translate('Select amenities')}</Translation>,
      group: 'Filters',
      combo: '5',
      global: true,
      onKeyDown: this.handleAmenitiesFilterHotkey,
      preventDefault: true,
    },
  ];

  render() {
    const { t: translate, isOpen } = this.props;
    const from = { height: 0 };
    const to = { height: 'auto' };

    return (
      <Spring config={{ tension: 2000, friction: 100, precision: 1 }} to={isOpen ? to : from}>
        {(style) => (
          <animated.div className={classNames(classes.root, { [classes['root--open']]: isOpen })} style={style}>
            <HotkeysTarget2 hotkeys={this.hotkeys}>
              {({ handleKeyDown, handleKeyUp }) => (
                <div className="p-2 flex flex-wrap" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
                  <FormGroup label={translate('Guest group')} className={classes['form-group']} style={{ flexGrow: 2 }}>
                    {this.renderGuestGroupFilter()}
                  </FormGroup>
                  <FormGroup label={translate('Segment')} className={classes['form-group']} style={{ flexGrow: 2 }}>
                    {this.renderRentableSegmentFilter()}
                  </FormGroup>
                  <FormGroup label={translate('Type')} className={classes['form-group']} style={{ flexGrow: 3 }}>
                    {this.renderRentableTypeFilter()}
                  </FormGroup>
                  <FormGroup label={translate('Labels')} className={classes['form-group']} style={{ flexGrow: 2 }}>
                    {this.renderRentableIdentityLabelFilter()}
                  </FormGroup>
                  <FormGroup label={translate('Amenities')} className={classes['form-group']} style={{ flexGrow: 3 }}>
                    {this.renderAmenityFilter()}
                  </FormGroup>
                </div>
              )}
            </HotkeysTarget2>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export default withTranslation()(FilterPanel);
