import React, { PureComponent } from 'react';
import { Button, Divider, FormGroup, Popover, TagInput, NumericInput } from '@blueprintjs/core';
import { defaultPopoverProps } from '../../config/popover';
import { Translation } from 'react-i18next';
import formatGuestTypeCount from '../../utils/formatGuestTypeCount';
import { debounce, isEqual } from 'lodash';

interface GuestType {
  key: string;
  name: string;
  description: string;
  maximumLimit: number;
}

interface GuestGroup {
  [key: string]: string;
}

interface GuestGroupFilterProps {
  guestTypes: GuestType[];
  selectedGuestGroup: GuestGroup;
  inputRef?: (input: HTMLInputElement | null) => void;
  onChange: (selectedGuestGroup: { [key: string]: string }) => void;
}

interface GuestGroupFilterState {
  localGuestGroup: GuestGroup;
}

export default class GuestGroupFilter extends PureComponent<GuestGroupFilterProps, GuestGroupFilterState> {
  constructor(props: GuestGroupFilterProps) {
    super(props);
    this.state = {
      localGuestGroup: props.selectedGuestGroup,
    };
  }

  componentDidUpdate(prevProps: GuestGroupFilterProps) {
    if (!isEqual(this.props.selectedGuestGroup, prevProps.selectedGuestGroup)) {
      this.setState({ localGuestGroup: this.props.selectedGuestGroup });
    }
  }

  debouncedOnChange = debounce(this.props.onChange, 300);

  handleTagRemove = (_tag: React.ReactNode, index: number) => {
    const key = Object.keys(this.props.selectedGuestGroup)[index];
    const updatedGuestGroup = { ...this.props.selectedGuestGroup };
    delete updatedGuestGroup[key];

    this.props.onChange(updatedGuestGroup);
  };

  handleTagRemoveButtonClick = (e: React.MouseEvent, _tagProps: any) => {
    const tag = e.currentTarget.parentElement!;
    const index = +tag.getAttribute('data-tag-index')!;
    this.handleTagRemove(tag, index);

    e.stopPropagation();
  };

  handleValueChange = (valueAsNumber: number, name: string) => {
    const updatedGuestGroup = { ...this.props.selectedGuestGroup };
    const guestType = this.props.guestTypes.find((gt) => gt.key === name);

    if (guestType && valueAsNumber > guestType.maximumLimit) {
      valueAsNumber = guestType.maximumLimit;
    }

    if (valueAsNumber > 0) {
      updatedGuestGroup[name] = valueAsNumber.toString();
    } else {
      delete updatedGuestGroup[name];
    }

    this.setState({ localGuestGroup: updatedGuestGroup });
    this.debouncedOnChange(updatedGuestGroup);
  };

  handleClearButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onChange({});
  };

  renderClearButton() {
    return (
      <Translation>
        {(translate) => <Button icon="cross" small minimal title={translate('Reset filter')} onClick={this.handleClearButtonClick} />}
      </Translation>
    );
  }

  renderPopoverContent() {
    const guestTypes = this.props.guestTypes;
    const guestTypes1 = guestTypes.filter((guestType) => guestType.key !== 'pets');
    const guestTypes2 = guestTypes.filter((guestType) => guestType.key === 'pets');

    return (
      <div className="p-3 grid gap-3">
        {guestTypes1.map((guestType) => this.renderFormGroup(guestType))}
        <Divider className="m-0" />
        {guestTypes2.map((guestType) => this.renderFormGroup(guestType))}
      </div>
    );
  }

  renderFormGroup(guestType: GuestType) {
    const id = `guest-type-${guestType.key}`;
    const value = this.state.localGuestGroup[guestType.key] || 0;

    return (
      <FormGroup
        className="mb-0"
        key={guestType.key}
        labelInfo={guestType.description}
        label={guestType.name}
        labelFor={id}
        inline
        contentClassName="ml-auto w-20 input input--small"
      >
        <NumericInput
          fill
          id={id}
          value={value}
          min={0}
          max={guestType.maximumLimit}
          name={guestType.key}
          onValueChange={(valueAsNumber) => this.handleValueChange(valueAsNumber, guestType.key)}
        />
      </FormGroup>
    );
  }

  render() {
    const { inputRef } = this.props;

    const values = Object.entries(this.state.localGuestGroup).map(([key, value]) => {
      return formatGuestTypeCount(key, parseInt(value, 10));
    });

    const rightElement = values.length > 0 ? this.renderClearButton() : undefined;

    return (
      <Popover {...defaultPopoverProps} position="bottom-left" content={this.renderPopoverContent()}>
        <Translation>
          {(translate) => (
            <TagInput
              placeholder={translate('Filter by guest group…')}
              values={values}
              tagProps={{ minimal: true, onRemove: this.handleTagRemoveButtonClick }}
              inputProps={{ readOnly: true, className: 'w-0' }}
              rightElement={rightElement}
              onRemove={this.handleTagRemove}
              inputRef={inputRef}
            />
          )}
        </Translation>
      </Popover>
    );
  }
}
