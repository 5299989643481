import React, { PureComponent } from 'react';
import { Menu, MenuDivider } from '@blueprintjs/core';
import ParkItem from './ParkItem';
import filterPark from '../../utils/filterPark';
import { OrganizationData } from './OrganizationListQueryData';

interface OrganizationItemProps {
  organization: OrganizationData;
  starredParkIds: string[];
  query: string;
  onParkItemClick: (parkId: string) => void;
  onStarButtonClick: (parId: string, isStarred: boolean) => void;
}

export default class OrganizationItem extends PureComponent<OrganizationItemProps> {
  get filteredParks() {
    let { organization, query } = this.props;
    return organization.parks.filter((park) => filterPark(query, park));
  }

  renderHeader() {
    let { organization } = this.props;
    return <MenuDivider title={organization.name} />;
  }

  renderFilteredParks() {
    let { starredParkIds, onParkItemClick, onStarButtonClick } = this.props;

    return this.filteredParks.map((park) => (
      <ParkItem
        key={park.id}
        park={park}
        isStarred={starredParkIds.includes(park.id)}
        onClick={onParkItemClick}
        onStarButtonClick={onStarButtonClick}
      />
    ));
  }

  renderDivider() {
    return <MenuDivider />;
  }

  render() {
    return (
      <Menu className="p-0">
        {this.renderHeader()}
        {this.renderFilteredParks()}
        {this.renderDivider()}
      </Menu>
    );
  }
}
