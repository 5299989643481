import React from 'react';
import formatBlockedByGroupedRentableTypeAgendaPeriod from '../../utils/formatBlockedByGroupedRentableTypeAgendaPeriod';
import BlockedByGroupedRentableTypeAgendaPeriodBadge from '../blocked-by-grouped-rentable-type-agenda-period-badge';
import { AgendaPeriodEventPopover } from '../agenda-period-event-popover';

interface BlockedByGroupedRentableTypeAgendaPeriodEventPopoverProps {
  blockedByGroupedRentableTypeAgendaPeriod: {
    startDate: string;
    endDate: string;
    blockingReservation: {
      booking: {
        referenceNr: string;
        bookingNr: string;
        firstName: string;
        lastName: string;
        company: string;
      };
    };
  };
}

export default function BlockedByGroupedRentableTypeAgendaPeriodEventPopover({
  blockedByGroupedRentableTypeAgendaPeriod,
}: BlockedByGroupedRentableTypeAgendaPeriodEventPopoverProps) {
  return (
    <AgendaPeriodEventPopover
      title={formatBlockedByGroupedRentableTypeAgendaPeriod(blockedByGroupedRentableTypeAgendaPeriod)}
      startDate={blockedByGroupedRentableTypeAgendaPeriod.startDate}
      endDate={blockedByGroupedRentableTypeAgendaPeriod.endDate}
      renderBadge={() => <BlockedByGroupedRentableTypeAgendaPeriodBadge />}
    />
  );
}
