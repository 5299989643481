import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult, MutationFunction } from '@apollo/client/react';
import { loader } from 'graphql.macro';
const undoCheckOutReservationMutation = loader('./undoCheckOutReservationMutation.graphql');

interface UndoCheckOutReservationVariables {
  reservationId: string;
}

export interface UndoCheckOutReservationData {
  undoCheckOutReservation: {
    id: string;
    reservationAgendaPeriods: { id: string }[];
  };
}

export type UndoCheckOutReservationFn = MutationFunction<UndoCheckOutReservationData, UndoCheckOutReservationVariables>;

export type Result = MutationResult<UndoCheckOutReservationData>;

interface UndoCheckOutReservationMutationProps {
  children: (undoCheckOutReservation: UndoCheckOutReservationFn, result: Result) => JSX.Element | null;
}

const REFETCH_QUERIES = ['rentableIdentityCellsQuery', 'rentableCellsQuery'];

export default function UndoCheckOutReservationMutation({ children }: UndoCheckOutReservationMutationProps) {
  return (
    <Mutation<UndoCheckOutReservationData, UndoCheckOutReservationVariables>
      mutation={undoCheckOutReservationMutation}
      refetchQueries={REFETCH_QUERIES}
      awaitRefetchQueries
    >
      {children}
    </Mutation>
  );
}
