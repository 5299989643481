import React from 'react';
import { Button } from '@blueprintjs/core';

interface ResetButtonProps {
  onClick: () => void;
}

export default function ResetButton({ onClick }: ResetButtonProps) {
  return <Button minimal icon="cross" onClick={onClick} />;
}
