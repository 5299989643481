import React, { Component } from 'react';
import { FormGroup, Radio, RadioGroup, Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import SettingsDialogQueryData, { Layout } from './SettingsDialogQueryData';
import { UpdateSettingsFn } from './UpdateSettingsMutation';
import { showSuccessToast, showApolloErrorToast } from '../toaster';
import LoadingButton from '../loading-button';
import DateInput from '../date-input';
import serializeDate from '../../utils/serializeDate';
import Translation from '../translation';
import { changeLanguage } from '../../config/i18n';
import { ApolloError } from '@apollo/client';

interface SettingsDialogProps {
  isOpen: boolean;
  isSaving: boolean;
  data: SettingsDialogQueryData;
  updateSettings: UpdateSettingsFn;
  onClose: () => void;
}

interface SettingsDialogState {
  locale: string;
  startDate: string | null;
  dateInputValue: Date;
  layout: Layout;
}

enum StartDate {
  Today = 'today',
  Custom = 'custom',
}

export default class SettingsDialog extends Component<SettingsDialogProps, SettingsDialogState> {
  private initialState: SettingsDialogState;

  constructor(props: SettingsDialogProps) {
    super(props);

    const { locale } = this.props.data.user;
    const { planboardStartDate: startDate } = this.props.data.user;
    const { planboardLayout: layout } = this.props.data.user;

    const dateInputValue = startDate === null ? new Date() : new Date(startDate);

    this.initialState = { locale, startDate, dateInputValue, layout: layout !== Layout.Flat ? layout : Layout.OrderByType };
    this.state = this.initialState;
  }

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { updateSettings, onClose } = this.props;
    const { locale, startDate, layout } = this.state;
    const variables = { locale, startDate, layout };

    try {
      await updateSettings({ variables });
      const message = <Translation>{(translate) => translate('Settings have been applied')}</Translation>;
      showSuccessToast({ message });
      changeLanguage(locale);
      onClose();
    } catch (e) {
      if (e instanceof ApolloError) {
        showApolloErrorToast(e);
      } else {
        throw e;
      }
    }
  };

  handleClose = () => {
    this.setState(this.initialState, () => this.props.onClose());
  };

  handleLocaleChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ locale: e.currentTarget.value });
  };

  handleStartDateChange = (e: React.FormEvent<HTMLInputElement>) => {
    switch (e.currentTarget.value) {
      case StartDate.Today:
        return this.setState({ startDate: null });
      case StartDate.Custom:
        return this.setState(({ dateInputValue }) => ({ startDate: serializeDate(dateInputValue) }));
    }
  };

  handleDateInputChange = (selectedDate: Date) => {
    this.setState({ startDate: serializeDate(selectedDate), dateInputValue: selectedDate });
  };

  handleLayoutChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ layout: e.currentTarget.value as Layout });
  };

  renderLocaleFormGroup() {
    const { locale } = this.state;

    return (
      <Translation>
        {(translate) => (
          <FormGroup label={<b>{translate('Language')}</b>}>
            <RadioGroup inline selectedValue={locale} className="flex" onChange={this.handleLocaleChange}>
              <Radio readOnly label={translate('Dutch')} value="nl" />
              <Radio readOnly label={translate('English')} value="en" />
              <Radio readOnly label={translate('German')} value="de" />
              {window.location.href.includes('staging') && <Radio readOnly label={translate('French')} value="fr" />}
            </RadioGroup>
          </FormGroup>
        )}
      </Translation>
    );
  }

  renderStartDateFormGroup() {
    const { startDate, dateInputValue } = this.state;

    return (
      <Translation>
        {(translate) => (
          <FormGroup helperText={translate('Scroll to this date when opening the planboard')} label={<b>{translate('Start date')}</b>}>
            <RadioGroup
              inline
              selectedValue={startDate === null ? StartDate.Today : StartDate.Custom}
              className="flex"
              onChange={this.handleStartDateChange}
            >
              <Radio readOnly label={translate('Today')} value={StartDate.Today} />
              <Radio readOnly label={translate('Date')} value={StartDate.Custom} />
              <DateInput value={dateInputValue} onChange={this.handleDateInputChange} />
            </RadioGroup>
          </FormGroup>
        )}
      </Translation>
    );
  }

  renderLayoutFormGroup() {
    const { layout } = this.state;

    return (
      <Translation>
        {(translate) => (
          <FormGroup label={<b>{translate('Layout')}</b>}>
            <RadioGroup selectedValue={layout} onChange={this.handleLayoutChange}>
              <Radio readOnly label={translate('Grouped by type')} value={Layout.Nested} />
              <Radio readOnly label={translate('Sort on object (object name - type reference - type name)')} value={Layout.OrderByObject} />
              <Radio readOnly label={translate('Sort on type (type reference - type name - object name)')} value={Layout.OrderByType} />
            </RadioGroup>
          </FormGroup>
        )}
      </Translation>
    );
  }

  renderBody() {
    return (
      <div className={Classes.DIALOG_BODY}>
        <div className="space-between-8">
          {this.renderLocaleFormGroup()}
          {this.renderStartDateFormGroup()}
          {this.renderLayoutFormGroup()}
        </div>
      </div>
    );
  }

  renderFooter() {
    const { isSaving } = this.props;

    return (
      <Translation>
        {(translate) => (
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button type="button" onClick={this.handleClose}>
                {translate('Cancel')}
              </Button>

              <LoadingButton type="submit" isLoading={isSaving} intent={Intent.PRIMARY}>
                {translate('Apply')}
              </LoadingButton>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Translation>
        {(translate) => (
          <Dialog title={translate('Settings')} isOpen={isOpen} onClose={this.handleClose} style={{ width: 560 }}>
            <form onSubmit={this.handleSubmit}>
              {this.renderBody()}
              {this.renderFooter()}
            </form>
          </Dialog>
        )}
      </Translation>
    );
  }
}
