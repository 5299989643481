import React, { PureComponent } from 'react';
import classes from './PeriodHeader.module.css';
import { DateRange } from 'moment-range';
import moment, { Moment } from 'moment';
import momentRange from '../../utils/momentRange';
import WeekHeader from './WeekHeader';
import TodayWeekDate from './TodayWeekDate';
import HolidayWeekDate from './HolidayWeekDate';
import DefaultWeekDate from './DefaultWeekDate';
import serializeDate from '../../utils/serializeDate';
import AvailabilityFilterWeekDate from './AvailabilityFilterWeekDate';
import { TODAY } from '../../config/planboard';
import { CELL_WIDTH } from '../grid';

interface PeriodHeaderProps {
  period: DateRange;
  holidays: Array<{ name: string; startDate: string; endDate: string }>;
  availabilityFilterHoverStartDate?: string;
  availabilityFilterHoverEndDate?: string;
  availabilityFilterStartDate?: string;
  availabilityFilterEndDate?: string;
  onWeekHeaderMouseDown: (e: React.MouseEvent) => void;
  onWeekDatesMouseMove: (e: React.MouseEvent, hoverDate: Moment) => void;
  onWeekDatesMouseLeave: (e: React.MouseEvent) => void;
  onAvailabilityFilterStartDateClick: (e: React.MouseEvent, date: Moment) => void;
  onAvailabilityFilterEndDateClick: (e: React.MouseEvent, date: Moment) => void;
}

export default class PeriodHeader extends PureComponent<PeriodHeaderProps> {
  handleWeekDatesMouseMove = (e: React.MouseEvent) => {
    const { onWeekDatesMouseMove } = this.props;

    const weekDatesElement = e.currentTarget;

    // @ts-ignore: Property 'dataset' does not exist
    const startDate = moment(weekDatesElement.dataset.date);

    const boundingClientRect = weekDatesElement.getBoundingClientRect();
    const daysOffset = Math.floor((e.clientX - boundingClientRect.left) / CELL_WIDTH);

    const hoverDate = startDate.clone().add(daysOffset, 'days');

    onWeekDatesMouseMove(e, hoverDate);
  };

  handleWeekDatesMouseLeave = (e: React.MouseEvent) => {
    const { onWeekDatesMouseLeave } = this.props;
    onWeekDatesMouseLeave(e);
  };

  renderWeekHeader(week: DateRange) {
    const { onWeekHeaderMouseDown } = this.props;
    return <WeekHeader startDate={serializeDate(week.start)} endDate={serializeDate(week.end)} onMouseDown={onWeekHeaderMouseDown} />;
  }

  renderWeekDates(week: DateRange) {
    return (
      <nav
        className={classes['week-dates']}
        data-date={serializeDate(week.start)}
        onMouseMove={this.handleWeekDatesMouseMove}
        onMouseLeave={this.handleWeekDatesMouseLeave}
      >
        {Array.from(week.by('day')).map((date) => this.renderWeekDate(date))}
      </nav>
    );
  }

  renderWeekDate(date: Moment) {
    const {
      holidays,
      availabilityFilterHoverStartDate,
      availabilityFilterHoverEndDate,
      availabilityFilterStartDate,
      availabilityFilterEndDate,
      onAvailabilityFilterStartDateClick,
      onAvailabilityFilterEndDateClick,
    } = this.props;

    const isAvailabilityFilterStartDate = availabilityFilterStartDate && date.isSame(availabilityFilterStartDate, 'date');
    const isAvailabilityFilterEndDate = availabilityFilterEndDate && date.isSame(availabilityFilterEndDate, 'date');

    const isAvailabilityFilterHoverStartDate = availabilityFilterHoverStartDate && date.isSame(availabilityFilterHoverStartDate, 'date');
    const isAvailabilityFilterHoverEndDate = availabilityFilterHoverEndDate && date.isSame(availabilityFilterHoverEndDate, 'date');

    const isToday = date.isSame(TODAY, 'date');
    const holiday = holidays.find(({ startDate: holidayStartDate, endDate: holidayEndDate }) =>
      date.isBetween(holidayStartDate, holidayEndDate, 'day', '[)')
    );

    const weekDateProps = {
      date: serializeDate(date),
    };

    if (isAvailabilityFilterStartDate || isAvailabilityFilterHoverStartDate) {
      const isActive = isAvailabilityFilterStartDate !== undefined;
      return (
        <AvailabilityFilterWeekDate
          key={date.toString()}
          holiday={holiday}
          isActive={isActive}
          onClick={onAvailabilityFilterStartDateClick}
          {...weekDateProps}
        />
      );
    } else if (isAvailabilityFilterEndDate || isAvailabilityFilterHoverEndDate) {
      const isActive = isAvailabilityFilterEndDate !== undefined;
      return (
        <AvailabilityFilterWeekDate
          key={date.toString()}
          holiday={holiday}
          isActive={isActive}
          onClick={onAvailabilityFilterEndDateClick}
          {...weekDateProps}
        />
      );
    } else if (isToday) {
      return <TodayWeekDate key={date.toString()} {...weekDateProps} />;
    } else if (holiday) {
      return <HolidayWeekDate key={date.toString()} holiday={holiday} {...weekDateProps} />;
    } else {
      return <DefaultWeekDate key={date.toString()} {...weekDateProps} />;
    }
  }

  renderWeeks() {
    const { period } = this.props;

    return Array.from(period.by('week')).map((date) => (
      <section key={date.toString()} className={classes.week}>
        {this.renderWeekHeader(momentRange(date.clone().startOf('isoWeek'), date.clone().endOf('isoWeek')))}
        {this.renderWeekDates(momentRange(date.clone().startOf('isoWeek'), date.clone().endOf('isoWeek')))}
      </section>
    ));
  }

  render() {
    return <header className={classes.root}>{this.renderWeeks()}</header>;
  }
}
