import React from 'react';
import OwnerMaintenanceAgendaPeriodEventPopover from '../owner-maintenance-agenda-period-event-popover';
import formatOwnerMaintenanceAgendaPeriod from '../../utils/formatOwnerMaintenanceAgendaPeriod';
import AgendaPeriodEventItem from '../agenda-period-event-item/AgendaPeriodEventItem';
import Translation from '../translation';

interface OwnerMaintenanceAgendaPeriodEventItemProps {
  ownerMaintenanceAgendaPeriod: {
    memo: string;
    startDate: string;
    endDate: string;
  };
  isActive: boolean;
  isIndented: boolean;
}

export default function OwnerMaintenanceAgendaPeriodEventItem({
  ownerMaintenanceAgendaPeriod,
  isActive,
  isIndented,
}: OwnerMaintenanceAgendaPeriodEventItemProps) {
  return (
    <Translation>
      {(translate) => (
        <AgendaPeriodEventItem
          title={formatOwnerMaintenanceAgendaPeriod(ownerMaintenanceAgendaPeriod, translate)}
          renderPopover={() => <OwnerMaintenanceAgendaPeriodEventPopover ownerMaintenanceAgendaPeriod={ownerMaintenanceAgendaPeriod} />}
          className="owner-maintenance-agenda-period"
          isActive={isActive}
          isIndented={isIndented}
        />
      )}
    </Translation>
  );
}
