import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Classes } from '@blueprintjs/core';
import Translation from '../translation';

interface RentableIdentitySidebarPlaceholderProps {
  onCloseButtonClick: () => void;
}

export default class RentableIdentitySidebarPlaceholder extends Component<RentableIdentitySidebarPlaceholderProps> {
  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar>
            <Sidebar.Header href="" title={translate('Loading rental…')} onCloseButtonClick={this.props.onCloseButtonClick} />
            <Sidebar.Content>
              <Sidebar.Section title={translate('Summary')}>
                <Sidebar.Table>
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Nummer</span>}
                    data={<span className={Classes.SKELETON}>81</span>}
                  />
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Accommodatietype</span>}
                    data={<span className={Classes.SKELETON}>Bungalow Deluxe max. 6 pers.</span>}
                  />
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Verhuur segment</span>}
                    data={<span className={Classes.SKELETON}>Vakantiehuis</span>}
                  />
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Prioriteit</span>}
                    data={<span className={Classes.SKELETON}>Laag</span>}
                  />
                </Sidebar.Table>
              </Sidebar.Section>

              <Sidebar.Section title={translate('Miscellaneous')}>
                <Sidebar.Table>
                  <Sidebar.TableRow
                    icon={<span className={Classes.SKELETON}>....</span>}
                    header={<span className={Classes.SKELETON}>Huisdieren toegestaan?</span>}
                    data={<span className={Classes.SKELETON}>Toegestaan</span>}
                  />
                  <Sidebar.TableRow
                    icon={<span className={Classes.SKELETON}>....</span>}
                    header={<span className={Classes.SKELETON}>Accepteert externe reserveringen?</span>}
                    data={<span className={Classes.SKELETON}>Ja</span>}
                  />
                </Sidebar.Table>
              </Sidebar.Section>

              <Sidebar.Section title={translate('Labels')}>
                <span className={Classes.SKELETON}>Geen labels</span>
              </Sidebar.Section>

              <Sidebar.Section title={translate('Setup')}>
                <span className={Classes.SKELETON}>Deze accommodatie wordt verhuurd sinds 01-01-2018.</span>
              </Sidebar.Section>
            </Sidebar.Content>
          </Sidebar>
        )}
      </Translation>
    );
  }
}
