import React, { PureComponent, CSSProperties } from 'react';
import EventLink from '../event-link';
import ReservationAgendaPeriodEventItem from '../reservation-agenda-period-event-item';
import { DisplayMode } from '../../actions/displayMode';
import { SidebarType } from '../../actions/sidebarPanel';

interface ReservationAgendaPeriodEventLinkProps {
  reservationAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;

    reservation: {
      id: string;
      startDate: string;
      endDate: string;
      departureDate: string;
      lateCheckout: boolean;
      fixedRentable: boolean;
      url: string;

      booking: {
        id: string;
        referenceNr: string;
        source: string;
        isOption: boolean;
        stateDescription: string;
        bookingNr: string;
        firstName: string;
        lastName: string;
        company: string;

        channel: {
          name: string;
        };

        // Alerts display mode
        hasUninvoicedAmountForGuest?: boolean;
        isConfirmed?: boolean;
        isTemporary?: boolean;
        hasUnpaidAmountForGuest?: boolean;
        hasPastDeadlineAmountForGuest?: boolean;
      };

      // Alerts display mode
      hasUnfinishedTodos?: boolean;

      // Presence display mode
      checkedInOn?: string;
      checkedOutOn?: string;
      isCheckedIn?: boolean;
      isCheckedOut?: boolean;
    };
  };
  style: CSSProperties;
}

export default class ReservationAgendaPeriodEventLink extends PureComponent<ReservationAgendaPeriodEventLinkProps> {
  renderItem = ({ isActive, isIndented, displayMode }: { isActive: boolean; isIndented: boolean; displayMode: DisplayMode }) => (
    <ReservationAgendaPeriodEventItem
      reservationAgendaPeriod={this.props.reservationAgendaPeriod}
      isActive={isActive}
      isIndented={isIndented}
      displayMode={displayMode}
    />
  );

  render() {
    return (
      <EventLink
        url={this.props.reservationAgendaPeriod.reservation.url}
        style={this.props.style}
        sidebarType={SidebarType.ReservationAgendaPeriod}
        sidebarId={this.props.reservationAgendaPeriod.id}
        renderItem={this.renderItem}
      />
    );
  }
}
