import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './translations.json';
import mapValues from 'lodash/mapValues';
import detectDefaultLocale from './detectDefaultLocale';
import formatValue from './formatValue';
import { polyfill } from 'es6-object-assign';

export type TranslationKey = keyof typeof translations;
export { detectDefaultLocale };

// IE11 polyfill (to display <UnsupportedBrowserNotice />) because this code is executed onload
polyfill();

const nl = { translation: mapValues(translations, (value, _key) => value.nl) };
const de = { translation: mapValues(translations, (value, _key) => value.de) };
const fr = { translation: mapValues(translations, (value, _key) => value.fr) };
const en = { translation: mapValues(translations, (_value, key) => key) };

const defaultLocale = detectDefaultLocale();

const options = {
  resources: { nl, en, de, fr },
  lng: defaultLocale,
  nsSeparator: false as false,
  keySeparator: false as false,
  interpolation: { escapeValue: false, format: formatValue },
};

export const changeLanguage = (locale: string) => {
  i18n.changeLanguage(locale);
};

export default i18n.use(initReactI18next).init(options);
