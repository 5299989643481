import React from 'react';
import EventItem from '../event-item';

interface AgendaPeriodEventItemProps {
  title: string;
  className: string;
  isActive: boolean;
  isIndented: boolean;
  renderPopover: () => React.ReactNode;
}

export default function AgendaPeriodEventItem({ title, className, isActive, isIndented, renderPopover }: AgendaPeriodEventItemProps) {
  return (
    <EventItem
      title={title}
      className={className}
      isActive={isActive}
      isIndented={isIndented}
      isBooking={false}
      renderPopover={renderPopover}
    />
  );
}
