import React from 'react';
import { Icon } from '@blueprintjs/core';
import LoadingButton from '../loading-button';
import Translation from '../translation';

interface UndoButtonProps {
  isUndoingCheckIn: boolean;
  [additionalProp: string]: any;
}

export default function UndoButton({ isUndoingCheckIn, ...props }: UndoButtonProps) {
  return (
    <LoadingButton isLoading={isUndoingCheckIn} icon={<Icon icon="undo" iconSize={12} />} {...props}>
      <Translation>{(translate) => translate('Undo')}</Translation>
    </LoadingButton>
  );
}
