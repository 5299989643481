import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult, MutationFunction } from '@apollo/client/react';
import { loader } from 'graphql.macro';
const checkOutReservationMutation = loader('./checkOutReservationMutation.graphql');

interface CheckOutReservationVariables {
  reservationId: string;
  checkoutDate: string;
  checkoutHour: string;
  checkoutMinute: string;
}

export interface CheckOutReservationData {
  checkOutReservation: {
    id: string;
    reservationAgendaPeriods: { id: string }[];
  };
}

export type CheckOutReservationFn = MutationFunction<CheckOutReservationData, CheckOutReservationVariables>;
export type Result = MutationResult<CheckOutReservationData>;

interface CheckOutReservationMutationProps {
  children: (checkOutReservation: CheckOutReservationFn, result: Result) => JSX.Element | null;
}

const REFETCH_QUERIES = () => ['rentableIdentityCellsQuery', 'rentableCellsQuery'];

export default function CheckOutReservationMutation({ children }: CheckOutReservationMutationProps) {
  return (
    <Mutation<CheckOutReservationData, CheckOutReservationVariables>
      mutation={checkOutReservationMutation}
      refetchQueries={REFETCH_QUERIES}
      awaitRefetchQueries
    >
      {children}
    </Mutation>
  );
}
