import React, { PureComponent } from 'react';
import { Button, InputGroup, ButtonGroup, HotkeysTarget2 } from '@blueprintjs/core';
import Translation, { withTranslation, WithTranslation } from '../translation';
import { SearchQuery } from '../../actions/searchQuery';
import invoke from 'lodash/invoke';

interface SearchQueryFormProps {
  searchQuery: SearchQuery;
  onSubmit: (searchQuery: SearchQuery) => void;
}

interface SearchQueryFormState {
  searchQuery: SearchQuery;
}

class SearchQueryForm extends PureComponent<SearchQueryFormProps & WithTranslation, SearchQueryFormState> {
  state = { searchQuery: this.props.searchQuery };
  private input: HTMLInputElement | null = null;

  renderSearchButton() {
    return <Translation>{(translate) => <Button type="submit" minimal icon="search" title={translate('Search')} />}</Translation>;
  }

  handleSearchQueryInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.currentTarget.value });
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.props.onSubmit(this.state.searchQuery);
  };

  handleResetButtonClick = () => {
    this.setState({ searchQuery: '' }, () => this.props.onSubmit(''));
  };

  handleInputRef = (input: HTMLInputElement | null) => {
    this.input = input;
  };

  handleSearchQueryInputHotkey = () => {
    invoke(this.input, 'focus');
  };

  handleSearchQueryInputKeyDown = (e: React.KeyboardEvent) => {
    invoke(e.which === 27 && this.input, 'blur');
  };

  private hotkeys = [
    {
      label: <Translation>{(translate) => translate('Search rental')}</Translation>,
      combo: 'z',
      global: true,
      preventDefault: true,
      onKeyDown: this.handleSearchQueryInputHotkey,
    },
  ];

  render() {
    const { t: translate } = this.props;
    const { searchQuery } = this.state;

    return (
      <HotkeysTarget2 hotkeys={this.hotkeys}>
        {({ handleKeyDown, handleKeyUp }) => (
          <form onSubmit={this.handleSubmit} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
            <ButtonGroup fill>
              <InputGroup
                inputRef={this.handleInputRef}
                fill
                value={searchQuery}
                onChange={this.handleSearchQueryInputChange}
                onKeyDown={this.handleSearchQueryInputKeyDown}
                placeholder={translate('Search by rental name…')}
                rightElement={this.renderSearchButton()}
              />
              <Button
                icon="cross"
                disabled={!searchQuery && !this.props.searchQuery}
                className="-ml-px"
                title={translate('Reset search query')}
                onClick={this.handleResetButtonClick}
              />
            </ButtonGroup>
          </form>
        )}
      </HotkeysTarget2>
    );
  }
}

export default withTranslation()(SearchQueryForm);
