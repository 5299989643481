import React, { Component } from 'react';
import Sidebar from '../sidebar';
import ReservationLink from '../reservation-link';
import { Callout, Intent, Icon } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import formatAmount, { CurrencyUnit } from '../../utils/formatAmount';
import { PastDeadlineGuestInvoice, PastDeadlinePaymentCollector, GuestInvoice } from './ReservationAgendaPeriodSidebarQueryData';
import { Translation, Trans } from 'react-i18next';

interface PaymentStatusSectionProps {
  reservation: {
    id: string;
    url: string;
    booking: {
      bookingNr: string;
      hasUninvoicedAmountForGuest: boolean;
      isConfirmed: boolean;
      isTemporary: boolean;
      foreignCurrency: CurrencyUnit;
      guestInvoices: GuestInvoice[];
      pastDeadlineGuestInvoices: PastDeadlineGuestInvoice[];
      grandTotalGuest: number;
      totalPaidGuest: number;
    };
  };
}

export default class PaymentStatusSection extends Component<PaymentStatusSectionProps> {
  renderPastDeadlinePaymentCollectorWarning({ id, deadline, foreignTotal }: PastDeadlinePaymentCollector) {
    const { foreignCurrency } = this.props.reservation.booking;
    const formattedAmount = formatAmount(parseFloat(foreignTotal), foreignCurrency);

    return (
      <Translation key={id}>
        {(translate) => (
          <Callout intent={Intent.DANGER} icon={null} className="flex">
            <Icon icon="time" iconSize={11} className="pt-1 mr-2" />
            <main>
              {translate('A payment of {{formattedAmount}} should have been received by {{deadline, date | L}}', {
                formattedAmount,
                deadline,
              })}
            </main>
          </Callout>
        )}
      </Translation>
    );
  }

  renderPastDeadlineInvoiceWarning({ id, pastDeadlinePaymentCollectors }: PastDeadlineGuestInvoice) {
    return (
      <React.Fragment key={id}>
        {pastDeadlinePaymentCollectors.map(this.renderPastDeadlinePaymentCollectorWarning.bind(this))}
      </React.Fragment>
    );
  }

  renderPastDeadlineInvoicesWarnings() {
    const { pastDeadlineGuestInvoices } = this.props.reservation.booking;
    return <React.Fragment>{pastDeadlineGuestInvoices.map(this.renderPastDeadlineInvoiceWarning.bind(this))}</React.Fragment>;
  }

  renderUnconfirmedChangeNotice() {
    return (
      <Callout intent={Intent.WARNING} icon={null} className="flex">
        <FontAwesomeIcon icon={faPaperPlane} className="pt-1 mr-2 text-bp4-orange2" />
        <main>
          <Trans>
            A change must be confirmed first: <ReservationLink reservation={this.props.reservation} />
          </Trans>
        </main>
      </Callout>
    );
  }

  renderTemporaryReservationNotice() {
    return (
      <Translation>
        {(translate) => (
          <Callout intent={Intent.WARNING} icon={null} className="flex">
            <FontAwesomeIcon icon={faSpinner} className="pt-1 mr-2 text-bp4-orange2" />
            <main>{translate('The guest is busy creating this reservation')}</main>
          </Callout>
        )}
      </Translation>
    );
  }

  renderUnconfirmedReservationNotice() {
    return (
      <Callout intent={Intent.WARNING} icon={null} className="flex">
        <FontAwesomeIcon icon={faPaperPlane} className="pt-1 mr-2 text-bp4-orange2" />
        <main>
          <Trans>
            The reservation must be confirmed first: <ReservationLink reservation={this.props.reservation} />
          </Trans>
        </main>
      </Callout>
    );
  }

  renderUnpaidCustomerInvoicesNotices() {
    const { foreignCurrency, grandTotalGuest, totalPaidGuest } = this.props.reservation.booking;
    const formattedAmount = formatAmount(grandTotalGuest - totalPaidGuest, foreignCurrency);

    return (
      <Translation>
        {(translate) => (
          <Callout intent={Intent.WARNING} icon={null} className="flex">
            <Icon icon="euro" iconSize={11} className="pt-1 mr-2" />
            <main>{translate('The guest has an outstanding amount of {{formattedAmount}}', { formattedAmount })}</main>
          </Callout>
        )}
      </Translation>
    );
  }

  renderFullyPaidNotice() {
    const { foreignCurrency, grandTotalGuest } = this.props.reservation.booking;
    const formattedAmount = grandTotalGuest > 0 ? `(${formatAmount(grandTotalGuest, foreignCurrency)})` : '';

    return (
      <Translation>
        {(translate) => (
          <Callout intent={Intent.SUCCESS} icon={null} className="flex">
            <Icon icon="euro" iconSize={11} className="pt-1 mr-2" />
            <main>{translate('The reservation has been fully paid {{formattedAmount}}', { formattedAmount })}</main>
          </Callout>
        )}
      </Translation>
    );
  }

  renderNoCustomerOrderNotice() {
    return (
      <Translation>
        {(translate) => (
          <Callout intent={Intent.SUCCESS} icon={null} className="flex">
            <main>{translate('There is no guest order')}</main>
          </Callout>
        )}
      </Translation>
    );
  }

  render() {
    const { hasUninvoicedAmountForGuest, isConfirmed, isTemporary, pastDeadlineGuestInvoices, totalPaidGuest, grandTotalGuest } =
      this.props.reservation.booking;

    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Payment status')}>
            <div className="space-between-2">
              {hasUninvoicedAmountForGuest && isConfirmed && this.renderUnconfirmedChangeNotice()}
              {isTemporary && this.renderTemporaryReservationNotice()}
              {!isConfirmed && !isTemporary && this.renderUnconfirmedReservationNotice()}
              {!hasUninvoicedAmountForGuest && pastDeadlineGuestInvoices.length > 0 && this.renderPastDeadlineInvoicesWarnings()}
              {!hasUninvoicedAmountForGuest &&
                grandTotalGuest === 0 &&
                grandTotalGuest === totalPaidGuest &&
                this.renderNoCustomerOrderNotice()}
              {!hasUninvoicedAmountForGuest && grandTotalGuest > totalPaidGuest && this.renderUnpaidCustomerInvoicesNotices()}
              {!hasUninvoicedAmountForGuest && grandTotalGuest > 0 && grandTotalGuest <= totalPaidGuest && this.renderFullyPaidNotice()}
            </div>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}
