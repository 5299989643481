import React from 'react';
import { Subscription } from '@apollo/client/react/components';
import { loader } from 'graphql.macro';
import CheckinForm from './CheckinForm';
import CheckinFormQuery from './CheckinFormQuery';
import isEmpty from 'lodash/isEmpty';
import CheckInReservationMutation from './CheckInReservationMutation';
import UndoCheckInReservationMutation from './UndoCheckInReservationMutation';
const reservationUnfinishedTodosUpdatedSubscription = loader('./reservationUnfinishedTodosUpdatedSubscription.graphql');
const rentableIdentityBlockingTodosUpdatedSubscription = loader('./rentableIdentityBlockingTodosUpdatedSubscription.graphql');

interface CheckinFormContainerProps {
  reservationId: string;
}

export default function CheckinFormContainer({ reservationId }: CheckinFormContainerProps) {
  return (
    <CheckinFormQuery reservationId={reservationId}>
      {({ data, loading }) => {
        if (isEmpty(data) || data === undefined || loading) {
          return null;
        }

        return (
          <Subscription subscription={reservationUnfinishedTodosUpdatedSubscription} variables={{ reservationId }}>
            {() => (
              <Subscription
                subscription={rentableIdentityBlockingTodosUpdatedSubscription}
                variables={{ rentableIdentityId: data.reservation.rentableIdentity.id }}
              >
                {() => (
                  <CheckInReservationMutation>
                    {(checkIn, { loading: isCheckingIn }) => (
                      <UndoCheckInReservationMutation>
                        {(undoCheckIn, { loading: isUndoingCheckIn }) => (
                          <CheckinForm
                            data={data}
                            checkIn={checkIn}
                            undoCheckIn={undoCheckIn}
                            isCheckingIn={isCheckingIn}
                            isUndoingCheckIn={isUndoingCheckIn}
                          />
                        )}
                      </UndoCheckInReservationMutation>
                    )}
                  </CheckInReservationMutation>
                )}
              </Subscription>
            )}
          </Subscription>
        );
      }}
    </CheckinFormQuery>
  );
}
