import React, { PureComponent } from 'react';
import RentableCellsQuery from './RentableCellsQuery';
import { DateRange } from 'moment-range';
import isEmpty from 'lodash/isEmpty';
import { MapStateToProps, connect } from 'react-redux';
import { DisplayMode } from '../../actions/displayMode';
import { State } from '../../reducers';
import { ErrorMessage, Placeholder } from '../rentable-identity-cells';
import RentableRentableIdentityCell from './RentableRentableIdentityCell';

interface OwnProps {
  ids: string[];
  parkId: string;
  period: DateRange;
  cacheOnly: boolean;
}

interface StateProps {
  displayMode: DisplayMode;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state) => {
  return { displayMode: state.displayMode };
};

type RentableCellsContainerProps = OwnProps & StateProps;

class RentableCellsContainer extends PureComponent<RentableCellsContainerProps> {
  render() {
    const { period, ids, parkId, displayMode, cacheOnly } = this.props;

    return (
      <RentableCellsQuery period={period} ids={ids} parkId={parkId} displayMode={displayMode} cacheOnly={cacheOnly}>
        {({ data, loading, error }) => {
          if (error) {
            // tslint:disable-next-line no-console
            console.error(error);

            return <ErrorMessage />;
          }

          if (loading || isEmpty(data) || data === undefined) {
            // Show placeholder when still loading, or empty cache
            return (
              <>
                {ids.map((id) => (
                  <Placeholder key={id} />
                ))}
              </>
            );
          }

          // Iterate over ids instead of data.rentables because there may be less rentables in the response
          // than the number of requested ids (depending on the period), because only active rentables are returned
          // Regardless a row needs to be rendered for every id (possible with rentableIdentity = { rentables: [] })
          return (
            <>
              {ids.map((id) => (
                <RentableRentableIdentityCell key={id} id={id} data={data} period={period} />
              ))}
            </>
          );
        }}
      </RentableCellsQuery>
    );
  }
}

export default connect(mapStateToProps)(RentableCellsContainer);
