import React, { Component } from 'react';
import Sidebar from '../sidebar';
import Translation from '../translation';
import compact from 'lodash/compact';
import Flag from './Flag';

interface ContactDetailsSectionProps {
  reservation: {
    booking: {
      firstName: string;
      lastName: string;
      company: string;
      email: string;
      phone: string;
      locale: string;
    };
  };
}

export default class ContactDetailsSection extends Component<ContactDetailsSectionProps> {
  renderNameTableRow() {
    const { firstName, lastName, locale } = this.props.reservation.booking;

    if (!firstName && !lastName) {
      return null;
    }

    const data = (
      <span>
        {<Flag locale={locale} />}
        {compact([firstName, lastName]).join(' ')}
      </span>
    );

    return <Translation>{(translate) => <Sidebar.TableRow header={translate('Name')} data={data} />}</Translation>;
  }

  renderCompanyTableRow() {
    let { company } = this.props.reservation.booking;

    return (
      <Translation>{(translate) => (company ? <Sidebar.TableRow header={translate('Company name')} data={company} /> : null)}</Translation>
    );
  }

  renderEmailTableRow() {
    let { email } = this.props.reservation.booking;

    return (
      <Translation>
        {(translate) => (email ? <Sidebar.TableRow header={translate('Email')} data={<a href={`mailto:${email}`}>{email}</a>} /> : null)}
      </Translation>
    );
  }

  renderPhoneTableRow() {
    let { phone } = this.props.reservation.booking;
    return <Translation>{(translate) => (phone ? <Sidebar.TableRow header={translate('Telephone')} data={phone} /> : null)}</Translation>;
  }

  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Contact details')}>
            <Sidebar.Table>
              {this.renderNameTableRow()}
              {this.renderCompanyTableRow()}
              {this.renderEmailTableRow()}
              {this.renderPhoneTableRow()}
            </Sidebar.Table>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}
