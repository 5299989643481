import React, { PureComponent, CSSProperties } from 'react';
import EventLink from '../event-link';
import BlockedByGroupedRentableTypeAgendaPeriodEventItem from '../blocked-by-grouped-rentable-type-agenda-period-event-item';
import { SidebarType } from '../../actions/sidebarPanel';

interface BlockedByGroupedRentableTypeAgendaPeriodEventLinkProps {
  blockedByGroupedRentableTypeAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;
    blockingReservation: {
      id: string;
      url: string;
      booking: {
        referenceNr: string;
        bookingNr: string;
        firstName: string;
        lastName: string;
        company: string;
      };
    };
  };
  style: CSSProperties;
}

export default class BlockedByGroupedRentableTypeAgendaPeriodEventLink extends PureComponent<BlockedByGroupedRentableTypeAgendaPeriodEventLinkProps> {
  renderItem = ({ isActive, isIndented }: { isActive: boolean; isIndented: boolean }) => (
    <BlockedByGroupedRentableTypeAgendaPeriodEventItem
      blockedByGroupedRentableTypeAgendaPeriod={this.props.blockedByGroupedRentableTypeAgendaPeriod}
      isActive={isActive}
      isIndented={isIndented}
    />
  );

  render() {
    return (
      <EventLink
        url={this.props.blockedByGroupedRentableTypeAgendaPeriod.blockingReservation.url}
        style={this.props.style}
        sidebarType={SidebarType.BlockedByGroupedRentableTypeAgendaPeriod}
        sidebarId={this.props.blockedByGroupedRentableTypeAgendaPeriod.id}
        renderItem={this.renderItem}
      />
    );
  }
}
