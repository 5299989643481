import React from 'react';
import RentableIdentitySidebar from './RentableIdentitySidebar';
import RentableIdentitySidebarQuery from './RentableIdentitySidebarQuery';
import RentableIdentitySidebarPlaceholder from './RentableIdentitySidebarPlaceholder';
import { SidebarErrorMessage } from '../sidebar';

interface RentableIdentitySidebarContainerProps {
  rentableIdentityId: string;
  onCloseButtonClick: () => void;
}

export default function RentableIdentitySidebarContainer({ rentableIdentityId, ...rest }: RentableIdentitySidebarContainerProps) {
  return (
    <RentableIdentitySidebarQuery rentableIdentityId={rentableIdentityId}>
      {({ data, loading, error }) => {
        if (error) {
          return <SidebarErrorMessage error={error} {...rest} />;
        }
        if (loading) {
          return <RentableIdentitySidebarPlaceholder {...rest} />;
        } else if (data) {
          return <RentableIdentitySidebar rentableIdentity={data.rentableIdentity} {...rest} />;
        } else {
          return null;
        }
      }}
    </RentableIdentitySidebarQuery>
  );
}
