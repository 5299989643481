import React, { Component } from 'react';
import ReservationSelectionDialogQuery from './ReservationSelectionDialogQuery';
import ReservationSelectionDialog from './ReservationSelectionDialog';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { State } from '../../reducers';
import * as actions from '../../actions/reservationSelection';
import * as sidebarPanelActions from '../../actions/sidebarPanel';
import { apolloClient } from '../../config/apollo';
import { Booking } from '../../actions/bookingMode';
// tslint:disable-next-line:no-duplicate-imports
import { SidebarType } from '../../actions/sidebarPanel';
import deserializeObject from '../../utils/deserializeObject';
import serializeObject from '../../utils/serializeObject';
import ReservationSelectionDialogQueryData from './ReservationSelectionDialogQueryData';
import { isEmpty, isEqual } from 'lodash';

interface OwnProps {
  parkId: string;
}

interface StateProps {
  rentableId?: string;
  startDate?: string;
  endDate?: string;
  activeBooking?: Booking;
  dialogGuestGroup: string;
  isSidebarPanelOpen: boolean;
}

interface DispatchProps {
  clearReservationSelection: () => void;
  openSidebarPanel: (sidebarType: SidebarType, id: string) => void;
  closeSidebarPanel: () => void;
}

type ReservationSelectionDialogContainerProps = StateProps & DispatchProps & OwnProps;
type RefetchFunction = (variables?: Partial<{ guestGroup: { [key: string]: string } }>) => void;

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state, _ownProps) => {
  const { rentableId, startDate, endDate } = state.reservationSelection;

  return {
    rentableId,
    startDate,
    endDate,
    dialogGuestGroup: state.filterPanel.selectedGuestGroup || 'adults:2',
    activeBooking: state.bookingMode.booking,
    isSidebarPanelOpen: state.sidebarPanel.isOpen,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, State> = {
  clearReservationSelection: actions.clearReservationSelection,
  openSidebarPanel: sidebarPanelActions.openSidebarPanel,
  closeSidebarPanel: sidebarPanelActions.closeSidebarPanel,
};

class ReservationSelectionDialogContainer extends Component<ReservationSelectionDialogContainerProps> {
  // tslint:disable-next-line:no-object-literal-type-assertion
  private lastData: ReservationSelectionDialogQueryData = {} as ReservationSelectionDialogQueryData;

  state = {
    isClosing: false,
    selectionGuestGroup: deserializeObject(this.props.dialogGuestGroup),
  };

  handleClose = (e?: React.SyntheticEvent) => {
    this.setState({ isClosing: true }, () => apolloClient.reFetchObservableQueries());
  };

  handleClosed = () => {
    this.setState({ isClosing: false }, () => this.props.clearReservationSelection());
    this.setState({ selectionGuestGroup: deserializeObject(this.props.dialogGuestGroup) });
  };

  handleGuestGroupChange = (refetch: RefetchFunction, selectedGuestGroup: { [key: string]: string }) => {
    this.setState({ selectionGuestGroup: selectedGuestGroup }, () => {
      refetch({
        guestGroup: selectedGuestGroup,
      });
    });
  };

  handleQueryCompleted = (data: ReservationSelectionDialogQueryData) => {
    this.lastData = data;
  };

  componentDidUpdate(prevProps: ReservationSelectionDialogContainerProps) {
    if (!isEqual(this.props.dialogGuestGroup, prevProps.dialogGuestGroup)) {
      this.setState({ selectionGuestGroup: deserializeObject(this.props.dialogGuestGroup) });
    }
  }

  render() {
    const { rentableId, startDate, endDate, activeBooking, parkId } = this.props;
    const { isClosing, selectionGuestGroup } = this.state;

    if (startDate && endDate && rentableId) {
      return (
        <ReservationSelectionDialogQuery
          rentableId={rentableId}
          startDate={startDate}
          endDate={endDate}
          guestGroup={serializeObject(selectionGuestGroup)}
          bookingId={activeBooking ? activeBooking.id : undefined}
          parkId={parkId}
          onCompleted={this.handleQueryCompleted}
        >
          {({ data, loading, error, refetch }) => {
            if ((loading || error) && isEmpty(this.lastData)) {
              return null;
            } else {
              return (
                <ReservationSelectionDialog
                  startDate={startDate}
                  endDate={endDate}
                  rentable={data ? data.rentable : this.lastData?.rentable}
                  guestTypes={data ? data.filterPanel.guestTypes : this.lastData?.filterPanel.guestTypes}
                  guestGroup={serializeObject(selectionGuestGroup)}
                  activeBooking={activeBooking}
                  isOpen={!isClosing}
                  isLoading={loading}
                  onClose={this.handleClose}
                  onClosed={this.handleClosed}
                  onGuestGroupChange={(selectedGuestGroup) => this.handleGuestGroupChange(refetch, selectedGuestGroup)}
                />
              );
            }
          }}
        </ReservationSelectionDialogQuery>
      );
    } else {
      return null;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationSelectionDialogContainer);
