import React, { Component } from 'react';
import without from 'lodash/without';
import { H3, Card, Divider, Classes } from '@blueprintjs/core';
import StarredParkListContainer from './StarredParkListContainer';
import OrganizationListContainer from './OrganizationListContainer';
import SearchField from './SearchField';
import debounce from 'lodash/debounce';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push as pushAction } from 'redux-first-routing';
import Translation from '../translation';

interface ParkOverviewProps {
  dispatch: Dispatch;
}

interface ParkOverviewState {
  query: string;
  searchFieldQuery: string;
  starredParkIds: string[];
}

class ParkOverview extends Component<ParkOverviewProps, ParkOverviewState> {
  private LOCAL_STORAGE_KEY = 'starredParkIds';

  constructor(props: ParkOverviewProps) {
    super(props);

    const query = '';
    const searchFieldQuery = '';
    const starredParkIds = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY) || '[]');

    this.state = { query, searchFieldQuery, starredParkIds };
  }

  handleStarButtonClick = (parkId: string, isStarred: boolean) => {
    isStarred ? this.unstarPark(parkId) : this.starPark(parkId);
  };

  handleSearchFieldChange = (query: string) => {
    this.setState({ searchFieldQuery: query }, () => this.debouncedSetQuery(query));
  };

  handleSearchFieldReset = () => {
    this.setState({ searchFieldQuery: '' }, () => this.debouncedSetQuery(''));
  };

  // tslint:disable-next-line member-ordering
  debouncedSetQuery = debounce((query: string) => {
    window.requestAnimationFrame(() => this.setState({ query }));
  }, 50);

  handleParkItemClick = (parkId: string) => {
    this.props.dispatch(pushAction(`${process.env.PUBLIC_URL}/parks/${parkId}`));
  };

  render() {
    const { query, searchFieldQuery, starredParkIds } = this.state;

    return (
      <Translation>
        {(translate) => (
          <Card className={`${Classes.ELEVATION_1} space-between-4`}>
            <H3>{translate('Parks')}</H3>
            <Divider />
            <SearchField query={searchFieldQuery} onChange={this.handleSearchFieldChange} onReset={this.handleSearchFieldReset} />
            <StarredParkListContainer
              query={query}
              parkIds={starredParkIds}
              onParkItemClick={this.handleParkItemClick}
              onStarButtonClick={this.handleStarButtonClick}
            />
            <OrganizationListContainer
              query={query}
              starredParkIds={starredParkIds}
              onParkItemClick={this.handleParkItemClick}
              onStarButtonClick={this.handleStarButtonClick}
            />
          </Card>
        )}
      </Translation>
    );
  }

  private starPark = (parkId: string) => {
    const starredParkIds = [...this.state.starredParkIds, parkId];
    this.setState({ starredParkIds }, () => localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(starredParkIds)));
  };

  private unstarPark = (parkId: string) => {
    const starredParkIds = without(this.state.starredParkIds, parkId);
    this.setState({ starredParkIds }, () => localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(starredParkIds)));
  };
}

export default connect()(ParkOverview);
