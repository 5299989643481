import React from 'react';
import Page from '../page';
import Translation from '../translation';
import { Callout, Card, Classes, H3 } from '@blueprintjs/core';

interface UnauthorizedPageProps {
  message: string;
  redirectUrl: string;
}

export default function UnauthorizedPage({ message, redirectUrl }: UnauthorizedPageProps) {
  return (
    <Translation>
      {(translate) => (
        <Page>
          <Page.Launchbar />
          <Page.Container className="mx-auto max-w-xl mt-4 md:mt-8 px-4 md:px-8 mb-8 md:pb-8">
            <Card className={`${Classes.ELEVATION_1} space-between-4`}>
              <H3>Planbord</H3>
              <Callout>
                {message} <a href={redirectUrl}>{translate('Sign in via Booking Experts →')}</a>
              </Callout>
            </Card>
          </Page.Container>
        </Page>
      )}
    </Translation>
  );
}
