import React from 'react';
import TakeOverIcalAgendaPeriodForm from '../take-over-ical-agenda-period-form';

interface TakeOverIcalAgendaPeriodPanelProps {
  icalAgendaPeriod: { id: string };
}

export default function TakeOverIcalAgendaPeriodPanel({ icalAgendaPeriod }: TakeOverIcalAgendaPeriodPanelProps) {
  return <TakeOverIcalAgendaPeriodForm icalAgendaPeriodId={icalAgendaPeriod.id} />;
}
