import React from 'react';
import IcalAgendaPeriodEventPopover from '../ical-agenda-period-event-popover';
import formatIcalAgendaPeriod from '../../utils/formatIcalAgendaPeriod';
import AgendaPeriodEventItem from '../agenda-period-event-item';

interface IcalAgendaPeriodEventItemProps {
  icalAgendaPeriod: {
    startDate: string;
    endDate: string;
    memo: string;
    icalChannel: {
      name: string;
    };
  };
  isActive: boolean;
  isIndented: boolean;
}

export default function IcalAgendaPeriodEventItem({ icalAgendaPeriod, isActive, isIndented }: IcalAgendaPeriodEventItemProps) {
  return (
    <AgendaPeriodEventItem
      title={formatIcalAgendaPeriod(icalAgendaPeriod)}
      renderPopover={() => <IcalAgendaPeriodEventPopover icalAgendaPeriod={icalAgendaPeriod} />}
      className="ical-agenda-period"
      isActive={isActive}
      isIndented={isIndented}
    />
  );
}
