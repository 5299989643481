import classNames from 'classnames';

interface Reservation {
  booking: {
    source: string;
    isOption: boolean;
  };
  hasDateRangeExtended: boolean;
}

export default function generateReservationClassName(reservation: Reservation) {
  return classNames(
    'reservation',
    `reservation--${reservation.booking.source}`,
    reservation.booking.isOption && 'reservation--option',
    reservation.hasDateRangeExtended && 'reservation--late-checkout'
  );
}
