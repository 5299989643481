export const UPDATE_SERVER_VERSION = 'UPDATE_SERVER_VERSION';

export class UpdateServerVersion {
  readonly type = UPDATE_SERVER_VERSION;
  constructor(public serverVersion: string) {}
}

export const updateServerVersion = (serverVersion: string) => {
  return { type: UPDATE_SERVER_VERSION, serverVersion };
};
