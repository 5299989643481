import React, { PureComponent } from 'react';
import RentableIdentityCell from '../rentable-identity-cell';
import RentableIdentityCellsQuery from './RentableIdentityCellsQuery';
import Placeholder from './Placeholder';
import ErrorMessage from './ErrorMessage';
import { DateRange } from 'moment-range';
import isEmpty from 'lodash/isEmpty';
import { MapStateToProps, connect } from 'react-redux';
import { DisplayMode } from '../../actions/displayMode';
import { State } from '../../reducers';

interface OwnProps {
  ids: string[];
  parkId: string;
  rentableTypeId: string;
  period: DateRange;
  cacheOnly: boolean;
}

interface StateProps {
  displayMode: DisplayMode;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state) => {
  return { displayMode: state.displayMode };
};

type RentableIdentityCellsContainerProps = OwnProps & StateProps;

class RentableIdentityCellsContainer extends PureComponent<RentableIdentityCellsContainerProps> {
  render() {
    const { period, ids, parkId, rentableTypeId, displayMode, cacheOnly } = this.props;

    return (
      <RentableIdentityCellsQuery
        period={period}
        ids={ids}
        parkId={parkId}
        rentableTypeId={rentableTypeId}
        displayMode={displayMode}
        cacheOnly={cacheOnly}
      >
        {({ data, loading, error }) => {
          if (error) {
            return <ErrorMessage />;
          }

          if (loading || isEmpty(data) || data === undefined) {
            // Show placeholder when still loading, or empty cache
            return (
              <>
                {ids.map((id) => (
                  <Placeholder key={id} />
                ))}
              </>
            );
          }

          return (
            <>
              {data.rentableIdentities.map((rentableIdentity) => (
                <RentableIdentityCell key={rentableIdentity.id} rentableIdentity={rentableIdentity} period={period} />
              ))}
            </>
          );
        }}
      </RentableIdentityCellsQuery>
    );
  }
}

export default connect(mapStateToProps)(RentableIdentityCellsContainer);
