import React, { PureComponent } from 'react';
import { loader } from 'graphql.macro';
import SettingsProviderQueryData from './SettingsProviderQueryData';
import SettingsProvider from './SettingsProvider';
import { Query } from '@apollo/client/react/components';
import { changeLanguage, detectDefaultLocale } from '../../config/i18n';
const settingsProviderQuery = loader('./settingsProviderQuery.graphql');

interface SettingsProviderContainerProps {
  children: React.ReactNode;
}

export default class SettingsProviderContainer extends PureComponent<SettingsProviderContainerProps> {
  handleCompleted = (data?: SettingsProviderQueryData) => {
    // Skips rerendering of components if already rendered in correct locale
    if (data && data.user.locale !== detectDefaultLocale()) {
      changeLanguage(data.user.locale);
    }
  };

  render() {
    return (
      <Query<SettingsProviderQueryData> query={settingsProviderQuery} onCompleted={this.handleCompleted}>
        {() => <SettingsProvider children={this.props.children} />}
      </Query>
    );
  }
}
