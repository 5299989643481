import React from 'react';
import formatOwnerMaintenanceAgendaPeriod from '../../utils/formatOwnerMaintenanceAgendaPeriod';
import OwnerMaintenanceAgendaPeriodBadge from '../owner-maintenance-agenda-period-badge';
import { AgendaPeriodEventPopover } from '../agenda-period-event-popover';
import Translation from '../translation';

interface OwnerMaintenanceAgendaPeriodEventPopoverProps {
  ownerMaintenanceAgendaPeriod: {
    memo: string;
    startDate: string;
    endDate: string;
  };
}

export default function OwnerMaintenanceAgendaPeriodEventPopover({
  ownerMaintenanceAgendaPeriod,
}: OwnerMaintenanceAgendaPeriodEventPopoverProps) {
  return (
    <Translation>
      {(translate) => (
        <AgendaPeriodEventPopover
          title={formatOwnerMaintenanceAgendaPeriod(ownerMaintenanceAgendaPeriod, translate)}
          startDate={ownerMaintenanceAgendaPeriod.startDate}
          endDate={ownerMaintenanceAgendaPeriod.endDate}
          renderBadge={() => <OwnerMaintenanceAgendaPeriodBadge />}
        />
      )}
    </Translation>
  );
}
