import React, { PureComponent } from 'react';
import TargetRentableSelect from './TargetRentableSelect';
import TargetRentableSelectQuery from './TargetRentableSelectQuery';
import isEmpty from 'lodash/isEmpty';
import TargetRentableSelectPlaceholder from './TargetRentableSelectPlaceholder';

interface TargetRentableSelectContainerProps {
  sourceReservationAgendaPeriod: { startDate: string; endDate: string; parkId: string; rentableId: string };
  targetRentable?: { id: string };
  onChange: (targetRentableId?: string) => void;
}

export default class TargetRentableSelectContainer extends PureComponent<TargetRentableSelectContainerProps> {
  render() {
    const { sourceReservationAgendaPeriod, targetRentable, onChange } = this.props;

    return (
      <TargetRentableSelectQuery sourceReservationAgendaPeriod={sourceReservationAgendaPeriod}>
        {({ data, loading }) => {
          if (!loading && !isEmpty(data)) {
            return (
              <TargetRentableSelect
                sourceReservationAgendaPeriod={sourceReservationAgendaPeriod}
                targetRentable={targetRentable}
                data={data!}
                onChange={onChange}
              />
            );
          }

          if (loading) {
            return <TargetRentableSelectPlaceholder />;
          }

          return null;
        }}
      </TargetRentableSelectQuery>
    );
  }
}
