import React, { PureComponent } from 'react';
import PeriodHeader from './PeriodHeader';
import PeriodHeaderQuery from './PeriodHeaderQuery';
import { DateRange } from 'moment-range';
import { Moment } from 'moment';
import * as actions from '../../actions/availabilityFilter';
import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../reducers';

const EMPTY_ARRAY: [] = [];

interface OwnProps {
  parkId: string;
  period: DateRange;
  onWeekHeaderMouseDown: (e: React.MouseEvent) => void;
}

interface StateProps {
  availabilityFilterHoverStartDate?: string;
  availabilityFilterHoverEndDate?: string;
  availabilityFilterStartDate?: string;
  availabilityFilterEndDate?: string;
  canSetHoverStartDate?: boolean;
  canSetHoverEndDate?: boolean;
  canToggleEndDate?: boolean;
  canToggleStartDate?: boolean;
  canClearAvailabilityFilter?: boolean;
}

type PeriodHeaderContainerProps = ConnectedProps<typeof connector> & OwnProps;

const mapStateToProps = (state: State) => {
  const { hoverStartDate, hoverEndDate, startDate, endDate } = state.availabilityFilter;

  let stateProps: StateProps = {
    availabilityFilterHoverStartDate: hoverStartDate,
    availabilityFilterHoverEndDate: hoverEndDate,
    availabilityFilterStartDate: startDate,
    availabilityFilterEndDate: endDate,
  };

  if (hoverStartDate === undefined && startDate === undefined && hoverEndDate === undefined && endDate === undefined) {
    stateProps = { ...stateProps, canSetHoverStartDate: true };
  }

  if (hoverStartDate !== undefined && startDate === undefined && hoverEndDate === undefined && endDate === undefined) {
    stateProps = { ...stateProps, canSetHoverStartDate: true, canToggleEndDate: true };
  }

  if (hoverStartDate !== undefined && startDate !== undefined && hoverEndDate === undefined && endDate === undefined) {
    stateProps = { ...stateProps, canSetHoverEndDate: true, canToggleStartDate: true };
  }

  if (hoverStartDate !== undefined && startDate !== undefined && hoverEndDate !== undefined && endDate === undefined) {
    stateProps = { ...stateProps, canSetHoverEndDate: true, canToggleStartDate: true };
  }

  if (hoverStartDate !== undefined && startDate !== undefined && hoverEndDate !== undefined && endDate !== undefined) {
    stateProps = { ...stateProps, canClearAvailabilityFilter: true };
  }

  return stateProps;
};

const mapDispatchToProps = {
  setAvailabilityFilterHoverStartDate: actions.setAvailabilityFilterHoverStartDate,
  setAvailabilityFilterHoverEndDate: actions.setAvailabilityFilterHoverEndDate,
  clearAvailabilityFilterHoverStartDate: actions.clearAvailabilityFilterHoverStartDate,
  clearAvailabilityFilterHoverEndDate: actions.clearAvailabilityFilterHoverEndDate,
  toggleAvailabilityFilterStartDate: actions.toggleAvailabilityFilterStartDate,
  toggleAvailabilityFilterEndDate: actions.toggleAvailabilityFilterEndDate,
};

class PeriodHeaderContainer extends PureComponent<PeriodHeaderContainerProps> {
  handleWeekDatesMouseMove = (e: React.MouseEvent, hoverDate: Moment) => {
    const { setAvailabilityFilterHoverStartDate, setAvailabilityFilterHoverEndDate, canSetHoverStartDate, canSetHoverEndDate } = this.props;

    if (canSetHoverStartDate) {
      setAvailabilityFilterHoverStartDate(hoverDate);
    }

    if (canSetHoverEndDate) {
      setAvailabilityFilterHoverEndDate(hoverDate);
    }
  };

  handleWeekDatesMouseLeave = (e: React.MouseEvent) => {
    const { clearAvailabilityFilterHoverStartDate, clearAvailabilityFilterHoverEndDate, canSetHoverStartDate, canSetHoverEndDate } =
      this.props;

    if (canSetHoverStartDate) {
      clearAvailabilityFilterHoverStartDate();
    }

    if (canSetHoverEndDate) {
      clearAvailabilityFilterHoverEndDate();
    }
  };

  handleAvailabilityFilterStartDateClick = (e: React.MouseEvent, date: Moment) => {
    this.props.toggleAvailabilityFilterStartDate(date);

    // Prevent click handler on <Planboard /> from running
    e.stopPropagation();
  };

  handleAvailabilityFilterEndDateClick = (e: React.MouseEvent, date: Moment) => {
    this.props.toggleAvailabilityFilterEndDate(date);

    // Prevent click handler on <Planboard /> from running
    e.stopPropagation();
  };

  render() {
    const {
      parkId,
      period,
      availabilityFilterHoverStartDate,
      availabilityFilterHoverEndDate,
      availabilityFilterStartDate,
      availabilityFilterEndDate,
      onWeekHeaderMouseDown,
    } = this.props;

    return (
      <PeriodHeaderQuery parkId={parkId} period={period}>
        {({ data, loading, error }) => {
          const holidays = loading || error || data === undefined ? EMPTY_ARRAY : data.holidays;

          return (
            <PeriodHeader
              period={period}
              holidays={holidays}
              availabilityFilterHoverStartDate={availabilityFilterHoverStartDate}
              availabilityFilterHoverEndDate={availabilityFilterHoverEndDate}
              availabilityFilterStartDate={availabilityFilterStartDate}
              availabilityFilterEndDate={availabilityFilterEndDate}
              onWeekHeaderMouseDown={onWeekHeaderMouseDown}
              onWeekDatesMouseLeave={this.handleWeekDatesMouseLeave}
              onWeekDatesMouseMove={this.handleWeekDatesMouseMove}
              onAvailabilityFilterStartDateClick={this.handleAvailabilityFilterStartDateClick}
              onAvailabilityFilterEndDateClick={this.handleAvailabilityFilterEndDateClick}
            />
          );
        }}
      </PeriodHeaderQuery>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(PeriodHeaderContainer);
