import { TranslateFunction } from '../components/translation';

interface OwnerMaintenanceAgendaPeriod {
  memo: string;
}

export default function formatOwnerMaintenanceAgendaPeriod(
  ownerMaintenanceAgendaPeriod: OwnerMaintenanceAgendaPeriod,
  translate: TranslateFunction
) {
  return ownerMaintenanceAgendaPeriod.memo || translate('Blocked by owner');
}
