import React, { PureComponent, CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import classes from './ReservationSelectionCell.module.css';
import Translation from '../translation';
import classNames from 'classnames';

interface ReservationSelectionCellProps {
  style: CSSProperties;
  numberOfNights: number;
  isBookingMode: boolean;
}

export default class ReservationSelectionCell extends PureComponent<ReservationSelectionCellProps> {
  renderItem() {
    const { numberOfNights: lengthOfStay, isBookingMode } = this.props;
    const className = classNames(classes.item, { [classes['item--booking']]: isBookingMode });

    return (
      <div className={className}>
        {lengthOfStay > 1 && (
          <Translation>
            {(translate) => <div className={classes.label}>{translate('{{lengthOfStay}} nights', { lengthOfStay })}</div>}
          </Translation>
        )}
        <FontAwesomeIcon icon={faPlus} className={classes.icon} />
      </div>
    );
  }

  render() {
    const { style } = this.props;

    return (
      <div className={classes.root} style={style}>
        {this.renderItem()}
      </div>
    );
  }
}
