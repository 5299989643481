import React from 'react';
import MaintenanceAgendaPeriodSidebar from './MaintenanceAgendaPeriodSidebar';
import MaintenanceAgendaPeriodSidebarQuery from './MaintenanceAgendaPeriodSidebarQuery';
import { AgendaPeriodSidebarPlaceholder } from '../agenda-period-sidebar';
import { SidebarErrorMessage } from '../sidebar';

interface MaintenanceAgendaPeriodSidebarContainerProps {
  maintenanceAgendaPeriodId: string;
  onCloseButtonClick: () => void;
}

export default function MaintenanceAgendaPeriodSidebarContainer({
  maintenanceAgendaPeriodId,
  ...rest
}: MaintenanceAgendaPeriodSidebarContainerProps) {
  return (
    <MaintenanceAgendaPeriodSidebarQuery maintenanceAgendaPeriodId={maintenanceAgendaPeriodId}>
      {({ data, loading, error }) => {
        if (error) {
          return <SidebarErrorMessage error={error} {...rest} />;
        }
        if (loading) {
          return <AgendaPeriodSidebarPlaceholder {...rest} />;
        } else if (data) {
          return <MaintenanceAgendaPeriodSidebar maintenanceAgendaPeriod={data.maintenanceAgendaPeriod} {...rest} />;
        } else {
          return null;
        }
      }}
    </MaintenanceAgendaPeriodSidebarQuery>
  );
}
