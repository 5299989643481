import React from 'react';
import Sidebar from '../sidebar';
import ErrorMessage from '../error-message';
import { withTranslation, WithTranslation } from '../translation';
import getStatusCode from '../../utils/getStatusCode';

interface SidebarErrorMessageProps {
  error: Error;
  onCloseButtonClick: () => void;
}

class SidebarErrorMessage extends React.Component<SidebarErrorMessageProps & WithTranslation> {
  get title() {
    const { t: translate, error } = this.props;
    return getStatusCode(error) === 404 ? translate('Object not found') : translate('An error has occured');
  }

  render() {
    const { error, onCloseButtonClick } = this.props;

    return (
      <Sidebar>
        <Sidebar.Header href="" title={this.title} onCloseButtonClick={onCloseButtonClick} />
        <Sidebar.Content>
          <ErrorMessage error={error} />
        </Sidebar.Content>
      </Sidebar>
    );
  }
}

export default withTranslation()(SidebarErrorMessage);
