import { InMemoryCache } from '@apollo/client/cache';

// See https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces

const possibleTypes = {
  AgendaPeriod: [
    'IcalAgendaPeriod',
    'MaintenanceAgendaPeriod',
    'OwnerMaintenanceAgendaPeriod',
    'NotEnoughStockAgendaPeriod',
    'BlockedByGroupedRentableTypeAgendaPeriod',
    'ReservationAgendaPeriod',
  ],
  Row: ['RentableSegmentRow', 'RentableTypeRow', 'RentableIdentitiesRow', 'RentablesRow'],
};

export default new InMemoryCache({ possibleTypes });
