import React, { useContext } from 'react';
import Launchbar from './Launchbar';
import LaunchbarQuery from './LaunchbarQuery';
import LaunchbarQueryData from './LaunchbarQueryData';
import isEmpty from 'lodash/isEmpty';
import { HotkeysContext } from '@blueprintjs/core';
import { showHotkeysDialog } from '@blueprintjs/core/lib/esm/components/hotkeys/hotkeysDialog';

interface LaunchbarContainerProps {
  parkId?: string;
}

const fallbackData: LaunchbarQueryData = {
  planboard: {
    bookingexpertsUrl: 'https://app.bookingexperts.nl/',
    parkcmsUrl: 'https://cms.bookingexperts.nl/',
    supportLinkBaseUrl: 'https://apps.bookingexperts.nl/support_links/',
  },
  user: { id: '-1', name: '', locale: 'nl', editUrl: '#' },
  role: { isAdminRole: false },
};

export default function LaunchbarContainer(props: LaunchbarContainerProps) {
  const [{ hotkeys }] = useContext(HotkeysContext);

  const handleHotkeysLinkClick = () => {
    showHotkeysDialog(hotkeys);
  };

  return (
    <LaunchbarQuery>
      {({ data, loading, error }) => {
        if (loading) {
          return <Launchbar data={fallbackData} {...props} onHotkeysLinkClick={handleHotkeysLinkClick} />;
        }

        if (error) {
          return <Launchbar data={fallbackData} {...props} onHotkeysLinkClick={handleHotkeysLinkClick} />;
        }

        if (data && !isEmpty(data)) {
          return <Launchbar data={data} {...props} onHotkeysLinkClick={handleHotkeysLinkClick} />;
        }

        return null;
      }}
    </LaunchbarQuery>
  );
}
