import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Intent, Callout } from '@blueprintjs/core';
import Translation from '../translation';

interface GuestMemoSectionProps {
  reservation: {
    note: string;
    extraOrderItems: Array<{
      id: string;
      name: string;
      memo: string;
    }>;
  };
}

export default class GuestMemoSection extends Component<GuestMemoSectionProps> {
  get guestMemos() {
    const { note, extraOrderItems } = this.props.reservation;
    const reservationMemo = note ? { key: 'note', text: note } : null;
    const orderMemoItems = extraOrderItems
      .filter((extraOrderItem) => extraOrderItem.memo)
      .map(({ id, name, memo }) => ({ key: id, text: `${name}: ${memo}` }));

    return reservationMemo ? [reservationMemo, ...orderMemoItems] : orderMemoItems;
  }

  renderGuestMemos() {
    return (
      <Callout intent={Intent.PRIMARY} icon={null}>
        <ul className="pl-4">
          {this.guestMemos.map(({ key, text }) => (
            <li key={key}>{text}</li>
          ))}
        </ul>
      </Callout>
    );
  }

  render() {
    return (
      <Translation>
        {(translate) =>
          this.guestMemos.length ? <Sidebar.Section title={translate('Memo by guest')}>{this.renderGuestMemos()}</Sidebar.Section> : null
        }
      </Translation>
    );
  }
}
