import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import HelpScoutQueryData from './HelpScoutQueryData';
const helpScoutQuery = loader('./helpScoutQuery.graphql');

interface HelpScoutQueryProps {
  children: (result: QueryResult<HelpScoutQueryData>) => JSX.Element | null;
}

export default function HelpScoutQuery({ children }: HelpScoutQueryProps) {
  return <Query query={helpScoutQuery}>{children}</Query>;
}
