import { consumer } from '../config/apollo/action_cable_socket_link';

export const UNAUTHENTICATE_SESSION = 'UNAUTHENTICATE_SESSION';
export const UNAUTHORIZE_SESSION = 'UNAUTHORIZE_SESSION';

export enum SessionPhase {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  Unauthorized = 'unauthorized',
}

export interface SessionData {
  message: string;
  redirectUrl: string;
}

// tslint:disable-next-line max-classes-per-file
export class UnauthenticateSession {
  readonly type = UNAUTHENTICATE_SESSION;
  constructor(public data: SessionData) {}
}

// tslint:disable-next-line max-classes-per-file
export class UnauthorizeSession {
  readonly type = UNAUTHORIZE_SESSION;
  constructor(public data: SessionData) {}
}

export const invalidateSession = (statusCode: 401 | 403, data: SessionData) => {
  consumer.disconnect(); // Disable ActionCable link

  return { type: { 401: UNAUTHENTICATE_SESSION, 403: UNAUTHORIZE_SESSION }[statusCode], data };
};
