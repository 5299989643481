import React, { PureComponent, CSSProperties } from 'react';
import EventLink from '../event-link';
import IcalAgendaPeriodEventItem from '../ical-agenda-period-event-item';
import { SidebarType } from '../../actions/sidebarPanel';

interface IcalAgendaPeriodEventLinkProps {
  icalAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;
    memo: string;
    url: string;
    icalChannel: {
      name: string;
    };
  };
  style: CSSProperties;
}

export default class IcalAgendaPeriodEventLink extends PureComponent<IcalAgendaPeriodEventLinkProps> {
  renderItem = ({ isActive, isIndented }: { isActive: boolean; isIndented: boolean }) => (
    <IcalAgendaPeriodEventItem icalAgendaPeriod={this.props.icalAgendaPeriod} isActive={isActive} isIndented={isIndented} />
  );

  render() {
    return (
      <EventLink
        url={this.props.icalAgendaPeriod.url}
        style={this.props.style}
        sidebarType={SidebarType.IcalAgendaPeriod}
        sidebarId={this.props.icalAgendaPeriod.id}
        renderItem={this.renderItem}
      />
    );
  }
}
