import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult, MutationFunction } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import { apolloClient } from '../../config/apollo';
const updateSettingsMutation = loader('./updateSettingsMutation.graphql');

export interface UpdateSettingsData {
  updateSettings: {
    user: {
      id: string;
      locale: string;
      planboardStartDate: string | null;
      planboardLayout: string;
    };
  };
}

interface UpdateSettingsVariables {
  locale: string;
  startDate: string | null;
  layout: string;
}

export type Result = MutationResult<UpdateSettingsData>;
export type UpdateSettingsFn = MutationFunction<UpdateSettingsData, UpdateSettingsVariables>;

interface UpdateSettingsMutationProps {
  children: (updateSettings: UpdateSettingsFn, result: Result) => JSX.Element | null;
}

export default function UpdateSettingsMutation({ children }: UpdateSettingsMutationProps) {
  // Refetch all active queries when planboard settings are changed
  const handleCompleted = () => apolloClient.resetStore();

  return (
    <Mutation<UpdateSettingsData, UpdateSettingsVariables> mutation={updateSettingsMutation} onCompleted={handleCompleted}>
      {children}
    </Mutation>
  );
}
