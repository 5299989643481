import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Classes, Callout, Intent } from '@blueprintjs/core';
import Translation from '../translation';

interface AgendaPeriodSidebarPlaceholderProps {
  onCloseButtonClick: () => void;
}

export default class AgendaPeriodSidebarPlaceholder extends Component<AgendaPeriodSidebarPlaceholderProps> {
  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar>
            <Sidebar.Header href="" title={translate('Loading…')} onCloseButtonClick={this.props.onCloseButtonClick} />
            <Sidebar.Content>
              <Sidebar.Section title={translate('Summary')}>
                <Sidebar.Table>
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Type</span>}
                    data={<span className={Classes.SKELETON}>Geblokkeerde periode</span>}
                  />
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Startdatum</span>}
                    data={<span className={Classes.SKELETON}>1 januari 2018</span>}
                  />
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Einddatum</span>}
                    data={<span className={Classes.SKELETON}>2 januari 2018</span>}
                  />
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Accommodatie</span>}
                    data={<span className={Classes.SKELETON}>Boomhut villa — 001</span>}
                  />
                </Sidebar.Table>
              </Sidebar.Section>

              <Sidebar.Section title={translate('Memo')}>
                <Callout intent={Intent.PRIMARY} icon={null} className={Classes.SKELETON}>
                  ...
                </Callout>
              </Sidebar.Section>
            </Sidebar.Content>
          </Sidebar>
        )}
      </Translation>
    );
  }
}
