import { locationChangeAction, LOCATION_CHANGE, State as LocationState } from 'redux-first-routing';
import deserializeSidebarType from '../utils/deserializeSidebarType';
import { SidebarType, SidebarToggle } from '../actions/sidebarPanel';

interface SidebarPanelCollectionState0 {
  type?: undefined;
  id?: undefined;
}

interface SidebarPanelCollectionState1 {
  type?: SidebarType;
  id: string;
}

export type SidebarPanelState = (SidebarPanelCollectionState0 | SidebarPanelCollectionState1) & {
  isOpen: boolean;
};

function deserializeStateFromLocation(location: Location | LocationState): SidebarPanelState {
  const searchParams = new URLSearchParams(location.search);
  const isOpen = searchParams.get('sidebar') === SidebarToggle.Open;
  const regExp = new RegExp(`/parks/\\d+/([a-z_]+)/(\\d+)`);
  const match = location.pathname.match(regExp);

  if (match) {
    const resource = match[1];
    const id = match[2];

    return { isOpen, type: deserializeSidebarType(resource), id };
  } else {
    return { isOpen };
  }
}

export default function sidebarPanel(
  state = deserializeStateFromLocation(window.location),
  action: locationChangeAction<string, string, string>
): SidebarPanelState {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return deserializeStateFromLocation(action.payload);
    }

    default: {
      return state;
    }
  }
}
