import React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';
import { loader } from 'graphql.macro';
import CheckoutFormQueryData from './CheckoutFormQueryData';
const checkoutFormQuery = loader('./checkoutFormQuery.graphql');

interface CheckoutFormQueryProps {
  reservationId: string;
  children: (result: QueryResult<CheckoutFormQueryData, Variables>) => JSX.Element | null;
}

interface Variables {
  reservationId: string;
}

export default function CheckoutFormQuery({ reservationId, children }: CheckoutFormQueryProps) {
  return (
    <Query<CheckoutFormQueryData, Variables> query={checkoutFormQuery} variables={{ reservationId }}>
      {children}
    </Query>
  );
}
