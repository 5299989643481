import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { PopoverTooltip } from '../tooltip';
import classes from './EventItem.module.css';

interface EventItemProps {
  title: string;
  className: string;
  isActive: boolean;
  isIndented: boolean;
  isBooking: boolean;
  renderPopover: () => React.ReactNode;
  icon?: React.ReactNode;
}

export default class EventItem extends PureComponent<EventItemProps> {
  renderContentBefore() {
    const { isIndented, renderPopover } = this.props;

    return (
      <aside className={classes.content__before}>
        <PopoverTooltip className={classes['popover-icon']} render={isIndented ? renderPopover : () => null}>
          <FontAwesomeIcon icon={faSearch} />
        </PopoverTooltip>
      </aside>
    );
  }

  renderContentInner() {
    const { title, icon } = this.props;

    return (
      <main className={classes.content__inner}>
        {icon ? <div className="h-full flex">{icon}&nbsp;</div> : null}
        <div className="truncate">{title}</div>
      </main>
    );
  }

  render() {
    const { title, isActive, isIndented, isBooking } = this.props;

    const className = classNames(classes.root, this.props.className, {
      [classes['root--booking']]: isBooking,
      [classes['root--active']]: isActive,
      [classes['root--indented']]: isIndented,
    });

    return (
      <div className={className} title={title}>
        <div className={classes.content}>
          {this.renderContentBefore()}
          {this.renderContentInner()}
        </div>
      </div>
    );
  }
}
