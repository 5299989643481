import React from 'react';
import SearchQueryForm from './SearchQueryForm';
import * as actions from '../../actions/searchQuery';
// tslint:disable-next-line:no-duplicate-imports
import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../reducers';

const mapStateToProps = (state: State) => {
  return { searchQuery: state.searchQuery };
};

const mapDispatchToProps = {
  updateSearchQuery: actions.updateSearchQuery,
};

type SearchQueryFormContainerProps = ConnectedProps<typeof connector>;

function SearchQueryFormContainer({ searchQuery, updateSearchQuery }: SearchQueryFormContainerProps) {
  return <SearchQueryForm searchQuery={searchQuery} onSubmit={updateSearchQuery} />;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(SearchQueryFormContainer);
