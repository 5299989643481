import React, { PureComponent, MouseEvent } from 'react';
import { MenuItem } from '@blueprintjs/core';
import StarButton from './StarButton';
import { ParkData } from './OrganizationListQueryData';

interface ParkItemProps {
  park: ParkData;
  isStarred: boolean;
  onClick: (parkId: string) => void;
  onStarButtonClick: (parkId: string, isStarred: boolean) => void;
}

export default class ParkItem extends PureComponent<ParkItemProps> {
  handleClick = (e: MouseEvent) => {
    let { onClick, park } = this.props;

    // Don't do anything when cmd + click
    if (e.metaKey) {
      return;
    }

    onClick(park.id);

    e.preventDefault();
  };

  handleStarButtonClick = (isStarred: boolean) => {
    this.props.onStarButtonClick(this.props.park.id, isStarred);
  };

  renderIcon() {
    let { isStarred } = this.props;
    return <StarButton isStarred={isStarred} onClick={this.handleStarButtonClick} />;
  }

  render() {
    let { park } = this.props;
    return (
      <MenuItem
        href={process.env.PUBLIC_URL + 'parks/' + park.id}
        text={park.name}
        label={park.id}
        icon={this.renderIcon()}
        onClick={this.handleClick}
      />
    );
  }
}
