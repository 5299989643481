import React, { Component } from 'react';
import { Callout, Intent, Icon } from '@blueprintjs/core';
import UAParser from 'ua-parser-js';
import { Trans } from 'react-i18next';
import Translation from '../translation';

const BrowserLink = ({ name, url }: { name: string; url: string }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {name}
  </a>
);

const ChromeLink = () => <BrowserLink name="Chrome" url="https://www.google.com/chrome/browser/desktop/" />;
const SafariLink = () => <BrowserLink name="Safari" url="http://www.apple.com/osx/" />;
const FirefoxLink = () => <BrowserLink name="Firefox" url="http://www.mozilla.org/firefox/new/" />;

export default class UnsupportedBrowserWarning extends Component {
  render() {
    const { browser } = new UAParser().getResult();
    const formattedBrowser = `${browser.name} ${browser.major}`;

    // Wrap in <Translation /> so it reloads when user locale changes
    return (
      <Translation>
        {() => (
          <Callout intent={Intent.WARNING} icon={<Icon icon="warning-sign" />}>
            <Trans formattedBrowser={formattedBrowser}>
              The (version) of the browser you are using ({{ formattedBrowser }}) is not supported by us. Please upgrade to the latest
              version of <ChromeLink />, <SafariLink />, or <FirefoxLink /> to use the planboard
            </Trans>
          </Callout>
        )}
      </Translation>
    );
  }
}
