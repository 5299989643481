import React from 'react';
import DisplayModeSwitcher from './DisplayModeSwitcher';
import * as actions from '../../actions/displayMode';
// tslint:disable-next-line:no-duplicate-imports
import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../reducers';
import Translation from '../translation';

const mapStateToProps = (state: State) => {
  return { displayMode: state.displayMode };
};

const mapDispatchToProps = {
  updateDisplayMode: actions.updateDisplayMode,
};

type DisplayModeSwitcherContainerProps = ConnectedProps<typeof connector>;

function DisplayModeSwitcherContainer({ displayMode, updateDisplayMode }: DisplayModeSwitcherContainerProps) {
  return (
    <Translation>
      {(translate) => <DisplayModeSwitcher displayMode={displayMode} translate={translate} onChange={updateDisplayMode} />}
    </Translation>
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(DisplayModeSwitcherContainer);
