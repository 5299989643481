import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Classes, Callout, Intent, RadioGroup, Radio } from '@blueprintjs/core';
import Translation from '../translation';

interface ReservationAgendaPeriodSidebarPlaceholderProps {
  onCloseButtonClick: () => void;
}

export default class ReservationAgendaPeriodSidebarPlaceholder extends Component<ReservationAgendaPeriodSidebarPlaceholderProps> {
  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar>
            <Sidebar.Header href="" title={translate('Loading reservation…')} onCloseButtonClick={this.props.onCloseButtonClick} />
            <Sidebar.Content>
              <Sidebar.Section title={translate('Booking')}>
                <RadioGroup onChange={() => {}}>
                  <Radio disabled>
                    <span className={Classes.SKELETON}>4 persoons Comfort - 1 - zo 31 okt. tot za 6 nov.</span>
                  </Radio>
                </RadioGroup>
              </Sidebar.Section>

              <Sidebar.Section title={translate('Summary')}>
                <Sidebar.Table>
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Type</span>}
                    data={<span className={Classes.SKELETON}>Backoffice reservering</span>}
                  />
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Accommodatie</span>}
                    data={<span className={Classes.SKELETON}>Bungalow Deluxe max. 6 pers. - 80</span>}
                  />
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Periode</span>}
                    data={<span className={Classes.SKELETON}>1 januari 2018 t/m 7 januari 2018</span>}
                  />
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Aantal nachten</span>}
                    data={<span className={Classes.SKELETON}>6 nachten</span>}
                  />
                </Sidebar.Table>
              </Sidebar.Section>

              <Sidebar.Section title={translate('Contact details')}>
                <Sidebar.Table>
                  <Sidebar.TableRow
                    header={<span className={Classes.SKELETON}>Naam</span>}
                    data={<span className={Classes.SKELETON}>Voornaam Achternaam</span>}
                  />
                </Sidebar.Table>
              </Sidebar.Section>

              <Sidebar.Section title={translate('Presence')}>
                <Sidebar.Table>
                  <Sidebar.TableRow
                    icon={<span className={Classes.SKELETON}>....</span>}
                    header={<span className={Classes.SKELETON}>Ingecheckt op</span>}
                    data={<span className={Classes.SKELETON}>—</span>}
                  />
                  <Sidebar.TableRow
                    icon={<span className={Classes.SKELETON}>....</span>}
                    header={<span className={Classes.SKELETON}>Uitgecheckt op</span>}
                    data={<span className={Classes.SKELETON}>—</span>}
                  />
                </Sidebar.Table>
              </Sidebar.Section>

              <Sidebar.Section title={translate('Payment status')}>
                <Callout intent={Intent.SUCCESS} className={Classes.SKELETON}>
                  ...
                </Callout>
              </Sidebar.Section>

              <Sidebar.Section title={translate('Todos')}>
                <table className="w-full-2 -m-1">
                  <tbody>
                    <tr>
                      <td className="p-1 w-px align-top">
                        <span className={Classes.SKELETON}>....</span>
                      </td>
                      <td className="p-1 w-px align-top">
                        <span className={Classes.SKELETON}>1x</span>
                      </td>
                      <td className="p-1 align-top">
                        <span className={Classes.SKELETON}>Bed klaarmaken</span>
                      </td>
                      <td className="p-1 whitespace-no-wrap align-top text-right">
                        <span className={Classes.SKELETON}>bij aankomst</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Sidebar.Section>
            </Sidebar.Content>
          </Sidebar>
        )}
      </Translation>
    );
  }
}
