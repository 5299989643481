import React, { PureComponent } from 'react';
import RentableIdentityHeader from '../rentable-identity-header';

interface RentableIdentityHeadersProps {
  rentableIdentities: Array<{
    id: string;
    name: string;
    priority: number;
    blockedForExternalReservations: boolean;
    blockingTodosCount: number;
    petsAllowed: boolean;
    url: string;
  }>;
}

export default class RentableIdentityHeaders extends PureComponent<RentableIdentityHeadersProps> {
  render() {
    return this.props.rentableIdentities.map((rentableIdentity) => (
      <RentableIdentityHeader key={rentableIdentity.id} rentableIdentity={rentableIdentity} />
    ));
  }
}
