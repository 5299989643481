import React from 'react';
import { Moment } from 'moment';
import Translation from '../translation';

interface LocaleDateProps {
  date: Moment | Date | string;
  format: string;
}

export default function LocaleDate({ date, format }: LocaleDateProps) {
  // @ts-ignore
  return <Translation>{(translate) => translate(`{{date, date | ${format}}}`, { date })}</Translation>;
}
