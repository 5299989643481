import React from 'react';
import Translation from '../translation';

export default function ErrorMessage() {
  return (
    <div className="bg-white p-2 h-full border-l border-b">
      <div className="text-red-900 bg-red-50 rounded-sm flex items-center p-2">
        <Translation>{(translate) => translate('Something went wrong while loading the rentals')}</Translation>
      </div>
    </div>
  );
}
