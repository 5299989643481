import React, { PureComponent } from 'react';
import classNames from 'classnames';
import classes from './AvailabilityFilterMarker.module.css';
import moment, { Moment } from 'moment';

interface AvailabilityFilterMarkerProps {
  date: string;
  isStart?: boolean;
  isEnd?: boolean;
  calculateOffset: (args: { date: Moment }) => number;
  onClick: (e: React.MouseEvent) => void;
}

export default class AvailabilityFilterMarker extends PureComponent<AvailabilityFilterMarkerProps> {
  render() {
    const { date, isStart, isEnd, calculateOffset, onClick } = this.props;

    const offset = calculateOffset({ date: moment(date).add(12, 'hour') });
    const style = isStart ? { left: 0, width: offset } : isEnd ? { left: offset, right: 0 } : {};

    const className = classNames(classes.root, {
      [classes['root--start']]: isStart,
      [classes['root--end']]: isEnd,
    });

    return <div className={className} style={style} title="Klik om te sluiten" onClick={onClick} />;
  }
}
