export default function deserializeObject(str: string): { [key: string]: string } {
  const initialObj: { [key: string]: string } = {};

  if (str === '') {
    return initialObj;
  }

  return str.split(' ').reduce((obj, entry: string) => {
    const [key, value] = entry.split(':');
    obj[key] = value;
    return obj;
  }, initialObj);
}
