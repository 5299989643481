import React, { PureComponent } from 'react';
import SidebarPanel from './SidebarPanel';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { State } from '../../reducers';
import * as actions from '../../actions/sidebarPanel';
import * as moveActions from '../../actions/moveAction';
// tslint:disable-next-line:no-duplicate-imports
import { SidebarType } from '../../actions/sidebarPanel';

// tslint:disable-next-line no-empty-interface
interface OwnProps {}

interface StateProps {
  isOpen: boolean;
  type?: SidebarType;
  id?: string;
}

interface DispatchProps {
  closeSidebarPanel: () => void;
  deactivateMoveAction: () => void;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State, _ownProps: OwnProps) => {
  return state.sidebarPanel;
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  closeSidebarPanel: actions.closeSidebarPanel,
  deactivateMoveAction: moveActions.deactivateMoveAction,
};

type SidebarPanelContainerProps = OwnProps & StateProps & DispatchProps;

class SidebarPanelContainer extends PureComponent<SidebarPanelContainerProps> {
  render() {
    return <SidebarPanel {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarPanelContainer);
