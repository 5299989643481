import {
  UNAUTHENTICATE_SESSION,
  UnauthenticateSession,
  UNAUTHORIZE_SESSION,
  UnauthorizeSession,
  SessionPhase,
  SessionData,
} from '../actions/session';

export interface SessionState {
  phase: SessionPhase;
  data?: SessionData;
}

export default function session(
  state = { phase: SessionPhase.Authenticated },
  action: UnauthenticateSession | UnauthorizeSession
): SessionState {
  switch (action.type) {
    case UNAUTHENTICATE_SESSION: {
      return { phase: SessionPhase.Unauthenticated, data: action.data };
    }
    case UNAUTHORIZE_SESSION: {
      return { phase: SessionPhase.Unauthorized, data: action.data };
    }

    default: {
      return state;
    }
  }
}
