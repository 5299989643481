import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { Tag } from '@blueprintjs/core';
import Translation from '../translation';
import calculateLabelStyle from '../../utils/calculateLabelStyle';

interface Label {
  id: string;
  name: string;
  backgroundColor: string;
  foregroundColor: string;
}

interface LabelSectionProps {
  labels: Label[];
}

export default class LabelSection extends Component<LabelSectionProps> {
  renderLabel(label: Label) {
    return (
      <Tag key={label.id} className="rounded-sm m-1 p-1" style={calculateLabelStyle(label)}>
        {label.name}
      </Tag>
    );
  }

  renderLabels() {
    const { labels } = this.props;

    return <div className="flex flex-wrap -m-1">{labels.map(this.renderLabel)}</div>;
  }

  renderEmpty() {
    return <Translation>{(translate) => <span className="italic text-bp4-gray3">{translate('No labels assigned')}</span>}</Translation>;
  }

  render() {
    const { length } = this.props.labels;

    return (
      <Translation>
        {(translate) => <Sidebar.Section title={translate('Labels')}>{length ? this.renderLabels() : this.renderEmpty()}</Sidebar.Section>}
      </Translation>
    );
  }
}
