import React, { PureComponent } from 'react';
import RentableIdentityHeader from './RentableIdentityHeader';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../actions/sidebarPanel';
import isSidebarOpened from '../../utils/isSidebarOpened';
import { State } from '../../reducers';
// tslint:disable-next-line:no-duplicate-imports
import { SidebarType } from '../../actions/sidebarPanel';

interface OwnProps {
  rentableIdentity: {
    id: string;
    name: string;
    priority: number;
    blockedForExternalReservations: boolean;
    blockingTodosCount: number;
    petsAllowed: boolean;
    url: string;
  };
  title?: string;
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { searchQuery } = state;
  return { isActive: isSidebarOpened(state.sidebarPanel, SidebarType.RentableIdentity, ownProps.rentableIdentity.id), searchQuery };
};

const mapDispatchToProps = {
  toggleSidebarPanel: actions.toggleSidebarPanel,
};

type RentableIdentityHeaderContainerProps = ConnectedProps<typeof connector> & OwnProps;

class RentableIdentityHeaderContainer extends PureComponent<RentableIdentityHeaderContainerProps> {
  handleClick = () => {
    const { rentableIdentity, toggleSidebarPanel } = this.props;
    toggleSidebarPanel(SidebarType.RentableIdentity, rentableIdentity.id);
  };

  render() {
    const { rentableIdentity, title, isActive, searchQuery } = this.props;

    return (
      <RentableIdentityHeader
        rentableIdentity={rentableIdentity}
        title={title}
        isActive={isActive}
        searchQuery={searchQuery}
        onClick={this.handleClick}
      />
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(RentableIdentityHeaderContainer);
