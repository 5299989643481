import React, { PureComponent } from 'react';
import classNames from 'classnames';
import classes from './AvailabilityFilterWeekDate.module.css';
import WeekDate from './WeekDate';
import { Moment } from 'moment';
import Translation from '../translation';

interface AvailabilityFilterWeekDateProps {
  date: string;
  holiday?: { name: string };
  isActive: boolean;
  onClick: (e: React.MouseEvent, date: Moment) => void;
}

export default class AvailabilityFilterWeekDate extends PureComponent<AvailabilityFilterWeekDateProps> {
  render() {
    const { date, holiday, isActive, ...props } = this.props;
    const className = classNames(classes.root, { [classes['root--active']]: isActive });

    const renderTooltip = () => {
      return (
        <Translation>
          {(translate) => (holiday ? holiday.name : translate(isActive ? 'Change/remove date' : 'Filter by availability'))}
        </Translation>
      );
    };

    return <WeekDate date={date} {...props} className={className} renderTooltip={renderTooltip} />;
  }
}
