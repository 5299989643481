import { SidebarType } from '../actions/sidebarPanel';

export default function serializeSidebarType(sidebarType: SidebarType) {
  switch (sidebarType) {
    case SidebarType.ReservationAgendaPeriod: {
      return 'reservation_agenda_periods';
    }
    case SidebarType.RentableIdentity: {
      return 'rentable_identities';
    }
    case SidebarType.IcalAgendaPeriod: {
      return 'ical_agenda_periods';
    }
    case SidebarType.MaintenanceAgendaPeriod: {
      return 'maintenance_agenda_periods';
    }
    case SidebarType.OwnerMaintenanceAgendaPeriod: {
      return 'owner_maintenance_agenda_periods';
    }
    case SidebarType.NotEnoughStockAgendaPeriod: {
      return 'not_enough_stock_agenda_periods';
    }
    case SidebarType.BlockedByGroupedRentableTypeAgendaPeriod: {
      return 'blocked_by_grouped_rentable_type_agenda_periods';
    }
  }
}
