import React, { PureComponent } from 'react';
import FilterPanelToggleButton from './FilterPanelToggleButton';
import * as actions from '../../actions/filterPanel';
import { MapDispatchToProps, connect, MapStateToProps } from 'react-redux';
import { State } from '../../reducers';

// tslint:disable-next-line no-empty-interface
interface OwnProps {}

interface StateProps {
  isOpen: boolean;
  numberOfActiveFilters: number;
}

interface DispatchProps {
  toggleFilterPanel: () => void;
  resetFilterPanel: () => void;
}

type FilterPanelToggleButtonContainerProps = OwnProps & StateProps & DispatchProps;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state, _ownProps) => {
  const {
    isOpen,
    selectedGuestGroup,
    selectedRentableSegmentIds,
    selectedRentableTypeIds,
    selectedRentableIdentityLabelIds,
    selectedAmenityIds,
  } = state.filterPanel;

  const numberOfActiveFilters =
    (selectedGuestGroup ? 1 : 0) +
    (selectedRentableSegmentIds.length ? 1 : 0) +
    (selectedRentableTypeIds.length ? 1 : 0) +
    (selectedRentableIdentityLabelIds.length ? 1 : 0) +
    (selectedAmenityIds.length ? 1 : 0);

  return { isOpen, numberOfActiveFilters };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, State> = {
  toggleFilterPanel: actions.toggleFilterPanel,
  resetFilterPanel: actions.resetFilterPanel,
};

class FilterPanelToggleButtonContainer extends PureComponent<FilterPanelToggleButtonContainerProps> {
  render() {
    const { isOpen, numberOfActiveFilters, toggleFilterPanel, resetFilterPanel } = this.props;

    return (
      <FilterPanelToggleButton
        isOpen={isOpen}
        numberOfActiveFilters={numberOfActiveFilters}
        onClick={toggleFilterPanel}
        onHotkey={toggleFilterPanel}
        onResetButtonClick={resetFilterPanel}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanelToggleButtonContainer);
