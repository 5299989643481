import React from 'react';
import Badge from '../badge';
import Translation from '../translation';

export default function BlockedByGroupedRentableTypeAgendaPeriodBadge() {
  return (
    <Translation>
      {(translate) => (
        <Badge className="blocked-by-grouped-rentable-type-agenda-period">{translate('Blocked by grouped rentableType')}</Badge>
      )}
    </Translation>
  );
}
