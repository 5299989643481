import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Translation from '../translation';

interface MiscellaneousSectionProps {
  rentableIdentity: {
    petsAllowed: boolean;
    blockedForExternalReservations: boolean;
  };
}

export default class MiscellaneousSection extends Component<MiscellaneousSectionProps> {
  renderPetsAllowedTableRow() {
    let { petsAllowed } = this.props.rentableIdentity;
    const icon = <FontAwesomeIcon icon={faDog} />;

    return (
      <Translation>
        {(translate) => (
          <Sidebar.TableRow
            icon={icon}
            header={translate('Pets allowed?')}
            data={petsAllowed ? translate('Allowed') : translate('Not allowed')}
          />
        )}
      </Translation>
    );
  }

  renderBlockedTableRow() {
    let { blockedForExternalReservations } = this.props.rentableIdentity;
    const icon = <FontAwesomeIcon icon={faEyeSlash} />;

    return (
      <Translation>
        {(translate) => (
          <Sidebar.TableRow
            icon={icon}
            header={translate('Accepts external reservations?')}
            data={!blockedForExternalReservations ? translate('Yes') : translate('No')}
          />
        )}
      </Translation>
    );
  }

  render() {
    return (
      <Translation>
        {(translate) => (
          <Sidebar.Section title={translate('Miscellaneous')}>
            <Sidebar.Table>
              {this.renderPetsAllowedTableRow()}
              {this.renderBlockedTableRow()}
            </Sidebar.Table>
          </Sidebar.Section>
        )}
      </Translation>
    );
  }
}
