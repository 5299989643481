import React, { PureComponent, CSSProperties, ReactNode } from 'react';
import classes from './EventLink.module.css';
import isRegularClick from '../../utils/isRegularClick';
import { DisplayMode } from '../../actions/displayMode';

interface EventLinkProps {
  url?: string;
  style: CSSProperties;
  isActive: boolean;
  displayMode: DisplayMode;
  renderItem: (props: { isActive: boolean; isIndented: boolean; displayMode: DisplayMode }) => ReactNode;
  onClick: () => void;
}

interface EventLinkState {
  isMouseOver: boolean;
}

export default class EventLink extends PureComponent<EventLinkProps, EventLinkState> {
  state: EventLinkState = { isMouseOver: false };

  handleMouseEnter = (e: React.MouseEvent) => {
    this.setState({ isMouseOver: true });
  };

  handleMouseLeave = (e: React.MouseEvent) => {
    this.setState({ isMouseOver: false });
  };

  // Stop this event from propagating to elements behind it (RentableCell)
  stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

  // tslint:disable-next-line member-ordering
  handleMouseMove = this.stopPropagation;

  // tslint:disable-next-line member-ordering
  handleMouseOver = this.stopPropagation;

  // tslint:disable-next-line member-ordering
  handleMouseOut = this.stopPropagation;

  // tslint:disable-next-line member-ordering
  handleMouseDown = this.stopPropagation;

  // tslint:disable-next-line member-ordering
  handleMouseUp = this.stopPropagation;

  handleClick = (e: React.MouseEvent) => {
    if (isRegularClick(e)) {
      e.preventDefault();
      this.props.onClick();
    }
  };

  render() {
    const { url, style, isActive, displayMode } = this.props;
    const { isMouseOver } = this.state;
    const isIndented = isMouseOver || isActive;

    return (
      <a
        href={url}
        style={style}
        className={classes.root}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.handleMouseMove}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onClick={this.handleClick}
      >
        {this.props.renderItem({ isActive, isIndented, displayMode })}
      </a>
    );
  }
}
