import React, { PureComponent } from 'react';
import classes from './RentableTypeCell.module.css';
import formatRentableType from '../../utils/formatRentableType';
import Translation from '../translation';

interface RentableTypeCellProps {
  rentableType: { name: string; reference: string };
  onMouseDown: (e: React.MouseEvent) => void;
}

export default class RentableTypeCell extends PureComponent<RentableTypeCellProps> {
  renderTitle() {
    return <span className={classes.title}>{formatRentableType(this.props.rentableType)}</span>;
  }

  render() {
    return (
      <Translation>
        {(translate) => (
          <header className={classes.root} onMouseDown={this.props.onMouseDown} title={translate('Grab to drag forward or backward')}>
            {this.renderTitle()}
          </header>
        )}
      </Translation>
    );
  }
}
