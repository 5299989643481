interface Reservation {
  booking: {
    referenceNr: string;
    bookingNr: string;
    firstName: string;
    lastName: string;
    company: string;
  };
}

export default function formatReservation(reservation: Reservation) {
  let titleParts = [];
  let { booking } = reservation;
  let { referenceNr, bookingNr, firstName, lastName, company } = booking;

  if (firstName) {
    titleParts.push(firstName);
  }
  if (lastName) {
    titleParts.push(lastName);
  }
  if (!firstName && !lastName && company) {
    titleParts.push(company);
  }
  if (!firstName && !lastName && referenceNr) {
    titleParts.push(referenceNr);
  }
  titleParts.push(bookingNr);

  return titleParts.join(' ');
}
