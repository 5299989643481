import React from 'react';
import IndexRoute from '../index-route';
import PlanboardRoute from '../planboard-route';
import NotFoundPage from '../not-found-page';
import UnauthenticatedPage from '../unauthenticated-page';
import UnauthorizedPage from '../unauthorized-page';
import { connect, MapStateToProps } from 'react-redux';
import { State } from '../../reducers';
import { pathToRegexp } from 'path-to-regexp';
import { SessionPhase } from '../../actions/session';
import { SessionState } from '../../reducers/session';

interface StateProps {
  pathname: string;
  session: SessionState;
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state) => {
  return { pathname: state.router.pathname, session: state.session };
};

type RouterProps = StateProps;

const ROUTES = [
  { path: process.env.PUBLIC_URL, render: () => <IndexRoute /> },
  { path: `${process.env.PUBLIC_URL}/parks/undefined`, render: () => <NotFoundPage /> },
  { path: `${process.env.PUBLIC_URL}/parks/:park_id`, render: ([parkId]: string[]) => <PlanboardRoute parkId={parkId} /> },
  {
    path: `${process.env.PUBLIC_URL}/parks/:park_id/:sidebar/:sidebar_id`,
    render: ([parkId]: string[]) => <PlanboardRoute parkId={parkId} />,
  },
];

function Router({ pathname, session }: RouterProps) {
  if (session.phase === SessionPhase.Unauthenticated && session.data !== undefined) {
    return <UnauthenticatedPage {...session.data} />;
  }

  if (session.phase === SessionPhase.Unauthorized && session.data !== undefined) {
    return <UnauthorizedPage {...session.data} />;
  }

  for (let route of ROUTES) {
    const regExp = pathToRegexp(route.path);
    const result = pathname.match(regExp);

    if (result !== null) {
      return route.render([...result.splice(1)]);
    }
  }

  return <NotFoundPage />;
}

export default connect(mapStateToProps)(Router);
