import { combineReducers } from 'redux';
import reservationSelection, { ReservationSelectionState } from './reservationSelection';
import moveAction, { MoveActionState } from './moveAction';
import sidebarPanel, { SidebarPanelState } from './sidebarPanel';
import filterPanel, { FilterPanelState } from './filterPanel';
import availabilityFilter, { AvailabilityFilterState } from './availabilityFilter';
import searchQuery, { SearchQueryState } from './searchQuery';
import displayMode, { DisplayModeState } from './displayMode';
import currentDate, { CurrentDateState } from './currentDate';
import planboardVersion, { PlanboardVersionState } from './planboardVersion';
import session, { SessionState } from './session';
import { routerReducer as router, State as RouterState } from 'redux-first-routing';
import bookingMode, { BookingModeState } from './bookingMode';

export interface State {
  availabilityFilter: AvailabilityFilterState;
  bookingMode: BookingModeState;
  currentDate: CurrentDateState;
  planboardVersion: PlanboardVersionState;
  displayMode: DisplayModeState;
  filterPanel: FilterPanelState;
  moveAction: MoveActionState;
  reservationSelection: ReservationSelectionState;
  searchQuery: SearchQueryState;
  session: SessionState;
  sidebarPanel: SidebarPanelState;
  router: RouterState;
}

export const reducer = combineReducers<State>({
  availabilityFilter,
  bookingMode,
  currentDate,
  planboardVersion,
  displayMode,
  filterPanel,
  moveAction,
  reservationSelection,
  searchQuery,
  session,
  sidebarPanel,
  router,
});
