import React, { PureComponent } from 'react';
import ReservationAgendaPeriodEventItem from './ReservationAgendaPeriodEventItem';
import { DisplayMode } from '../../actions/displayMode';
import { Booking } from '../../actions/bookingMode';

interface ReservationAgendaPeriodEventItemContainerProps {
  reservationAgendaPeriod: {
    id: string;
    startDate: string;
    endDate: string;

    reservation: {
      id: string;
      startDate: string;
      endDate: string;
      departureDate: string;
      lateCheckout: boolean;
      fixedRentable: boolean;

      booking: {
        id: string;
        referenceNr: string;
        source: string;
        isOption: boolean;
        stateDescription: string;
        bookingNr: string;
        firstName: string;
        lastName: string;
        company: string;

        channel: {
          name: string;
        };

        // Alerts display mode
        hasUninvoicedAmountForGuest?: boolean;
        isConfirmed?: boolean;
        isTemporary?: boolean;
        hasUnpaidAmountForGuest?: boolean;
        hasPastDeadlineAmountForGuest?: boolean;
      };

      // Alerts display mode
      hasUnfinishedTodos?: boolean;

      // Presence display mode fields
      checkedInOn?: string;
      checkedOutOn?: string;
      isCheckedIn?: boolean;
      isCheckedOut?: boolean;
    };
  };
  isActive: boolean;
  isIndented: boolean;
  displayMode: DisplayMode;
  activeBooking?: Booking;
}

export default class ReservationAgendaPeriodEventItemContainer extends PureComponent<ReservationAgendaPeriodEventItemContainerProps> {
  render() {
    const { reservationAgendaPeriod, isActive, isIndented, displayMode, activeBooking } = this.props;

    return (
      <ReservationAgendaPeriodEventItem
        reservationAgendaPeriod={reservationAgendaPeriod}
        isActive={isActive}
        isIndented={isIndented}
        displayMode={displayMode}
        activeBooking={activeBooking}
      />
    );
  }
}
