import React, { PureComponent, FormEvent } from 'react';
import { Button, InputGroup } from '@blueprintjs/core';
import Translation from '../translation';

interface SearchFieldProps {
  query: string;
  onChange: (value: string) => void;
  onReset: () => void;
}

export default class SearchField extends PureComponent<SearchFieldProps> {
  handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange(e.currentTarget.value);
  };

  render() {
    const { query, onReset } = this.props;
    const rightElement = query === '' ? <Button disabled minimal icon="search" /> : <Button minimal icon="cross" onClick={onReset} />;

    return (
      <Translation>
        {(translate) => (
          <InputGroup
            value={query}
            autoFocus
            type="search"
            placeholder={translate('Search by park name…')}
            onChange={this.handleChange}
            rightElement={rightElement}
          />
        )}
      </Translation>
    );
  }
}
