import React, { PureComponent } from 'react';
import { setHotkeysDialogProps } from '@blueprintjs/core';

import { withTranslation, WithTranslation } from '../translation';

interface SettingsProviderProps {
  children: React.ReactNode;
}

class SettingsProvider extends PureComponent<SettingsProviderProps & WithTranslation> {
  componentDidMount() {
    this.updateHotkeysDialogProps();
  }

  componentDidUpdate() {
    this.updateHotkeysDialogProps();
  }

  updateHotkeysDialogProps() {
    setHotkeysDialogProps({
      title: this.props.t('Hotkeys'),
      globalHotkeysGroup: this.props.t('Planboard'),
    });
  }

  render() {
    return this.props.children;
  }
}

export default withTranslation()(SettingsProvider);
